import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MailIcon from '@mui/icons-material/Mail';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material';
import FormHeader from 'components/FormHeader';
import {
  GroupResponse,
  OrganisationResponse,
  PractitionerData,
  RequestError,
  useGetPractitionerByGroupAndEmail,
} from 'api';
import { InviteUserOrgOptions, LicensedProduct } from 'types/types';
import { useAuth } from 'contexts/AuthContext';
import { useInviteContext } from 'pages/AddUser/InviteProvider';
import { VALID_EMAIL_REGEX } from 'variables';
import UserSelect from 'components/UserSelect';
import useGetProducts from 'components/hooks/useGetProducts';
import { useSubmitUser } from './useSubmitUser';
import { AddUserFormProps, FormData, ROLE_MAP } from './types';
import { OrgsAndDetailsSection } from './OrgsAndDetailsSection/OrgsAndDetailsSections';

type ErrorSnackBarProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  text: string;
};

const StyledMailIcon = styled(MailIcon)(({ theme }) => ({
  fill: theme.palette.blue.main,
  height: '20px',
}));

const ErrorSnackBar = ({ open, onClose, text, title }: ErrorSnackBarProps) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Alert elevation={6} variant="filled" severity="error" onClose={onClose}>
      <AlertTitle>{title}</AlertTitle>
      {text}
    </Alert>
  </Snackbar>
);

const isGeneralError = (isError: boolean, error: any): boolean =>
  isError && !(error instanceof RequestError);

const AddUserForm = ({
  mutate,
  resetMutation,
  inviteUserError,
  isInviteUserError,
  currentOrgId,
  currentGroupId,
  initialPractitioner,
  supportedRoles,
}: AddUserFormProps) => {
  const products = useGetProducts();
  const preselectedProducts = Object.entries(products)
    .filter(([, options]) => options.defaultSelected)
    .map(([product]) => product as LicensedProduct);

  const [preselectedPractitioner, setPreselectedPractitioner] =
    useState<PractitionerData | null>(initialPractitioner ?? null);
  const {
    currentGroup,
    groups,
    practitionerOrganisations,
    organisationsInGroups,
    formState,
    setFormState,
  } = useInviteContext();

  const { selectedGroup, selectedOrgs } = formState;

  const { t } = useTranslation();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setValue,
    watch,
    control,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      organisationIds: [],
      licensedProducts: preselectedProducts,
      roles: ROLE_MAP.STANDARD,
      emailAddress: undefined,
      jobTitle: '',
    },
  });

  register('roles', { required: true });
  register('emailAddress', { required: true, pattern: VALID_EMAIL_REGEX });
  const formEmailAddress = watch('emailAddress');

  const setSelectedOrgs = (org: any) => {
    if (org?.id) {
      setFormState({ selectedOrgs: [...(formState.selectedOrgs ?? []), org] });
      setValue('licensedProducts', preselectedProducts);
    }

    if (org === null) {
      setFormState({ selectedOrgs: [] });
      setFormState({ permissionRole: 'STANDARD' });
      setValue('licensedProducts', []);
    }
  };
  const onRemoveOrg = (id: string) => {
    const filteredOrgsById =
      formState?.selectedOrgs?.filter(
        (org: OrganisationResponse) => org?.id !== id,
      ) ?? [];
    setFormState({ selectedOrgs: filteredOrgsById });

    if (filteredOrgsById.length === 0) {
      setFormState({ permissionRole: 'STANDARD' });
      setValue('licensedProducts', []);
    }
  };

  const {
    data: rawExistingPractitioner,
    isPending: emailPending,
    error: existingPractitionerError,
  } = useGetPractitionerByGroupAndEmail(
    currentGroupId,
    formEmailAddress,
    user!.permissions.Add_User_to_Partner_Organisation === 'Global' &&
      !preselectedPractitioner,
  );

  const existingPractitioner =
    preselectedPractitioner || rawExistingPractitioner;

  const userAlreadyHasAccess = useMemo(
    () =>
      !!practitionerOrganisations.find((item) => {
        if ((item as GroupResponse).organisations) {
          return !!(item as GroupResponse).organisations.find(
            ({ id: orgId }) => currentOrgId === orgId,
          );
        }
        return currentOrgId === item.id;
      }),
    [currentOrgId, practitionerOrganisations],
  );

  const { onSubmit } = useSubmitUser(
    selectedGroup,
    existingPractitioner,
    selectedOrgs,
    organisationsInGroups,
    handleSubmit,
    mutate,
  );

  const resetFormValues = () => {
    setFormState({
      permissionRole: 'STANDARD',
      orgType: InviteUserOrgOptions.ONE_ORG,
    });
    setSelectedOrgs(null);
  };

  const emailValidationError =
    !!existingPractitionerError &&
    existingPractitionerError instanceof RequestError &&
    existingPractitionerError.errorCodes.find(
      (code) => code === 'INVALID_EMAIL_ADDRESS',
    );

  useEffect(() => {
    if (existingPractitioner) {
      if (!currentGroup && groups && currentGroupId) {
        setFormState({
          currentGroup: groups.find(({ id }) => id === currentGroupId),
        });
      }
      if (!formEmailAddress && preselectedPractitioner) {
        setValue('emailAddress', preselectedPractitioner.emailAddress);
      }
      setValue('licensedProducts', existingPractitioner.licensedProducts);
      setValue('roles', existingPractitioner.roles);
      setValue('jobTitle', existingPractitioner.jobTitle);
    } else {
      setValue('licensedProducts', [...getValues('licensedProducts')]);
      setValue('roles', ROLE_MAP[formState?.permissionRole ?? 'STANDARD']);
      setValue('jobTitle', '');
    }
    setFormState({ existingPractitioner });
  }, [
    existingPractitioner,
    setValue,
    getValues,
    formEmailAddress,
    currentGroup,
    groups,
    currentGroupId,
    formState?.permissionRole,
    preselectedPractitioner,
    setFormState,
  ]);

  return (
    <form noValidate data-testid="add-user-form-v2" onSubmit={onSubmit}>
      <FormHeader
        icon={StyledMailIcon}
        title={t('ui.event-management.users.invite.check-email')}
        color="blue"
      />
      <UserSelect
        error={existingPractitionerError}
        formErrors={formErrors}
        emailValue={formEmailAddress}
        onEmailSelected={(emailAddress) => {
          setValue('emailAddress', emailAddress);
          resetFormValues();
        }}
        onClear={() => {
          setValue('emailAddress', undefined);
          setPreselectedPractitioner(null);
        }}
        userAlreadyHasAccess={userAlreadyHasAccess}
      />
      {!emailValidationError ? (
        <OrgsAndDetailsSection
          formEmailAddress={formEmailAddress}
          selectedOrgs={selectedOrgs}
          setSelectedOrgs={setSelectedOrgs}
          onRemoveOrg={onRemoveOrg}
          control={control}
          setValue={setValue}
          currentOrgId={currentOrgId}
          emailLoading={preselectedPractitioner ? false : emailPending}
          userAlreadyHasAccess={userAlreadyHasAccess}
          supportedRoles={supportedRoles}
        />
      ) : null}
      <ErrorSnackBar
        open={isGeneralError(isInviteUserError, inviteUserError)}
        onClose={resetMutation}
        title={t('ui.event-management.users.create.error.title')}
        text={t('ui.event-management.users.create.error.details')}
      />
    </form>
  );
};

export default AddUserForm;
