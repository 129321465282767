import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormHeader from 'components/FormHeader';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import { Span } from '@insights-ltd/design-library/components';
import { PractitionerData } from 'api';
import {
  getRoleDescription,
  isAdvancedUser,
  isGroupManager,
  isSuperAdmin,
} from 'utils/role';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, styled } from '@mui/material';
import AccountOrganisationCard from 'components/OrganisationInfoCard/AccountOrganisationCard';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { Role } from 'types/types';

const StyledDiv = styled('div')({
  '& > div': {
    marginBottom: '0.5rem !important',
  },
});

const StyledSpan = styled(Span)(({ theme }) => ({
  fontWeight: `${theme.typography.fontWeightBold} !important`,
}));

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  display: 'inline-block',
}));

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  fill: theme.palette.green.main,
  height: '20px',
}));

const DetailsDescription = ({
  roles,
  id,
  organisationId,
  organisationCount,
  groupCount,
}: {
  roles: Role[];
  id: string;
  organisationId: string;
  organisationCount: number;
  groupCount: number;
}) => {
  const { t } = useTranslation();

  const isAdmin = isSuperAdmin(roles);

  const getOrganisationsText = (): string => {
    const stringArr: string[] = [];

    if (!isAdvancedUser(roles)) {
      if (organisationCount > 0) {
        stringArr.push(
          t(
            'ui.event-management.users.add-existing-practitioner.current-organisations.descriptions.organisations',
            { count: organisationCount },
          ),
        );
      }
      if (groupCount > 0) {
        stringArr.push(
          t(
            'ui.event-management.users.add-existing-practitioner.current-organisations.descriptions.groups',
            { count: groupCount },
          ),
        );
      }
    } else if (organisationCount > 0) {
      stringArr.push(
        t(
          'ui.event-management.users.add-existing-practitioner.current-organisations.descriptions.organisations',
          { count: organisationCount },
        ),
      );
    }

    return stringArr.join(', ');
  };

  const organisationsText = getOrganisationsText();

  const userDetailUrlKey = isGroupManager(roles)
    ? 'ui.event-management.users.add-existing-practitioner.current-organisations.descriptions.link.gm'
    : 'ui.event-management.users.add-existing-practitioner.current-organisations.descriptions.link';
  const userDetailUrl = roles.includes('GROUP_MANAGER')
    ? `/organisations/${organisationId}/practitioners/${id}/edit`
    : `/organisations/${organisationId}/practitioners/${id}`;
  const role = t(getRoleDescription(roles ?? []));

  return (
    <Grid
      container
      flexDirection="column"
      sx={(theme) => ({
        marginBottom: '1.5rem',
        color: theme.palette.text.secondary,
      })}
    >
      <Grid item sx={{ lineHeight: '1.75rem' }}>
        <Trans
          i18nKey="ui.event-management.users.add-existing-practitioner.current-organisations.descriptions.user-role"
          values={{ role }}
          components={{ '1': <StyledSpan color="textSecondary" /> }}
        />
      </Grid>
      <Grid item sx={{ lineHeight: '1.75rem' }}>
        <Trans
          i18nKey="ui.event-management.users.add-existing-practitioner.current-organisations.descriptions.has-access-to"
          values={{ organisationsText }}
          components={{ '1': <StyledSpan color="textSecondary" /> }}
        />
      </Grid>
      <Box mt="1.75rem" />
      <Grid item>
        {!isAdmin ? (
          <Trans
            i18nKey={userDetailUrlKey}
            components={{
              '1': (
                <StyledLink
                  to={userDetailUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

const ExistingUserDetails = ({
  user,
  organisations,
  orgsInGroups,
  groupCount = 0,
  organisationCount = 0,
}: {
  user?: PractitionerData;
  organisations: any[];
  orgsInGroups: GroupOrganisationHashMap;
  groupCount?: number;
  organisationCount?: number;
}) => {
  const { t } = useTranslation();
  const organisationCards = organisations.map((organisation: any, index) => {
    return (
      <AccountOrganisationCard
        parent={orgsInGroups[organisation.id]}
        key={organisation.id}
        id={organisation.id}
        orgCount={organisation?.organisations?.length}
        name={organisation.name}
        type={organisation?.type ? 'Group' : 'Organisation'}
        groupType={organisation?.type}
        sx={{
          paddingBottom: '1rem !important',
          paddingRight: index % 2 === 0 ? '1rem !important' : '0',
        }}
      />
    );
  });

  const orgId = organisations[0]?.organisations
    ? organisations[0]?.organisations[0]?.id
    : organisations[0]?.id;

  return (
    <StyledDiv
      sx={{
        '& > div:first-of-type': {
          marginTop: '40px',
        },
      }}
      data-testid="existing-user-details"
    >
      <FormHeader
        title={t(
          'ui.event-management.users.add-existing-practitioner.current-organisations.title',
        )}
        icon={StyledOrganisationIcon}
        color="green"
      />
      <DetailsDescription
        roles={user?.roles ?? []}
        id={user?.id ?? ''}
        groupCount={groupCount}
        organisationCount={organisationCount}
        organisationId={orgId ?? ''}
      />
      <Grid container sx={{ marginTop: '1.5rem' }}>
        {organisationCards}
      </Grid>
    </StyledDiv>
  );
};

export default ExistingUserDetails;
