import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgLearners = ({ title, ...props }: SvgComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    {title ? <title>{title}</title> : null}
    <rect width={40} height={40} fill="#F7F9E6" rx={4} />
    <g fill="#aab517">
      <path d="M21.059 18.925a.198.198 0 0 1-.014-.37 2.542 2.542 0 1 0-1.989 0 .198.198 0 0 1-.013.37 3.133 3.133 0 0 0-2.12 2.962v2.346a.391.391 0 0 0 .39.392h.698a.195.195 0 0 1 .195.18l.282 3.373a.39.39 0 0 0 .39.358h2.346a.39.39 0 0 0 .39-.358l.281-3.374a.195.195 0 0 1 .195-.18h.699a.391.391 0 0 0 .39-.39v-2.347a3.133 3.133 0 0 0-2.12-2.962zM14.214 13.991a.198.198 0 0 1-.018-.365 2.151 2.151 0 1 0-1.821 0 .198.198 0 0 1-.017.365 2.743 2.743 0 0 0-1.811 2.575v1.175a.396.396 0 0 0 .391.39h.649a.195.195 0 0 1 .194.174l.332 3a.391.391 0 0 0 .39.347h1.563a.391.391 0 0 0 .39-.347l.333-3a.195.195 0 0 1 .194-.173h.648a.391.391 0 0 0 .39-.391v-1.176a2.74 2.74 0 0 0-1.807-2.574ZM27.746 13.991a.198.198 0 0 1-.017-.365 2.151 2.151 0 1 0-1.821 0 .198.198 0 0 1 .113.189.196.196 0 0 1-.13.176 2.741 2.741 0 0 0-1.809 2.575v1.175a.396.396 0 0 0 .391.39h.647a.195.195 0 0 1 .194.174l.333 3a.391.391 0 0 0 .389.347H27.6a.391.391 0 0 0 .389-.347l.333-3a.195.195 0 0 1 .194-.173h.648a.391.391 0 0 0 .39-.391v-1.176a2.74 2.74 0 0 0-1.808-2.574z" />
      <path d="M21.059 18.925a.198.198 0 0 1-.014-.37 2.542 2.542 0 1 0-1.989 0 .198.198 0 0 1-.013.37 3.133 3.133 0 0 0-2.12 2.962v2.346a.391.391 0 0 0 .39.392h.698a.195.195 0 0 1 .195.18l.282 3.373a.39.39 0 0 0 .39.358h2.346a.39.39 0 0 0 .39-.358l.281-3.374a.195.195 0 0 1 .195-.18h.699a.391.391 0 0 0 .39-.39v-2.347a3.133 3.133 0 0 0-2.12-2.962zM14.214 13.991a.198.198 0 0 1-.018-.365 2.151 2.151 0 1 0-1.821 0 .198.198 0 0 1-.017.365 2.743 2.743 0 0 0-1.811 2.575v1.175a.396.396 0 0 0 .391.39h.649a.195.195 0 0 1 .194.174l.332 3a.391.391 0 0 0 .39.347h1.563a.391.391 0 0 0 .39-.347l.333-3a.195.195 0 0 1 .194-.173h.648a.391.391 0 0 0 .39-.391v-1.176a2.74 2.74 0 0 0-1.807-2.574ZM27.746 13.991a.198.198 0 0 1-.017-.365 2.151 2.151 0 1 0-1.821 0 .198.198 0 0 1 .113.189.196.196 0 0 1-.13.176 2.741 2.741 0 0 0-1.809 2.575v1.175a.396.396 0 0 0 .391.39h.647a.195.195 0 0 1 .194.174l.333 3a.391.391 0 0 0 .389.347H27.6a.391.391 0 0 0 .389-.347l.333-3a.195.195 0 0 1 .194-.173h.648a.391.391 0 0 0 .39-.391v-1.176a2.74 2.74 0 0 0-1.808-2.574z" />
    </g>
  </svg>
);

export default SvgLearners;
