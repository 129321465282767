import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { P } from '@insights-ltd/design-library/components';
import { dateInPast } from '@insights-ltd/design-library/utils';
import ExperienceList from 'components/EventsList/EventsList';
import { EventResponse } from 'api';
import { sortCancelledExperiences } from 'utils/mappers/sorting';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type ExperienceViewType = 'active' | 'archived' | 'cancelled';

type LearnerExperiencesProps = {
  events: EventResponse[];
  showOrgs?: boolean;
};

const StyledIcon = styled(CalendarIcon)(({ theme: th }) => ({
  width: '20px',
  height: '20px',
  fill: th.palette.common.black,
  color: th.palette.text.primary,
}));

const StyledDiv = styled('div')(({ theme }) => ({
  '> :first-of-type': {
    marginBottom: theme.spacing(spacingSizeMap.XS),
  },
  '& > * + *': {
    marginBottom: theme.spacing(spacingSizeMap.M),
  },
}));

const LearnerExperiences = ({ events, showOrgs }: LearnerExperiencesProps) => {
  const { t } = useTranslation();

  const [experiencesView, setExperiencesView] =
    useState<ExperienceViewType>('active');

  const tabbedEvents: Record<ExperienceViewType, EventResponse[]> = {
    active: events.filter(
      ({ endsAt, timezone, eventStatus }) =>
        !dateInPast(endsAt, timezone) && eventStatus === 'ACTIVE',
    ),
    archived: events.filter(
      ({ endsAt, timezone, eventStatus }) =>
        dateInPast(endsAt, timezone) && eventStatus === 'ACTIVE',
    ),
    cancelled: sortCancelledExperiences(
      events.filter(({ eventStatus }) => eventStatus === 'CANCELLED'),
    ),
  };

  const filteredEvents = tabbedEvents[experiencesView];

  const translations: Record<ExperienceViewType, string> = {
    cancelled: 'ui.event-management.learner.experiences.cancelled.no-results',
    archived: 'ui.event-management.learner.experiences.past.no-results',
    active: 'ui.event-management.learner.experiences.upcoming.no-results',
  };

  return (
    <Grid item container spacing={2}>
      <Grid
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
        item
        xs={12}
        container
      >
        <StyledIcon />
        <Box mr={(theme) => theme.spacing(spacingSizeMap.S)} />
        <P variant="body-large">
          {t('ui.event-management.learner.experiences')}
        </P>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={experiencesView}
            onChange={(_, value) => setExperiencesView(value)}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              label={t('ui.event-management.learner.upcoming-events-tab', {
                count: tabbedEvents.active.length,
              })}
              value="active"
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: { minWidth: '160px' },
              })}
            />
            <Tab
              label={t('ui.event-management.learner.past-events-tab', {
                count: tabbedEvents.archived.length,
              })}
              value="archived"
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: { minWidth: '160px' },
              })}
            />
            <Tab
              label={t('ui.event-management.learner.cancelled-events-tab', {
                count: tabbedEvents.cancelled.length,
              })}
              value="cancelled"
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: { minWidth: '160px' },
              })}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <StyledDiv>
            <ExperienceList
              events={filteredEvents}
              showOrgs={showOrgs}
              experienceView={experiencesView}
              noResultsText={t(translations[experiencesView])}
            />
          </StyledDiv>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LearnerExperiences;
