import React, { useMemo, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { User, useAuth } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { InviteUserOrgOptions } from 'types/types';
import AddUserOptions from 'pages/AddUser/AddUserForm/AddUserOptions/AddUserOptions';
import ExistingUserDetails from 'pages/AddUser/AddUserForm/ExistingUserDetails/ExistingUserDetails';
import {
  getRoleOptions,
  getUserOptions,
  updateCurrentOrg,
} from 'pages/AddUser/AddUserForm/helper';
import UserDetailsSection from 'pages/AddUser/AddUserForm/UserDetailsSection/UserDetailsSection';
import { OrganisationResponse, OrganisationSupportedRole } from 'api';
import { PermissionRole } from 'pages/AddUser/AddUserForm/types';
import { isGroupManager } from 'utils/role';
import { useInviteContext } from 'pages/AddUser/InviteProvider';

const filterPreExistingOrganisations =
  (organisations: OrganisationResponse[]) =>
  ({ id }: OrganisationResponse) =>
    !organisations.some(({ id: pOrgId }) => id === pOrgId);

type SectionProps = {
  user?: User;
  selectedOrgs: OrganisationResponse[];
  organisations: OrganisationResponse[];
  onRemoveOrg: (id: string) => void;
  groupOptions: OrganisationResponse[];
  setSelectedOrgs: (org: any) => void;
};

type InviteSectionProps = SectionProps & {
  formEmailAddress?: string;
  setValue: any;
  control: any;
  supportedRoles: OrganisationSupportedRole[];
};

type ExistingUserSectionProps = SectionProps;

const InviteUserSection = ({
  user,
  selectedOrgs,
  organisations,
  onRemoveOrg,
  groupOptions,
  formEmailAddress,
  setValue,
  control,
  setSelectedOrgs,
  supportedRoles,
}: InviteSectionProps) => {
  const { t } = useTranslation();
  const {
    groups,
    organisationsInGroups,
    setFormState,
    formState: { permissionRole, selectedGroup, orgType, existingPractitioner },
  } = useInviteContext();
  const roleOptions = getRoleOptions(
    t,
    selectedOrgs ?? [],
    supportedRoles ?? [],
  );
  return (
    <>
      <AddUserOptions
        selectedOrgs={selectedOrgs}
        setSelectedOrgs={(orgs) => {
          setSelectedOrgs(orgs);
        }}
        setSelectedGroup={(group) => {
          setFormState({ selectedGroup: group });
        }}
        selectedGroup={selectedGroup}
        orgType={orgType}
        setOrgType={(type) => {
          setFormState({ orgType: type });
        }}
        organisations={organisations}
        groups={groups}
        organisationsInGroups={organisationsInGroups}
        onRemoveOrg={onRemoveOrg}
        options={getUserOptions(
          t,
          existingPractitioner!,
          isGroupManager(user?.roles),
        )}
        groupOptions={groupOptions}
        existingUser={false}
      />
      {selectedOrgs?.length > 0
        ? (orgType === InviteUserOrgOptions.ONE_ORG ||
            InviteUserOrgOptions.MULTI_ORG) && (
            <UserDetailsSection
              existingPractitioner={existingPractitioner}
              email={formEmailAddress ?? ''}
              control={control}
              permissionRole={permissionRole}
              setPermissionRole={(role: PermissionRole) => {
                setFormState({ permissionRole: role });
              }}
              setValue={setValue}
              roleOptions={roleOptions}
            />
          )
        : null}
    </>
  );
};

const ExistingUserSection = ({
  user,
  selectedOrgs,
  organisations,
  onRemoveOrg,
  groupOptions,
  setSelectedOrgs,
}: ExistingUserSectionProps) => {
  const { t } = useTranslation();
  const {
    practitionerOrganisations,
    rawPractitionerOrganisations,
    practitionerOrgsInGroups,
    practitionerGroupCount,
    practitionerOrgCount,
    groups,
    organisationsInGroups,
    setFormState,
    formState: { selectedGroup, orgType, existingPractitioner },
  } = useInviteContext();
  return (
    <>
      <ExistingUserDetails
        user={existingPractitioner!}
        organisations={
          existingPractitioner?.roles.includes('GROUP_MANAGER')
            ? practitionerOrganisations ?? []
            : rawPractitionerOrganisations
        }
        orgsInGroups={
          existingPractitioner?.roles.includes('ADVANCED_USER')
            ? practitionerOrgsInGroups
            : {}
        }
        groupCount={
          existingPractitioner?.roles.includes('ADVANCED_USER')
            ? practitionerGroupCount
            : 0
        }
        organisationCount={practitionerOrgCount}
      />
      <AddUserOptions
        selectedOrgs={selectedOrgs}
        setSelectedOrgs={setSelectedOrgs}
        setSelectedGroup={(group) => {
          setFormState({ selectedGroup: group });
        }}
        selectedGroup={selectedGroup}
        orgType={orgType}
        setOrgType={(type) => {
          setFormState({ orgType: type });
        }}
        organisations={organisations}
        groups={groups}
        organisationsInGroups={organisationsInGroups}
        onRemoveOrg={onRemoveOrg}
        existingUser
        titleKey="ui.event-management.users.add-existing-practitioner.new-organisations.title"
        options={getUserOptions(
          t,
          existingPractitioner!,
          isGroupManager(user?.roles),
        )}
        groupOptions={groupOptions}
      />
      {selectedOrgs.length ? (
        <Grid container justifyContent="flex-end" mt="2.5rem">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            sx={{
              width: '252px',
              padding: '10px 20px',
            }}
          >
            {t(
              'ui.event-management.users.add-existing-practitioner.new-organisations.add-user',
            )}
          </Button>
        </Grid>
      ) : null}
    </>
  );
};

type OrgsAndDetailsSectionProps = {
  formEmailAddress?: string;
  selectedOrgs: OrganisationResponse[];
  setSelectedOrgs: (org: any) => void;
  onRemoveOrg: (id: string) => void;
  control: any;
  setValue: (...args: any) => void;
  userAlreadyHasAccess?: boolean;
  currentOrgId: string;
  emailLoading: boolean;
  supportedRoles: OrganisationSupportedRole[];
};

export const OrgsAndDetailsSection = ({
  formEmailAddress,
  selectedOrgs,
  setSelectedOrgs,
  onRemoveOrg,
  control,
  setValue,
  currentOrgId,
  emailLoading,
  userAlreadyHasAccess,
  supportedRoles,
}: OrgsAndDetailsSectionProps) => {
  const { user } = useAuth();
  const [ignoreState, setIgnoreState] = React.useState(false);
  const {
    rawOrganisations,
    practitionerOrganisations,
    rawPractitionerOrganisations,
    isPending,
    canInviteCurrentPractitioner,
    formState,
    setFormState,
  } = useInviteContext();

  const { selectedGroup, currentOrg, orgType, existingPractitioner } =
    formState;
  const canInvitePractitioner =
    !existingPractitioner &&
    user?.permissions.Organisation_InvitePractitioner === 'Global';
  const canAddPractitioner =
    existingPractitioner &&
    user?.permissions.Organisation_Add_User === 'Global';
  const hideComponent =
    (isPending && existingPractitioner) ||
    !formEmailAddress ||
    emailLoading ||
    !canInviteCurrentPractitioner ||
    userAlreadyHasAccess ||
    existingPractitioner?.errors;

  const organisations = useMemo(
    () =>
      rawOrganisations.filter(
        filterPreExistingOrganisations(rawPractitionerOrganisations),
      ),
    [rawOrganisations, rawPractitionerOrganisations],
  );
  const groupOptions =
    selectedGroup?.organisations.filter(
      filterPreExistingOrganisations(rawPractitionerOrganisations),
    ) ?? [];

  const commonProps: InviteSectionProps & ExistingUserSectionProps = {
    user,
    selectedOrgs,
    organisations,
    onRemoveOrg,
    groupOptions,
    formEmailAddress,
    setValue,
    control,
    setSelectedOrgs: (org) => {
      setIgnoreState(orgType === 'ONE_ORG');
      setSelectedOrgs(org);
    },
    supportedRoles,
  };

  useEffect(() => {
    if (
      formEmailAddress &&
      !emailLoading &&
      selectedOrgs?.length < 1 &&
      orgType === 'ONE_ORG' &&
      !practitionerOrganisations?.find(({ id }) => currentOrgId === id)
    ) {
      if (!ignoreState) {
        updateCurrentOrg(
          organisations,
          (organisation) => {
            setFormState({
              currentOrg: organisation,
              selectedGroup: null,
            });
            setSelectedOrgs(organisation);
          },
          currentOrgId,
          practitionerOrganisations,
        );
      }
    }

    if (
      practitionerOrganisations?.find(({ id }) => currentOrgId === id) &&
      selectedOrgs?.find(({ id }) => currentOrgId === id)
    ) {
      setFormState({ currentOrg: undefined });
      setSelectedOrgs(null);
    }
  }, [
    currentOrg,
    formEmailAddress,
    currentOrgId,
    organisations,
    practitionerOrganisations,
    existingPractitioner,
    setSelectedOrgs,
    selectedOrgs,
    emailLoading,
    setFormState,
    orgType,
    ignoreState,
  ]);

  if (hideComponent) {
    return null;
  }

  if (existingPractitioner && canAddPractitioner) {
    return <ExistingUserSection {...commonProps} />;
  }

  if (!existingPractitioner && canInvitePractitioner) {
    return <InviteUserSection {...commonProps} />;
  }

  return null;
};
