type Column =
  | 'numbers'
  | 'initials'
  | 'name'
  | 'position'
  | 'organisation'
  | 'colourEnergies';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type ColumnMap = Optional<Record<Column, number>, 'numbers' | 'organisation'>;

const getTableXCoordinates = (
  showNumbers: boolean,
  largestSvgStringPixelLength: number,
  isMultiOrg: boolean = false,
): { columns: ColumnMap; lengthRemoved: number } => {
  const columns: ColumnMap = isMultiOrg
    ? {
        initials: 0,
        name: 0,
        position: 0,
        organisation: 0,
        colourEnergies: 0,
      }
    : {
        initials: 0,
        name: 0,
        position: 0,
        colourEnergies: 0,
      };

  const columnLengths: Record<Column, number> = isMultiOrg
    ? {
        numbers: 20,
        initials: 40,
        name: largestSvgStringPixelLength * 0.75,
        organisation: 50,
        position: 50,
        colourEnergies: 57,
      }
    : {
        numbers: 30,
        initials: 40,
        name: largestSvgStringPixelLength,
        position: 57,
        colourEnergies: 50,
        organisation: 0,
      };
  let lengthRemoved = 0;

  if (showNumbers) {
    columns.numbers = 5;
    columns.initials = columns.numbers + columnLengths.numbers;
  } else {
    lengthRemoved = columnLengths.numbers;
    columns.initials = 5;
  }

  columns.name = columns.initials! + columnLengths.initials;
  columns.position = columns.name + columnLengths.name;

  if (isMultiOrg) {
    columns.organisation = columns.position + columnLengths.position;
    columns.colourEnergies = columns.organisation + columnLengths.organisation;
  } else {
    columns.colourEnergies = columns.position + columnLengths.position;
  }

  return { columns, lengthRemoved };
};

export default getTableXCoordinates;
