import React from 'react';
import { useTranslation } from 'react-i18next';
import EventDetailsIcon from '@mui/icons-material/DateRange';
import FormHeader from 'components/FormHeader';
import { EventDetailsForm } from 'components/EventForms';
import FormNavigation from './FormNavigation';
import { StepProps } from './types';

const EventDetails = ({ onBack, onNext, event, updateEvent }: StepProps) => {
  const { t } = useTranslation();

  const onSubmit = (formData: any) => {
    updateEvent(formData);
    onNext();
  };

  return (
    <>
      <FormHeader
        icon={EventDetailsIcon}
        title={t('ui.event-management.events.create.event-details')}
      />
      <EventDetailsForm
        event={event}
        onSubmit={onSubmit}
        invitedCount={0}
        isCreating
      >
        <FormNavigation onBack={onBack} />
      </EventDetailsForm>
    </>
  );
};

export default EventDetails;
