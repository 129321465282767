import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgUsers = ({ title, ...props }: SvgComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    {title ? <title>{title}</title> : null}
    <g fill="#0e567b">
      <path d="M13.273 11.866a.25.25 0 0 1-.017-.467 3.21 3.21 0 1 0-2.512 0 .25.25 0 0 1-.017.467 3.956 3.956 0 0 0-2.677 3.739v2.963a.494.494 0 0 0 .493.494h.882a.246.246 0 0 1 .246.227l.356 4.259a.493.493 0 0 0 .492.452h2.962a.493.493 0 0 0 .492-.452l.356-4.259a.246.246 0 0 1 .246-.227h.882a.494.494 0 0 0 .493-.494v-2.963a3.956 3.956 0 0 0-2.677-3.739ZM4.63 5.636a.25.25 0 0 1-.022-.461 2.716 2.716 0 1 0-2.3 0 .25.25 0 0 1-.021.461A3.463 3.463 0 0 0 0 8.887v1.483a.5.5 0 0 0 .494.494h.819a.246.246 0 0 1 .245.219l.42 3.787a.494.494 0 0 0 .491.439h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.246.246 0 0 1 .245-.219h.818a.494.494 0 0 0 .494-.494V8.886a3.46 3.46 0 0 0-2.283-3.25ZM21.716 5.636a.25.25 0 0 1-.021-.461 2.716 2.716 0 1 0-2.3 0 .25.25 0 0 1 .143.238.247.247 0 0 1-.164.223 3.461 3.461 0 0 0-2.284 3.251v1.483a.5.5 0 0 0 .494.494h.816a.246.246 0 0 1 .246.219l.42 3.787a.494.494 0 0 0 .491.439h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.246.246 0 0 1 .245-.219h.818A.494.494 0 0 0 24 10.37V8.886a3.46 3.46 0 0 0-2.284-3.25Z" />
      <path d="M13.273 11.866a.25.25 0 0 1-.017-.467 3.21 3.21 0 1 0-2.512 0 .25.25 0 0 1-.017.467 3.956 3.956 0 0 0-2.677 3.739v2.963a.494.494 0 0 0 .493.494h.882a.246.246 0 0 1 .246.227l.356 4.259a.493.493 0 0 0 .492.452h2.962a.493.493 0 0 0 .492-.452l.356-4.259a.246.246 0 0 1 .246-.227h.882a.494.494 0 0 0 .493-.494v-2.963a3.956 3.956 0 0 0-2.677-3.739ZM4.63 5.636a.25.25 0 0 1-.022-.461 2.716 2.716 0 1 0-2.3 0 .25.25 0 0 1-.021.461A3.463 3.463 0 0 0 0 8.887v1.483a.5.5 0 0 0 .494.494h.819a.246.246 0 0 1 .245.219l.42 3.787a.494.494 0 0 0 .491.439h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.246.246 0 0 1 .245-.219h.818a.494.494 0 0 0 .494-.494V8.886a3.46 3.46 0 0 0-2.283-3.25ZM21.716 5.636a.25.25 0 0 1-.021-.461 2.716 2.716 0 1 0-2.3 0 .25.25 0 0 1 .143.238.247.247 0 0 1-.164.223 3.461 3.461 0 0 0-2.284 3.251v1.483a.5.5 0 0 0 .494.494h.816a.246.246 0 0 1 .246.219l.42 3.787a.494.494 0 0 0 .491.439h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.246.246 0 0 1 .245-.219h.818A.494.494 0 0 0 24 10.37V8.886a3.46 3.46 0 0 0-2.284-3.25Z" />
    </g>
  </svg>
);

export default SvgUsers;
