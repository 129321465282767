import React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { P, Span } from '@insights-ltd/design-library/components';
import { SxProps } from '@mui/material';

type Props = {
  crumbs: { [key: string]: string };
  activeText: string;
  sx?: SxProps;
};

const Breadcrumbs = ({ crumbs, activeText, sx }: Props) => (
  <MuiBreadcrumbs sx={sx} aria-label="breadcrumb">
    {Object.entries(crumbs).map(([to, text]) => (
      <Span key={to} variant="body-small">
        <Link display="block" component={RouterLink} to={to}>
          {text}
        </Link>
      </Span>
    ))}
    <P variant="body-small" color="textPrimary">
      {activeText}
    </P>
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
