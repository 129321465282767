import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'components/Svgs/icons/Search';
import { InputAdornment, styled, TextField } from '@mui/material';

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholderKey?: string;
};

const StyledSearchIcon = styled(SearchIcon)({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'text',
  transition: 'box-shadow 200ms',
});

const OrganisationsSearchBox = ({ value, onChange, placeholderKey }: Props) => {
  const { t } = useTranslation();

  let placeholder;

  if (placeholderKey) {
    placeholder = placeholderKey;
  } else {
    placeholder =
      'ui.event-management.organisations.search.placeholder.search-term';
  }

  return (
    <TextField
      sx={(theme) => ({ backgroundColor: theme.palette.common.white })}
      id="organisation-search"
      data-testid="OrganisationSearchInput"
      fullWidth
      variant="outlined"
      placeholder={t(placeholder)}
      InputProps={{
        'aria-label': t('ui.event-management.organisations.search.label.bold'),
        startAdornment: (
          <InputAdornment position="start">
            <StyledSearchIcon />
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
export default OrganisationsSearchBox;
