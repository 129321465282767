import React from 'react';
import {
  RadioGroupControl,
  Text,
} from '@insights-ltd/design-library/components';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getPermissionRolesText } from 'pages/AddUser/AddUserForm/helper';
import { PermissionRole } from 'pages/AddUser/AddUserForm/types';
import { getRolePermissionV2Options } from './helper';

type RolePermissionV2Props = {
  isSuperAdmin: boolean;
  roleChangeIsPermitted: boolean | undefined;
  permissionRole: PermissionRole;
  changeRoleType: (value: string) => void;
};

const StyledDiv = styled('div')({
  marginTop: '1rem',
});

const StyledList = styled('ul')(({ theme }) => ({
  lineHeight: '28px',
  color: theme.palette && theme.palette.text && theme.palette.text.primary,
  paddingLeft: '20px',
}));

const RolePermissionV2 = ({
  isSuperAdmin,
  roleChangeIsPermitted,
  permissionRole,
  changeRoleType,
}: RolePermissionV2Props) => {
  const { t } = useTranslation();
  const roleOptions = getRolePermissionV2Options(t, isSuperAdmin);

  return (
    <StyledDiv data-testid="role-permission-v2">
      <Text variant="body-bold" as="h2" data-testid="role-permission-v2-title">
        {t('ui.event-management.users.invite.permission-role.label')}
      </Text>
      <RadioGroupControl
        data-testid="role-permission-v2-options"
        options={roleOptions}
        disabled={!roleChangeIsPermitted}
        name="roles"
        title={t('ui.event-management.users.create.permission-roles')}
        value={permissionRole}
        onChange={changeRoleType}
        variant="boxed"
        sx={{
          marginTop: '0.5rem',
          '& > div > div:not(:first-of-type)': {
            paddingLeft: '0.5rem !important',
          },
        }}
      />
      <StyledList data-testid="role-permission-v2-text">
        {getPermissionRolesText(t)[permissionRole].map((text: string) => (
          <li key={text}>{text}</li>
        ))}
      </StyledList>
    </StyledDiv>
  );
};

export default RolePermissionV2;
