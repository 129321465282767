import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ROUTE } from 'variables';
import { FullScreenSpinner } from 'components/FullScreen';
import { PermissionRequirement } from 'permissions';
import useIsPermitted from './hooks/useIsPermitted';

type ProtectedRouteProps = {
  requiredPermissions: PermissionRequirement[];
  redirectTo?: string;
  children: any;
};

const ProtectedRoute = ({
  requiredPermissions,
  redirectTo = DEFAULT_ROUTE,
  children,
}: ProtectedRouteProps) => {
  const { isPermitted, status } = useIsPermitted(requiredPermissions);
  const { t } = useTranslation();

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  if (!isPermitted) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};

export default ProtectedRoute;
