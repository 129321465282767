import { OrganisationResponse } from 'api/httpEntities';
import { useGetAllOrganisationsAndGroups } from 'components/hooks/useGetAllOrganisationsAndGroups';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';

type FormMode = 'EDIT' | 'NEW';

const useGetCoreDetails = (
  mode: FormMode,
): {
  setMyOrganisation: boolean;
  organisationsInGroups: GroupOrganisationHashMap;
  isPermitted: boolean;
  isPending: boolean;
  isError: boolean;
  organisations: OrganisationResponse[];
  myOrganisation: OrganisationResponse;
  showOrganisationDropdown: boolean;
} => {
  const {
    organisations: organisationsData,
    organisationsInGroups,
    status,
  } = useGetAllOrganisationsAndGroups();

  const isMultipleOrgs = (organisationsData?.length ?? 1) > 1;
  const isPermitted = isMultipleOrgs;
  const setMyOrganisation = !isPermitted || mode === 'EDIT';
  const showOrganisationDropdown = isPermitted && mode === 'NEW';

  const responseStatus = status;

  return {
    setMyOrganisation,
    organisationsInGroups,
    isPermitted,
    isPending: responseStatus === 'pending',
    isError: responseStatus === 'error',
    organisations: organisationsData || [],
    myOrganisation: organisationsData?.[0] || null,
    showOrganisationDropdown,
  };
};

export default useGetCoreDetails;
