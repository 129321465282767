import { Role } from 'types/types';
import { ROLES } from 'variables';
import { sortRoles } from './mappers/sorting';

export const isSuperAdmin = (roles?: Role[]): boolean =>
  !!roles?.includes('SUPER_ADMIN');

export const isGroupManager = (roles?: Role[]): boolean =>
  !!roles?.includes('GROUP_MANAGER') && !roles?.includes('SUPER_ADMIN');

export const isAdvancedUser = (roles?: Role[]): boolean =>
  !!roles?.includes('ADVANCED_USER') && !roles?.includes('GROUP_MANAGER');

export const isStandardUser = (roles?: Role[]): boolean =>
  !!roles?.every((role) => role === 'STANDARD');

export const getRoleDescription = (roles: Role[]) => {
  return ROLES[(roles ?? []).sort(sortRoles)[0]];
};
