import useGetAssociatedGroup from 'components/hooks/useGetAssociatedGroup';
import { useEffect, useState } from 'react';
import { Group, GroupResponse, RequestError } from 'api/httpEntities';

const useSelectGroupState = (
  organisationId?: string | undefined,
  allGroups?: GroupResponse[] | undefined,
) => {
  const { associatedGroup } = useGetAssociatedGroup(
    organisationId ?? '',
    allGroups ?? [],
  );

  const [selectedGroup, setSelectedGroup] = useState<Group | null>(
    associatedGroup ?? null,
  );
  const [groupError, setGroupError] = useState<RequestError | null>();

  useEffect(() => {
    setSelectedGroup(associatedGroup);
  }, [associatedGroup, setSelectedGroup]);

  return {
    associatedGroup,
    selectedGroup,
    setSelectedGroup,
    groupError,
    setGroupError,
  };
};

export default useSelectGroupState;
