import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  useMemo,
  useState,
} from 'react';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  useAddOrganisationToGroup,
  useGetAllSupportedRoles,
  useGetGroup,
  useRemoveOrganisationFromGroup,
} from 'api';
import { Link as RouterLink, LinkProps, useParams } from 'react-router-dom';
import FullScreenSpinner from 'components/FullScreen/FullScreenSpinner';
import FullScreenError from 'components/FullScreen/FullScreenError';
import {
  AddRemoveModal,
  H1,
  Tag,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { GroupType } from 'api/httpEntities';
import { useGetAllOrganisationsAndGroups } from 'components/hooks/useGetAllOrganisationsAndGroups';
import { useQueryClient } from '@tanstack/react-query';
import PermissionGuard from 'components/PermissionGuard';
import { isSuperAdmin } from 'utils/role';
import { useAuth } from 'contexts/AuthContext';
import GroupOrganisationList from './GroupOrganisationList';
import {
  GroupOrganisationError,
  errorHandler,
  useGetGroupOrganisationErrorMessage,
} from './Group.helper';

interface StyledTagProps {
  label: string;
}

interface StyledButtonProps {
  component?: ForwardRefExoticComponent<
    LinkProps & RefAttributes<HTMLAnchorElement>
  >;
  to?: string;
}

const StyledTag = styled(Tag)<StyledTagProps>(({ theme, label }) => ({
  margin: `0 ${theme.spacing(spacingSizeMap.S)}`,
  padding: `0 0.75rem`,
  color:
    label === 'Partner group'
      ? `${theme.palette.green.dark}`
      : `${theme.palette.orange.dark}`,
}));

const StyledButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
  marginLeft: '0.5rem',
  borderWidth: '0.125rem !important',
  borderColor: `${theme.palette.blue.main}`,
  padding: '0.4rem 1rem',
}));

const Group: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { groupId } = useParams<{ groupId: string }>();
  const [groupOrganisationError, setGroupOrganisationError] =
    useState<GroupOrganisationError>('OTHER');
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [organisationToAdd, setOrganisationIdToAdd] = useState<string>('');

  const errorMsg = useGetGroupOrganisationErrorMessage(
    groupOrganisationError,
    organisationToAdd,
  );
  const { user } = useAuth();
  const { data: group, status, refetch } = useGetGroup(groupId || '');
  const {
    availableOrganisations,
    organisations,
    refetchData: refetchGroups,
  } = useGetAllOrganisationsAndGroups();

  const { data: supportedRolesData } = useGetAllSupportedRoles();

  const supportedRoles = useMemo(
    () => supportedRolesData || [],
    [supportedRolesData],
  );

  const { mutate: addOrganisationToGroup } =
    useAddOrganisationToGroup(queryClient);
  const { mutate: removeOrganisationToGroup } =
    useRemoveOrganisationFromGroup(queryClient);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const close = () => {
    setIsOpen(false);
    setGroupOrganisationError('OTHER');
  };

  const refetchData = () => {
    refetchGroups();
    refetch().then();
  };

  const groupTypeLabel: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.group.label.customer'),
    PARTNER: t('ui.event-management.organisations.group.label.partner'),
  };

  const breadcrumbText: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.group.breadcrumb.customer', {
      groupName: group?.name,
    }),
    PARTNER: t('ui.event-management.group.breadcrumb.partner', {
      groupName: group?.name,
    }),
  };

  const showCreateOrg = !(
    !isSuperAdmin(user?.roles) && group?.type === 'CUSTOMER'
  );

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisation.error-loading')}
      />
    );
  }

  const translations = {
    addAllLoading: '',
    title: t('ui.event-management.group.add-remove-organisation.dialog.title', {
      groupName: group.name,
    }),
    clear: t('ui.event-management.team.add-remove-modal.clear'),
    findItem: t(
      'ui.event-management.group.add-remove-organisation.dialog.find-organisations',
    ),
    findItemPlaceHolder: t(
      'ui.event-management.group.add-remove-organisation.dialog.find-organisations.placeholder',
    ),
    findItemResults: {
      prompt: t(
        'ui.event-management.group.add-remove-organisation.dialog.find-organisations.search-results.prompt',
      ),
      plural: (count: number) =>
        t(
          'ui.event-management.group.add-remove-organisation.dialog.find-organisations.search-results.plural',
          { count },
        ),
      singular: (count: number) =>
        t(
          'ui.event-management.group.add-remove-organisation.dialog.find-organisations.search-results.singular',
          { count },
        ),
      none: () =>
        t(
          'ui.event-management.group.add-remove-organisation.dialog.find-organisations.search-results.none',
        ),
    },
    itemsInGroup: t(
      'ui.event-management.group.add-remove-organisation.dialog.organisations-in-group',
      { count: group.organisations.length },
    ),
    itemsInGroupPlaceholder: t(
      'ui.event-management.group.add-remove-organisation.dialog.organisations-in-group.placeholder',
    ),
    itemsInGroupResults: {
      plural: (count: number) =>
        t(
          'ui.event-management.group.add-remove-organisation.dialog.organisations-in-group.search-results.plural',
          { count },
        ),
      singular: (count: number) =>
        t(
          'ui.event-management.group.add-remove-organisation.dialog.organisations-in-group.search-results.singular',
          { count },
        ),
      none: () =>
        t(
          'ui.event-management.group.add-remove-organisation.dialog.organisations-in-group.search-results.none',
        ),
    },
    add: t('ui.event-management.group.add-remove-organisation.dialog.add'),
    remove: t(
      'ui.event-management.group.add-remove-organisation.dialog.remove',
    ),
    finish: t(
      'ui.event-management.group.add-remove-organisation.dialog.finish',
    ),
    errorMsg,
    addAll: {
      addAllText: t('ui.event-management.group.add-remove.add-all', {
        count: availableOrganisations.length,
      }),
      confirmAddAllAction: t(
        'ui.event-management.group.add-remove.add-all.confirm',
      ),
      cancelAddAllAction: t(
        'ui.event-management.group.add-remove.add-all.cancel',
      ),
      confirmationTitle: t(
        'ui.event.management.team.add-remove-learners.dialog.add-all-confirm',
      ),
    },
  };

  return (
    <>
      <Helmet>
        <title>{group?.name ?? ''}</title>
      </Helmet>
      <FullHeight>
        <Container
          maxWidth={false}
          data-testid="group-type-header"
          sx={(theme) => ({
            maxWidth: '100%',
            backgroundColor:
              group?.type === 'PARTNER'
                ? theme.palette.green.light
                : theme.palette.orange.light,
            padding: '0 !important',
          })}
        >
          <Container
            maxWidth={false}
            sx={(theme) => ({
              padding: `${theme.spacing(spacingSizeMap.M)} 0 !important`,
              [theme.breakpoints.up('lg')]: {
                padding: `${theme.spacing(spacingSizeMap.M)}`,
              },
            })}
          >
            <Container maxWidth="lg">
              <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
                <Breadcrumbs
                  crumbs={{
                    '/': t('ui.event-management.dashboard.home.title'),
                    '/organisations': t(
                      'ui.event-management.organisations.title',
                    ),
                  }}
                  activeText={breadcrumbText[group?.type ?? 'CUSTOMER']}
                />
              </Box>
              <Grid container justifyContent="space-between">
                <Grid
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                    '& > h1': {
                      overflow: 'visible',
                      overflowWrap: 'break-word',
                      maxWidth: '75%',
                    },
                  }}
                  item
                  xs={12}
                  md={5}
                >
                  <H1 variant="h2">{group?.name ?? ''}</H1>
                  <StyledTag
                    color="white"
                    label={groupTypeLabel[group?.type ?? 'CUSTOMER']}
                  />
                </Grid>
                <Grid
                  sx={(theme) => ({
                    [theme.breakpoints.up('sm')]: {
                      marginTop: theme.spacing(spacingSizeMap.S),
                    },
                  })}
                  item
                  xs={12}
                  md="auto"
                >
                  {showCreateOrg ? (
                    <PermissionGuard
                      requiredPermissions={[
                        {
                          action:
                            'Organisation_Group_Create_Organisation_In_Group',
                          scope: 'Global',
                        },
                      ]}
                    >
                      <Button
                        component={RouterLink}
                        to={`/groups/${group?.id}/create-organisation`}
                        variant="contained"
                        color="primary"
                      >
                        <AddIcon />
                        {t(
                          'ui.event-management.organisations.create-organisation',
                        )}
                      </Button>
                    </PermissionGuard>
                  ) : null}
                  <PermissionGuard
                    requiredPermissions={[
                      { action: 'Organisation_Group_Update', scope: 'Global' },
                    ]}
                  >
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {t(
                        'ui.event-management.group.add-remove-organisation.button',
                      )}
                    </StyledButton>
                    <AddRemoveModal
                      isOpen={isOpen}
                      onClose={close}
                      filterBy={['name']}
                      clearSearch={() => {
                        setGroupOrganisationError('OTHER');
                      }}
                      isAddLoading={false}
                      translations={translations}
                      onAdd={(organisationId) => {
                        const orgName = availableOrganisations?.find(
                          (org) => org?.id === organisationId,
                        )?.name;
                        setOrganisationIdToAdd(orgName || '');
                        setGroupOrganisationError('OTHER');
                        addOrganisationToGroup(
                          { groupId: group?.id ?? '', organisationId },
                          {
                            onSuccess: () => {
                              refetchData();
                              setShowErrorDialog(false);
                            },
                            onError: errorHandler(
                              setGroupOrganisationError,
                              setShowErrorDialog,
                            ),
                          },
                        );
                      }}
                      showError={
                        showErrorDialog || groupOrganisationError !== 'OTHER'
                      }
                      onRemove={(organisationId) => {
                        const orgName = organisations?.find(
                          (org) => org?.id === organisationId,
                        )?.name;
                        setOrganisationIdToAdd(orgName || '');
                        setGroupOrganisationError('OTHER');
                        return removeOrganisationToGroup(
                          {
                            groupId: group?.id ?? '',
                            organisationId,
                          },
                          {
                            onSuccess: () => {
                              refetchData();
                            },
                            onError: errorHandler(
                              setGroupOrganisationError,
                              setShowErrorDialog,
                            ),
                          },
                        );
                      }}
                      availableItems={availableOrganisations}
                      itemsInGroup={group?.organisations ?? []}
                    />
                    <StyledButton
                      component={RouterLink}
                      to={`/groups/${group?.id}/edit`}
                      variant="outlined"
                      color="primary"
                    >
                      {t('ui.event-management.group.manage-group')}
                    </StyledButton>
                  </PermissionGuard>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Container>
        <Container
          sx={{
            padding: '0 !important',
          }}
        >
          <GroupOrganisationList
            organisations={group?.organisations ?? []}
            group={group}
            user={user}
            supportedRoles={supportedRoles}
          />
        </Container>
      </FullHeight>
    </>
  );
};

export default Group;
