import { UseQueryResult } from '@tanstack/react-query';
import {
  QueryOptionsConfig,
  EvaluatorLinkResponse,
  EvaluatorLink,
  useGetOrganisationEvaluatorLinks,
  useGetAllPractitionerEvaluatorLinks,
} from 'api';
import { useAuth } from 'contexts/AuthContext';
import { useMemo } from 'react';

const useGetPractitionerEvaluatorLinks = (
  id: string | null,
  config?: QueryOptionsConfig<EvaluatorLinkResponse>,
) => {
  const { user } = useAuth();
  const { data: orgData, ...orgRest } = useGetOrganisationEvaluatorLinks(
    id ?? '',
    { enabled: !!id, ...config },
  );

  const { data: practitionerData, ...practitionerRest } =
    useGetAllPractitionerEvaluatorLinks(user?.id ?? '', {
      enabled: !id,
      ...config,
    });

  const linkResponse = id ? orgData : practitionerData;

  const rest: Omit<UseQueryResult<EvaluatorLinkResponse, Error>, 'data'> = id
    ? orgRest
    : practitionerRest;

  const PRACTITIONER = useMemo(
    () =>
      linkResponse?.evaluatorLinks?.filter(
        ({ origin }) => user?.id === origin.practitioner.id,
      ) ?? [],
    [user, linkResponse],
  );

  const data: Record<'ALL' | 'PRACTITIONER', EvaluatorLink[]> = {
    ALL: linkResponse?.evaluatorLinks ?? [],
    PRACTITIONER,
  };

  return {
    data,
    ...rest,
  };
};

export default useGetPractitionerEvaluatorLinks;
