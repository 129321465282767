import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import MenuItem from '@mui/material/MenuItem';
import { COUNTRIES } from 'variables';
import { StyledLabel } from 'components/StyledLabel';
import { EventForm, LocationData } from './types';
import { Select } from './Select';
import { getErrorMessage } from './validation';

const CHARACTER_LIMIT = 160;

type Props = {
  event: EventForm;
  onSubmit: (formData: LocationData) => void;
  children: ReactNode;
};

const isValidUrlOrEmpty = (link: string) => {
  if (!link) return true;
  try {
    // eslint-disable-next-line no-new
    new URL(link);
    return true;
  } catch {
    return false;
  }
};

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  '> *:not(:first-of-type)': {
    marginTop: `${theme.spacing(spacingSizeMap.L)}`,
  },
}));

const LocationForm = ({ event, onSubmit, children }: Props) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<LocationData>({
    defaultValues: event,
  });

  const [location, details] = watch(['location', 'details']);
  const physicalLocation = location === 'PHYSICAL';

  const { t } = useTranslation();

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {t('ui.event-management.events.create.label.location')}
        </FormLabel>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
        <Controller
          render={({ field }) => (
            <RadioGroup
              aria-label={t('ui.event-management.events.create.label.location')}
              {...field}
            >
              <FormControlLabel
                value="REMOTE"
                control={<Radio color="primary" />}
                label={
                  <strong>
                    {t(
                      'ui.event-management.events.create.label.location.remote',
                    )}
                  </strong>
                }
              />
              <FormControlLabel
                value="PHYSICAL"
                control={<Radio color="primary" />}
                label={
                  <strong>
                    {t(
                      'ui.event-management.events.create.label.location.in-person',
                    )}
                  </strong>
                }
              />
            </RadioGroup>
          )}
          name="location"
          control={control}
        />
      </FormControl>
      {/*
        Ideally this would be mounted/unmounted with no data-testid:
        https://github.com/react-hook-form/react-hook-form/issues/3543
      */}
      <Box
        sx={(theme) => ({
          display: location !== 'REMOTE' ? 'none' : 'block',
          '> * + *': {
            marginTop: theme.spacing(spacingSizeMap.L),
          },
        })}
        data-testid="remote-fields"
      >
        <TextField
          id="meetingLink"
          label={t('ui.event-management.events.create.label.meeting-link')}
          variant="outlined"
          fullWidth
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="http://"
          error={Boolean(errors.meetingLink)}
          helperText={
            errors.meetingLink &&
            t('ui.event-management.events.create.error.valid-url')
          }
          {...register('meetingLink', {
            validate: (link) => isValidUrlOrEmpty(link),
          })}
        />
        <TextField
          id="details"
          label={t('ui.event-management.events.create.label.location-details')}
          variant="outlined"
          fullWidth
          multiline
          rows={8}
          type="text"
          inputProps={{ maxLength: 160 }}
          helperText={t(
            'ui.event-management.events.create.location-details.character-count',
            { count: CHARACTER_LIMIT - details.length },
          )}
          sx={(theme) => ({
            marginTop: theme.spacing(spacingSizeMap.L),
            '& .MuiFormHelperText-root': {
              marginLeft: 0,
              marginBottom: 0,
            },
          })}
          {...register('details')}
        />
      </Box>

      <StyledDiv
        style={{ display: location !== 'PHYSICAL' ? 'none' : 'block' }}
        data-testid="physical-fields"
      >
        <TextField
          id="addressLine1"
          label={t('ui.event-management.events.create.label.address-line-1')}
          variant="outlined"
          fullWidth
          type="text"
          error={Boolean(errors.addressLine1)}
          helperText={t(getErrorMessage(errors.addressLine1))}
          {...register('addressLine1', {
            required: physicalLocation,
          })}
        />
        <Box>
          <StyledLabel optional={false}>
            {t('ui.event-management.events.create.label.address-line-2')}
            <StyledLabel optional>
              {t(
                'ui.event-management.events.create.label.address-line-2-optional',
              )}
            </StyledLabel>
          </StyledLabel>
          <TextField
            id="addressLine2"
            label={t('ui.event-management.events.create.label.address-line-2')}
            variant="outlined"
            fullWidth
            type="text"
            {...register('addressLine2')}
          />
        </Box>
        <TextField
          id="city"
          label={t('ui.event-management.events.create.label.city')}
          variant="outlined"
          fullWidth
          type="text"
          error={Boolean(errors.city)}
          helperText={t(getErrorMessage(errors.city))}
          {...register('city', {
            required: physicalLocation,
          })}
        />
        <TextField
          id="state"
          label={t('ui.event-management.events.create.label.state')}
          variant="outlined"
          fullWidth
          type="text"
          error={Boolean(errors.state)}
          helperText={t(getErrorMessage(errors.state))}
          {...register('state', {
            required: physicalLocation,
          })}
        />
        <TextField
          id="postcode"
          label={t('ui.event-management.events.create.label.post-code')}
          variant="outlined"
          fullWidth
          type="text"
          error={Boolean(errors.postcode)}
          helperText={t(getErrorMessage(errors.postcode))}
          {...register('postcode', {
            required: physicalLocation,
          })}
        />
        <Select
          id="country"
          name="country"
          label={t('ui.event-management.events.create.label.country')}
          control={control}
          rules={{ required: physicalLocation }}
          error={Boolean(errors.country)}
          errorMessage={t(getErrorMessage(errors.country))}
          sx={{
            '& .MuiInputLabel-asterisk': {
              display: 'none',
            },
          }}
        >
          {COUNTRIES.map((textKey) => {
            const countryTranslation = t(textKey);
            return (
              <MenuItem key={textKey} value={countryTranslation}>
                {countryTranslation}
              </MenuItem>
            );
          })}
        </Select>
      </StyledDiv>
      {children}
    </StyledForm>
  );
};

export default LocationForm;
