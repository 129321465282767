import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types/types';
import { getLearnerByEmail } from 'api/organisations/organisations';
import { processFile } from './bulkUploadUtils';

const ACCEPTED_MIME_TYPES = [
  'text/comma-separated-values',
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
];

type Props = {
  onBulkAddLearners: (learners: User[]) => void;
  organisationId: string;
};
const useBulkUpload = ({
  onBulkAddLearners,
  organisationId,
}: Props): {
  handleSelectFile: (element: HTMLInputElement) => Promise<void>;
  fileName: string;
  errorMessage: string | null;
  bulkResultModalOpen: boolean;
  setBulkResultModalOpen: Dispatch<SetStateAction<boolean>>;
  failedList: string[];
  progress: number;
} => {
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [failedList, setFailedList] = useState<string[]>([]);
  const [bulkResultModalOpen, setBulkResultModalOpen] =
    useState<boolean>(false);
  const [progress, setProgress] = useState(0);

  const { t } = useTranslation();
  useEffect(() => {
    setFailedList([]);
  }, [organisationId]);

  const pushFailedItem = (newValue: string) =>
    setFailedList((prevArray) => [...prevArray, newValue]);

  const handleSelectFile = async (element: HTMLInputElement) => {
    if (!element || !element.files || !element.files[0]) {
      setErrorMessage(
        t('ui.event-management.events.upload-error.no-file-error'),
      );
      return;
    }
    const fileToLoad = element.files[0];
    const fileReader = new FileReader();

    if (!ACCEPTED_MIME_TYPES.includes(fileToLoad.type)) {
      setErrorMessage(
        t('ui.event-management.events.upload-error.file-error', {
          fileName: fileToLoad.name,
        }),
      );
      return;
    }

    setErrorMessage(null);
    setFailedList([]);
    setFileName(fileToLoad.name);

    fileReader.onerror = () => {
      setErrorMessage(
        t('ui.event-management.events.upload-error.file-error', {
          fileName: fileToLoad.name,
        }),
      );
    };

    fileReader.onload = async (fileLoadedEvent) => {
      try {
        const loadedList = processFile(fileLoadedEvent);
        const learners: (User | undefined)[] = await Promise.all(
          loadedList.map(async ({ email }, i) => {
            setProgress((100 * (i + 1)) / loadedList.length);
            try {
              const response = await getLearnerByEmail(organisationId, email);
              return {
                fullName: response.fullName,
                emailAddress: response.primaryEmail,
                id: response.id,
              };
            } catch {
              pushFailedItem(email);
              return undefined;
            }
          }),
        );
        const filteredLearners = learners.filter((row) => !!row) as User[];
        onBulkAddLearners(filteredLearners);
        setProgress(0);
      } catch (error) {
        if (error instanceof Error) setErrorMessage(t(error.message));
      }
    };
    fileReader.readAsText(fileToLoad);
  };

  return {
    handleSelectFile,
    fileName,
    errorMessage,
    bulkResultModalOpen,
    setBulkResultModalOpen,
    failedList,
    progress,
  };
};
export default useBulkUpload;
