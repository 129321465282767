import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Text } from '@insights-ltd/design-library/components';
import { styled } from '@mui/material';

export type Props = {
  open: boolean;
  onClose: () => void;
  handleConfirmDelete: () => void;
  groupName: string;
};

const StyledDiv = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledHeader = styled(Text)({
  fontSize: '18px !important',
});

const StyledSubHeader = styled(Text)({
  margin: `0.25rem 0 0 !important`,
});

const DeleteGroupDialog = ({
  open,
  onClose,
  handleConfirmDelete,
  groupName,
}: Props) => {
  const { t } = useTranslation();

  const content = (
    <StyledDiv>
      <StyledHeader variant="body-bold">
        {t('ui.event-management.manage-group.confirm-delete.body-text', {
          groupName: groupName ?? '',
        })}
      </StyledHeader>
      <br />
      <StyledSubHeader>
        {t('ui.event-management.manage-group.confirm-delete.description')}
      </StyledSubHeader>
    </StyledDiv>
  );
  const title = t('ui.event-management.manage-group.confirm-delete.title');
  const confirmText = t(
    'ui.event-management.manage-group.confirm-delete.confirm-button',
  );
  const cancelText = t(
    'ui.event-management.manage-group.confirm-delete.cancel-button',
  );
  return (
    <Dialog
      dialogProps={{
        title,
        onClose,
        maxWidth: 'md',
      }}
      title={title}
      content={content}
      open={open}
      primaryButtonText={confirmText}
      secondaryButtonText={cancelText}
      onSubmit={handleConfirmDelete}
      onClose={onClose}
      variant="delete"
    />
  );
};

export default DeleteGroupDialog;
