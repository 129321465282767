import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, styled } from '@mui/material';
import { H2, H3, P } from '@insights-ltd/design-library/components';
import { ChangeOrganisation } from 'components/ChangeOrganisation';
import { useAuth } from 'contexts/AuthContext';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import usePagination from 'components/hooks/usePagination';
import { OrganisationResponse } from 'api';
import useGetMyOrganisations from 'components/hooks/useGetMyOrganisations';
import FullScreenSpinner from 'components/FullScreen/FullScreenSpinner';
import FullScreenError from 'components/FullScreen/FullScreenError';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { queryStatus } from 'utils/queryStatus';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import EvaluatorLinkList, {
  ITEMS_PER_PAGE,
} from './EvaluatorLinkList/EvaluatorLinkList';
import CreateEvaluatorLink from './CreateEvaluator/CreateEvaluatorLink';
import useSortedEvaluatorLinks from './useSortedEvaluatorLinks';

const StyledH3 = styled(H3)(({ theme }) => ({
  margin: `${theme.spacing(spacingSizeMap.S)} 0 ${theme.spacing(
    spacingSizeMap.M,
  )} !important`,
}));

type EvaluatorLinksFilterCategory = 'ALL' | 'PRACTITIONER';

const EvaluatorLinks = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const isAbleToSee3DRS = user?.licensedProducts.includes(
    'DISCOVERY_SELF_AWARE_PROFILE',
  );

  const { allMyOrganisations, status: orgStatus } = useGetMyOrganisations();

  const defaultOrganisation =
    allMyOrganisations && allMyOrganisations.length === 1
      ? allMyOrganisations[0]
      : null;

  const {
    searchState: {
      organisation = defaultOrganisation,
      filter = 'ALL',
      searchTerm,
    },
    setSearchState,
  } = useSearchContext();

  const setOrganisation = (org: OrganisationResponse | null) => {
    setSearchState({ organisation: org });
  };

  const setFilterCategory = (filterCategory: EvaluatorLinksFilterCategory) => {
    setSearchState({ filter: filterCategory });
  };
  const setSearchTerm = (term: string) => {
    setSearchState({ searchTerm: term });
  };
  const organisationId = organisation?.id ?? null;

  const { data, evaluatorStatus, setLinkSortBy, sortCategory } =
    useSortedEvaluatorLinks(organisationId);

  const links =
    searchTerm && searchTerm.length > 1 && data
      ? data[filter as EvaluatorLinksFilterCategory].filter(({ name }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : data[filter as EvaluatorLinksFilterCategory] ?? [];
  const preFilteredCount = data[filter as EvaluatorLinksFilterCategory].length;
  const paginator = usePagination(links, ITEMS_PER_PAGE);

  const { handlePageChange } = paginator;

  const status = queryStatus(orgStatus, evaluatorStatus);

  const handleSetFilterCategory = (value: EvaluatorLinksFilterCategory) => {
    setFilterCategory(value);
    handlePageChange(1);
  };

  const handleSetSearchTerm = (value: string) => {
    setSearchTerm(value);
    handlePageChange(1);
  };

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  if (status === 'error') {
    return <FullScreenError message={t('ui.event-management.generic.error')} />;
  }

  const listElement = (
    <EvaluatorLinkList
      isOrgSpecific={!!organisationId}
      filter={filter as EvaluatorLinksFilterCategory}
      paginator={paginator}
      searchTerm={searchTerm}
      onFilterChange={(value) => {
        handleSetFilterCategory(value);
      }}
      onSearchTermChange={(value) => {
        handleSetSearchTerm(value);
      }}
      preFilteredCount={preFilteredCount}
      sortCategory={sortCategory}
      setLinkSortBy={setLinkSortBy}
    />
  );

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.evaluator-links.title')}</title>
      </Helmet>
      <FullHeight>
        <Container maxWidth="lg">
          <Box sx={{ paddingTop: '25px', paddingBottom: '25px' }}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.events.nav.home'),
              }}
              activeText={t('ui.event-management.evaluator-links.title')}
            />
          </Box>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                <H2 variant="h2">
                  {t('ui.event-management.evaluator-links.title')}
                </H2>
              </Box>
              <Box>
                <P color="textSecondary">
                  {t('ui.event-management.evaluator-links.header')}
                </P>
              </Box>
            </Grid>
            <Grid item xs={12} md="auto">
              {allMyOrganisations && allMyOrganisations?.length > 1 ? (
                <ChangeOrganisation
                  disabled={false}
                  organisation={organisation ?? null}
                  handleOrganisationChange={(value) => {
                    setOrganisation(value);
                    handleSetSearchTerm('');
                  }}
                  hasAllOrgsOption
                />
              ) : null}
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: '36px',
              '& > h3': {
                marginTop: '0 !important',
                marginBottom: '0.75rem !important',
              },
            }}
          >
            <StyledH3 variant="h3">
              {t('ui.event-management.evaluator-links.create-link.title')}
            </StyledH3>
            <CreateEvaluatorLink
              defaultDialect="en-GB"
              organisationId={organisationId}
              disabled={!isAbleToSee3DRS}
            />
          </Box>
          {isAbleToSee3DRS ? listElement : null}
        </Container>
      </FullHeight>
    </>
  );
};

export default EvaluatorLinks;
