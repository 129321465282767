import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { useTheme } from '@mui/material';
import getTableXCoordinates from './getTableXCoordinates';

type TeamTableHeaderProps = {
  largestNameWidth: number;
  showNumbers: boolean;
  isMultiOrg: boolean;
};

const TeamTableHeader = ({
  largestNameWidth,
  showNumbers = true,
  isMultiOrg = false,
}: TeamTableHeaderProps) => {
  const theme = useTheme();
  const [{ tableFontSize }] = useWheelContext();
  const { t } = useTranslation();

  const { columns, lengthRemoved } = getTableXCoordinates(
    showNumbers,
    largestNameWidth,
    !!isMultiOrg,
  );

  const minWidth = 195 - lengthRemoved;
  return (
    <g>
      <rect
        fill={theme.palette.blue.main}
        width={minWidth + largestNameWidth}
        height="15"
      />
      <text
        y="9px"
        fontSize={`${tableFontSize}px`}
        fontWeight="bold"
        fill={theme.palette.grey[100]}
        textAnchor="start"
        role="row"
        alignmentBaseline="central"
      >
        {showNumbers && (
          <tspan role="columnheader" x={columns.numbers}>
            {isMultiOrg
              ? t('ui.event-management.events.team-wheel.table.number-abbrev')
              : t('ui.event-management.events.team-wheel.table.number')}
          </tspan>
        )}
        <tspan role="columnheader" x={columns.initials}>
          {t('ui.event-management.events.team-wheel.table.initials')}
        </tspan>
        <tspan role="columnheader" x={columns.name}>
          {t('ui.event-management.events.team-wheel.table.name')}
        </tspan>
        <tspan role="columnheader" x={columns.position}>
          {t('ui.event-management.events.team-wheel.table.wheel-position')}
        </tspan>
        {isMultiOrg && (
          <tspan role="columnheader" x={columns.organisation}>
            {t('ui.event-management.events.team-wheel.table.organisation')}
          </tspan>
        )}
        <tspan role="columnheader" x={columns.colourEnergies}>
          {t('ui.event-management.events.team-wheel.table.order-preference')}
        </tspan>
      </text>
    </g>
  );
};
export default TeamTableHeader;
