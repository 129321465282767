import React from 'react';
import SelectOrganisation from 'components/SelectOrganisation/SelectOrganisation';
import { Group, GroupResponse, OrganisationResponse } from 'api';
import SelectGroup from 'components/SelectGroup/SelectGroup';
import { useTranslation } from 'react-i18next';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { InviteUserOrgOptions } from 'types/types';
import { OrgsInGroupSearch } from 'components/OrgsInGroupSearch';

const MultiOrSingleOrgSelect = ({
  orgType,
  selectedOrgs,
  setSelectedOrgs,
  organisations,
  groups,
  organisationsInGroups,
  onRemoveOrg,
  selectedGroup,
  setSelectedGroup,
  groupOptions,
}: {
  orgType: InviteUserOrgOptions;
  selectedOrgs: any;
  setSelectedOrgs: any;
  organisations: OrganisationResponse[];
  groups: GroupResponse[];
  organisationsInGroups: GroupOrganisationHashMap;
  onRemoveOrg: (id: string) => void;
  selectedGroup: Group | null;
  setSelectedGroup: (value: GroupResponse | null) => void;
  groupOptions: OrganisationResponse[];
}) => {
  const { t } = useTranslation();

  if (orgType === 'MULTI_ORG') {
    return (
      <>
        <SelectGroup
          selectedGroup={selectedGroup}
          options={groups || []}
          onSelect={(group) => {
            setSelectedGroup(group as GroupResponse);
            setSelectedOrgs(null);
          }}
          fieldLabel={t('ui.event-management.users.invite.select-group.label')}
          actionType="change"
        />

        {selectedGroup ? (
          <OrgsInGroupSearch
            onSelect={(value: OrganisationResponse) => setSelectedOrgs(value)}
            onRemoveOrg={onRemoveOrg}
            loading={false}
            options={groupOptions}
            labelKey="ui.event-management.users.invite.select-organisation.label"
            selectedMultiOrgs={selectedOrgs}
            {...{
              selectedGroup,
              t,
            }}
          />
        ) : null}
      </>
    );
  }
  return (
    <SelectOrganisation
      label={t('ui.event-management.users.invite.select-organisation.label')}
      options={organisations as OrganisationResponse[]}
      onSelect={(org) => {
        setSelectedOrgs(org);
        setSelectedGroup(null);
      }}
      selectedId={selectedOrgs[0]?.id}
      onChange={() => {
        setSelectedOrgs(null);
      }}
      associatedGroup={organisationsInGroups?.[selectedOrgs[0]?.id]}
    />
  );
};

export default MultiOrSingleOrgSelect;
