import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Text } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import OrgIcon from 'components/Svgs/icons/Organisation';
import { OrganisationResponse } from 'api';
import LinkButton from 'components/LinkButton';
import { useGetAllOrganisationsAndGroups } from 'components/hooks/useGetAllOrganisationsAndGroups';
import ChangeOrgDialog from './ChangeOrgDialog';

type Props = {
  disabled: boolean;
  organisation: OrganisationResponse | null;
  handleOrganisationChange: (value: OrganisationResponse | null) => void;
  hasAllOrgsOption?: boolean;
  open?: boolean;
  handleOnClose?: () => void;
};

const StyledOrgIcon = styled(OrgIcon)(({ theme }) => ({
  width: theme.spacing(spacingSizeMap.L),
  height: theme.spacing(spacingSizeMap.L),
  marginRight: '12px',
  padding: '5px 4px',
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: '5px',
  fill: theme.palette.grey[500],
}));

const OrganisationPicker = ({
  disabled,
  organisation,
  handleOrganisationChange,
  handleOnClose = () => {},
  hasAllOrgsOption = true,
  open = false,
}: Props) => {
  const { t } = useTranslation();
  const { organisations, isPending, organisationsInGroups } =
    useGetAllOrganisationsAndGroups();

  const [changingOrg, setChangingOrg] = useState(false);

  return (
    <>
      <Grid container direction="row">
        <Grid item>
          <Grid
            container
            sx={(theme) => ({
              padding: '6px 16px 2px 10px',
              backgroundColor: theme.palette.grey[100],
              border: `1px solid ${theme.palette.grey[500]}`,
              borderRadius: '5px',
            })}
          >
            <Grid item>
              <StyledOrgIcon />
            </Grid>
            <Grid item>
              <Text data-testid="filtered-organisation" variant="body-bold">
                {organisation?.name ||
                  t(
                    'ui.event-management.learners.organisation.change-org.all-organisations',
                  )}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        {!disabled && (
          <LinkButton onClick={() => setChangingOrg(true)}>
            {t('ui.event-management.learners.organisation.change')}
          </LinkButton>
        )}
      </Grid>
      <ChangeOrgDialog
        open={changingOrg || open}
        isLoading={isPending}
        onClose={() => {
          setChangingOrg(false);
          handleOnClose();
        }}
        organisationId={organisation?.id || ''}
        organisations={organisations!}
        handleOrganisationChange={handleOrganisationChange}
        hasAllOrgsOption={hasAllOrgsOption}
        organisationsInGroups={organisationsInGroups}
      />
    </>
  );
};
export default OrganisationPicker;
