import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { QueryStatus, useQueryClient } from '@tanstack/react-query';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { Button } from '@mui/material';
import PermissionGuard from 'components/PermissionGuard';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import { useGetLearnerByOrganisation, useDeleteLearner } from 'api';
import { queryStatus } from 'utils/queryStatus';
import EditLearnerForm from './EditLearnerForm';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

type Props = {
  crumbs?: Record<string, string>;
  otherStatus?: QueryStatus;
};
const EditLearner = ({ crumbs, otherStatus }: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { learnerId, organisationId } = useParams<{
    learnerId: string;
    organisationId: string;
  }>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { status: learnerStatus, data: learner } = useGetLearnerByOrganisation(
    learnerId || '',
    organisationId || '',
  );

  const status = queryStatus(learnerStatus, otherStatus || 'success');
  const {
    mutate,
    isPending: isDeleting,
    isError,
    reset,
  } = useDeleteLearner(learnerId || '', queryClient);

  const isLoading = status === 'pending';

  const breadcrumbs = crumbs ?? {
    '/': t('ui.event-management.dashboard.home.title'),
    '/learners': t('ui.event-management.learners-and-teams.title'),
    [`/learners/${organisationId}/${learnerId}`]: learner?.fullName ?? '',
  };

  const handleConfirmDelete = () =>
    mutate(learnerId || '', {
      onSuccess: () => {
        setShowConfirmDialog(false);
        navigate('/learners', {
          state: {
            activeTab: 'learners',
            deletedLearner: learner!.fullName,
          },
        });
      },
    });

  if (isLoading) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.learner.error-loading-learner')}
      />
    );
  }

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.learner.edit.title"
      activeTextKey="ui.event-management.learner.edit.title"
      crumbs={breadcrumbs}
      headingKey="ui.event-management.learner.edit.title"
      secondaryContent={
        <PermissionGuard
          requiredPermissions={[{ action: 'Learner_Delete', scope: 'Global' }]}
        >
          <Button
            data-testid="delete-learner-button"
            sx={(theme) => ({
              color: theme.palette.error.main,
              borderColor: theme.palette.error.main,
              border: '1px solid',
              backgroundColor: 'transparent',
              '&:hover': {
                borderColor: theme.palette.error.main,
                backgroundColor: `${theme.palette.error.main}16`,
              },
            })}
            onClick={() => setShowConfirmDialog(true)}
            variant="outlined"
          >
            {t('ui.event-management.learner.edit.delete-learner')}
          </Button>
        </PermissionGuard>
      }
    >
      <EditLearnerForm
        learner={learner!}
        organisationId={organisationId || ''}
      />
      <ConfirmDeleteDialog
        open={showConfirmDialog}
        onClose={() => {
          reset();
          setShowConfirmDialog(false);
        }}
        onConfirm={handleConfirmDelete}
        isLoading={isDeleting}
        isError={isError}
      />
    </BreadcrumbLayout>
  );
};

export default EditLearner;
