import { CompletedEvaluation, EvaluatorLink } from 'api/httpEntities';
import { Evaluator } from 'types/types';

const names = [
  'Semper Pretium Neque Company',
  'Curabitur Corporation',
  'Suspendisse PC',
  'Nunc Ltd',
  'Pharetra Quisque Company',
  'Non Dapibus Corporation',
  'Dui Corporation',
  'Felis Nulla Corp.',
  'Et Tristique Pellentesque Institute',
  'Enim Etiam LLP',
  'Tortor Nibh Associates',
  'Sagittis Lobortis Incorporated',
  'Lobortis Consulting',
  'Purus In Consulting',
  'Lectus Cum Sociis Limited',
  'Auctor Ullamcorper Corp.',
  'Ultrices Posuere PC',
  'Leo Morbi Neque Limited',
  'Sed Associates',
  'Nec Enim Associates',
  'Primis In Faucibus Limited',
  'Blandit At Associates',
  'Vivamus Nibh Dolor Associates',
  'Neque Venenatis Inc.',
  'Magna PC',
  'Amet Ante Inc.',
  'Semper Auctor Mauris Incorporated',
  'Ut Ltd',
  'Erat Volutpat Institute',
  'Donec Foundation',
  'Cum Sociis Industries',
  'Amet Lorem Semper Limited',
  'Ac Mattis Company',
  'Scelerisque Dui Limited',
  'Aenean Gravida Inc.',
  'Nunc Sollicitudin Associates',
  'Nunc Id Ltd',
  'Sed Dictum Proin Institute',
  'At Egestas Consulting',
  'Phasellus Dapibus LLP',
  'Neque Tellus Ltd',
  'Vel Institute',
  'Arcu Foundation',
  'Pede Nonummy Ut Foundation',
  'Aliquam PC',
  'Amet Diam Institute',
  'Nonummy Ultricies Ornare Corporation',
  'Vel Nisl Quisque LLP',
  'Vel Associates',
  'Rutrum Urna Limited',
  'Nisi LLP',
  'Pellentesque Ltd',
  'Molestie Company',
  'Elit Pretium Industries',
  'Parturient Montes Associates',
  'Eu Tellus Industries',
  'Sed Industries',
  'Libero Morbi Limited',
  'Commodo Auctor LLP',
  'Cubilia Associates',
  'Nulla Interdum Curabitur Associates',
  'Aliquam LLC',
  'A Industries',
  'Imperdiet Limited',
  'Lorem Tristique PC',
  'Ante Blandit Limited',
  'Id Risus Quis Limited',
  'At Libero Morbi Inc.',
  'Sodales Elit Limited',
  'Ut Lacus Ltd',
  'Ut Erat Institute',
  'Euismod Inc.',
  'Turpis Nulla Incorporated',
  'Mollis Non Cursus Institute',
  'Rutrum Justo Ltd',
  'Eu Placerat Eget LLP',
  'Pharetra Ut Consulting',
  'Dolor Dapibus Incorporated',
  'Enim Mauris Quis LLP',
  'Velit Justo Nec Corporation',
  'Est Mollis Inc.',
  'Egestas Limited',
  'Nec Ante Ltd',
  'Nec Leo Associates',
  'Facilisis Corp.',
  'Accumsan Sed Foundation',
  'Laoreet Libero Et Limited',
  'Et Malesuada Fames Institute',
  'Nunc PC',
  'Nulla Foundation',
  'Orci Ut LLC',
  'Dignissim Maecenas Ornare PC',
  'Eu Eleifend Nec LLP',
  'Eu Industries',
  'Risus Varius Institute',
  'Curabitur Egestas Incorporated',
  'Non Lacinia LLC',
  'Pede Malesuada PC',
  'Sed Eu Nibh Company',
  'Cum Sociis Company',
  'Ut Consulting',
  'Ipsum Porta Industries',
  'Phasellus Corp.',
  'Molestie LLP',
  'Tortor Nibh Corp.',
  'Pellentesque Inc.',
  'Ipsum Primis Company',
  'Donec Consectetuer Mauris PC',
  'Ut Nisi A Institute',
  'Sagittis Nullam Company',
  'Habitant Morbi Tristique PC',
  'In Ornare Institute',
  'A Auctor PC',
  'Molestie Orci Tincidunt Associates',
  'Posuere Industries',
  'Rutrum Justo Associates',
  'Eu Odio LLP',
  'Vitae Semper Ltd',
  'Cras Company',
  'Gravida Non Sollicitudin Corp.',
  'Nec Imperdiet Nec Corp.',
  'Etiam LLP',
  'Nascetur Ridiculus Corporation',
  'Enim Institute',
  'Consequat Incorporated',
  'Fusce Fermentum Fermentum Ltd',
  'Quisque Tincidunt Foundation',
  'Mus Proin Vel Industries',
  'Porttitor Associates',
  'Vitae Dolor Incorporated',
  'Vitae Purus PC',
  'Libero Et Institute',
  'Ridiculus Mus Corp.',
  'Libero Et Tristique LLC',
  'At Pede Cras Industries',
  'Ornare In Faucibus PC',
  'Eu Augue Porttitor Consulting',
  'Sapien Gravida Ltd',
  'Neque Industries',
  'Id Libero Donec Consulting',
  'In Condimentum Corporation',
  'Amet Consectetuer PC',
  'Tempus Inc.',
  'Metus In PC',
  'Orci Phasellus Institute',
  'Tristique Institute',
  'Pellentesque Habitant Corp.',
  'Libero Corp.',
  'Velit Eu LLC',
  'Ridiculus Mus Ltd',
  'Sociosqu Associates',
  'Lacinia Vitae Sodales LLP',
  'Ut Industries',
  'Eget Ipsum Donec Corp.',
  'Bibendum Sed Est LLP',
  'Cursus Diam Limited',
  'Cras Dictum Ultricies Institute',
  'Auctor Velit Ltd',
  'Commodo Auctor PC',
  'Dictum Cursus Institute',
  'Magna Suspendisse Associates',
  'Nec Mauris Incorporated',
  'Suspendisse Tristique Inc.',
  'Porttitor Tellus Inc.',
  'Lacus Aliquam PC',
  'Bibendum Fermentum Corp.',
  'Nascetur Ridiculus Corp.',
  'Varius Orci PC',
  'Diam Proin Dolor Institute',
  'Maecenas Iaculis Aliquet LLC',
  'Donec Porttitor PC',
  'Neque Vitae Inc.',
  'Sed Dolor Foundation',
  'Eget LLC',
  'Dui Corp.',
  'Suspendisse Eleifend Foundation',
  'Suspendisse Sed Company',
  'Vivamus Industries',
  'Senectus Et Netus Institute',
  'Nec Consulting',
  'Eros Nec Incorporated',
  'Morbi Tristique Industries',
  'Sed Sapien Nunc Corp.',
  'Libero LLC',
  'Dapibus Id Industries',
  'Cum Sociis LLP',
  'Faucibus Orci Luctus LLC',
  'Sit Consulting',
  'Suscipit Est Ac Corporation',
  'At Pretium Aliquet Consulting',
  'Arcu Nunc Institute',
  'Dui Quis Industries',
  'Lobortis Ltd',
  'Sem Pellentesque Ut Inc.',
  'Semper Et PC',
  'Conubia LLC',
  'Magnis Dis Parturient Ltd',
  'Auctor Quis Ltd',
  'Et Inc.',
  'Dolor Elit Ltd',
  'Risus At Incorporated',
  'Amet Risus Donec Associates',
  'In Ornare Sagittis PC',
  'Vulputate Risus Institute',
  'Sem Institute',
  'Nascetur Ridiculus Corp.',
  'Eleifend Vitae Limited',
  'Est Nunc Laoreet Ltd',
  'Sem Ut Dolor PC',
  'Maecenas Malesuada LLP',
  'Donec Inc.',
  'Est Tempor Ltd',
  'Consectetuer LLC',
  'Aliquam Erat Volutpat Corporation',
  'Eleifend Egestas Sed Corporation',
  'Nonummy Ut Molestie Inc.',
  'At Velit Pellentesque PC',
  'Imperdiet Non Inc.',
  'Adipiscing Ltd',
  'Imperdiet PC',
  'Molestie Arcu Sed PC',
  'Enim Nec Inc.',
  'Mauris Erat Company',
  'Eget Metus In Ltd',
  'Neque In Ornare Associates',
  'Lobortis PC',
  'Eget Limited',
  'Mauris A Associates',
  'Erat Nonummy Ultricies LLP',
  'Consequat Purus Ltd',
  'Curabitur Associates',
  'Leo Corporation',
  'Dolor Nulla PC',
  'Dictum Placerat Consulting',
  'Vel Associates',
  'Odio Nam Interdum Corp.',
  'Nec Foundation',
  'Donec Est Associates',
  'Lobortis Tellus Corp.',
  'Ac Mattis Corp.',
  'Metus Aliquam Corporation',
  'Accumsan Consulting',
  'Est Corp.',
  'Nonummy Fusce Corp.',
  'Quis Accumsan Convallis Limited',
  'Nulla Magna Ltd',
  'Tellus Faucibus LLC',
  'Metus Vitae Ltd',
  'Lorem Industries',
  'Convallis Est Foundation',
  'Est PC',
  'Auctor Ullamcorper Ltd',
  'Donec LLC',
  'Leo Company',
  'Tellus Id Limited',
  'Vestibulum Massa Incorporated',
  'Eu Nulla Corp.',
  'Sit Industries',
  'Semper Cursus Integer Inc.',
  'Auctor Mauris Corp.',
  'Sodales At Limited',
  'Vulputate Corporation',
  'Libero PC',
  'Tempus Non Ltd',
  'Est Tempor Bibendum Corporation',
  'Elit Fermentum Risus Industries',
  'Dui In Industries',
  'Ridiculus Mus Associates',
  'Consectetuer Cursus Et Ltd',
  'Scelerisque Sed Limited',
  'Ac Metus Corp.',
  'Commodo At Libero Ltd',
  'Duis Sit Limited',
  'Viverra Maecenas Ltd',
  'Nisi Aenean Eget Associates',
  'Et Rutrum Eu Industries',
  'Fringilla Euismod Inc.',
  'In Hendrerit Consectetuer Incorporated',
  'Sagittis Felis Industries',
  'Arcu Nunc Corporation',
  'Suspendisse Aliquet LLP',
  'Phasellus Dapibus Incorporated',
  'Dui Suspendisse Foundation',
  'Elementum Incorporated',
  'Pellentesque Corp.',
  'Tortor Dictum Eu Corporation',
  'Augue Eu Associates',
  'Penatibus Consulting',
  'Non Associates',
  'Fermentum Metus Limited',
  'Libero Institute',
  'Sed Id Limited',
  'Ut Tincidunt Orci Inc.',
  'Feugiat Incorporated',
  'Tincidunt Donec LLC',
  'Est Company',
  'Odio Aliquam Vulputate LLC',
  'Nascetur Industries',
  'Erat In Corporation',
  'Risus Duis A LLC',
  'A Corporation',
];

const createMockLinkData = (): EvaluatorLink[] => {
  const mockData: EvaluatorLink[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < names.length; i++) {
    mockData.push({
      id: `mock-id-${i}`,
      name: names[i],
      totalCompletedEvaluators: Math.round(Math.random() * 100),
      link: 'https://example.com',
      organisation: {
        id: `mock-org-id-${i}`,
        name: `org name ${i}`,
        dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
      },
      evaluatorModel: `DDDRS` as Evaluator,
      origin: {
        createdAt: '2022-03-17T16:15:32Z',
        practitioner: {
          id: 'test 1',
        },
      },
      dialect: 'en-GB',
    });
  }

  return mockData;
};

export const mockLinks = createMockLinkData();

export const mockLink: EvaluatorLink = {
  id: '2d02476f-b038-425c-a5fd-595fae268d98',
  name: 'Test 1',
  dialect: 'en-GB',
  link: 'https://example.com',
  totalCompletedEvaluators: 0,
  organisation: {
    id: 'insights',
    name: 'Insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  evaluatorModel: 'DDDRS',
  origin: {
    createdAt: '2022-03-17T16:15:32Z',
    practitioner: {
      id: 'test-user-id',
    },
  },
};

export const emptyLink: EvaluatorLink = {
  id: '',
  name: '',
  link: '',
  totalCompletedEvaluators: 0,
  organisation: {
    id: '',
    name: '',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  evaluatorModel: 'DDDRS',
  origin: {
    createdAt: '',
    practitioner: {
      id: '',
    },
  },
  dialect: 'en-GB',
};

const rawData = [
  {
    id: 'dec1bfa0-c84a-4def-8efb-2d15f4a1ec6d',
    emailAddress: 'jrbaker@rocketmail.com',
    fullName: 'Joseph',
  },
  {
    id: '0cc26261-eaed-4de4-87e4-6a4f43d200c5',
    emailAddress: 'steven_t_hall@live.com',
    fullName: 'Steven',
  },
  {
    id: '5b2b6956-8377-43a7-9d01-c703accc443f',
    emailAddress: 'a_james@yahoo.com',
    fullName: 'Abigail',
  },
  {
    id: '766faf2a-a713-429b-8173-ebd980507312',
    emailAddress: 'i_brown@outlook.com',
    fullName: 'Isabella',
  },
  {
    id: '190e7f98-62d9-4162-b138-bc393fec7925',
    emailAddress: 'j_f_foster@hotmail.com',
    fullName: 'Jennifer',
  },
];

export const generateFakeEvaluatorsData: () => CompletedEvaluation[] = () => {
  return rawData.map((item) => {
    return {
      learner: item,
      referralCode: 'R&D',
      createdAt: '2012-07-08T08:45:23.370+01:00',
    } as CompletedEvaluation;
  });
};

export const generateMockEvaluationsData = (
  numberOfEvaluations: number = 300,
) => {
  const evaluations: CompletedEvaluation[] = [];
  for (let i = 0; i < numberOfEvaluations; i += 1) {
    evaluations.push({
      id: `test-evaluation-${i}`,
      createdAt: '2024-01-08T14:09:35.7102Z',
      profile: {
        id: `test-profile-${i}`,
        chapters: [
          {
            name: 'FOUNDATION',
          },
          {
            name: 'MANAGEMENT',
          },
          {
            name: 'INTERVIEW',
          },
          {
            name: 'EFFECTIVE_SELLING',
          },
          {
            name: 'PERSONAL_ACHIEVEMENT',
          },
        ],
        createdAt: '2024-01-08T14:10:50.409411Z',
      },
      colourScores: {
        conscious: {
          blue: 261,
          green: 400,
          yellow: 260,
          red: 347,
          preferred: ['green', 'red'],
        },
      },
      learner: {
        id: `test-learner-${i}`,
        emailAddress: `test-${i}@test.com`,
        fullName: `Test learner-${i}`,
      },
      referralCode: 'you-would-never',
    });
  }

  return evaluations;
};

export const mockData = generateFakeEvaluatorsData();
