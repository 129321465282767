import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Helmet from 'react-helmet';
import { H1, P, Span } from '@insights-ltd/design-library/components';
import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import Logo from 'components/Svgs/PaperPlane';
import { FormData } from 'pages/ForgottenPassword/types';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';

const StyledH1 = styled(H1)({
  textAlign: 'center',
});

const StyledP = styled(P)({
  textAlign: 'center',
});

const Confirmation = ({ emailAddress }: FormData) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.forgotten-password')}</title>
      </Helmet>
      <SelfServiceLayout>
        <Card
          sx={(theme) => ({
            maxWidth: '31rem',
            padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
          })}
        >
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <P>
                  <Logo />
                </P>
              </Grid>
              <Grid
                sx={(theme) => ({
                  flexBasis: 'auto',
                  width: '100%',
                  margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                })}
                item
                xs={12}
              >
                <StyledH1 variant="h3">
                  {t(
                    'ui.event-management.forgotten-password-confirmation.heading',
                  )}
                </StyledH1>
                <StyledP variant="body" color="textSecondary">
                  <Trans
                    i18nKey="ui.event-management.forgotten-password-confirmation.sub-heading.bold"
                    values={{ emailAddress }}
                    components={{ bold: <Span variant="body-bold" /> }}
                  />
                </StyledP>
              </Grid>
              <Grid item xs={12}>
                <P variant="body-bold">
                  <Link component={RouterLink} to="/sign-in">
                    {t(
                      'ui.event-management.forgotten-password-confirmation.back',
                    )}
                  </Link>
                </P>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SelfServiceLayout>
    </>
  );
};

export default Confirmation;
