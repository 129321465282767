import React from 'react';
import { SvgComponentProps } from './types';

const SvgAssignChapters = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect width={48} height={48} fill="#FDF1E7" rx={4} />
    <path
      fill="#EE7814"
      d="M31.9 14.84a.5.5 0 0 1-.4-.49V12.8a2 2 0 0 0-2-2H17a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h14.5a2 2 0 0 0 2-2v-16a2 2 0 0 0-1.6-1.96ZM17 12.8h12.25a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25H17a1 1 0 0 1 0-2Z"
    />
  </svg>
);

export default SvgAssignChapters;
