import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import type { QueryStatus } from '@tanstack/react-query';
import PlusIcon from '@mui/icons-material/Add';
import {
  FullScreenError as PageError,
  FullScreenSpinner as PageSpinner,
} from 'components/FullScreen';
import Button from '@mui/material/Button';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { SearchFilterV2 } from 'components/SearchFilter/SearchFilter';
import Notification from 'components/Notification';
import usePagination from 'components/hooks/usePagination';
import useHistoryState from 'components/hooks/useHistoryState';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import SearchResultInfo from 'components/SearchResultInfo/SearchResultInfo';
import {
  type SearchResultsMessages,
  getSearchResultMessage,
} from 'utils/getSearchResultMessage';
import { useDebounceValue } from 'components/hooks/useDebouncedValue';
import { MINIMUM_SEARCH_CHARACTER_LIMIT } from 'variables';
import TeamsList from './TeamsList';
import useGetTeamResults from './TeamTab.helper';
import LearnerSearchMessage from '../LearnersTab/LearnerSearchMessage';

const ITEMS_PER_PAGE = 10;

type Props = {
  organisationId: string | null;
  handleOpenOrgSelector: () => void;
};

const Loader = ({ status }: { status: QueryStatus }) => {
  const { t } = useTranslation();

  if (status === 'error') {
    return (
      <PageError
        message={t('ui.event-management.learners.error-loading-teams')}
      />
    );
  }
  if (status === 'pending') {
    return <PageSpinner message={t('ui.event-management.loading')} />;
  }
  return null;
};

const searchResultsMessages: SearchResultsMessages = {
  lastPageKey:
    'ui.event-management.learners.teams.result.limit-exceeded.last-page',
  searchTermKey:
    'ui.event-management.learners.teams.result.limit-exceeded.search-term',
  defaultKey: 'ui.event-management.learners.teams.result.limit-exceeded',
};

const TeamsTab = ({ organisationId, handleOpenOrgSelector }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const {
    searchState: { searchTerm, checkbox: showMyTeam },
    setSearchState,
  } = useSearchContext();
  const debouncedSearchTerm = useDebounceValue(searchTerm ?? '');
  const {
    items: sortedItems,
    status,
    title,
    limitExceeded,
    isFetched,
  } = useGetTeamResults({
    searchTerm: debouncedSearchTerm,
    myTeamsOnly: showMyTeam,
    organisationId,
  });
  const paginator = usePagination(sortedItems, ITEMS_PER_PAGE);
  const { handlePageChange, pagedItems } = paginator;
  const [deletedTeam] = useHistoryState<string>('deletedTeam');

  const onChange = (newSearchTerm: string) => {
    handlePageChange(1);
    setSearchState({ searchTerm: newSearchTerm });
  };

  let notification = null;
  if (deletedTeam) {
    notification = t('ui.event-management.learners.teams.delete-success', {
      teamName: deletedTeam,
    });
  }

  const teamData = sortedItems;

  const searchResultMessage = getSearchResultMessage(
    searchResultsMessages,
    isLastPage,
    searchTerm,
  );

  const handleSearchMessageClick = () => {
    ref.current?.focus();
  };

  const onPageChange = ({ lastPage }: { lastPage?: boolean }) => {
    setIsLastPage(!!lastPage);
  };
  return (
    <>
      {!organisationId &&
        (!searchTerm || searchTerm.length < MINIMUM_SEARCH_CHARACTER_LIMIT) && (
          <LearnerSearchMessage
            i18nKey="ui.event-management.learners.teams.search-limit-message"
            onClick={handleOpenOrgSelector}
          />
        )}
      <SearchFilterV2
        label={t('ui.event-management.list-filter.search')}
        placeholder={t('ui.event-management.learners.search.placeholder')}
        handleTermChange={onChange}
        fieldName={t('ui.event-management.list-filter.by-name')}
        allowEmptySearches={false}
        showButton={false}
        inputState={searchTerm}
        minCharacters={2}
        ref={ref}
        checkbox={{
          checked: !!showMyTeam,
          label: t('ui.event-management.learners.all-teams.my-teams-filter'),
          onChange: (value) => setSearchState({ checkbox: value }),
        }}
      />
      {notification && <Notification message={notification} />}
      <Loader status={status} />
      {status !== 'pending' && status !== 'error' && (
        <>
          <Box
            mt={(theme) => theme.spacing(spacingSizeMap.M)}
            mb={(theme) => theme.spacing(spacingSizeMap.XS)}
          >
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm="auto">
                {title}
              </Grid>
              <Grid item xs={3} sm="auto">
                <Button
                  sx={{ padding: '0.5rem 1rem' }}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/teams/create"
                  startIcon={<PlusIcon />}
                  fullWidth
                >
                  {t('ui.event-management.teams.create-team')}
                </Button>
              </Grid>
            </Grid>
            {limitExceeded ? (
              <Grid item xs={12} mt={0}>
                <SearchResultInfo
                  onClick={handleSearchMessageClick}
                  i18nKey={searchResultMessage}
                  sx={(theme) => ({
                    marginBottom: theme.spacing(spacingSizeMap.S),
                    padding: theme.spacing(spacingSizeMap.S),
                  })}
                />
              </Grid>
            ) : null}
          </Box>
          {pagedItems?.length > 0 ? (
            <TeamsList
              teamsResponse={teamData!}
              handlePageChange={onPageChange}
            />
          ) : (
            isFetched && t('ui.event-management.learners.teams.list.no-results')
          )}
        </>
      )}
    </>
  );
};
export default TeamsTab;
