import { useTranslation } from 'react-i18next';
import { AllDialectOptions } from 'types/dialects';
import { LicensedProduct } from 'types/types';

import {
  DFC_CORE_LANGUAGES,
  DIALECT_CODES,
  ENABLED_EVALUATOR_DIALECTS,
} from 'variables';

export const getEvaluatorDialects = (productType: LicensedProduct) => {
  if (productType === 'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE') {
    return [
      {
        textKey:
          'ui.event-management.events.create.event-language.english_united_kingdom',
        value: DIALECT_CODES.ENGLISH,
      },
    ];
  }
  if (productType === 'DISCOVERY_FULL_CIRCLE_PROFILE') {
    return DFC_CORE_LANGUAGES;
  }

  return ENABLED_EVALUATOR_DIALECTS.filter(
    (dialect) => !dialect.disabledForProducts?.includes(productType),
  );
};

export const useFormatEvaluatorDialect = (
  evaluatorDialects: { textKey: string; value: AllDialectOptions }[],
): Record<AllDialectOptions, string> => {
  const { t } = useTranslation();
  return evaluatorDialects.reduce(
    (acc, { textKey, value }) => {
      return { ...acc, [value]: t(textKey) };
    },
    {} as Record<string, string>,
  );
};
