import { useState } from 'react';

const DEFAULT_SEARCH_TERM = '';
type FilterBehaviour = 'DEFAULT_RETURN_ALL' | 'DEFAULT_RETURN_NONE';

const useSearchFilter = <T, K extends keyof T>(
  items: T[] | undefined,
  searchFields: K[],
  defaultSearchTerm = DEFAULT_SEARCH_TERM,
  behaviour: FilterBehaviour = 'DEFAULT_RETURN_ALL',
  filterFn?: (filter: string) => (item: T) => boolean,
): {
  filteredItems: T[] | undefined;
  handleTermChange: (newTerm: string) => void;
  searchTerm: string;
  selectedFilter: string;
  handleFilterChange: (filter: string) => void;
} => {
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
  const [selectedFilter, setSelectedFilter] = useState<string>('');

  const searchResults =
    behaviour === 'DEFAULT_RETURN_NONE' && searchTerm === DEFAULT_SEARCH_TERM
      ? []
      : items?.filter((item) =>
          searchFields.some((searchField) =>
            String(item[searchField])
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
        );

  const filteredItems =
    selectedFilter && filterFn
      ? searchResults?.filter(filterFn(selectedFilter))
      : searchResults;

  const handleTermChange = (newTerm: string) => setSearchTerm(newTerm);
  const handleFilterChange = (filter: string) => setSelectedFilter(filter);

  return {
    filteredItems,
    handleTermChange,
    searchTerm,
    selectedFilter,
    handleFilterChange,
  };
};

export default useSearchFilter;
