import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgMailSent = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 136 120"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g clipPath="url(#mail_sent_svg__a)">
      <path
        fill="#DB3F7E"
        fillRule="evenodd"
        d="M134.551 59.742c0 32.996-26.748 59.745-59.744 59.745-32.996 0-59.745-26.75-59.745-59.744 0-32.996 26.749-59.744 59.745-59.744 32.996 0 59.744 26.748 59.744 59.743Z"
        clipRule="evenodd"
      />
      <path
        fill="#D8E6E0"
        fillRule="evenodd"
        d="M79.25 56.258A438470.42 438470.42 0 0 0 50.34 80.795l-5.918-29.121h10.735l24.092 4.584Z"
        clipRule="evenodd"
      />
      <path
        fill="#D8E6E0"
        fillRule="evenodd"
        d="M133.898 25.928 78.075 77.595 42.261 53.023l8.202-5.122a717.615 717.615 0 0 0 83.434-21.973Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFEFE"
        fillRule="evenodd"
        d="M133.898 25.928 26.29 48.35c6.31 3.246 12.618 6.493 18.927 9.74l88.681-32.162Z"
        clipRule="evenodd"
      />
      <path
        fill="#C0C2BD"
        fillRule="evenodd"
        d="m50.341 80.795 11.544-9.798-8.617-6.688h-2.927v16.486Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFFEFE"
        fillRule="evenodd"
        d="M51.623 65.093c10.795 6.21 21.59 12.42 32.384 18.632l49.891-57.797-82.275 39.165Z"
        clipRule="evenodd"
      />
      <path
        stroke="#B1B3AF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.519}
        d="m37.899 116.685 21.156-27.159M1.39 71.504 27.89 61.485 1.389 71.504Zm6.266 22.67L33.28 77.346 7.655 94.175Z"
      />
      <path
        stroke="#B1B3AF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.656}
        d="m123.876 27.807 9.018-1.879-5.753 7.828"
      />
    </g>
    <defs>
      <clipPath id="mail_sent_svg__a">
        <path fill="#fff" d="M0 0h135.254v120H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMailSent;
