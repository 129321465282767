import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { PractitionerData, useGetPractitioner } from 'api';
import isSufficientScope from 'utils/isSufficientScope';
import AuthContext, { User } from 'contexts/AuthContext';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { Role } from 'types/types';
import { BetaContext } from '@insights-ltd/design-library/components';
import { UserPermissions, PermissionActions } from 'permissions';
import { BETA_ROLE_MAP, ROLE_MAP } from './roleToPermissions';

// This is needed to cover the state where the data fetch is successful but the
// useEffect has not run yet.
const waitingForRerender = (status: string, user?: PractitionerData) =>
  status === 'success' && !user;

export const getUserWithPermissions = (
  user: PractitionerData,
  roleMap: Record<Role, UserPermissions>,
): User => ({
  ...user,
  permissions: user.roles.reduce(
    (accumulator: UserPermissions, currRole: Role) => {
      const rolePermissions: UserPermissions = roleMap[currRole];
      const actions = Object.keys(roleMap[currRole]) as PermissionActions[];
      // eslint-disable-next-line no-restricted-syntax
      for (const action of actions) {
        if (
          !accumulator[action] ||
          isSufficientScope(rolePermissions[action], accumulator[action])
        ) {
          // eslint-disable-next-line no-param-reassign
          accumulator[action] = rolePermissions[action];
        }
      }
      return accumulator;
    },
    {} as UserPermissions,
  ),
});

const AuthenticationProvider = ({ children }: any) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<User | undefined>();
  const { status, error, data } = useGetPractitioner();
  const { betaEnabled } = useContext(BetaContext) ?? { betaEnabled: false };
  const value = useMemo(() => {
    if (status === 'success') {
      return { user };
    }

    return {};
  }, [status, user]);

  React.useEffect(() => {
    if (!error && data) {
      setUser(
        getUserWithPermissions(data, betaEnabled ? BETA_ROLE_MAP : ROLE_MAP),
      );
      datadogRum.setUser({ id: data.id });
    }
  }, [betaEnabled, data, error]);

  if (status === 'pending' || waitingForRerender(status, user)) {
    return (
      <FullScreenSpinner
        message={t('ui.event-management.authentication.loading')}
      />
    );
  }

  // Ignore unauthenticated users as they will fall into Unauthenticated Routes
  if (status === 'error' && error?.message !== '401') {
    return (
      <FullScreenError
        message={t('ui.event-management.authentication.error')}
      />
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthenticationProvider;
