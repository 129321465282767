import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, P, Span } from '@insights-ltd/design-library/components';
import { Grid, Link, List, ListItem, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import { ChaptersStatus } from 'api';
import { getChapterKey } from 'utils/getChapterKey';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import DFCAgreePurchaseModal from './DFCAgreePurchaseModal';

export type PurchaseSummaryLine = {
  chapterName: string;
  unitCost: number;
  count: number;
};

export type PurchaseDialogProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmPurchase: () => void;
  chapterSummaries: PurchaseSummaryLine[];
  purchaseChapters: ChaptersStatus[];
  contributorsCount: number;
  unitBalance: number;
  firstModalOpen: boolean;
  OnConfirm: () => void;
  OnDecline: () => void;
  newContributors: number;
  showNewContributors: boolean;
};

export type PurchaseSummaryProps = {
  lines: PurchaseSummaryLine[];
  contributorsCount: number;
  unitBalance: number;
  totalUnitCost: number;
  invalidTransaction: boolean;
  newContributors: number;
  showNewContributors: boolean;
};

const StyledDivRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '> :last-child': {
    marginLeft: 'auto',
  },
});

const StyledSpan = styled('span')(({ theme }) => ({
  overflow: 'balance',
  width: ' 70%',
  color: theme.palette.grey[600],
}));

const StyledTransactionBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'invalidTransaction',
})<{ invalidTransaction: boolean }>(({ invalidTransaction, theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.XS),
  padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
    spacingSizeMap.S,
  )}`,
  background: theme.palette.primary.light,
  borderRadius: '4px',
  border: invalidTransaction
    ? `solid 1px ${theme.palette.error.main}`
    : 'undefined,',
}));

const StyledSummaryBox = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.S),
}));

const StyledSummaryRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '> :first-of-type': {
    width: '25px',
  },
  '> :last-child': {
    marginLeft: 'auto',
  },
});

const PurchaseSummary = ({
  lines,
  contributorsCount,
  unitBalance,
  totalUnitCost,
  invalidTransaction,
  newContributors,
  showNewContributors,
}: PurchaseSummaryProps) => {
  const { t } = useTranslation();

  const postTransBalance = unitBalance - totalUnitCost;
  const summaryUnitsLabel = t(
    'ui.event-management.events.assign-chapters.summary-box.units',
  );
  return (
    <Grid container spacing={2} direction="column">
      {invalidTransaction && (
        <Grid item>
          <Alert
            severity="error"
            sx={(theme) => ({
              alignItems: 'center',
              padding: '0',
              svg: {
                fill: theme.palette.error.main,
              },
            })}
          >
            <Span color="error" variant="body-bold">
              {t('ui.event-management.events.purchase-profiles.error-balance')}{' '}
              <Link component={RouterLink} to="/account" color="textPrimary">
                <Span color="primary">
                  {t(
                    'ui.event-management.events.purchase-profiles.error-balance-action-link',
                  )}
                </Span>
              </Link>
            </Span>
          </Alert>
        </Grid>
      )}
      <Grid item>
        <StyledTransactionBox invalidTransaction={invalidTransaction}>
          <StyledDivRow>
            <Span color="textSecondary">
              {t('ui.event-management.events.purchase-profiles.unit-balance')}
            </Span>
            <Span variant="body-bold" color="textSecondary">
              {unitBalance}
            </Span>
          </StyledDivRow>
          <StyledDivRow>
            <Span color={invalidTransaction ? 'error' : 'textSecondary'}>
              {t(
                'ui.event-management.events.purchase-profiles.transaction-total',
              )}
            </Span>
            <Span
              variant="body-bold"
              color={invalidTransaction ? 'error' : 'textSecondary'}
            >
              {totalUnitCost}
            </Span>
          </StyledDivRow>
          {!invalidTransaction && (
            <StyledDivRow>
              <Span color="textSecondary">
                {t(
                  'ui.event-management.events.purchase-profiles.post-transaction-balance',
                )}
              </Span>
              <Span variant="body-bold" color="textSecondary">
                {postTransBalance}
              </Span>
            </StyledDivRow>
          )}
        </StyledTransactionBox>
      </Grid>
      <Grid item>
        <StyledSummaryBox>
          <P variant="body-bold" color="textPrimary">
            {t('ui.event-management.events.purchase-profiles.summary-title')}:
          </P>
          <List disablePadding>
            {lines.map(({ chapterName, count, unitCost }) => {
              const titleKey = getChapterKey(chapterName);
              const sumChapterUnits = count * unitCost;
              return (
                <ListItem dense disableGutters key={titleKey}>
                  <StyledSummaryRow>
                    <Span color="textSecondary">{count}x</Span>
                    <Span color="textSecondary">
                      {t(
                        'ui.event-management.events.purchase-profiles.dfc.pre.purchase.profile.count',
                      )}
                    </Span>
                    <Span color="textSecondary">
                      {`${sumChapterUnits} ${summaryUnitsLabel}`}
                    </Span>
                  </StyledSummaryRow>
                </ListItem>
              );
            })}
            {showNewContributors && newContributors && (
              <ListItem disableGutters key="total-contributors">
                <StyledSummaryRow>
                  <Span color="textSecondary">{contributorsCount}x</Span>
                  <StyledSpan>
                    {t(
                      'ui.event-management.events.purchase-profiles.dfc.pre.purchase.contributor.count',
                    )}
                  </StyledSpan>
                  <Span color="textSecondary">{`${0} ${summaryUnitsLabel}`}</Span>
                </StyledSummaryRow>
              </ListItem>
            )}
            <ListItem dense disableGutters key="total-line">
              <StyledDivRow>
                <P variant="h4" color="textPrimary">
                  {t(
                    'ui.event-management.events.purchase-profiles.basket-total-label',
                  )}
                  :
                </P>
                <P
                  data-testid="totalTransactionUnits"
                  variant="h4"
                  color="textPrimary"
                >
                  {`${totalUnitCost}  ${summaryUnitsLabel}`}
                </P>
              </StyledDivRow>
            </ListItem>
          </List>
        </StyledSummaryBox>
      </Grid>
    </Grid>
  );
};

const DFCPurchaseDialog = ({
  open,
  onClose,
  handleConfirmPurchase,
  chapterSummaries,
  purchaseChapters,
  contributorsCount,
  unitBalance,
  firstModalOpen,
  OnConfirm,
  OnDecline,
  newContributors,
  showNewContributors,
}: PurchaseDialogProps) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    handleConfirmPurchase();
  };

  const summaryLines = chapterSummaries
    .map((summary) => {
      return {
        ...summary,
        count: purchaseChapters.filter(
          ({ status, name }) =>
            status === 'PENDING' && name === summary.chapterName,
        ).length,
      };
    })
    .filter((sum) => sum.count > 0);

  const totalUnitCost = summaryLines.reduce(
    (acc, val) => acc + val.count * val.unitCost,
    0,
  );
  const invalidTransaction: boolean = unitBalance < totalUnitCost;

  return (
    <>
      <Dialog
        onSubmit={handleConfirm}
        onClose={onClose}
        open={open}
        content={
          <DFCAgreePurchaseModal
            open={firstModalOpen}
            onConfirm={OnConfirm}
            onDecline={OnDecline}
          />
        }
      />

      <Dialog
        onSubmit={handleConfirm}
        onClose={onClose}
        open={open}
        content={
          <PurchaseSummary
            lines={summaryLines}
            contributorsCount={contributorsCount}
            unitBalance={unitBalance}
            totalUnitCost={totalUnitCost}
            invalidTransaction={invalidTransaction}
            newContributors={newContributors}
            showNewContributors={showNewContributors}
          />
        }
        primaryButtonText={t('ui.event-management.learner.purchase.button')}
        secondaryButtonText={t('ui.event-management.cancel')}
        title={t('ui.event-management.learner.purchase.button')}
        disablePrimaryButton={invalidTransaction}
      />
    </>
  );
};

export default DFCPurchaseDialog;
