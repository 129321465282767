import React from 'react';
import { useTranslation } from 'react-i18next';
import LocationIcon from '@mui/icons-material/LocationOn';
import { LocationData, LocationForm } from 'components/EventForms';
import FormHeader from 'components/FormHeader';
import EditFormNavigation from './EditFormNavigation';
import { EditProps } from './types';

const EditLocation = ({
  eventForm,
  onCancel,
  saveEvent,
  saving,
}: EditProps) => {
  const { t } = useTranslation();

  const onSubmit = (formData: LocationData) => {
    saveEvent(formData);
  };

  return (
    <>
      <FormHeader
        icon={LocationIcon}
        title={t('ui.event-management.events.create.location')}
      />
      <LocationForm event={eventForm} onSubmit={onSubmit}>
        <EditFormNavigation onCancel={onCancel} saving={saving} />
      </LocationForm>
    </>
  );
};

export default EditLocation;
