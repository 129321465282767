export type SearchResultsMessages = {
  lastPageKey: string;
  searchTermKey: string;
  defaultKey: string;
};

export const getSearchResultMessage = (
  { lastPageKey, searchTermKey, defaultKey }: SearchResultsMessages,
  isLastPage: boolean,
  searchTerm?: string,
  minCharacters: number = 0,
) => {
  if (isLastPage) {
    return lastPageKey;
  }
  if (searchTerm && searchTerm.length >= minCharacters) {
    return searchTermKey;
  }

  return defaultKey;
};
