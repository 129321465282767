import { BaseDialects } from '@insights-ltd/design-library/utils';
import { EventType } from './types';

export type AdditionalDialectOptions = 'evaluation-dialect';

export type AllDialectOptions = Dialects | AdditionalDialectOptions;

export type Dialects = BaseDialects | 'is-IS';

export type DialectCodes = { [key: string]: AllDialectOptions };

export type DialectDetail = {
  textKey: string;
  eventType: EventType[];
  pronouns: {
    singular: Inclusivity[];
    plural: Inclusivity[];
  };
};

export type DialectDetails = {
  [key in AllDialectOptions]?: DialectDetail;
};

export type Pronoun = 'HE' | 'SHE' | 'THEY';

export type Inclusivity = { textKey: string; value: Pronoun };

export type ProfileDialectStrategies = 'SINGLE_DIALECT' | 'EVALUATION_DIALECT';

export const SUPPORTED_DIALECTS = [
  'da-DK',
  'de-DE',
  'en-GB',
  'es-ES',
  'fr-FR',
  'nl-NL',
  'pl-PL',
  'it-IT',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'ja-JP',
  'ro-RO',
  'cs-CZ',
  'ko-KR',
  'sv-SE',
  'uk-UA',
  'sr-RS',
  'el-GR',
  'zh-CN',
  'vi-VN',
  'tr-TR',
] as const;
export type PreferredDialect = Extract<
  Dialects,
  (typeof SUPPORTED_DIALECTS)[number]
>;

export const BETA_SUPPORTED_DIALECTS = [
  'da-DK',
  'de-DE',
  'en-GB',
  'es-ES',
  'fr-FR',
  'nl-NL',
  'pl-PL',
  'it-IT',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'ja-JP',
  'ro-RO',
  'cs-CZ',
  'ko-KR',
  'sv-SE',
  'uk-UA',
  'sr-RS',
  'el-GR',
  'zh-CN',
  'vi-VN',
  'tr-TR',
] as const;
export type PreferredBetaDialect = Extract<
  Dialects,
  (typeof BETA_SUPPORTED_DIALECTS)[number]
>;
