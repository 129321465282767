import Button from '@mui/material/Button';
import React from 'react';
import { ScoreType } from 'types/types';
import { useTranslation } from 'react-i18next';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type ChartDownloadProps = {
  handleDownloadPreferred: (scoreType: ScoreType) => void;
  handleDownloadDominant: (scoreType: ScoreType) => void;
  disabled?: boolean;
};

const ChartDownload = ({
  handleDownloadPreferred,
  handleDownloadDominant,
  disabled = false,
}: ChartDownloadProps) => {
  const { t } = useTranslation();
  const [{ scoreType }] = useWheelContext();
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          handleDownloadDominant(scoreType);
        }}
        sx={(theme) => ({ marginRight: theme.spacing(spacingSizeMap.S) })}
        disabled={disabled}
      >
        {t('ui.event-management.events.team-wheel.download-dominant-chart')}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          handleDownloadPreferred(scoreType);
        }}
        disabled={disabled}
      >
        {t('ui.event-management.events.team-wheel.download-preferred-chart')}
      </Button>
    </>
  );
};

export default ChartDownload;
