import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import CreateEventForm from './CreateEventForm';

const CreateEvent = () => {
  const { t } = useTranslation();
  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.create-event"
      activeTextKey="ui.event-management.events.create.crumb"
      crumbs={{
        '/': t('ui.event-management.events.nav.home'),
        '/experiences': t('ui.event-management.events.title'),
      }}
      headingKey="ui.event-management.events.create.title"
    >
      <CreateEventForm />
    </BreadcrumbLayout>
  );
};

export default CreateEvent;
