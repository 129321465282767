import React, { useState } from 'react';
import { Paginator } from 'components/hooks/usePagination';
import { EvaluatorLink } from 'api';
import DirectLinkCard from 'components/DirectLinkCard/DirectLinkCard';
import { Box, Grid, styled, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PaginationControls } from 'components/PaginationControls/PaginationControls';
import { SearchFilterV2 } from 'components/SearchFilter/SearchFilter';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { H2, P, SortSelect } from '@insights-ltd/design-library/components';
import SearchCount from 'components/SearchCount/SearchCount';
import { DIALECT_MAP } from 'variables';
import { Dialects } from 'types/dialects';
import { EvaluatorLinksSortCategory } from 'types/types';

export const ITEMS_PER_PAGE = 9;

export type EvaluatorLinkFilter = 'ALL' | 'PRACTITIONER';

type EvaluatorLinkListProps = {
  isOrgSpecific: boolean;
  paginator: Paginator<EvaluatorLink>;
  filter: EvaluatorLinkFilter;
  searchTerm?: string;
  onFilterChange: (value: EvaluatorLinkFilter) => void;
  onSearchTermChange: (value: string) => void;
  preFilteredCount: number;
  sortCategory: EvaluatorLinksSortCategory;
  setLinkSortBy: (sortBy: EvaluatorLinksSortCategory) => void;
};

const translations = {
  placeholder:
    'ui.event-management.evaluator-links.search-bar.search.placeholder',
  byLabel: 'ui.event-management.evaluator-links.search-bar.search.by-link-name',
  search: 'ui.event-management.evaluator-links.search-bar.search',
  noResults: 'ui.event-management.evaluator-links.no-results',
  noneCreated: 'ui.event-management.evaluator-links.none-created',
  sortOptions: {
    dateCreated:
      'ui.event-management.evaluator-links.direct-link-card.sort-by-date-created',
    mostCompletedEvaluators:
      'ui.event-management.evaluator-links.direct-link-card.sort-by-most-completed-evaluators',
    leastCompletedEvaluators:
      'ui.event-management.evaluator-links.direct-link-card.sort-by-least-completed-evaluators',
  },
};

const StyledP = styled(P)(({ theme }) => ({
  marginTop: `${theme.spacing(spacingSizeMap.S)} !important`,
  marginLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
  fontWeight: theme.typography.fontWeightBold,
}));

const LinksContainer = ({
  linkCards,
  searchTitle,
  totalCount,
  paginator,
  sortCategory,
  setLinkSortBy,
}: {
  paginator: Paginator<EvaluatorLink>;
  linkCards: JSX.Element[];
  searchTitle: string;
  totalCount: number;
  sortCategory: EvaluatorLinksSortCategory;
  setLinkSortBy: (sortBy: EvaluatorLinksSortCategory) => void;
}) => {
  enum SortType {
    Date = 'DATE_CREATED',
    MostCompleted = 'MOST_COMPLETED',
    LeastCompleted = 'LEAST_COMPLETED',
  }

  const { t } = useTranslation();

  const onSortByChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLinkSortBy(e?.target?.value as EvaluatorLinksSortCategory);
  };

  const sortOptions: { label: string; value: SortType }[] = [
    { label: t(translations.sortOptions.dateCreated), value: SortType.Date },
    {
      label: t(translations.sortOptions.mostCompletedEvaluators),
      value: SortType.MostCompleted,
    },
    {
      label: t(translations.sortOptions.leastCompletedEvaluators),
      value: SortType.LeastCompleted,
    },
  ];

  if (totalCount === 0) {
    return (
      <>
        <Box
          sx={(theme) => ({
            marginTop: `${theme.spacing(spacingSizeMap.M)} !important`,
            marginLeft: `${theme.spacing(spacingSizeMap.XS)} !important`,
            marginBottom: `${theme.spacing(spacingSizeMap.XS)} !important`,
          })}
        >
          <H2 variant="h3">
            {searchTitle}
            <SearchCount
              sx={(theme) => ({
                marginLeft: theme.spacing(spacingSizeMap.XS),
              })}
              count={totalCount}
            />
          </H2>
        </Box>
        <StyledP color="textSecondary" variant="body-bold">
          {t(translations.noResults)}
        </StyledP>
      </>
    );
  }
  return (
    <>
      <Box
        sx={() => ({
          marginTop: `0`,
          marginBottom: '2px !important',
        })}
      >
        <H2 variant="h3">
          {searchTitle}
          <SearchCount
            sx={(theme) => ({
              marginLeft: theme.spacing(spacingSizeMap.XS),
            })}
            count={totalCount}
          />
        </H2>
      </Box>
      <Grid
        sx={() => ({
          display: 'flex',
          justifyContent: 'space-between',
        })}
        item
        xs={12}
        sm="auto"
        paddingLeft="0rem !important"
        paddingRight="0rem !important"
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingLeft: '0',
            paddingRight: '0',
          }}
        >
          <PaginationControls
            itemsPerPage={ITEMS_PER_PAGE}
            paginator={paginator}
          />
          <SortSelect
            labelText={t(
              'ui.event-management.evaluator-links.direct-link-card.sort-by',
            )}
            sortOptions={sortOptions}
            onChange={onSortByChange}
            value={sortCategory}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} marginTop="0 !important">
        {linkCards}
      </Grid>
      <Grid
        container
        direction="row"
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          padding: `${theme.spacing(spacingSizeMap.S)} 0`,
        })}
      >
        <PaginationControls
          itemsPerPage={ITEMS_PER_PAGE}
          paginator={paginator}
        />
      </Grid>
    </>
  );
};

const EvaluatorLinkList = ({
  isOrgSpecific,
  paginator,
  filter,
  searchTerm,
  onFilterChange,
  onSearchTermChange,
  preFilteredCount,
  sortCategory,
  setLinkSortBy,
}: EvaluatorLinkListProps) => {
  const { t } = useTranslation();
  const { pagedItems: pagedLinks, totalCount } = paginator;
  const [lastCopiedLinkId, setLastCopiedLinkId] = useState<string>();

  const getDialectTranslationKey = (dialect: Dialects) =>
    t(DIALECT_MAP.find(({ value }) => value === dialect)!.textKey);

  const directLinks = pagedLinks.map(
    (
      {
        id,
        link,
        name,
        organisation,
        totalCompletedEvaluators,
        evaluatorModel,
        dialect: code = 'en-GB',
      },
      index,
    ) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        key={id}
        sx={{
          paddingTop: index > 2 ? '1rem !important' : '0 !important',
          paddingLeft: '17px !important',
        }}
      >
        <DirectLinkCard
          id={id}
          evaluatorLink={link}
          organisation={organisation}
          name={name}
          completedEvaluators={totalCompletedEvaluators}
          linkCopied={lastCopiedLinkId === id}
          onCopy={setLastCopiedLinkId}
          evaluatorModel={evaluatorModel}
          dialect={{ code, name: getDialectTranslationKey(code) }}
        />
      </Grid>
    ),
  );

  const searchTranslations = {
    PRACTITIONER: {
      org: {
        noSearchTerm:
          'ui.event-management.evaluator-links.list.my-evaluator-links',
        searchTerm:
          'ui.event-management.evaluator-links.list-header.search-term',
      },
      all: {
        noSearchTerm:
          'ui.event-management.evaluator-links.list-header.all.my-evaluator-links',
        searchTerm:
          'ui.event-management.evaluator-links.list-header.all.search-term',
      },
    },
    ALL: {
      org: {
        noSearchTerm: 'ui.event-management.evaluator-links.list-header',
        searchTerm:
          'ui.event-management.evaluator-links.list-header.search-term',
      },
      all: {
        noSearchTerm: 'ui.event-management.evaluator-links.list-header.all',
        searchTerm:
          'ui.event-management.evaluator-links.list-header.all.search-term',
      },
    },
  };

  const {
    searchTerm: searchTermTranslation,
    noSearchTerm: noSearchTermTranslation,
  } = searchTranslations[filter][isOrgSpecific ? 'org' : 'all'];

  const searchResultsTitle =
    searchTerm && searchTerm.length > 1
      ? t(searchTermTranslation, {
          searchTerm,
        })
      : t(noSearchTermTranslation);

  return (
    <Grid>
      <Box
        sx={(theme) => ({
          marginBottom: theme.spacing(spacingSizeMap.S),
        })}
      >
        <Tabs
          value={filter}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_e, value) => {
            onFilterChange(value);
          }}
        >
          <Tab
            sx={(theme) => ({
              color:
                filter === 'ALL'
                  ? `${theme.palette.text.primary} !important`
                  : theme.palette.blue.main,
              minWidth: '4.5rem',
              margin: '0 0.5rem',
              padding: '16px 8px',
              fontWeight: theme.typography.fontWeightBold,
            })}
            value="ALL"
            label={t('ui.event-management.evaluator-links.filter.all-links')}
          />
          <Tab
            sx={(theme) => ({
              color:
                filter === 'PRACTITIONER'
                  ? `${theme.palette.text.primary} !important`
                  : theme.palette.blue.main,
              minWidth: '4.5rem',
              margin: '0 0.5rem',
              padding: '16px 8px',
              fontWeight: theme.typography.fontWeightBold,
            })}
            value="PRACTITIONER"
            label={t(
              'ui.event-management.evaluator-links.filter.my-created-links',
            )}
          />
        </Tabs>
      </Box>
      <SearchFilterV2
        fieldName={t(translations.byLabel)}
        label={t(translations.search)}
        placeholder={t(translations.placeholder)}
        showButton={false}
        sx={(theme: any) => ({
          marginTop: `${theme.spacing(spacingSizeMap.XS)} !important`,
          padding: `${theme.spacing(spacingSizeMap.S)} !important`,
        })}
        inputState={searchTerm}
        handleTermChange={onSearchTermChange}
        minCharacters={2}
      />
      {preFilteredCount > 0 ? (
        <LinksContainer
          linkCards={directLinks}
          paginator={paginator}
          totalCount={totalCount}
          searchTitle={searchResultsTitle}
          sortCategory={sortCategory}
          setLinkSortBy={setLinkSortBy}
        />
      ) : (
        <Box>
          <StyledP color="textSecondary" variant="body-bold">
            {t(translations.noneCreated)}
          </StyledP>
        </Box>
      )}
    </Grid>
  );
};

export default EvaluatorLinkList;
