import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Theme, styled, useMediaQuery } from '@mui/material';
import isPathActive from 'utils/isPathActive';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import { NavBarItem } from './types';
import MoreDropDownTab from './MoreDropdown';

type Props = {
  navBarItems: NavBarItem[];
};

const StyledDiv = styled('div')(({ theme }) => ({
  '> * + *': {
    marginLeft: theme.spacing(spacingSizeMap.M),
  },
  height: '100%',
}));

export const NavBar = ({ navBarItems }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { resetState: clearState } = useSearchContext();
  const isSmallerScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1150),
  );
  const tabIndex = navBarItems.findIndex(isPathActive(pathname));
  const totalTextLength = navBarItems.reduce(
    (acc, { text }) => acc + text.length,
    0,
  );

  const showMoreButton = totalTextLength > 60 || isSmallerScreen;

  const [firstItem, secondItem, thirdItem, ...rest] = navBarItems;

  const [navbarTabs, moreButtons] = showMoreButton
    ? [[firstItem, secondItem, thirdItem], rest]
    : [navBarItems, []];
  const onClickTab = (value: number) => {
    clearState();
    navigate(navBarItems[value].to);
  };

  const tabsValue = showMoreButton && tabIndex > 2 ? 3 : tabIndex;

  return (
    <StyledDiv>
      <Tabs
        value={tabsValue > -1 ? tabsValue : false}
        sx={(theme) => ({
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.grey[700],
          },
          '> div': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          },
          marginLeft: theme.spacing(spacingSizeMap.S),
          height: '100%',
        })}
      >
        {navbarTabs.map(({ text }, index) => (
          <Tab
            sx={(theme) => ({
              minWidth: '4.5rem',
              margin: `0`,
              padding: '8px 16px 20px',
              color:
                tabIndex === index
                  ? `${theme.palette.grey[700]} !important`
                  : theme.palette.blue.main,
              opacity: '1',
            })}
            value={index}
            label={text}
            key={text}
            onClick={() => onClickTab(index)}
          />
        ))}
        {showMoreButton && (
          <MoreDropDownTab
            tabsValue={tabIndex}
            navBarItems={moreButtons}
            onSelect={(tabSelected) => onClickTab(tabSelected)}
          />
        )}
      </Tabs>
    </StyledDiv>
  );
};
