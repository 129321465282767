import Link from '@mui/material/Link';
import { Group, GroupType } from 'api';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { P } from '@insights-ltd/design-library/components';

type Kind = 'orgCard' | 'subtitle' | 'bold';

type Props = {
  group: Group;
  i18nKey?: string | null;
  className?: string;
  kind?: Kind;
};

const GroupData = styled('span', {
  shouldForwardProp: (prop) => prop !== 'kind',
})<{ kind: Kind }>(({ theme, kind }) => {
  const classMap = {
    orgCard: {
      groupData: null,
    },
    bold: {
      groupData: {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    subtitle: {
      groupData: {
        color: theme.palette.text.secondary,
      },
    },
  };
  return classMap[kind].groupData;
});

const OrganisationGroupLink = ({
  group: { id, name, type },
  i18nKey = 'ui.event-management.organisation.parent-group',
  className,
  kind = 'subtitle',
}: Props) => {
  const { t } = useTranslation();
  const typeTranslations: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.type.customer'),
    PARTNER: t('ui.event-management.organisations.type.partner'),
  };

  return (
    <P className={className}>
      <Trans
        i18nKey={i18nKey ?? undefined}
        defaults="<0>Part of</0><1>{groupName}</1><2>group</2>"
        values={{ groupName: name, groupType: typeTranslations[type] }}
      >
        <GroupData kind={kind}>Part of</GroupData>
        <Link
          sx={(theme) => ({
            color: theme.palette.blue.main,
            fontWeight: theme.typography.fontWeightBold,
            wordBreak: 'break-word',
            flex: '4',
            margin: '0.25rem',
          })}
          component={RouterLink}
          to={`/groups/${id}`}
        >
          {name}
        </Link>
        <GroupData kind={kind}>group</GroupData>
        <GroupData
          sx={{ marginLeft: '0.25rem', fontWeight: '600' }}
          kind={kind}
        >
          ({typeTranslations[type]})
        </GroupData>
      </Trans>
    </P>
  );
};

export default OrganisationGroupLink;
