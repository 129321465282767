import { Box, Card, CardContent, Grid, Link, styled } from '@mui/material';
import { P } from '@insights-ltd/design-library/components';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DefaultIcon from 'components/Svgs/icons/Organisation';
import { useTranslation } from 'react-i18next';
import PermissionGuard from 'components/PermissionGuard';

type Props = {
  id: string;
  name: string;
};

const StyledGroupSvg = styled(DefaultIcon)(({ theme }) => ({
  position: 'relative',
  top: '24px',
  width: '48px',
  height: '48px',
  padding: '5px 4px',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: '5px',
  fill: theme.palette.grey[500],
}));

const StyledP = styled(P)({ lineHeight: '28px !important' });

const StyledSpan = styled('span')({ marginTop: '12px' });

const MainOrganisationCard = ({ id, name }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid
      data-testid={`organisation-card-${id}`}
      item
      xs={12}
      md={4}
      key={id}
      sx={{
        minHeight: '260px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: 0,
        }}
      >
        <StyledGroupSvg
          title={t('ui.event-management.organisations.group.alt.icon')}
        />
      </Box>
      <Card
        variant="outlined"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100% - 24px)',
          padding: '0',
          borderColor: theme.palette.grey[500],
        })}
      >
        <CardContent
          sx={{
            padding: '0 !important',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
              textAlign: 'center',
            }}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.blue.light,
                width: '100%',
                height: '107px',
                margin: '0',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              })}
            >
              <Link
                component={RouterLink}
                to={`/organisations/${id}`}
                sx={(theme) => ({
                  width: '100%',
                  fontSize: '1.5rem',
                  lineHeight: '30px !important',
                  fontWeight: theme.typography.fontWeightBold,
                  color: `${theme.palette.blue.main} !important`,
                  '&:hover': {
                    color: `${theme.palette.blue.dark} !important`,
                  },
                  '&:active': {
                    color: `${theme.palette.text.primary} !important`,
                  },
                  wordBreak: 'break-word',
                })}
              >
                {name}
              </Link>
              <StyledP color="textSecondary" variant="body-bold">
                {t('ui.event-management.organisations.main')}
              </StyledP>
            </Box>
            <Grid justifyContent="center" container direction="column">
              <StyledSpan>
                <Link
                  component={RouterLink}
                  to={`/organisations/${id}`}
                  state={{ activeTab: 'practitioners' }}
                >
                  <StyledP color="primary" variant="body-bold">
                    {t('ui.event-management.organisations.link.users')}
                  </StyledP>
                </Link>
                <Link
                  component={RouterLink}
                  to={`/organisations/${id}`}
                  state={{ activeTab: 'upcomingEvents' }}
                >
                  <StyledP color="primary" variant="body-bold">
                    {t('ui.event-management.organisations.link.experiences')}
                  </StyledP>
                </Link>
                <PermissionGuard
                  requiredPermissions={[
                    { action: 'Organisation_Update', scope: 'Global' },
                  ]}
                >
                  <Link
                    component={RouterLink}
                    to={`/organisations/${id}`}
                    state={{ activeTab: 'invites' }}
                  >
                    <StyledP color="primary" variant="body-bold">
                      {t('ui.event-management.organisations.link.invites')}
                    </StyledP>
                  </Link>
                </PermissionGuard>
              </StyledSpan>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default MainOrganisationCard;
