import { EventSummaryResponse, Group, GroupType } from 'api/httpEntities';
import {
  Org,
  sortByPosition,
  sortOrgsAlphabetically,
} from '@insights-ltd/design-library/utils';
import { DateTime } from 'luxon';
import {
  CHAPTER_POSITION,
  COLOUR_SCORE_COLOUR_POSITION,
  INSIGHTS_ORGANISATION_ID,
  ROLE_POSITION,
} from 'variables';
import { Role } from 'types/types';

export type GroupOrg = Org & { groupType?: GroupType; parent?: Group };

export const sortChapters = (nameA: string, nameB: string) =>
  sortByPosition(nameA, nameB, CHAPTER_POSITION);

export const sortOrgs = (a: Org, b: Org) =>
  sortOrgsAlphabetically(a, b, INSIGHTS_ORGANISATION_ID);

export const sortGroupsAndOrgs = (
  a: GroupOrg,
  b: GroupOrg,
  priorityId = INSIGHTS_ORGANISATION_ID,
) => {
  if (a.groupType && b.groupType) {
    return a.name.localeCompare(b.name);
  }

  if (a.groupType && !b.groupType) {
    return -1;
  }

  if (!a.groupType && b.groupType) {
    return 1;
  }

  if (a.parent && b.parent) {
    return a.name.localeCompare(b.name);
  }

  if (a.parent && !b.parent) {
    return -1;
  }

  if (!a.parent && b.parent) {
    return 1;
  }

  if (!a.parent && !b.parent && priorityId) {
    if (a.id === priorityId) return -1;
    if (b.id === priorityId) return 1;
  }
  return a.name.localeCompare(b.name);
};

export const sortRoles = (a: Role, b: Role) =>
  sortByPosition(a, b, ROLE_POSITION);

export const sortByColour = (a: string, b: string) =>
  sortByPosition(a, b, COLOUR_SCORE_COLOUR_POSITION);

export const sortColourScores = (
  [, scoreA]: [string, number],
  [, scoreB]: [string, number],
) => {
  if (scoreA === scoreB) {
    return 0;
  }
  return scoreA > scoreB ? -1 : 1;
};

export const sortByStartDate = (
  { startsAt: startsAtA }: Pick<EventSummaryResponse, 'startsAt'>,
  { startsAt: startsAtB }: Pick<EventSummaryResponse, 'startsAt'>,
) => {
  return DateTime.fromISO(startsAtA) > DateTime.fromISO(startsAtB) ? -1 : 1;
};

export const sortByCancelledDate = (
  {
    cancelledAt: cancelledAtA,
    startsAt: startsAtA,
  }: Pick<EventSummaryResponse, 'startsAt' | 'cancelledAt'>,
  {
    cancelledAt: cancelledAtB,
    startsAt: startsAtB,
  }: Pick<EventSummaryResponse, 'startsAt' | 'cancelledAt'>,
) => {
  if (cancelledAtA && cancelledAtB && cancelledAtA !== cancelledAtB) {
    return DateTime.fromISO(cancelledAtA) > DateTime.fromISO(cancelledAtB)
      ? -1
      : 1;
  }

  return sortByStartDate({ startsAt: startsAtA }, { startsAt: startsAtB });
};

export const sortCancelledExperiences = <T extends EventSummaryResponse>(
  ev: T[] = [],
) => {
  const [events, eventsWithNoDate] = ev?.reduce(
    (acc, current) => {
      if (current.cancelledAt) {
        acc[0].push(current);
      } else {
        acc[1].push(current);
      }

      return acc;
    },
    [[], []] as T[][],
  ) ?? [[], []];

  return [
    ...events.sort(sortByCancelledDate),
    ...eventsWithNoDate.sort(sortByStartDate),
  ];
};

export type Invitee = {
  email: string;
  fullName: string;
};

export const sortInviteesAlphabetically = <T extends Invitee>(
  inviteeA: T,
  inviteeB: T,
) => {
  const { fullName: nameA, email: emailA } = inviteeA;
  const { fullName: nameB, email: emailB } = inviteeB;

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  if (emailA < emailB) {
    return -1;
  }
  if (emailA > emailB) {
    return 1;
  }

  return 0;
};
