import { Box, Grid, SxProps, Theme } from '@mui/material';
import React from 'react';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const TabControls = ({ children, sx = {} }: Props) => (
  <Grid container sx={{ justifyContent: 'flex-end', ...sx }}>
    <Grid item xs={12} md="auto">
      <Box
        sx={(theme) => ({
          mb: theme.spacing(spacingSizeMap.L),
          mt: theme.spacing(-6),
        })}
      >
        {children}
      </Box>
    </Grid>
  </Grid>
);

export default TabControls;
