import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import { Trans, useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { Checkbox, P, Span } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type Props = {
  open: boolean;
  isLoading: boolean;
  isError: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmDeleteDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  isError,
}: Props) => {
  const { t } = useTranslation();
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [errorMessageKey, setErrorMessageKey] = useState('');

  useEffect(() => {
    if (isError) {
      setErrorMessageKey(
        'ui.event-management.events.learner.confirm-delete.error',
      );
    } else {
      setErrorMessageKey('');
    }
  }, [check1, check2, check3, isError]);

  useEffect(() => {
    setCheck1(false);
    setCheck2(false);
    setCheck3(false);
  }, [open]);

  const handleConfirm = () => {
    if (check1 && check2 && check3) {
      onConfirm();
    } else {
      setErrorMessageKey(
        'ui.event-management.events.learner.confirm-delete.validation-error',
      );
    }
  };

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="event-created-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="confirm-upload-dialog-title">
        {t('ui.event-management.learner.edit.delete-learner')}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans
            i18nKey="ui.event-management.events.learner.confirm-delete.body.bold"
            components={{ bold: <Span variant="body-bold" /> }}
          />
        </DialogContentText>
        <Grid container direction="column">
          <Grid item>
            <P>
              {t('ui.event-management.events.learner.confirm-delete.heading')}
            </P>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item md={2}>
                <Checkbox
                  id="confirm-delete-1"
                  name="confirmDelete1"
                  onChange={setCheck1}
                  required
                />
              </Grid>
              <Grid item md={10}>
                <P color="error" variant="body-bold">
                  {t(
                    'ui.event-management.events.learner.confirm-delete.item-1',
                  )}
                </P>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item md={2}>
                <Checkbox
                  id="confirm-delete-2"
                  name="confirmDelete2"
                  onChange={setCheck2}
                  required
                />
              </Grid>
              <Grid item md={10}>
                <P color="error" variant="body-bold">
                  {t(
                    'ui.event-management.events.learner.confirm-delete.item-2',
                  )}
                </P>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item md={2}>
                <Checkbox
                  id="confirm-delete-3"
                  name="confirmDelete3"
                  onChange={setCheck3}
                  required
                />
              </Grid>
              <Grid item md={10}>
                <P color="error" variant="body-bold">
                  {t(
                    'ui.event-management.events.learner.confirm-delete.item-3',
                  )}
                </P>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {errorMessageKey !== '' && (
          <>
            <Box mb={(theme) => theme.spacing(spacingSizeMap.M)} />
            <Alert
              severity="error"
              variant="standard"
              data-testid="error-message"
            >
              <Span variant="body-bold" color="error">
                {t(errorMessageKey)}
              </Span>
            </Alert>
          </>
        )}
      </DialogContent>
      <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
      <Divider />
      <Box mb={(theme) => theme.spacing(spacingSizeMap.XS)} />
      <DialogActions>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Button
              data-testid="confirm-button"
              variant="contained"
              color="error"
              fullWidth
              onClick={handleConfirm}
              disabled={isLoading}
              endIcon={
                isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )
              }
            >
              {t('ui.event-management.learner.edit.delete-learner')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid="cancel-button"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onClose}
            >
              {t(
                'ui.event-management.events.add-learners.cancel-upload.button',
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmDeleteDialog;
