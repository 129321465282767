import { Group, OrganisationResponse } from 'api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getNavigatePath } from './helper';
import { FormData } from './types';

export const useSubmitUser = (
  selectedGroup: Group | null,
  existingPractitioner: any,
  selectedOrgs: OrganisationResponse[],
  organisationsInGroups: Record<string, Group>,
  handleSubmit: any,
  mutate: any,
) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = handleSubmit((form: FormData) => {
    let requestData = form;
    requestData.dialect = i18n.language;
    requestData.organisationIds = selectedOrgs.map((org) => org?.id);
    const groupId = organisationsInGroups[requestData.organisationIds[0]]?.id;

    if (existingPractitioner) {
      const { licensedProducts, roles, jobTitle, id } = existingPractitioner;
      requestData = {
        ...form,
        id,
        licensedProducts,
        roles,
        jobTitle,
        groupId: selectedGroup ? selectedGroup.id : groupId,
      };
    }
    mutate(
      requestData,
      {
        onSuccess: () => {
          const { path, activeTab } = getNavigatePath(
            selectedGroup,
            requestData,
            !!existingPractitioner,
          );
          navigate(path, {
            state: { activeTab },
          });
        },
      },
      existingPractitioner?.id,
    );
  });
  return { onSubmit };
};
