import { QueryStatus } from '@tanstack/react-query';

export type QueryResult<TData = unknown, TError = unknown> = {
  error?: TError;
  data?: TData;
  status: QueryStatus;
  isLoading: boolean;
};

export const queryStatusIgnoreIdle = (
  ...statuses: { status: QueryStatus; isLoading: boolean }[]
): QueryStatus => {
  if (statuses.some(({ status }) => status === 'error')) {
    return 'error';
  }
  if (statuses.some(({ isLoading }) => isLoading)) {
    return 'pending';
  }
  return 'success';
};

type AllData<T> = T extends [QueryResult<infer Data>, ...infer Rest]
  ? [Data, ...AllData<Rest>]
  : [];

type AllLazyData<T> = T extends [QueryResult<infer Data>, ...infer Rest]
  ? [Data | undefined, ...AllLazyData<Rest>]
  : [];

type AllErrors<T> = T extends [
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  QueryResult<infer _Data, infer Error>,
  ...infer Rest,
]
  ? [Error | undefined, ...AllErrors<Rest>]
  : [];

type AnyError<T> = T extends [
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  QueryResult<infer _Data, infer Error>,
  ...infer Rest,
]
  ? Error | AnyError<Rest>
  : never;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MapConst<T, C> = T extends [infer _First, ...infer Rest]
  ? [C, ...MapConst<Rest, C>]
  : [];

type CombineQueryResults<
  NonLazy extends QueryResult<any, any>[],
  Lazy extends QueryResult<any, any>[],
> =
  | {
      status: 'success';
      data: [...AllData<NonLazy>, ...AllLazyData<Lazy>];
      errors: [];
      error: undefined;
    }
  | {
      status: 'pending';
      data: MapConst<[...NonLazy, ...Lazy], undefined>;
      errors: [];
      error: undefined;
    }
  | {
      status: 'error';
      data: MapConst<[...NonLazy, ...Lazy], undefined>;
      errors: AllErrors<[...NonLazy, ...Lazy]>;
      error: AnyError<[...NonLazy, ...Lazy]>;
    };

export const combineQueryResults = <
  S extends QueryResult<any, any>[],
  T extends QueryResult<any, any>[],
>(
  queryResults: [...S],
  lazyQueryResults: [...T] = [] as any,
): CombineQueryResults<S, T> => {
  const allQueryResults = [...queryResults, ...lazyQueryResults];
  const errors = allQueryResults.map(({ error }) => error);
  return {
    data: allQueryResults.map(({ data }) => data),
    errors,
    error: errors.find((error) => !!error),
    status: queryStatusIgnoreIdle(...allQueryResults),
  } as CombineQueryResults<S, T>;
};
