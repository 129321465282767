import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import {
  P,
  RadioGroupControl,
  Slider,
} from '@insights-ltd/design-library/components';
import { DotType, ScoresGroupingType } from 'types/types';
import { useTranslation } from 'react-i18next';
import {
  LabelType,
  useWheelContext,
  WheelState,
} from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import {
  useDotPositionOverrides,
  useDotSize,
} from 'pages/DownloadTeamWheel/hooks/useWheelOverrides';
import { ToggleFilter } from 'components/ToggleFilter';

const WheelControls = () => {
  const [
    {
      labelType,
      dotType,
      scoresGroupingType,
      scoreType,
      isLearnerCountExceeded,
      teamOrganisations,
      activeOrganisations,
      isMultiOrg,
    },
    setState,
  ] = useWheelContext();
  const { t } = useTranslation();
  const { dotSize, setDotSize } = useDotSize();
  const { resetDotPositionOverrides } = useDotPositionOverrides(scoreType);

  const handleChangeSlider = (_event: any, newValue: number | number[]) =>
    setDotSize(newValue as number);

  const handleChangeLabelType = (newValue: string) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      labelType: newValue as LabelType,
    }));
  };

  const handleDotTypeChange = (newValue: string) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      dotType: newValue as DotType,
    }));
  };

  const handleGroupingTypeChange = (newValue: string) => {
    setState((prevState: WheelState) => {
      const nextLabelType =
        prevState.labelType === 'INITIALS' ? 'NUMBER' : prevState.labelType;
      return {
        ...prevState,
        labelType: nextLabelType,
        scoresGroupingType: newValue as ScoresGroupingType,
      };
    });
  };

  const handleFilterChange = (payload: Record<string, boolean>) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      activeOrganisations: payload,
    }));
  };

  return (
    <Box pr={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => resetDotPositionOverrides()}
          >
            {t(
              'ui.event-management.events.team-wheel.controls.reset-dots-position',
            )}
          </Button>
        </Grid>
        {isMultiOrg && (
          <Grid item xs={12}>
            <ToggleFilter
              title={t(
                'ui.event-management.events.team-table.controls.toggle-organisations',
              )}
              items={teamOrganisations ?? []}
              onChange={handleFilterChange}
              value={activeOrganisations ?? {}}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <P id="grouping-label" variant="body-bold">
            {t('ui.event-management.events.team-wheel.controls.grouping-title')}
          </P>
          <RadioGroupControl
            name="grouping-selector"
            aria-labelledby="grouping-label"
            onChange={handleGroupingTypeChange}
            options={[
              {
                label: (
                  <strong>
                    {t(
                      'ui.event-management.events.team-wheel.controls.grouping-individual',
                    )}
                  </strong>
                ) as unknown as string,
                disabled: isLearnerCountExceeded,
                value: 'DISTINCT',
              },
              {
                label: (
                  <strong>
                    {t(
                      'ui.event-management.events.team-wheel.controls.grouping-grouped',
                    )}
                  </strong>
                ) as unknown as string,
                value: 'AGGREGATED',
              },
            ]}
            value={scoresGroupingType}
            variant="list"
            title="grouping-type"
          />
        </Grid>
        <Grid item xs={12}>
          <P id="grouping-label" variant="body-bold">
            {t(
              'ui.event-management.events.team-wheel.controls.data-displayed-title',
            )}
          </P>
          <RadioGroupControl
            name="dot-label-type-selector"
            aria-labelledby="dot-label-type"
            onChange={handleChangeLabelType}
            options={[
              {
                label: (
                  <strong>
                    {t(
                      'ui.event-management.events.team-wheel.controls.show-initials',
                    )}
                  </strong>
                ) as unknown as string,
                value: 'INITIALS',
                disabled:
                  scoresGroupingType === 'AGGREGATED' || isLearnerCountExceeded,
              },
              {
                label: (
                  <strong>
                    {t(
                      scoresGroupingType === 'AGGREGATED'
                        ? 'ui.event-management.events.team-wheel.controls.show-group'
                        : 'ui.event-management.events.team-wheel.controls.show-numbers',
                    )}
                  </strong>
                ) as unknown as string,
                value: 'NUMBER',
              },
              {
                label: (
                  <strong>
                    {t(
                      'ui.event-management.events.team-wheel.controls.show-dots-only',
                    )}
                  </strong>
                ) as unknown as string,
                value: 'ANONYMOUS',
              },
            ]}
            value={labelType}
            variant="list"
            title="label-type"
          />
        </Grid>
        <Grid item xs={12}>
          <Slider
            min={0.25}
            step={0.01}
            max={1}
            value={dotSize}
            onChange={handleChangeSlider}
            label={t('ui.event-management.events.team-wheel.controls.dot-size')}
            leftLabel={t(
              'ui.event-management.events.team-wheel.controls.small',
            )}
            rightLabel={t(
              'ui.event-management.events.team-wheel.controls.large',
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <P id="dot-type-label" variant="body-bold">
            {t('ui.event-management.events.team-wheel.controls.dot-type')}
          </P>
          <RadioGroupControl
            name="dot-type-selector"
            aria-labelledby="dot-type-label"
            onChange={handleDotTypeChange}
            options={[
              {
                label: (
                  <strong>
                    {t(
                      'ui.event-management.events.team-wheel.controls.four-colours',
                    )}
                  </strong>
                ) as unknown as string,
                value: '4-COLOUR',
              },
              {
                label: (
                  <strong>
                    {t(
                      'ui.event-management.events.team-wheel.controls.eight-colours',
                    )}
                  </strong>
                ) as unknown as string,
                value: '8-COLOUR',
              },
            ]}
            value={dotType}
            variant="list"
            title="dot-type"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default WheelControls;
