import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { useAuth } from 'contexts/AuthContext';
import useGetMyOrganisations from 'components/hooks/useGetMyOrganisations';
import { DEFAULT_ROUTE } from 'variables';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import HeaderLayout from './HeaderLayout';
import MobileNavBarDialog from './MobileNavBarDialog';
import { NavBar } from './NavBar';

const Header = () => {
  const { t } = useTranslation();
  const { user: loggedIn } = useAuth();
  const aboveMobile = useIsAboveMobile('md');
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const {
    allMyOrganisations,
    hasMultiOrgs,
    status: getMyOrganisationStatus,
  } = useGetMyOrganisations();

  const singleOrgView =
    getMyOrganisationStatus === 'success' &&
    !loggedIn?.roles.includes('GROUP_MANAGER') &&
    !hasMultiOrgs &&
    !!allMyOrganisations?.[0];

  const NAVBAR_ITEMS = [
    {
      text: t('ui.event-management.events.nav.home'),
      to: DEFAULT_ROUTE,
    },
    {
      text: t('ui.event-management.events.nav.experiences'),
      to: '/experiences',
    },
    singleOrgView
      ? {
          text: t('ui.event-management.events.nav.my-org'),
          to: `/organisations/${allMyOrganisations?.[0]?.id}`,
        }
      : {
          text: t('ui.event-management.events.nav.orgs'),
          to: '/organisations',
        },
    {
      text: t('ui.event-management.events.nav.learners-and-teams'),
      to: '/learners',
    },
    {
      text: t('ui.event-management.events.nav.evaluator-links'),
      to: `/evaluator-links`,
    },
  ];

  useEffect(() => {
    if (aboveMobile) setDialogOpen(false);
  }, [aboveMobile]);

  return (
    <>
      <HeaderLayout>
        {aboveMobile ? (
          <NavBar navBarItems={NAVBAR_ITEMS} />
        ) : (
          <Grid container justifyContent="flex-end">
            <IconButton
              onClick={() => setDialogOpen(true)}
              aria-label={t('ui.event-management.events.open-label')}
              size="small"
              sx={{ textAlign: 'right' }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        )}
      </HeaderLayout>
      <MobileNavBarDialog
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        navBarItems={
          loggedIn
            ? [
                ...NAVBAR_ITEMS,
                {
                  text: t('ui.event-management.my-account.title'),
                  to: '/account',
                },
              ]
            : NAVBAR_ITEMS
        }
      />
    </>
  );
};

export default Header;
