import { Box, styled } from '@mui/material';
import React from 'react';
import { InitialledScore } from 'pages/DownloadTeamWheel/scoreUtils';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { ScoreCount } from 'api';
import QuadrantTitles from './QuadrantTitles';
import StaticSVG from './StaticSVG';
import TeamWheelDots from './TeamWheelDots';
import WheelNumbering from './WheelNumbering';

type TeamWheelTemplate72Props = {
  scores: InitialledScore[];
  svgId: string;
  wheelPositions?: Record<number, ScoreCount> | undefined;
};

const StyledSvg = styled('svg')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  height: 'auto',
  fontFamily: theme.typography.fontFamily,
}));

const TeamWheelTemplate72 = ({
  scores,
  svgId,
  wheelPositions,
}: TeamWheelTemplate72Props) => {
  return (
    <Box
      sx={(theme) => ({
        p: theme.spacing(spacingSizeMap.S),
        border: `solid 1px ${theme.palette.grey[400]}`,
      })}
    >
      <StyledSvg id={svgId} viewBox="0 0 525 525">
        <StaticSVG />
        <QuadrantTitles />
        <WheelNumbering />
        <TeamWheelDots scores={scores} wheelPositions={wheelPositions} />
      </StyledSvg>
    </Box>
  );
};
export default TeamWheelTemplate72;
