import React from 'react';
import { SxProps, Theme, styled } from '@mui/material';
import { SvgLock2Bold, Tag } from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';

const StyledLock = styled(SvgLock2Bold)(({ theme }) => ({
  fill: theme.palette.grey[600],
  transform: 'scale(67%)',
  marginRight: '1px',
}));

const StyledDiv = styled('div')({});

type Props = {
  sx: SxProps<Theme>;
};

const PrivateTag = ({ sx }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledDiv sx={sx}>
      <Tag
        icon={<StyledLock />}
        label={t('ui.event-management.teams.permissions.label-private')}
        variant="default"
        color="grey"
      />
    </StyledDiv>
  );
};

export default PrivateTag;
