import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Dialog,
  Span,
  CheckboxV2,
  P,
} from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import { LearnerResponse } from 'api';
import { styled } from '@mui/material';
import EditContributorsDialogConfirm from './EditContributorsDialogConfirm';

const DivContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  textWrap: 'nowrap',
  alignItems: 'flex-start',
  gap: '0.5rem',
});

const DivContributorInfoContainer = styled('div')({
  marginBottom: '0.5rem',
  overflow: 'hidden',
});

const PStyled = styled(P)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});
export type Props = {
  open: boolean;
  onCancel: () => void;
  onCheckChange: (check: boolean, id: string) => void;
  contributors: LearnerResponse[];
  selectedContributorIds: string[];
  onHandleRemoveProfileContributors: () => void;
  isPending: boolean;
};

const EditContributorsDialog = ({
  open,
  onCancel,
  onCheckChange,
  selectedContributorIds,
  contributors,
  onHandleRemoveProfileContributors,
  isPending,
}: Props) => {
  const { t } = useTranslation();
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);

  const resetStates = () => {
    setConfirmationDialogOpen(false);
  };

  useEffect(() => {
    if (open === false) {
      resetStates();
    }
  }, [open]);

  return (
    <>
      <Dialog
        onSubmit={() => setConfirmationDialogOpen(true)}
        onClose={onCancel}
        open={open}
        content={
          <Box>
            <Span>
              {t(
                `ui.event-management.learners.profile.dfc.edite-contributors.modal.content`,
              )}
            </Span>
            <br /> <br />
            {contributors.map(({ id, primaryEmail, fullName }) => (
              <DivContainer key={id}>
                <CheckboxV2
                  value={id}
                  checked={selectedContributorIds.indexOf(id) > -1}
                  onChange={(c: boolean) => onCheckChange(c, id)}
                />
                <DivContributorInfoContainer>
                  <PStyled variant="body-bold">{fullName}</PStyled>
                  <PStyled>{primaryEmail}</PStyled>
                </DivContributorInfoContainer>
              </DivContainer>
            ))}
          </Box>
        }
        primaryButtonText={t(
          'ui.event-management.learners.profile.dfc.edite-contributors.modal.button.update',
        )}
        disablePrimaryButton={selectedContributorIds.length < 1}
        secondaryButtonText={t(
          'ui.event-management.learners.profile.dfc.edite-contributors.modal.button.cancel',
        )}
        title={t(
          'ui.event-management.learners.profile.dfc.edite-contributors.modal.title',
        )}
      />
      <EditContributorsDialogConfirm
        open={confirmationDialogOpen}
        onCancel={() => {
          resetStates();
        }}
        isPending={isPending}
        onHandleRemoveProfileContributors={onHandleRemoveProfileContributors}
      />
    </>
  );
};

export default EditContributorsDialog;
