import React from 'react';
import { useGetEvaluatorLink } from 'api';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Learner from './Learner';

const EvaluatorLinkLearner = () => {
  const { evaluatorLinkId } = useParams<{ evaluatorLinkId: string }>();
  const { data, status } = useGetEvaluatorLink(evaluatorLinkId || '');
  const { t } = useTranslation();
  const evaluationLinkCrumbs: Record<string, string> = {
    '/': t('ui.event-management.dashboard.home.title'),
    '/evaluator-links': t('ui.event-management.evaluator-links.title'),
    [`/evaluator-links/${evaluatorLinkId}`]:
      data === undefined ? '' : data!.name,
  };
  return (
    <Learner
      otherStatus={status}
      crumbs={evaluationLinkCrumbs}
      evaluatorLinkId={evaluatorLinkId}
    />
  );
};
export default EvaluatorLinkLearner;
