import React from 'react';
import { CheckboxV2, Span } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { SxProps, Theme, styled, ButtonBase } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled('div')({});
const OptionsList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(spacingSizeMap.S),
  paddingLeft: theme.spacing(spacingSizeMap.S),
  paddingRight: theme.spacing(spacingSizeMap.S),
  paddingBottom: '38px',
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: '4px',
  maxHeight: '176px',
  marginTop: '6px',
  overflowY: 'scroll',
  '> label > *': {
    paddingTop: '8px',
    paddingBottom: '0px',
  },
  '> label:first-of-type > *': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  '> label > span': {
    paddingRight: '12px',
  },
  '> label:hover > span': {
    backgroundColor: 'transparent !important',
  },
}));

interface Props<T extends { id: string; name: string }> {
  title: string;
  items: T[];
  sx?: SxProps<Theme>;
  value: Record<string, boolean>;
  onChange: (value: Record<string, boolean>) => void;
}

const ToggleFilter = <ItemsType extends { id: string; name: string }>({
  title,
  sx,
  items,
  value,
  onChange,
}: Props<ItemsType>) => {
  const { t } = useTranslation();

  const checkBoxes: JSX.Element[] = items.map(({ name, id }) => (
    <CheckboxV2
      key={`${id}-${name}`}
      data-testid={`toggle-${name}`}
      label={name}
      checked={value[id]}
      onChange={(checked) => {
        onChange({ ...value, [id]: checked });
      }}
      sx={(theme) => ({
        marginLeft: '-9px',
        marginTop: '1px',
        '& span:nth-of-type(2)': {
          paddingLeft: theme.spacing(spacingSizeMap.S),
        },
      })}
      disableRipple
      disableTouchRipple
      disableFocusRipple
    />
  ));

  const isAllUnselected = Object.values(value).every(
    (organisationVisible) => organisationVisible === false,
  );

  const buttonText = isAllUnselected
    ? t(
        'ui.event-management.events.team-table.controls.toggle-organisations.select-all',
      )
    : t(
        'ui.event-management.events.team-table.controls.toggle-organisations.unselect-all',
      );

  const onAllClick = () => {
    const newValue: Record<string, boolean> = {};

    Object.keys(value).forEach((id) => {
      newValue[id] = isAllUnselected;
    });
    onChange(newValue);
  };

  return (
    <StyledDiv data-testid="toggle-filter" sx={sx}>
      <StyledDiv
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Span variant="body-bold">{title}</Span>
        <ButtonBase
          disableTouchRipple
          sx={(theme) => ({
            fontSize: '1rem',
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover, &:focus': {
              textDecoration: 'underline',
            },
          })}
          onClick={onAllClick}
        >
          {buttonText}
        </ButtonBase>
      </StyledDiv>
      <OptionsList>{checkBoxes}</OptionsList>
    </StyledDiv>
  );
};

export default ToggleFilter;
