import {
  DFC_CORE_LANGUAGES,
  DIALECT_CODES,
  DialectKeyValue,
  ENABLED_EVALUATOR_DIALECTS,
  ENABLED_PROFILE_DIALECTS,
} from 'variables';
import { EventType, LicensedProduct } from 'types/types';

export const isIdtlExperience = (eventType: EventType | '') =>
  [
    'INSIGHTS_SELF_AWARE_LEADER',
    'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
  ].includes(eventType);

export const isDFCExperience = (eventType: EventType | '') =>
  eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE';

export const isExploreExperience = (eventType: EventType | '') =>
  eventType === 'INSIGHTS_EXPLORE';

export const experienceSupportsChapters = (eventType: EventType) =>
  !(isIdtlExperience(eventType) || isDFCExperience(eventType));

export const experienceSupportsDetails = (eventType: EventType) =>
  !isDFCExperience(eventType);

export const experienceSupportsLocation = (eventType: EventType) =>
  !isDFCExperience(eventType);

export const getEventDialects = (event: {
  eventType: EventType | '';
}): Array<DialectKeyValue> =>
  isIdtlExperience(event.eventType)
    ? [
        {
          textKey:
            'ui.event-management.events.create.event-language.english_united_kingdom',
          value: DIALECT_CODES.ENGLISH,
        },
      ]
    : ENABLED_PROFILE_DIALECTS.map((dialect) => {
        return {
          textKey: dialect.textKey,
          value: dialect.value,
        };
      });

export const getEvaluatorDialects = (event: {
  eventType: EventType;
  productType: LicensedProduct;
}) => {
  if (isIdtlExperience(event.eventType)) {
    return [
      {
        textKey:
          'ui.event-management.events.create.event-language.english_united_kingdom',
        value: DIALECT_CODES.ENGLISH,
      },
    ];
  }
  if (isDFCExperience(event.eventType)) {
    return DFC_CORE_LANGUAGES;
  }
  return ENABLED_EVALUATOR_DIALECTS.filter(
    (dialect) => !dialect.disabledForProducts?.includes(event.productType),
  );
};

export const canChangeEventType = (eventType: EventType | '') =>
  [
    'INSIGHTS_DISCOVERY_WORKSHOP',
    'INSIGHTS_DISCOVERY_ACCREDITATION',
    'INSIGHTS_SELF_AWARE_LEADER',
    'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
  ].includes(eventType);

const COMPATIBLE_EVENT_TYPES: Record<EventType, EventType[]> = {
  INSIGHTS_DISCOVERY_WORKSHOP: ['INSIGHTS_DISCOVERY_ACCREDITATION'],
  INSIGHTS_DISCOVERY_ACCREDITATION: ['INSIGHTS_DISCOVERY_WORKSHOP'],
  INSIGHTS_SELF_AWARE_LEADER: ['INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS'],
  INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS: ['INSIGHTS_SELF_AWARE_LEADER'],
  INSIGHTS_DISCOVERY_FULL_CIRCLE: [],
  INSIGHTS_EXPLORE: [],
};

export const eventTypesAreCompatible = (
  eventType1: EventType,
  eventType2: EventType,
) => {
  if (eventType1 === eventType2) {
    return true;
  }
  return COMPATIBLE_EVENT_TYPES[eventType1].includes(eventType2);
};
