import Grid from '@mui/material/Grid';
import React, { useRef, useEffect } from 'react';
import { measureSVGTextElement } from 'pages/DownloadTeamWheel/globalMethods';
import { useTheme } from '@mui/material';

type TextAnchor = 'start' | 'middle' | 'end';

type SVGTextMeasurerProps = {
  text: string;
  reportStringPixelLength: (length: number) => void;
  pixelFontSize?: number;
  textAnchor?: TextAnchor;
};

const SVGTextMeasurer = ({
  text,
  reportStringPixelLength,
  textAnchor = 'start',
  pixelFontSize = 6,
}: SVGTextMeasurerProps) => {
  const theme = useTheme();
  const textRef = useRef<SVGTextElement>(null);
  useEffect(() => {
    if (textRef.current) {
      reportStringPixelLength(measureSVGTextElement(textRef.current));
    } else {
      reportStringPixelLength(0);
    }
  }, [textRef, reportStringPixelLength]);

  return (
    <Grid
      item
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Grid
        item
        sx={{
          position: 'absolute',
          top: '0',
          right: '100vw',
          width: '1024px',
          height: '100px',
        }}
      >
        <svg
          id="SVGTextMeasurer"
          viewBox="0 0 1024 100"
          fontFamily={theme.typography.fontFamily}
          width="50vw"
          height="50vw"
        >
          <text
            x="0"
            y="0"
            textAnchor={textAnchor}
            fontSize={`${pixelFontSize}px`}
            ref={textRef}
          >
            {text}
          </text>
        </svg>
      </Grid>
    </Grid>
  );
};
export default SVGTextMeasurer;
