import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

type LearnersSearchBoxProps = {
  onChange: (value: string) => void;
  value: string;
};

const LearnersSearchBox = ({ onChange, value }: LearnersSearchBoxProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      id="searchLearners"
      name="searchLearners"
      variant="outlined"
      type="text"
      fullWidth
      label={t('ui.event-management.events.learners-list.search.placeholder')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
export default LearnersSearchBox;
