import React from 'react';
import { CoreDetailsData, CoreDetailsForm } from 'components/EventForms';
import EditFormNavigation from './EditFormNavigation';
import { EditProps } from './types';

const EditCoreDetails = ({
  eventForm,
  onCancel,
  saveEvent,
  saving,
  invitedCount = 0,
}: EditProps) => {
  const onSubmit = (formData: CoreDetailsData) => {
    saveEvent(formData);
  };

  return (
    <CoreDetailsForm
      event={eventForm}
      onSubmit={onSubmit}
      invitedCount={invitedCount}
    >
      <EditFormNavigation onCancel={onCancel} saving={saving} />
    </CoreDetailsForm>
  );
};

export default EditCoreDetails;
