import { Alert, Span } from '@insights-ltd/design-library/components';
import React from 'react';
import { Trans } from 'react-i18next';
import { Box, styled } from '@mui/material';

const StyledSpan = styled(Span)({
  cursor: 'pointer',
  userSelect: 'none',
});

const LearnerSearchMessage = ({
  onClick,
  i18nKey,
}: {
  onClick: () => void;
  i18nKey: string;
}) => {
  return (
    <Box
      data-testid="learners-search-message"
      sx={() => ({
        maxWidth: '720px !important',
        'div:nth-of-type(1)': {
          lineHeight: '28px',
          paddingBottom: '8px',
        },
        '> div': {
          marginTop: '1rem',
        },
      })}
    >
      <Alert kind="info">
        <Trans
          i18nKey={i18nKey}
          components={{
            linkElement: (
              <StyledSpan
                variant="body-bold"
                color="primary"
                onClick={onClick}
              />
            ),
          }}
        />
      </Alert>
    </Box>
  );
};

export default LearnerSearchMessage;
