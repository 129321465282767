import FullHeight from 'components/FullHeight';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider, Grid } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { H2 } from '@insights-ltd/design-library/components';
import { Group } from 'api/httpEntities';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateGroup } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useNavigate } from 'react-router-dom';
import CreateGroupForm from './CreateGroupForm';

const CreateGroup: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync } = useCreateGroup(queryClient);
  const navigate = useNavigate();

  const onSubmit = async (data: Omit<Group, 'id'>) =>
    mutateAsync(data).then(({ id }: Group) => {
      navigate(`/groups/${id}`);
    });

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.create-group.title')}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          maxWidth="lg"
        >
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.dashboard.home.title'),
                '/organisations': t('ui.event-management.organisations.title'),
              }}
              activeText={t('ui.event-management.create-group.breadcrumb')}
            />
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <H2 variant="h2">
                  {t('ui.event-management.create-group.title')}
                </H2>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt={(theme) => theme.spacing(spacingSizeMap.M)}
            mb={(theme) => theme.spacing(spacingSizeMap.L)}
          >
            <Divider />
          </Box>
          <CreateGroupForm onSubmit={onSubmit} />
        </Container>
      </FullHeight>
    </>
  );
};

export default CreateGroup;
