import { useAuth } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { Role } from 'types/types';
import { isGroupManager } from 'utils/role';

const getPrivateKey = (roles: Role[] | undefined) => {
  if (isGroupManager(roles)) {
    return 'ui.event-management.teams.permissions.option-private';
  }

  return 'ui.event-management.team.view-permissions';
};

export const useGetViewPermissionOptions = (isMultiOrg: boolean) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const privateKey = getPrivateKey(user?.roles);

  if (isMultiOrg) {
    return [
      {
        value: 'PRIVATE',
        label: t(privateKey),
      },
    ];
  }
  return [
    {
      value: 'PRIVATE',
      label: t('ui.event-management.teams.permissions.option-private'),
    },
    {
      value: 'ORGANISATION',
      label: t('ui.event-management.teams.permissions.option-organisation'),
    },
  ];
};

export type TeamVariant = 'single-org' | 'multi-org';
