import React from 'react';
import { Group, GroupType, OrganisationResponse } from 'api';
import { Box, Button, Grid, Paper, styled, Theme } from '@mui/material';
import { Span, Tag, Text } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled('div')({});

const StyledText = styled(Text)({
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  fontSize: '1rem !important',
});

const StyledTag = styled(Tag)(({ theme }) => ({
  margin: `0 ${theme.spacing(spacingSizeMap.S)}`,
  backgroundColor: theme.palette.blue.light,
  color: theme.palette.blue.dark,
}));

const ErrorMessage = styled('p')(({ theme }) => ({
  ...theme.typography.caption,
  color: `${theme.palette.error.main}`,
  margin: '3px 14px 0 14px',
}));

type Props = {
  selectedGroup?: (Group & { organisations?: OrganisationResponse[] }) | null;
  onSelect: (
    group: (Group & { organisations?: OrganisationResponse[] }) | null,
  ) => void;
  selectedLabel?: string | React.ReactNode;
  error?: string | null;
  actionType?: 'remove' | 'change';
  disabled?: boolean;
};
const DisplayOrgAssign = ({
  selectedGroup = null,
  onSelect,
  selectedLabel,
  error,
  actionType = 'remove',
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const count = selectedGroup?.organisations
    ? selectedGroup?.organisations.length
    : 0;
  const groupTypeLabel: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.group.label.customer'),
    PARTNER: t('ui.event-management.organisations.group.label.partner'),
  };

  const actionText = {
    remove: t('ui.event-management.organisations.edit.select-group.remove'),
    change: t('ui.event-management.organisations.edit.select-group.change'),
  };

  const labelColor = (theme: Theme): string => {
    return selectedGroup?.type !== 'PARTNER'
      ? `${theme.palette.orange.dark}`
      : `${theme.palette.green.dark}`;
  };

  const labelBackgroundColor = (theme: Theme): string => {
    return selectedGroup?.type !== 'PARTNER'
      ? `${theme.palette.orange.light}`
      : `${theme.palette.green.light}`;
  };

  const organisationsValue =
    count > 1
      ? `${t('ui.event-management.users.invite.organisations-plural', {
          count,
        })}`
      : `${t('ui.event-management.users.invite.organisations-singular', {
          count,
        })}`;

  if (selectedGroup !== null) {
    return (
      <Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            {selectedLabel ? (
              <Span variant="body-bold">{selectedLabel}</Span>
            ) : null}
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: '0.5rem' }}>
          <Paper elevation={0} variant="outlined">
            <Box
              sx={{
                padding: '0.75rem 1rem 0.75rem 1.25rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <StyledDiv
                sx={(theme) => ({
                  '& > div': {
                    margin: `0 ${theme.spacing(spacingSizeMap.S)}`,
                  },
                })}
              >
                <StyledText color="textPrimary" variant="body-bold">
                  {selectedGroup?.name}
                </StyledText>
                <StyledTag
                  color="white"
                  label={groupTypeLabel[selectedGroup?.type ?? 'CUSTOMER']}
                  sx={(theme: Theme) => ({
                    color: labelColor(theme),
                    backgroundColor: labelBackgroundColor(theme),
                  })}
                />
                <StyledText
                  sx={{
                    marginLeft: '1.625rem !important',
                  }}
                >
                  {organisationsValue}
                </StyledText>
              </StyledDiv>
              {!disabled && (
                <Button
                  onClick={() => {
                    onSelect(null);
                  }}
                  sx={{ minWidth: 0, padding: 0 }}
                  color={actionType === 'change' ? 'primary' : 'error'}
                >
                  {actionText[actionType]}
                </Button>
              )}
            </Box>
          </Paper>
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default DisplayOrgAssign;
