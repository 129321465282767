import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { InitialledScore } from 'pages/DownloadTeamWheel/scoreUtils';
import TeamEnergyChart from 'pages/DownloadTeamWheel/TeamEnergyChart';
import TeamTable from 'pages/DownloadTeamWheel/TeamTable';
import TeamWheel72 from 'pages/DownloadTeamWheel/TeamWheel72';
import WheelProvider from 'pages/DownloadTeamWheel/WheelProvider';
import useSorting from 'pages/DownloadTeamWheel/TeamTable/useSorting';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import renderToZip from './renderToZip';
import renderToPptx from './renderToPptx';

type ChartDownloadProps = {
  wheelDisplayScores: InitialledScore[];
  reportError: (fileName: string) => void;
  initialledScores: (InitialledScore & {
    organisationId: string;
    organisationName: string;
  })[];
  percentages: any;
  name: string;
  disabled?: boolean;
};

const DownloadAll = ({
  wheelDisplayScores,
  initialledScores,
  percentages,
  reportError,
  name,
  disabled = false,
}: ChartDownloadProps) => {
  const [preparingDownloadZip, setPreparingDownloadZip] = useState(false);
  const [preparingDownloadPptx, setPreparingDownloadPptx] = useState(false);
  const { t: pageT } = useTranslation();
  const [wheelState] = useWheelContext();
  const {
    fontSize,
    scoreType,
    tableFontSize,
    sortType,
    sortDirection,
    i18n,
    isLearnerCountExceeded,
    namesColumns,
    isMultiOrg,
  } = wheelState;
  const tableSize = 20;
  const sortedScores = useSorting(initialledScores, wheelState);

  const chunkArray = (array: any[], chunkSize: number): any[][] => {
    const result: any[] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  };

  useEffect(() => {
    if (preparingDownloadZip) {
      renderToZip(name, isLearnerCountExceeded)
        .catch((error) => reportError(error.message))
        .finally(() => setPreparingDownloadZip(false));
    }

    if (preparingDownloadPptx) {
      const copyright: string = pageT(
        'ui.event-management.footer.copyright-message',
        { Year: new Date().getUTCFullYear() },
      );
      renderToPptx({
        name,
        copyright,
        numberOfTables: chunkArray(initialledScores, tableSize).length,
        scoreType,
      })
        .catch((error) => reportError(error.message))
        .finally(() => setPreparingDownloadPptx(false));
    }
  });

  const handleDownloadAll = async () =>
    !preparingDownloadZip && setPreparingDownloadZip(true);

  const handleDownloadPptx = async () =>
    !preparingDownloadPptx && setPreparingDownloadPptx(true);

  return (
    <Grid
      item
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={async () => {
          await handleDownloadAll();
        }}
        disabled={disabled}
      >
        {pageT('ui.event-management.events.team-wheel.download-all')}
      </Button>
      <Button
        sx={(theme) => ({
          marginLeft: theme.spacing(spacingSizeMap.S),
        })}
        variant="outlined"
        color="primary"
        onClick={async () => {
          await handleDownloadPptx();
        }}
        disabled={disabled}
      >
        {pageT('ui.event-management.events.team-wheel.download-pptx')}
      </Button>
      {/* We need to render these to screen for the conversion process to convert them to Blob/Base64 */}
      {(preparingDownloadPptx || preparingDownloadZip) && (
        <Grid
          item
          sx={{
            position: 'absolute',
            top: '0',
            right: '100vw',
            width: '1000px',
            height: '3000px',
          }}
        >
          <I18nextProvider i18n={i18n}>
            <TeamWheel72 svgId="PPTX_TEAM_WHEEL" scores={wheelDisplayScores} />
          </I18nextProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType,
              scoresGroupingType: 'DISTINCT',
              dotType: '8-COLOUR',
              languageType: 'en-GB',
              labelType: 'INITIALS',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded: false,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              {chunkArray(sortedScores, tableSize).map(
                (learnerScores, index) => (
                  <TeamTable
                    key={`PPTX_TEAM_TABLE_${index + 1}`}
                    svgId={`PPTX_TEAM_TABLE_${index + 1}`}
                    scores={learnerScores}
                  />
                ),
              )}
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '8-COLOUR',
              languageType: 'en-GB',
              labelType: 'INITIALS',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="Conscious_Wheel_displayInitials_8_colour"
                scores={initialledScores}
              />
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '4-COLOUR',
              languageType: 'en-GB',
              labelType: 'INITIALS',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="Conscious_Wheel_displayInitials"
                scores={initialledScores}
              />
            </I18nextProvider>
            <I18nextProvider i18n={i18n}>
              <TeamEnergyChart
                svgId="Conscious_Dominant_Energies"
                percentages={percentages.dominant}
                chartTitleKey="ui.event-management.events.team-wheel.dominant-chart-title"
              />
            </I18nextProvider>
            <I18nextProvider i18n={i18n}>
              <TeamEnergyChart
                svgId="Conscious_Preferred_Energies"
                percentages={percentages.preferred}
                chartTitleKey="ui.event-management.events.team-wheel.preferred-chart-title"
              />
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '8-COLOUR',
              languageType: 'en-GB',
              labelType: 'NUMBER',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="Conscious_Wheel_displayInitials_false_8_colour"
                scores={initialledScores}
              />
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '4-COLOUR',
              languageType: 'en-GB',
              labelType: 'NUMBER',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="Conscious_Wheel_displayInitials_false"
                scores={initialledScores}
              />
            </I18nextProvider>
            <I18nextProvider i18n={i18n}>
              <TeamTable
                svgId="Conscious_Team_Table"
                scores={initialledScores}
              />
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'LESS_CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '8-COLOUR',
              languageType: 'en-GB',
              labelType: 'INITIALS',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="LessConscious_Wheel_displayInitials_8_colour"
                scores={initialledScores}
              />
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'LESS_CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '4-COLOUR',
              languageType: 'en-GB',
              labelType: 'INITIALS',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="LessConscious_Wheel_displayInitials"
                scores={initialledScores}
              />
            </I18nextProvider>
            <I18nextProvider i18n={i18n}>
              <TeamEnergyChart
                svgId="LessConscious_Dominant_Energies"
                percentages={percentages.dominant}
                chartTitleKey="ui.event-management.events.team-wheel.dominant-chart-title"
              />
            </I18nextProvider>
            <I18nextProvider i18n={i18n}>
              <TeamEnergyChart
                svgId="LessConscious_Preferred_Energies"
                percentages={percentages.preferred}
                chartTitleKey="ui.event-management.events.team-wheel.preferred-chart-title"
              />
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'LESS_CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '8-COLOUR',
              languageType: 'en-GB',
              labelType: 'NUMBER',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="LessConscious_Wheel_displayInitials_false_8_colour"
                scores={initialledScores}
              />
            </I18nextProvider>
          </WheelProvider>
          <WheelProvider
            initialState={{
              fontSize,
              tableFontSize,
              scoreType: 'LESS_CONSCIOUS',
              scoresGroupingType: 'DISTINCT',
              dotType: '4-COLOUR',
              languageType: 'en-GB',
              labelType: 'NUMBER',
              sortDirection,
              sortType,
              namesColumns,
              isLearnerCountExceeded,
              isMultiOrg,
            }}
          >
            <I18nextProvider i18n={i18n}>
              <TeamWheel72
                svgId="LessConscious_Wheel_displayInitials_false"
                scores={initialledScores}
              />
            </I18nextProvider>
            <I18nextProvider i18n={i18n}>
              <TeamTable
                svgId="LessConscious_Team_Table"
                scores={initialledScores}
              />
            </I18nextProvider>
          </WheelProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default DownloadAll;
