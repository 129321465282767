import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { H1, P } from '@insights-ltd/design-library/components';

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

const FullScreenError = ({ message }: { message: string }) => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      <H1 variant="body-bold">{t('ui.event-management.error')}</H1>
      <P>{message}</P>
    </StyledDiv>
  );
};

export default FullScreenError;
