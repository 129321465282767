import React from 'react';
import { Button } from '@mui/material';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GroupResponse } from 'api';

type InviteUserActionProps = {
  organisationId: any;
  organisationGroup: GroupResponse | null | undefined;
};
export const InviteUserAction = ({
  organisationId,
  organisationGroup,
}: InviteUserActionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const aboveMobile = useIsAboveMobile();
  const isPartnerGroup = organisationGroup?.type === 'PARTNER';
  if (!user) return null;
  const hasInviteAccess =
    user.permissions.Organisation_InvitePractitioner === 'Global';

  if (hasInviteAccess) {
    return (
      <Button
        onClick={() =>
          navigate('/organisations/invite-user', {
            state: {
              organisationId,
            },
          })
        }
        variant="contained"
        color="primary"
        fullWidth={!aboveMobile}
      >
        {t('ui.event-management.users.invite.organisation.invite-user.action')}
      </Button>
    );
  }
  if (isPartnerGroup && user.permissions.Organisation_Add_User === 'Global') {
    return (
      <Button
        onClick={() =>
          navigate('/organisations/invite-user', {
            state: {
              organisationId,
              groupId: organisationGroup?.id,
              groupName: organisationGroup.name,
            },
          })
        }
        variant="contained"
        color="primary"
        fullWidth={!aboveMobile}
      >
        {t('ui.event-management.users.invite.organisation.add-user.action')}
      </Button>
    );
  }
  return null;
};
