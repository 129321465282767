import { _delete, get, post, put } from '@insights-ltd/design-library/utils';
import { API_ROOT } from 'mocks/handlers/common';
import {
  EvaluatorLink,
  EvaluatorLinkResponse,
  GetCompletedEvaluationResponse,
  RequestErrorBody,
} from 'api';

export const evaluatorLinks = {
  all: (organisationId: string) =>
    get<EvaluatorLinkResponse>(
      `${API_ROOT}/organisations/${organisationId}/evaluator-links`,
    ),
  get: (evaluatorLinkId: string) =>
    get<EvaluatorLink>(`${API_ROOT}/evaluator-links/${evaluatorLinkId}`),
  create: (organisationId: string) =>
    post<undefined>(
      `${API_ROOT}/organisations/${organisationId}/evaluator-links`,
    ),
  delete: (evaluatorLinkId: string) =>
    _delete<undefined>(`${API_ROOT}/evaluator-links/${evaluatorLinkId}`),
  evaluations: {
    all: (evaluatorLinkId: string) =>
      get<GetCompletedEvaluationResponse>(
        `${API_ROOT}/evaluator-links/${evaluatorLinkId}/evaluations`,
      ),
  },
  profiles: {
    purchase: (organisationId: string, evaluatorLinkId: string) =>
      put<undefined | RequestErrorBody>(
        `${API_ROOT}/organisations/${organisationId}/evaluator-links/${evaluatorLinkId}/profiles`,
      ),
  },
};
