import { getTheme } from '@insights-ltd/design-library/themes';

const localTheme = getTheme();

type EnvObject = {
  text: string;
  color: string;
  visible: boolean;
};

const useGetEnvProps = (): EnvObject => {
  const { hostname } = new URL(window.location.href);
  const returnProps: EnvObject = {
    text: '',
    color: localTheme.brandColours.blue,
    visible: false,
  };

  const capitalise = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const isLocalhost = (host: string) => {
    return host === 'localhost';
  };

  const colourOf = (env: string) => {
    switch (env) {
      case 'test':
        return localTheme.validationColours.valid;
      case 'demo':
        return localTheme.validationColours.error;
      case 'localhost':
        return 'purple';
      default:
        return localTheme.brandColours.blue;
    }
  };

  const isNonProduction = (host: string) => {
    return host.match(
      /(?:http[s]*:\/\/)*(.*?).(?=[^/]*..{2,5})?practitioner.insights.com/,
    )?.[1];
  };

  const isReviewApp = (host: string) => {
    return host.match(/(?:review.insights.com)/)?.[0];
  };

  if (isLocalhost(hostname)) {
    returnProps.text = 'Local environment';
    returnProps.color = colourOf('localhost');
    returnProps.visible = true;
  }

  if (isReviewApp(hostname)) {
    returnProps.text = `Review environment`;
    returnProps.visible = true;
  }

  const nonProductionEnv = isNonProduction(hostname);
  if (nonProductionEnv) {
    const env = nonProductionEnv;
    returnProps.text = `${capitalise(env)} environment`;
    returnProps.color = colourOf(env);
    returnProps.visible = true;
  }

  return returnProps;
};

export default useGetEnvProps;
