import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { useSignOut } from 'api';
import PermissionGuard from 'components/PermissionGuard';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const Actions = ({ preferredDialect = 'en-GB' }) => {
  const { t } = useTranslation();
  const mutation = useSignOut();

  return (
    <Grid
      item
      xs={12}
      md="auto"
      lg={8}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& > * + *': {
          marginLeft: `${theme.spacing(spacingSizeMap.XS)} !important`,
        },
      })}
    >
      <Button
        variant="contained"
        color="primary"
        to="/account/edit"
        component={RouterLink}
        aria-label={t('ui.event-management.my-account.edit')}
      >
        {t('ui.event-management.my-account.edit')}
      </Button>
      <PermissionGuard
        requiredPermissions={[
          { action: 'Practitioner_Update', scope: 'Global' },
        ]}
      >
        <Button
          variant="outlined"
          color="primary"
          to={`/account/change-password?dialect=${preferredDialect}`}
          component={RouterLink}
          aria-label={t('ui.event-management.my-account.change-password')}
        >
          {t('ui.event-management.my-account.change-password')}
        </Button>
      </PermissionGuard>
      <Button
        variant="outlined"
        sx={(theme) => ({
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,
          border: '1px solid',
          backgroundColor: 'transparent',
          '&:hover': {
            borderColor: theme.palette.error.main,
            backgroundColor: `${theme.palette.error.main}16`,
          },
        })}
        aria-label={t('ui.event-management.my-account.sign-out')}
        onClick={() => {
          mutation.mutate(undefined, {
            onSuccess: () => datadogRum.clearUser(),
          });
        }}
      >
        {t('ui.event-management.my-account.sign-out')}
      </Button>
    </Grid>
  );
};
export default Actions;
