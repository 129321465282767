import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import CreateTeamForm from './CreateTeamForm';
import useGetCoreDetailsData from './useGetCoreDetailsData';

const CreateTeam = () => {
  const { t } = useTranslation();

  const {
    isPending,
    isError,
    isPermitted,
    organisations,
    myOrganisation,
    organisationsInGroups,
    showOrganisationDropdown,
  } = useGetCoreDetailsData('NEW');

  if (isPending) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (isError) {
    return (
      <FullScreenError message={t('ui.event-management.teams.error-loading')} />
    );
  }

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.teams.create-a-team"
      activeTextKey="ui.event-management.teams.create-a-team"
      crumbs={{
        '/': t('ui.event-management.events.nav.home'),
        '/learners': t('ui.event-management.learners-and-teams.title'),
      }}
      headingKey="ui.event-management.teams.create-a-team"
    >
      <CreateTeamForm
        isPermitted={isPermitted}
        organisations={organisations}
        organisationsInGroups={organisationsInGroups}
        myOrganisation={myOrganisation}
        showOrganisationDropdown={showOrganisationDropdown}
      />
    </BreadcrumbLayout>
  );
};
export default CreateTeam;
