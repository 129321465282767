import { getDownloadFileBlobForProfile } from 'api/practitioners/practitioners';
import { AnonymisationType } from 'types/types';

const downloadProfile = async (
  learnerId: string,
  profileId: string,
  profileDialect: string,
  anonymisationType?: AnonymisationType,
) => {
  const { blob, filename } = await getDownloadFileBlobForProfile(
    learnerId,
    profileId,
    profileDialect,
    anonymisationType,
  );
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};

export default downloadProfile;
