import { GroupResponse, OrganisationResponse } from 'api';
import { GetAllOrganisationsAndGroupResponse } from 'components/hooks/useGetAllOrganisationsAndGroups';
import { User } from 'contexts/AuthContext';
import { FilterCategory } from 'utils/isMatchedToOrgFilter';
import { isAdvancedUser } from 'utils/role';
import { isCategory } from 'utils/typeGuards/isCategory';

export const getFilteredOrgs = (
  organisations: GetAllOrganisationsAndGroupResponse[],
  isMatchedToOrgFilter: (
    organisation: OrganisationResponse | GroupResponse,
  ) => boolean,
  status: string,
) => {
  if (status === 'success') {
    return organisations.filter(isMatchedToOrgFilter);
  }
  return [];
};

export const isFilterCategory = (category: FilterCategory) =>
  isCategory(category, ['ALL', 'ORGANISATIONS', 'GROUPS']);

export const useGetActiveSearchCategory = ({
  user,
  organisations,
  category = '',
  searchTerm = '',
  minCharacters = 2,
}: {
  user?: User;
  organisations: OrganisationResponse[];
  category?: string;
  searchTerm?: string;
  minCharacters?: number;
}) => {
  const isAU = isAdvancedUser(user?.roles);
  const filterCategory = category as FilterCategory;
  const searchCategory: FilterCategory =
    filterCategory && isFilterCategory(filterCategory)
      ? filterCategory
      : 'ORGANISATIONS';
  const passiveCategory = isAU && !organisations?.length ? 'GROUPS' : 'ALL';

  const activeCategory =
    searchTerm && searchTerm?.length >= minCharacters
      ? searchCategory
      : passiveCategory;

  return activeCategory;
};
