import { EvaluatorLink } from 'api';
import { EvaluatorLinksSortCategory } from 'types/types';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import { QueryStatus } from '@tanstack/react-query';
import useGetPractitionerEvaluatorLinks from './useGetPractitionerEvaluatorLinks';

const useSortedEvaluatorLinks = (
  organisationId: string | null,
  initialLinkSortBy: EvaluatorLinksSortCategory = 'DATE_CREATED',
): {
  data: Record<'ALL' | 'PRACTITIONER', EvaluatorLink[]>;
  evaluatorStatus: QueryStatus;
  setLinkSortBy: (sortCategoryBy: EvaluatorLinksSortCategory) => void;
  sortCategory: EvaluatorLinksSortCategory;
} => {
  const {
    searchState: { sortBy = initialLinkSortBy },
    setSearchState,
  } = useSearchContext();

  const setLinkSortBy = (sortCategoryBy: EvaluatorLinksSortCategory) => {
    setSearchState({ sortBy: sortCategoryBy });
  };

  const { data, status: evaluatorStatus } =
    useGetPractitionerEvaluatorLinks(organisationId);

  switch (sortBy) {
    case 'DATE_CREATED':
      data?.ALL.sort((a: EvaluatorLink, b: EvaluatorLink) => {
        return a.origin.createdAt > b.origin.createdAt ? -1 : 1;
      });
      data?.PRACTITIONER.sort((a: EvaluatorLink, b: EvaluatorLink) => {
        return a.origin.createdAt > b.origin.createdAt ? -1 : 1;
      });
      break;
    case 'MOST_COMPLETED':
      data?.ALL.sort((a: EvaluatorLink, b: EvaluatorLink) => {
        return a.totalCompletedEvaluators > b.totalCompletedEvaluators ? -1 : 1;
      });
      data?.PRACTITIONER.sort((a: EvaluatorLink, b: EvaluatorLink) => {
        return a.totalCompletedEvaluators > b.totalCompletedEvaluators ? -1 : 1;
      });
      break;
    case 'LEAST_COMPLETED':
      data?.ALL.sort((a: EvaluatorLink, b: EvaluatorLink) => {
        return a.totalCompletedEvaluators < b.totalCompletedEvaluators ? -1 : 1;
      });
      data?.PRACTITIONER.sort((a: EvaluatorLink, b: EvaluatorLink) => {
        return a.totalCompletedEvaluators < b.totalCompletedEvaluators ? -1 : 1;
      });
      break;
    default:
      break;
  }

  return {
    data,
    evaluatorStatus,
    setLinkSortBy,
    sortCategory: sortBy as EvaluatorLinksSortCategory,
  };
};

export default useSortedEvaluatorLinks;
