import React, { useContext, useMemo, useState } from 'react';
import RequestErrorModal from './RequestErrorModal';

type RequestErrorState = {
  open: boolean;
  openErrorModal: () => void;
  setOnClose: (fn: any) => void;
};

const RequestErrorContext = React.createContext<RequestErrorState | undefined>(
  undefined,
);

export const useRequestErrorContext = () => {
  const context = useContext(RequestErrorContext);

  if (context === undefined) {
    throw Error(
      'context was undefined. Please ensure useRequestErrorContext is used within a RequestErrorModalProvider.',
    );
  }

  return context;
};

const RequestErrorModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const defaultOnClose = () => {};
  const [open, setOpen] = useState(false);
  const [onClose, setOnClose] = useState<() => void>(() => defaultOnClose);

  const handleOnClose = () => {
    onClose();
    setOpen(false);
  };

  const value: RequestErrorState = useMemo(
    () => ({ open, openErrorModal: () => setOpen(true), setOnClose }),
    [open, setOnClose],
  );
  return (
    <RequestErrorContext.Provider value={value}>
      {children}
      <RequestErrorModal open={open} onClose={handleOnClose} />
    </RequestErrorContext.Provider>
  );
};

export default RequestErrorModalProvider;
