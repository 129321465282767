import { DfcInviteeResponse } from 'api';
import { AllDialectOptions } from 'types/dialects';
import { notEnglish } from './languageUtils';

export const disabledDfcLearnerPronounProfile = (
  learner: DfcInviteeResponse,
  selectedDialect: AllDialectOptions,
): boolean => {
  return (
    learner.pronoun?.toUpperCase() === 'THEY' && notEnglish(selectedDialect)
  );
};
