import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import FormHeader from 'components/FormHeader';
import { PractitionersForm, Practitioner } from 'components/EventForms';
import { styled } from '@mui/material';
import FormNavigation from './FormNavigation';
import { StepProps } from './types';

const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  fill: theme.palette.blue.main,
}));

const Practitioners = ({ onBack, onNext, event, updateEvent }: StepProps) => {
  const { t } = useTranslation();
  const onSubmit = (practitioners: Practitioner[]) => {
    updateEvent({
      ...event,
      practitioners,
    });
    onNext();
  };
  return (
    <>
      <FormHeader
        icon={StyledPersonIcon}
        title={t('ui.event-management.events.edit.practitioners.header')}
        color="blue"
      />
      <PractitionersForm event={event} onSubmit={onSubmit}>
        <FormNavigation onBack={onBack} />
      </PractitionersForm>
    </>
  );
};

export default Practitioners;
