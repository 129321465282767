import { CSV_ROW_LIMIT, VALID_EMAIL_REGEX } from 'variables';

type RowDataOptional = {
  email?: string;
};
export type RowData = {
  email: string;
};
export const processFile = (
  fileLoadedEvent: ProgressEvent<FileReader>,
): RowData[] => {
  const textFromFileLoaded = String(fileLoadedEvent?.target?.result);
  if (!textFromFileLoaded || textFromFileLoaded === '') {
    throw new Error('ui.event-management.events.upload-error.empty');
  }
  const rows = textFromFileLoaded.split(/\r?\n/);
  const invitees = rows
    .map((email): RowDataOptional => ({ email }))
    .filter(
      (user, index, array): user is RowData =>
        Boolean(user.email?.match(VALID_EMAIL_REGEX)) && // remove rows with invalid email
        array.findIndex((r) => r.email === user.email) === index, // only retain first occurrence of duplicate emails
    );
  if (invitees.length < 1) {
    throw new Error('ui.event-management.events.upload-error.empty');
  }
  if (invitees.length > CSV_ROW_LIMIT) {
    throw new Error('ui.event-management.events.upload-error.too-many');
  }
  return invitees;
};
