import { UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  CreditUnitsRequest,
  TransferUnitsRequest,
  TransferUnitsResponse,
} from 'api/httpEntities';
import {
  creditUnitsToPractitionerWallet,
  removeUnitsFromPractitionerWallet,
  transferUnitsBetweenWallets,
} from './wallets';

export const useCreditUnitsToPractitionerWallet = (): UseMutationResult<
  void,
  Error,
  CreditUnitsRequest,
  unknown
> => useMutation({ mutationFn: creditUnitsToPractitionerWallet });

export const useRemoveUnitsFromPractitionerWallet = (): UseMutationResult<
  void,
  Error,
  CreditUnitsRequest,
  unknown
> => useMutation({ mutationFn: removeUnitsFromPractitionerWallet });

export const useTransferUnitsBetweenWallets = (): UseMutationResult<
  TransferUnitsResponse,
  Error,
  TransferUnitsRequest,
  unknown
> => useMutation({ mutationFn: transferUnitsBetweenWallets });
