import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryClient,
  QueryKey,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { getPractitionerByEmail } from 'api/api';
import { QueryOptionsConfig } from 'api/hooks';
import {
  Group,
  GroupResponse,
  PractitionerData,
  RequestError,
} from 'api/httpEntities';
import { QUERY_KEY_ROOTS } from 'variables';
import {
  createGroup,
  getGroup,
  getGroups,
  deleteGroup,
  updateGroup,
  createGroupedOrganisation,
  addOrganisationToGroup,
  addOrganisationsToGroup,
  removeOrganisationFromGroup,
  getGroupsByOrganisations,
  addExistingUserToOrgs,
  getExistingUserByGroup,
} from './organisationGroups';
import { AddExistingUserRequestData } from './organisationGroupsTypes';

export const useCreateGroup = (
  queryClient: QueryClient,
): UseMutationResult<Group, Error, Omit<Group, 'id'>, Error> =>
  useMutation({
    mutationFn: createGroup,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA],
      }),
  });

export const useGetGroup = (
  groupId: string,
  config: QueryOptionsConfig<GroupResponse> = {},
): UseQueryResult<GroupResponse, Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA, groupId] as QueryKey,
    queryFn: () => getGroup(groupId),
    ...config,
  });

export const useGetAllGroups = (
  config: any,
): UseQueryResult<GroupResponse[], Error> => {
  return useQuery({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_GROUPS_DATA],
    queryFn: async () => {
      const { organisationGroups } = await getGroups();

      return organisationGroups;
    },
    ...config,
  });
};

export const useDeleteGroup = (): UseMutationResult<
  void,
  Error,
  string,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA],
      });
    },
  });
};

export const useUpdateGroup = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: updateGroup,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA],
      }),
  });

export const useCreateGroupedOrganisation = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: createGroupedOrganisation,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA,
          QUERY_KEY_ROOTS.ORGANISATION_GROUPS_DATA,
        ],
      }),
  });

export const useAddOrganisationToGroup = (
  queryClient: QueryClient,
  onError: (err: RequestError) => void = () => {},
) =>
  useMutation({
    mutationFn: addOrganisationToGroup,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA,
          QUERY_KEY_ROOTS.ORGANISATION_GROUPS_DATA,
        ],
      }),
    onError,
  });

export const useAddOrganisationsToGroup = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: addOrganisationsToGroup,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA,
          QUERY_KEY_ROOTS.ORGANISATION_GROUPS_DATA,
        ],
      }),
  });

export const useRemoveOrganisationFromGroup = (
  queryClient: QueryClient,
  onError: (err: RequestError) => void = () => {},
) =>
  useMutation({
    mutationFn: removeOrganisationFromGroup,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_ROOTS.ORGANISATION_GROUP_DATA,
          QUERY_KEY_ROOTS.ORGANISATION_GROUPS_DATA,
        ],
      }),
    onError,
  });

export const useGetGroupByOrganisation = (
  id: string,
  config: QueryOptionsConfig<GroupResponse | null>,
) =>
  useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.ORGANISATIONS_ORGANISATION_GROUP_DATA,
      id,
    ] as QueryKey,
    queryFn: async () => {
      const { organisationGroups } = await getGroupsByOrganisations({
        ids: [id],
      });
      return organisationGroups.length > 0 ? organisationGroups[0] : null;
    },
    ...config,
  });

export const useGetGroupsByOrganisations = (
  ids: string[],
  config: QueryOptionsConfig<GroupResponse[]>,
) =>
  useQuery({
    queryKey: [
      QUERY_KEY_ROOTS.ORGANISATIONS_ORGANISATION_GROUP_DATA,
    ] as QueryKey,
    queryFn: async () => {
      const { organisationGroups } = await getGroupsByOrganisations({
        ids,
      });
      return organisationGroups;
    },
    ...config,
  });

export const useAddExistingUserToGroupOrgs = (): UseMutationResult<
  void,
  RequestError,
  AddExistingUserRequestData,
  unknown
> => {
  return useMutation({
    mutationFn: ({
      partnerGroupId,
      practitionerId,
      payload,
    }: {
      partnerGroupId: string;
      practitionerId: string;
      payload: { organisationIds: string[] };
    }) => {
      return addExistingUserToOrgs(partnerGroupId, practitionerId, payload);
    },
  });
};

export const useGetPractitionerByGroupAndEmail = (
  groupId: string,
  email: string | undefined,
  isGM: boolean,
): UseQueryResult<PractitionerData | null, RequestError> => {
  const getExistingPractitioner =
    groupId && isGM
      ? () => getExistingUserByGroup(groupId, email)
      : () => getPractitionerByEmail(email!);
  return useQuery({
    queryKey: [email, groupId],
    queryFn: async () => getExistingPractitioner(),
    enabled: !!email,
  });
};
