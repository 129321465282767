import { MutateOptions } from '@tanstack/react-query';
import { OrganisationSupportedRole, PractitionerData } from 'api/httpEntities';
import { InviteUserDataV2 } from 'api/practitioners/practitionerApiTypes';
import { LicensedProduct, Role } from 'types/types';

export const ROLE_MAP: RoleMapType = {
  CLIENT_SERVICES: [
    'STANDARD',
    'ADVANCED_USER',
    'GROUP_MANAGER',
    'SUPER_ADMIN',
  ],
  GROUP_MANAGER: ['STANDARD', 'ADVANCED_USER', 'GROUP_MANAGER'],
  ADVANCED_USER: ['STANDARD', 'ADVANCED_USER'],
  STANDARD: ['STANDARD'],
};
export interface FormData extends InviteUserDataV2 {
  id?: string;
  jobTitle: string;
  licensedProducts: LicensedProduct[];
}

const permissionRoles = [
  'CLIENT_SERVICES',
  'STANDARD',
  'GROUP_MANAGER',
  'ADVANCED_USER',
] as const;

export type PermissionRole = (typeof permissionRoles)[number];

export type RoleMapType = Record<PermissionRole, Role[]>;

export type AddUserFormProps = {
  mutate: (data: FormData, config: MutateOptions<any, any, any>) => void;
  resetMutation: (existingPractitioner?: boolean) => void;
  inviteUserError: any;
  isInviteUserError: boolean;
  currentOrgId: string;
  currentGroupId: string;
  initialPractitioner?: PractitionerData;
  supportedRoles: OrganisationSupportedRole[];
};

export type PermissionRoleTextType = {
  [key in PermissionRole]: any[];
};
