import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';

export type ConfirmType = 'grant' | 'remove';
export type Props = {
  open: boolean;
  confirmType: ConfirmType | undefined;
  onClose: () => void;
  handleConfirm: () => void;
  processing: boolean;
  usersName: string;
};

const textKeys: Record<ConfirmType, { [key: string]: string }> = {
  grant: {
    body: 'ui.event-management.access.confirm-grant.body',
    confirmButton: 'ui.event-management.access.confirm-grant.yes-button',
  },
  remove: {
    body: 'ui.event-management.access.confirm-remove.body',
    confirmButton: 'ui.event-management.access.confirm-remove.yes-button',
  },
};

const ConfirmAccessDialog = ({
  open,
  onClose,
  confirmType,
  handleConfirm,
  processing,
  usersName,
}: Props) => {
  const { t } = useTranslation();

  if (!confirmType) {
    return null;
  }

  const { body, confirmButton } = textKeys[confirmType];

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="confirmation-dialog-title">
        {t('ui.event-management.access.confirm.title')}
      </DialogTitle>
      <DialogContent dividers id="alert-dialog-description">
        <DialogContentText color="textPrimary" variant="body1">
          {t(body, { name: usersName })}
        </DialogContentText>
        <DialogContentText variant="body1">
          {t('ui.event-management.access.confirm.caption')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Button
              fullWidth
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              disabled={processing}
            >
              {t(confirmButton)}
            </Button>
          </Grid>
          <Grid item md={12}>
            <Button
              fullWidth
              onClick={onClose}
              color="primary"
              variant="outlined"
              disabled={processing}
            >
              {t('ui.event-management.access.confirm.no-button')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAccessDialog;
