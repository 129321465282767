import { Box, styled, useTheme } from '@mui/material';
import React, { useState } from 'react';
import {
  dedupedInitialFromScore,
  InitialledScore,
} from 'pages/DownloadTeamWheel/scoreUtils';
import SVGTextMeasurer from 'pages/DownloadTeamWheel/SVGTextMeasurer/SVGTextMeasurer';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import ColourOrderPreference from './ColourOrderPreference';
import TeamTableHeader from './TeamTableHeader';
import useSorting from './useSorting';
import getTableXCoordinates from './getTableXCoordinates';

const HEADER_HEIGHT = 16;
const ROW_HEIGHT = 12;

type TeamTableProps = {
  scores: (InitialledScore & {
    organisationId: string;
    organisationName: string;
  })[];
  svgId: string;
  test?: boolean;
};

const StyledSvg = styled('svg')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
}));

const truncateString = (s: string) => {
  if (s.trim().length > 16) {
    return `${s.trim().substring(0, 15)}...`;
  }

  return s;
};

const TeamTable = ({ scores, svgId, test = false }: TeamTableProps) => {
  const theme = useTheme();
  const [wheelState] = useWheelContext();
  const { namesColumns, isMultiOrg } = wheelState;
  const { showNumbers } = namesColumns;
  const [largestSvgStringPixelLength, setLargestSvgStringPixelLength] =
    useState(0);
  const fullHeight = HEADER_HEIGHT + ROW_HEIGHT * scores.length;
  const { columns, lengthRemoved } = getTableXCoordinates(
    showNumbers,
    largestSvgStringPixelLength,
    !!isMultiOrg,
  );
  const minWidth = 195 - lengthRemoved;
  const padding = 5;
  const longestName = scores
    .map((score) => score.fullName)
    .reduce((a, b) => (a.length > b.length ? a : b), '');
  const sortedScores = useSorting(scores, wheelState);
  const isConscious = wheelState.scoreType === 'CONSCIOUS';

  if (largestSvgStringPixelLength === 0) {
    return (
      <SVGTextMeasurer
        text={longestName}
        reportStringPixelLength={(length = 0) => {
          setLargestSvgStringPixelLength(Math.max(length + padding, 80));
        }}
      />
    );
  }

  return (
    <Box p={2}>
      <StyledSvg
        viewBox={`0 0 ${minWidth + largestSvgStringPixelLength + 4} 
        ${fullHeight + 4}`}
        id={svgId}
      >
        <g id="rowGroup" transform="translate(1, 1)" role="table">
          <rect
            width={minWidth + largestSvgStringPixelLength}
            height={fullHeight}
            fill={theme.palette.grey[100]}
          />
          {sortedScores.length > 1 && (
            <rect
              stroke={theme.palette.blue.main}
              width={minWidth + largestSvgStringPixelLength}
              height={fullHeight}
              strokeWidth="0.5"
              fill="none"
            />
          )}
          <TeamTableHeader
            showNumbers={showNumbers}
            largestNameWidth={largestSvgStringPixelLength}
            isMultiOrg={!!isMultiOrg}
          />
          {sortedScores.map((score, i: number) => {
            const position = isConscious
              ? score.wheelPositions.conscious
              : score.wheelPositions.lessConscious;
            const colours = isConscious
              ? score.colourScores.conscious
              : score.colourScores.lessConscious;
            const dedupedInitial = dedupedInitialFromScore(score).split('-');
            return (
              <g
                transform={`translate(0 ${ROW_HEIGHT * (i + 2)})`}
                key={`${score.initials}-${score.duplicateNumber}`}
              >
                <g role="row">
                  <text fontSize="6px" textAnchor="start">
                    {showNumbers && (
                      <tspan
                        role="cell"
                        x={columns.numbers}
                        data-testid="team-table-number"
                      >
                        {String(score.index + 1)}
                      </tspan>
                    )}
                    <tspan role="cell" x={columns.initials}>
                      {dedupedInitial[0]}
                      <tspan
                        baselineShift="super"
                        fontSize="4px"
                        data-testid={
                          test
                            ? `${dedupedInitial[0]}-${dedupedInitial[1]}-table-superscript`
                            : null
                        }
                      >
                        {parseInt(dedupedInitial[1], 10) > 0 &&
                          dedupedInitial[1]}
                      </tspan>
                    </tspan>
                    <tspan
                      role="cell"
                      x={columns.name}
                      data-testid="team-table-name"
                    >
                      {isMultiOrg
                        ? truncateString(score.fullName)
                        : score.fullName}
                    </tspan>
                    {isMultiOrg && (
                      <tspan
                        role="cell"
                        x={columns.organisation}
                        data-testid="team-table-organisation"
                      >
                        {truncateString(score.organisationName)}
                      </tspan>
                    )}
                    <tspan
                      role="cell"
                      x={columns.position}
                      data-testid="team-table-position"
                    >
                      {position}
                    </tspan>
                  </text>
                  <g
                    role="cell"
                    transform={`translate(${columns.colourEnergies - 2} -6)`}
                  >
                    <ColourOrderPreference colours={colours} test={test} />
                  </g>
                  <line
                    stroke={theme.palette.blue.main}
                    strokeWidth="0.5"
                    x1="0"
                    y1="4"
                    x2={minWidth + largestSvgStringPixelLength}
                    y2="4"
                  />
                </g>
              </g>
            );
          })}
        </g>
      </StyledSvg>
    </Box>
  );
};

export default TeamTable;
