import { useState, useCallback, useEffect } from 'react';

const useSelection = <T>(
  selectableIds: T[],
): {
  selectAll: () => void;
  select: (id: T) => void;
  deselect: (idToDeselect: T) => void;
  isSelected: (id: T) => boolean;
  selecting: boolean;
  deselectAll: () => void;
  selectedIds: T[];
  allAreSelected: boolean;
} => {
  const [selectedIds, setSelectedIds] = useState<T[]>([]);

  useEffect(
    () =>
      setSelectedIds((oldSelectedIds) =>
        oldSelectedIds.filter((id) => selectableIds.includes(id)),
      ),
    [selectableIds],
  );

  const selectAll = useCallback(() => {
    return setSelectedIds(selectableIds);
  }, [selectableIds]);

  const deselectAll = useCallback(() => setSelectedIds([]), []);
  const select = useCallback(
    (id: T) => setSelectedIds((oldSelectedIds) => [...oldSelectedIds, id]),
    [],
  );
  const deselect = useCallback(
    (idToDeselect: T) =>
      setSelectedIds((oldSelectedIds) =>
        oldSelectedIds.filter((id) => id !== idToDeselect),
      ),
    [],
  );

  const isSelected = useCallback(
    (id: T) => selectedIds.includes(id),
    [selectedIds],
  );

  const allAreSelected =
    Boolean(selectedIds.length) && selectedIds.length === selectableIds.length;

  const selecting = selectedIds.length > 0;

  return {
    selectAll,
    select,
    deselect,
    isSelected,
    selecting,
    deselectAll,
    selectedIds,
    allAreSelected,
  };
};

export default useSelection;
