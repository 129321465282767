import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColourEnergyIndicator,
  DataTable,
  P,
} from '@insights-ltd/design-library/components';
import {
  ColourScoreColour,
  LearnerAndInviteeResponse,
  TeamLearnerResponse,
} from 'api';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Box, styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import LinkEmail from 'components/LinkEmail/LinkEmail';
import { LatestProfileText } from 'utils/LatestProfileText';
import downloadProfile from 'api/downloadProfile';
import DownloadProgressDialog from 'components/DownloadProgressDialog';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import { Dialects } from 'types/dialects';
import { isIDTLSupported } from 'variables/dialects';

const StyledDiv = styled('div')({});

type LearnersListProps = {
  items: LearnerAndInviteeResponse[];
  itemsPerPage: number;
  onPageChange: ({ lastPage }: { lastPage?: boolean }) => void;
};

type TransformedLearnerAndInviteeResponse = LearnerAndInviteeResponse & {
  learnerId: string;
  colourScores?: {
    conscious: Record<ColourScoreColour, number> & {
      preferred: ColourScoreColour[];
    };
  } | null;
};

type LearnersDataStructure = Record<
  keyof Omit<
    TransformedLearnerAndInviteeResponse,
    | 'id'
    | 'learnerId'
    | 'knownAs'
    | 'leadingColourEnergy'
    | 'latestProfileEvaluationAt'
    | 'latestEvaluation'
    | 'preferredDialect'
  >,
  string
>;

const dataTransformer = ({
  id,
  organisation,
  ...rest
}: LearnerAndInviteeResponse): TransformedLearnerAndInviteeResponse => ({
  id: `${id}-${organisation.id}`,
  learnerId: id,
  organisation,
  colourScores: rest.colourScores as {
    conscious: Record<ColourScoreColour, number> & {
      preferred: ColourScoreColour[];
    };
  } | null,
  ...rest,
});

const StyledListItemWrapper = styled('div')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.XS),
  },
}));

const LearnersList = ({
  items,
  itemsPerPage,
  onPageChange,
}: LearnersListProps) => {
  const { t } = useTranslation();

  const { openErrorModal } = useRequestErrorContext();

  const [showDownloadProfile, setShowDownloadProfileDialog] = useState(false);

  const dataStructure: LearnersDataStructure = {
    fullName: t('ui.event-management.learners.heading.full-name'),
    colourScores: t('ui.event-management.learners.heading.colour-energies'),
    primaryEmail: t('ui.event-management.learners.heading.email'),
    latestProfile: t('ui.event-management.learners.heading.latest-profile'),
    organisation: t('ui.event-management.learners.heading.organisation'),
  };

  const onDownloadProfile = async (
    learnerId: string,
    profileId: string,
    dialect: Dialects,
  ) => {
    try {
      setShowDownloadProfileDialog(true);
      await downloadProfile(learnerId, profileId, dialect, 'NAMED');
    } catch (e) {
      setShowDownloadProfileDialog(false);
      openErrorModal();
    } finally {
      setShowDownloadProfileDialog(false);
    }
  };

  const columnPredicate = (
    columnName: keyof TransformedLearnerAndInviteeResponse,
    columnValue: any,
    props: any,
  ) => {
    const { latestProfile, latestEvaluation } = props as TeamLearnerResponse & {
      learnerId: string;
    };
    switch (columnName) {
      case 'latestProfile':
        return (
          <LatestProfileText
            latestProfile={latestProfile}
            latestEvaluation={latestEvaluation}
            onClick={() => {
              onDownloadProfile(
                props.learnerId,
                latestProfile?.id ?? '',
                latestProfile?.profileType !== 'IDTL_PROFILE' ||
                  isIDTLSupported(props.preferredDialect)
                  ? props.preferredDialect
                  : 'en-GB',
              );
            }}
          />
        );
      case 'primaryEmail':
        return (
          <LinkEmail
            sx={(theme) => ({
              fontWeight: `${theme.typography.fontWeightMedium} !important`,
            })}
            email={columnValue as string}
          />
        );
      case 'fullName':
        return (
          <Box
            sx={(theme) => ({
              '> a': {
                display: 'flex',
                justifyContent: 'flex-start',
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                textDecoration: 'none',
                textDecorationColor: theme.palette.primary.main,
                paddingLeft: '0',
                '&:visited': {
                  color: theme.palette.primary.main,
                  textDecorationColor: theme.palette.primary.main,
                },
              },
            })}
            display="flex"
          >
            <Button
              component={Link}
              to={`/learners/${props.organisation.id}/${props.learnerId}`}
              sx={(theme) => ({
                '&:hover': {
                  backgroundColor: theme.palette.grey[300],
                },
              })}
            >
              {columnValue}
            </Button>
          </Box>
        );
      case 'organisation':
        return (
          <Button
            component={Link}
            to={`/organisations/${props.organisation.id}`}
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: theme.palette.grey[300],
              },
              padding: '0',
              minWidth: `0 !important`,
            })}
          >
            {columnValue.name}
          </Button>
        );
      case 'colourScores':
        return (
          <StyledDiv data-testid="colour-scores" sx={{ maxWidth: '330px' }}>
            <ColourEnergyIndicator
              {...(columnValue?.conscious ? { ...columnValue?.conscious } : {})}
            />
          </StyledDiv>
        );
      default:
        return <P>{columnValue}</P>;
    }
  };

  const transformedData = items.map((item) => dataTransformer(item));

  return (
    <StyledListItemWrapper>
      <DataTable
        dataStructure={dataStructure}
        items={transformedData}
        itemsPerPage={itemsPerPage}
        onPageChange={onPageChange}
        columnPredicate={columnPredicate}
      />
      <DownloadProgressDialog open={showDownloadProfile} />
    </StyledListItemWrapper>
  );
};
export default LearnersList;
