import { OrganisationResponse } from 'api';
import React, {
  useContext,
  createContext,
  useMemo,
  useReducer,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Resource } from 'utils/isPathActive';

export type SearchState = {
  resource?: Resource;
  searchTerm?: string;
  category?: string;
  filter?: string;
  checkbox?: boolean;
  organisation?: OrganisationResponse | null;
  sortBy?: string;
};

export const SearchContext = createContext<
  | {
      searchState: SearchState;
      resetState: () => void;
      setSearchState: React.Dispatch<Partial<SearchState>>;
    }
  | undefined
>(undefined);

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (context === undefined) {
    throw Error(
      'context was undefined. Please ensure useSearchContext is used within a SearchProvider.',
    );
  }

  return context;
};

export const SearchProvider = ({
  children,
  overwrite,
}: {
  children: React.ReactNode;
  overwrite?: {
    searchState: Partial<SearchState>;
    setSearchState: React.Dispatch<Partial<SearchState>>;
  };
}) => {
  const location = useLocation();
  const [searchState, setSearchState] = useReducer(
    (data: SearchState, partialData: Partial<SearchState>) => ({
      ...data,
      ...partialData,
    }),
    {
      resource: location?.state?.activeTab,
      searchTerm: '',
      organisation: location?.state?.organisation ?? null,
    },
  );
  const resetState = useCallback(() => {
    setSearchState(
      overwrite?.searchState
        ? overwrite.searchState
        : {
            resource: undefined,
            searchTerm: undefined,
            category: undefined,
            filter: undefined,
            organisation: undefined,
            sortBy: undefined,
          },
    );
  }, [overwrite]);
  const value = useMemo(
    () => ({
      searchState,
      resetState,
      setSearchState,
      ...overwrite,
    }),
    [overwrite, searchState, resetState],
  );
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
