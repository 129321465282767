import { useEffect, useState } from 'react';

export const useDebounceValue = (value: any, delay: number = 500): any => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (typeof value === 'string') {
        setDebouncedValue(value.trim());
      } else {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
