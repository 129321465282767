import React from 'react';
import { getTheme } from '@insights-ltd/design-library/themes';

const localTheme = getTheme();

const StaticSVG = () => (
  <>
    <rect x="0" y="0" fill="white" width="525" height="525" />
    <g id="grey-shading">
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        d="M275.086,304.938l50,169.352c21.833-6.902,28.588-10.623,43.589-18.127L283.502,301.63"
      />
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        strokeWidth="1.5"
        d="M306.125,245.171l170.023-51.646c-5.184-14.4-10.656-29.318-19.412-43.718l-154.34,86.657"
      />
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        strokeWidth="1.5"
        d="M301.135,280.274l157.271,85.084c7.173-13.752,13.018-28.246,18.053-42.85l-171.976-50.016"
      />
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        strokeWidth="1.5"
        d="M239.726,301.345l-85.233,156.131l0,0c12.039,7.129,28.973,13.908,42.797,18.465l50.158-172.363"
      />
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        strokeWidth="1.5"
        d="M215.344,273.552L45.643,323.95l0,0c4.144,15.18,10.592,30.305,17.558,43.359l154.304-86.576"
      />
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        strokeWidth="3"
        d="M218,236.999L62.983,151.802c-7.027,13.363-13.536,27.36-18.144,43.719l0,0l169.987,48.275"
      />
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        strokeWidth="1.5"
        d="M282.041,215.235l86.623-154.88c-13.724-6.983-28.096-13.446-44.884-18l0,0L274.697,212.95"
      />
      <path
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        strokeWidth="1.5"
        d="M245.657,214.565L196.383,43.89c-15.486,4.106-31.076,9.018-47.079,16.913l0,0l89.025,156.089"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="274.443"
        y1="305.007"
        x2="324.914"
        y2="476.247"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="196.938"
        y1="42.048"
        x2="247.159"
        y2="212.438"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="305.26"
        y1="246.132"
        x2="478.193"
        y2="195.161"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="43.994"
        y1="323.136"
        x2="214.466"
        y2="272.892"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="247.629"
        y1="305.409"
        x2="198.585"
        y2="476.741"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="323.158"
        y1="41.553"
        x2="274.11"
        y2="212.897"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="306.169"
        y1="272.034"
        x2="478.744"
        y2="321.435"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="43.555"
        y1="196.861"
        x2="214.262"
        y2="245.726"
      />
    </g>
    <g id="colour-slices">
      <path
        fill={localTheme.eightColourProfileColours.guide}
        stroke={localTheme.grey[100]}
        d="M149.712,461.159l-13.873,25.164l4.496,2.496l11.389,5.693l11.57,4.805l11.746,4.629l11.926,3.738
                    l12.103,3.201l12.278,2.67l12.106,2.139l12.457,1.422l12.282,0.893l12.458,0.354l12.461-0.354l12.276-0.893l12.461-1.426
                    l12.276-1.957l12.105-2.848l12.103-3.201l11.925-3.738l11.925-4.449l11.57-5.16l11.211-5.518l3.852-2.139l-13.876-25.34
                    c-32.772,18.018-70.412,28.275-110.452,28.275C220.315,489.616,182.56,479.288,149.712,461.159z"
      />
      <path
        fill={localTheme.eightColourProfileColours.reformer}
        stroke={localTheme.grey[100]}
        d="M372.123,59.355l14.148-27.126l-11.456-5.686l-11.818-5.326l-12.177-4.6l-12.172-3.877l-12.35-3.332
                    l-12.352-2.97l-12.52-2.063L288.722,2.85l-12.513-0.978l-12.7-0.432l-12.694,0.29l-12.508,0.837l-12.685,1.38l-12.324,2.108
                    l-12.496,2.65l-12.313,3.193l-12.131,3.743l-11.942,4.649l-11.762,4.829l-11.572,5.737l-5.213,2.875l14.001,25.054
                    c32.811-18.074,70.509-28.369,110.617-28.369C301.011,30.417,339.074,40.93,372.123,59.355z"
      />
      <path
        fill={localTheme.eightColourProfileColours.sage}
        stroke={localTheme.grey[100]}
        d="M59.463,149.03l-25.521-13.9l-2.219,3.973l-5.639,11.376l-5.28,11.738l-4.56,12.093l-3.841,12.09
        l-3.299,12.268l-2.94,12.269l-2.039,12.438L2.62,235.995l-0.96,12.432l-0.418,12.615l0.3,12.611l0.844,12.428l1.382,12.605
        l2.106,12.244l2.644,12.418l3.185,12.236l3.728,12.057l4.631,11.869l4.808,11.689l5.71,11.504l1.868,3.381l27.135-14.869
        c-18.275-32.947-28.694-70.854-28.694-111.199C30.887,219.757,41.261,181.927,59.463,149.03z"
      />
      <path
        fill={localTheme.eightColourProfileColours.innovator}
        stroke={localTheme.grey[100]}
        d="M519.617,245.519l-0.96-12.266l-1.491-12.44l-2.203-12.094l-2.735-12.272l-3.264-12.097l-3.799-11.925
                    l-4.687-11.749l-4.861-11.576l-5.747-11.4l-2.758-4.927l-26.534,14.588c18.78,33.284,29.509,71.714,29.509,112.656
                    c0,39.512-9.983,76.689-27.561,109.154l25.579,14.383l3.133-5.682l5.443-11.16l5.086-11.52l4.372-11.875l3.664-11.881l3.125-12.063
                    l2.773-12.066l1.885-12.242l1.352-12.428l0.819-12.25l0.284-12.434L519.617,245.519z"
      />
      <path
        fill={localTheme.eightColourProfileColours.initiator}
        stroke={localTheme.grey[100]}
        d="M460.801,147.761l24.562-14.24l-3.379-5.864l-6.742-10.667l-7.428-10.379l-7.551-10.057l-8.374-9.441
        l-8.413-8.885l-9.089-8.602l-9.13-8.04l-9.49-7.626l-10.078-7.102l-10.344-6.45l-10.475-6.132l-13.056,24.907
        C409.061,79.875,439.944,110.621,460.801,147.761z"
      />
      <path
        fill={localTheme.eightColourProfileColours.scholar}
        stroke={localTheme.grey[100]}
        d="M148.779,59.396l-13.766-24.518l-7.896,4.607l-10.92,6.99l-10.627,7.699l-10.296,7.831l-9.665,8.681
                    l-9.096,8.722l-8.805,9.425l-8.234,9.463l-7.806,9.841L44.4,118.583l-6.603,10.726l-3.552,6.145l25.01,13.949
                    C80.049,111.653,111.155,80.391,148.779,59.396z"
      />
      <path
        fill={localTheme.eightColourProfileColours.storyteller}
        stroke={localTheme.grey[100]}
        d="M370.993,461.31l14.071,25.371l8.925-5.221l11.08-7.113l10.78-7.834l10.447-7.971l9.807-8.832l9.229-8.875
                    l8.936-9.592l8.351-9.629l7.921-10.014l7.378-10.633l6.699-10.91l3.1-5.379l-25.73-14.521
                    C440.969,408.522,409.316,440.226,370.993,461.31z"
      />
      <path
        fill={localTheme.eightColourProfileColours.companion}
        stroke={localTheme.grey[100]}
        d="M59.785,371.579l-25.863,14.316l5.222,8.924l6.97,10.855l7.677,10.564l7.807,10.236l8.655,9.607l8.697,9.043
                    l9.396,8.756l9.437,8.184l9.811,7.76l10.417,7.227l10.692,6.564l7.095,4.088l14.514-26.209
                    C112.315,440.673,80.861,409.415,59.785,371.579z"
      />
    </g>
    <g id="background">
      <path
        fill={localTheme.grey[100]}
        d="M260.487,489.616c-126.47,0-229.047-102.256-229.587-228.6c-0.001,0.334-0.013,0.666-0.013,1
                    c0,126.805,102.796,229.6,229.6,229.6s229.599-102.795,229.599-229.6c0-0.334-0.012-0.666-0.013-1
                    C489.534,387.36,386.958,489.616,260.487,489.616z"
      />
      <path
        fill="none"
        d="M260.487,489.616c126.47,0,229.046-102.256,229.586-228.6c-0.54-126.343-103.116-228.6-229.586-228.6
                    c-126.47,0-229.047,102.256-229.587,228.6C31.44,387.36,134.018,489.616,260.487,489.616z"
      />
      <path
        opacity="0.2"
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        d="M58.921,149.777c-1.718,3.267-3.398,6.58-5.024,9.958c1.628-3.348,3.335-6.65,5.12-9.905L58.921,149.777z"
      />
      <path
        opacity="0.2"
        fill={localTheme.grey[400]}
        stroke={localTheme.grey[400]}
        d="M218.693,240.204l1.38-1.859L59.016,149.83c-1.784,3.255-3.491,6.557-5.12,9.905
                    c-4.508,9.362-8.58,19.263-11.702,30.344L218.693,240.204z"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        d="M259.896,152.321c-58.521,0-106.271,47.75-106.271,106.215c0,58.578,47.75,106.271,106.271,106.271
                    c58.522,0,106.272-47.693,106.272-106.271C366.168,200.071,318.418,152.321,259.896,152.321z"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        d="M260.473,91.265c-91.872,0-166.81,74.938-166.81,166.752c0,91.873,74.938,166.811,166.81,166.811
                    c91.872,0,166.809-74.938,166.809-166.811C427.282,166.202,352.344,91.265,260.473,91.265z"
      />
      <rect
        x="253.906"
        y="260.147"
        fill={localTheme.grey[700]}
        width="5.703"
        height="1.211"
      />
      <rect
        x="262.95"
        y="260.147"
        fill={localTheme.grey[700]}
        width="5.645"
        height="1.211"
      />
      <polygon
        fill={localTheme.grey[700]}
        points="255.461,256.001 259.436,260.032 260.242,259.228 256.268,255.194 "
      />
      <polygon
        fill={localTheme.grey[700]}
        points="261.913,262.394 265.831,266.427 266.635,265.677 262.661,261.646 "
      />

      <rect
        x="257.023"
        y="260.984"
        transform="matrix(-0.708 -0.7062 0.7062 -0.708 253.6258 632.5452)"
        fill={localTheme.grey[700]}
        width="1.139"
        height="5.701"
      />
      <polygon
        fill={localTheme.grey[700]}
        points="261.625,259.054 262.374,259.86 266.405,255.827 265.598,255.022 "
      />
      <rect
        x="260.357"
        y="254.388"
        fill={localTheme.grey[700]}
        width="1.152"
        height="5.703"
      />
      <polygon
        fill={localTheme.grey[100]}
        points="269.055,258.997 268.768,258.247 268.538,257.499 268.192,256.808 267.788,256.06 267.27,255.481
                    266.75,254.907 266.233,254.331 265.542,253.87 264.909,253.524 264.217,253.122 263.467,252.833 262.661,252.661 261.913,252.544
                    261.049,252.544 260.242,252.544 259.436,252.661 258.688,252.833 257.938,253.122 257.189,253.524 256.498,253.87
                    255.922,254.331 255.346,254.907 254.771,255.481 254.31,256.06 253.906,256.808 253.561,257.499 253.273,258.247 253.1,258.997
                    252.928,259.86 252.928,260.608 252.928,261.474 253.1,262.28 253.273,263.085 253.561,263.835 253.906,264.526 254.31,265.159
                    254.771,265.851 255.346,266.427 255.922,266.944 256.498,267.405 257.189,267.81 257.938,268.155 258.688,268.442
                    259.436,268.673 260.242,268.731 261.049,268.788 261.913,268.731 262.661,268.673 263.467,268.442 264.217,268.155
                    264.909,267.81 265.542,267.405 266.233,266.944 266.75,266.427 267.27,265.851 267.788,265.159 268.192,264.526 268.538,263.835
                    268.768,263.085 269.055,262.28 269.114,261.474 269.17,260.608 269.114,259.86 "
      />

      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M350.961,204.564c6.336,7.776,9.736,18.489,11.809,27.591l1.037,0.288 M350.961,204.564"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M352.172,311.931c5.529-8.41,8.467-18.201,10.771-28.801 M352.172,311.931"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M287.948,360.776c10.367-2.305,17.512-5.703,27.072-10.715 M287.948,360.776"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M204.716,349.716c8.985,6.625,19.354,9.734,28.685,11.809 M204.716,349.716"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M157.311,284.628c1.095,5.012,2.708,9.678,4.608,14.4c1.843,4.723,4.954,9.043,6.912,13.766 M157.311,284.628"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M156.908,233.306l-0.288-0.288c2.707-11.75,7.027-21.369,12.614-29.203 M156.908,233.306"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M206.213,167.354c10.31-6.106,19.757-10.022,29.261-12.096 M206.213,167.354"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M285.297,156.007c10.426,2.477,18.49,6.336,27.592,11.52 M285.297,156.007"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M304.249,97.371c16.359,3.917,22.811,8.697,37.844,16.358 M304.249,97.371"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M404.473,174.439c8.754,13.075,13.766,25.862,17.453,39.167 M404.473,174.439"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M405.28,340.673c6.566-11.809,12.268-24.998,16.357-38.938 M405.28,340.673"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M304.307,419.124c15.494-4.09,24.768-9.043,37.842-15.84 M304.307,419.124"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M178.047,403.054c14.112,7.545,24.48,12.498,38.361,16.07 M178.047,403.054"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M99.423,301.62c2.938,13.939,9.907,27.072,16.876,39.457 M99.423,301.62"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M99.423,215.047c3.917-15.149,9.216-27.303,16.127-39.975 M99.423,215.047"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M176.435,113.901c13.478-7.603,27.245-12.787,40.493-16.358 M176.435,113.901"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        d="M259.55,151.802c-58.521,0-106.271,47.75-106.271,106.214c0,58.58,47.75,106.271,106.271,106.271
                    s106.272-47.691,106.272-106.271C365.823,199.553,318.072,151.802,259.55,151.802z"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        d="M260.126,90.746c-91.872,0-166.81,74.938-166.81,166.751c0,91.873,74.938,166.811,166.81,166.811
                    s166.81-74.938,166.81-166.811C426.936,165.684,351.999,90.746,260.126,90.746z"
      />

      <rect
        x="253.56"
        y="259.63"
        fill={localTheme.grey[700]}
        width="5.703"
        height="1.209"
      />
      <rect
        x="262.603"
        y="259.63"
        fill={localTheme.grey[700]}
        width="5.645"
        height="1.209"
      />

      <polygon
        fill={localTheme.grey[700]}
        points="255.115,255.481 259.09,259.515 259.896,258.708 255.922,254.675 "
      />
      <polygon
        fill={localTheme.grey[700]}
        points="261.566,261.876 265.484,265.907 266.29,265.159 262.315,261.126 "
      />

      <rect
        x="256.676"
        y="260.466"
        transform="matrix(-0.7069 -0.7073 0.7073 -0.7069 252.8431 631.4087)"
        fill={localTheme.grey[700]}
        width="1.14"
        height="5.701"
      />
      <polygon
        fill={localTheme.grey[700]}
        points="261.279,258.534 262.027,259.341 266.059,255.31 265.252,254.503 "
      />

      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M350.616,204.045c6.336,7.776,9.735,18.489,11.809,27.591l1.037,0.288 M350.616,204.045"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M351.826,311.411c5.529-8.408,8.467-18.201,10.771-28.799 M351.826,311.411"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M287.601,360.257c10.368-2.305,17.512-5.703,27.073-10.715 M287.601,360.257"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M204.37,349.196c8.986,6.625,19.354,9.734,28.685,11.809 M204.37,349.196"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M156.965,284.108c1.095,5.012,2.708,9.678,4.608,14.4c1.843,4.725,4.954,9.043,6.912,13.768 M156.965,284.108"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M156.562,232.788l-0.288-0.288c2.707-11.75,7.027-21.369,12.614-29.203 M156.562,232.788"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M205.867,166.836c10.311-6.106,19.757-10.022,29.261-12.096 M205.867,166.836"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M284.952,155.489c10.426,2.477,18.49,6.336,27.591,11.52 M284.952,155.489"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M303.902,96.852c16.359,3.917,22.811,8.697,37.844,16.358 M303.902,96.852"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M404.126,173.921c8.755,13.075,13.767,25.862,17.453,39.167 M404.126,173.921"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M404.933,340.153c6.566-11.807,12.269-24.998,16.358-38.938 M404.933,340.153"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M303.96,418.604c15.494-4.088,24.768-9.043,37.843-15.84 M303.96,418.604"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M177.701,402.534c14.112,7.547,24.48,12.5,38.362,16.07 M177.701,402.534"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M99.077,301.101c2.938,13.939,9.907,27.072,16.876,39.457 M99.077,301.101"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M99.077,214.529c3.917-15.149,9.216-27.303,16.127-39.975 M99.077,214.529"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="0"
        strokeDasharray="2.0001 3.0002"
        d="M176.088,113.383c13.478-7.603,27.245-12.787,40.493-16.358 M176.088,113.383"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        d="M260.703,33.13c-124.819,0-226.599,101.779-226.599,226.482c0,124.82,101.779,226.6,226.599,226.6
        c124.762,0,226.541-101.779,226.541-226.6C487.244,134.91,385.464,33.13,260.703,33.13z"
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        d="M260.127,210.497c-26.15,0-47.463,21.543-47.463,47.981c0,26.439,21.313,47.98,47.463,47.98
        c26.092,0,47.405-21.541,47.405-47.98C307.532,232.04,286.219,210.497,260.127,210.497z"
      />
      <path
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="2"
        d="M368.316,62.648"
      />

      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="150.91"
        y1="460.292"
        x2="135.145"
        y2="488.925"
      />
      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="386.39"
        y1="29.013"
        x2="370.09"
        y2="58.615"
      />
      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="371.488"
        y1="460.962"
        x2="387.252"
        y2="489.562"
      />
      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="133.807"
        y1="29.761"
        x2="150.48"
        y2="60.009"
      />
      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="461.514"
        y1="368.388"
        x2="488.356"
        y2="383.054"
      />
      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="27.608"
        y1="131.333"
        x2="59.573"
        y2="148.796"
      />
      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="461.363"
        y1="148.026"
        x2="487.744"
        y2="133.267"
      />
      <path
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="2"
        d="M461.363,147.026"
      />
      <line
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="3"
        x1="29.552"
        y1="387.61"
        x2="60.677"
        y2="370.196"
      />
      <path
        fill="none"
        stroke={localTheme.grey[100]}
        strokeWidth="3.5"
        d="M260.649,1.75C117.664,1.75,1.75,117.663,1.75,260.647c0,142.988,115.914,258.9,258.899,258.9
                    c142.986,0,258.898-115.912,258.898-258.9C519.547,117.663,403.635,1.75,260.649,1.75z M260.487,489.616
                    c-126.804,0-229.6-102.795-229.6-229.6c0-126.804,102.796-229.6,229.6-229.6s229.599,102.795,229.599,229.6
                    C490.086,386.821,387.292,489.616,260.487,489.616z"
      />
      <circle
        fill="none"
        stroke={localTheme.grey[100]}
        strokeWidth="3.5"
        cx="260.487"
        cy="260.017"
        r="229.6"
      />
    </g>
    <g id="spokes">
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        strokeDasharray="4.0003 6"
        x1="260.704"
        y1="32.87"
        x2="260.704"
        y2="484.87"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        strokeDasharray="4.0003 6"
        x1="34.315"
        y1="258.87"
        x2="486.982"
        y2="258.87"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        strokeDasharray="4.0003 6"
        x1="100.385"
        y1="419.413"
        x2="421.468"
        y2="98.329"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        strokeDasharray="4.0003 6"
        x1="100.94"
        y1="98.829"
        x2="421.024"
        y2="418.911"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="151.052"
        y1="60.964"
        x2="370.69"
        y2="456.776"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="63.132"
        y1="368.69"
        x2="458.611"
        y2="149.237"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="369.083"
        y1="60.111"
        x2="152.549"
        y2="457.63"
      />
      <line
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="2"
        x1="62.334"
        y1="150.604"
        x2="459.853"
        y2="367.138"
      />
      <circle
        fill={localTheme.grey[100]}
        stroke={localTheme.grey[100]}
        strokeWidth="2.0001"
        cx="260.024"
        cy="259.022"
        r="20.625"
      />
    </g>
    <g id="centre_circle">
      <path
        fill={localTheme.grey[100]}
        d="M260.178,305.913c25.702,0,46.696-21.221,46.696-47.266c0-26.043-20.994-47.265-46.696-47.265
                    c-25.761,0-46.755,21.222-46.755,47.265C213.423,284.692,234.417,305.913,260.178,305.913L260.178,305.913z"
      />
    </g>
    <g id="quadrants">
      <polygon
        fill={localTheme.fourColourProfileColours.coolBlue}
        points="259.94,260.169 236.625,260.169 236.648,258.935 236.74,257.677 236.899,256.468 237.103,255.282
                    237.353,254.095 237.671,252.933 238.034,251.794 238.466,250.702 238.919,249.612 239.441,248.565 240.008,247.548
                    240.622,246.552 241.256,245.603 241.959,244.677 242.686,243.798 243.458,242.944 244.276,242.138 245.117,241.378
                    246.002,240.644 246.91,239.954 247.863,239.339 248.838,238.745 249.837,238.2 250.881,237.7 251.949,237.274 253.016,236.896
                    254.128,236.562 255.263,236.302 256.398,236.063 257.555,235.921 258.736,235.827 259.94,235.804 259.94,260.169 "
      />
      <polygon
        fill={localTheme.fourColourProfileColours.earthGreen}
        points="259.94,258.3 259.94,282.354 258.736,282.308 257.555,282.214 256.398,282.073 255.263,281.862
                    254.128,281.583 253.016,281.255 251.949,280.88 250.881,280.458 249.837,279.968 248.838,279.431 247.863,278.868 246.91,278.235
                    246.002,277.558 245.117,276.856 244.276,276.083 243.458,275.288 242.686,274.468 241.959,273.579 241.256,272.667
                    240.622,271.729 240.008,270.749 239.441,269.741 238.919,268.714 238.466,267.636 238.034,266.56 237.671,265.437 237.353,264.29
                    237.103,263.142 236.899,261.95 236.74,260.757 236.648,259.54 236.625,258.3 259.94,258.3 "
      />
      <polygon
        fill={localTheme.fourColourProfileColours.fieryRed}
        points="259.94,260.169 259.94,235.804 261.138,235.827 262.315,235.921 263.469,236.063 264.622,236.302
                    265.752,236.562 266.838,236.896 267.926,237.274 268.989,237.7 270.005,238.2 271.001,238.745 271.975,239.339 272.923,239.954
                    273.829,240.644 274.712,241.378 275.571,242.138 276.364,242.944 277.133,243.798 277.879,244.677 278.557,245.603
                    279.214,246.552 279.824,247.548 280.367,248.565 280.866,249.612 281.34,250.702 281.748,251.794 282.132,252.933
                    282.425,254.095 282.698,255.282 282.902,256.468 283.06,257.677 283.15,258.935 283.172,260.169 259.94,260.169 "
      />
      <polygon
        fill={localTheme.fourColourProfileColours.sunshineYellow}
        points="259.94,258.466 283.172,258.466 283.15,259.698 283.06,260.907 282.902,262.091 282.698,263.274
                    282.425,264.415 282.132,265.554 281.748,266.669 281.34,267.737 280.866,268.808 280.367,269.829 279.824,270.829
                    279.214,271.804 278.557,272.733 277.879,273.64 277.133,274.522 276.364,275.337 275.571,276.126 274.712,276.894
                    273.829,277.591 272.923,278.265 271.975,278.892 271.001,279.45 270.005,279.983 268.989,280.472 267.926,280.89 266.838,281.261
                    265.752,281.589 264.622,281.866 263.469,282.075 262.315,282.214 261.138,282.308 259.94,282.354 259.94,258.466 "
      />
      <path
        fill="none"
        stroke={localTheme.grey[800]}
        strokeWidth="1.5"
        d="M259.915,281.786c12.498,0,22.693-10.252,22.693-22.752c0-12.557-10.195-22.752-22.693-22.752
        c-12.5,0-22.752,10.195-22.752,22.752C237.163,271.534,247.415,281.786,259.915,281.786L259.915,281.786z"
      />

      <g>
        <line
          fill={localTheme.grey[800]}
          stroke={localTheme.grey[800]}
          x1="259.915"
          y1="235.747"
          x2="259.915"
          y2="281.251"
        />
        <line
          fill={localTheme.grey[800]}
          stroke={localTheme.grey[800]}
          x1="236.625"
          y1="258.499"
          x2="283.172"
          y2="258.499"
        />
      </g>
    </g>
  </>
);
export default StaticSVG;
