import { InviteeListOptions, Contributor } from 'types/types';
import { InviteeResponse } from 'api';

export const readyToPurchase = (invitee: { status: InviteeListOptions }) =>
  invitee.status === 'READY_TO_PURCHASE';

export const profilesReadyToPurchased = (invitee: InviteeResponse) =>
  invitee.status === 'READY_TO_PURCHASE' && invitee.profileStatus === 'NONE';

export const contributorProfilesReadyToPurchased = (contributor: Contributor) =>
  contributor.status === 'READY_TO_PURCHASE';

export const profilesPurchased = (invitee: InviteeResponse) =>
  invitee.profileStatus === 'PURCHASED' || invitee.profileStatus === 'REUSED';
