import { BaseDialects } from '@insights-ltd/design-library/utils';
import { useTranslation } from 'react-i18next';
import {
  Dialects,
  PreferredDialect,
  SUPPORTED_DIALECTS,
  BETA_SUPPORTED_DIALECTS,
} from 'types/dialects';

export const DIALECTS: { [key: string]: Dialects } = {
  AFRIKAANS: 'af-ZA',
  BAHASA: 'id-ID',
  BULGARIAN: 'bg-BG',
  CHINESE_SIMPLIFIED: 'zh-CN',
  CHINESE_TRADITIONAL_HK: 'zh-HK',
  CROATIAN: 'hr-HR',
  CZECH: 'cs-CZ',
  DANISH: 'da-DK',
  DUTCH: 'nl-NL',
  ENGLISH: 'en-GB',
  ENGLISH_SOUTH_AFRICA: 'en-ZA',
  ESTONIAN: 'et-EE',
  FINNISH: 'fi-FI',
  FRENCH: 'fr-FR',
  FRENCH_CANADA: 'fr-CA',
  GEORGIAN: 'ka-GE',
  GERMAN: 'de-DE',
  GREEK: 'el-GR',
  HUNGARIAN: 'hu-HU',
  ITALIAN: 'it-IT',
  ICELANDIC: 'is-IS',
  JAPANESE: 'ja-JP',
  KOREAN: 'ko-KR',
  LITHUANIAN: 'lt-LT',
  NORWEGIAN_BOKMAL: 'nb-NO',
  POLISH: 'pl-PL',
  PORTUGUESE_BRAZIL: 'pt-BR',
  PORTUGUESE_PORTUGAL: 'pt-PT',
  ROMANIAN: 'ro-RO',
  RUSSIAN: 'ru-RU',
  SERBIAN_LATIN: 'sr-RS',
  SLOVAKIAN: 'sk-SK',
  SLOVENIAN: 'sl-SI',
  SPANISH_MEXICO: 'es-MX',
  SPANISH_SPAIN: 'es-ES',
  SWEDISH: 'sv-SE',
  TURKISH: 'tr-TR',
  UKRAINIAN: 'uk-UA',
  VIETNAMESE: 'vi-VN',
};

/**
  @description We are keeping the locales here static values rather than i18n keys
 as we want these values to be universal across all dialects and not translated
*/
export const LOCALES: { [p: string]: string } = {
  [DIALECTS.AFRIKAANS]: 'Afrikaans (ZA)',
  [DIALECTS.BAHASA]: 'Bahasa (ID)',
  [DIALECTS.CZECH]: 'Čeština (CZ)',
  [DIALECTS.DANISH]: 'Dansk (DK)',
  [DIALECTS.GERMAN]: 'Deutsch (DE)',
  [DIALECTS.ESTONIAN]: 'Eesti keel (EE)',
  [DIALECTS.ENGLISH]: 'English (UK)',
  [DIALECTS.ENGLISH_SOUTH_AFRICA]: 'English (ZA)',
  [DIALECTS.SPANISH_SPAIN]: 'Español (ES)',
  [DIALECTS.SPANISH_MEXICO]: 'Español (MX)',
  [DIALECTS.FRENCH]: 'Français (FR)',
  [DIALECTS.FRENCH_CANADA]: 'Français (CA)',
  [DIALECTS.CROATIAN]: 'Hravtski (HR)',
  [DIALECTS.ICELANDIC]: 'Íslenska (IS)',
  [DIALECTS.ITALIAN]: 'Italiano (IT)',
  [DIALECTS.LITHUANIAN]: 'Lietuvių k. (LT)',
  [DIALECTS.HUNGARIAN]: 'Magyar (HU)',
  [DIALECTS.DUTCH]: 'Nederlands (NL)',
  [DIALECTS.NORWEGIAN_BOKMAL]: 'Norsk bokmål (NO)',
  [DIALECTS.POLISH]: 'Polski (PL)',
  [DIALECTS.PORTUGUESE_BRAZIL]: 'Português (BR)',
  [DIALECTS.PORTUGUESE_PORTUGAL]: 'Português (PT)',
  [DIALECTS.ROMANIAN]: 'Română (RO)',
  [DIALECTS.SLOVAKIAN]: 'Slovenčina (SK)',
  [DIALECTS.SLOVENIAN]: 'Slovenščina (SI)',
  [DIALECTS.SERBIAN_LATIN]: 'Srpski latinica (RS)',
  [DIALECTS.FINNISH]: 'Suomi (FI)',
  [DIALECTS.SWEDISH]: 'Svenska (SE)',
  [DIALECTS.VIETNAMESE]: 'Tiếng Việt (VN)',
  [DIALECTS.TURKISH]: 'Türkçe (TR)',
  [DIALECTS.GREEK]: 'Ελληνικά (GR)',
  [DIALECTS.BULGARIAN]: 'Български (BG)',
  [DIALECTS.RUSSIAN]: 'Русский (RF)',
  [DIALECTS.UKRAINIAN]: 'Українська (UA)',
  [DIALECTS.GEORGIAN]: 'ქართული (GE)',
  [DIALECTS.KOREAN]: '한국어 (KR)',
  [DIALECTS.JAPANESE]: '日本語（JP）',
  [DIALECTS.CHINESE_SIMPLIFIED]: '中文（CN）',
  [DIALECTS.CHINESE_TRADITIONAL_HK]: '中文（HK）',
};

export const AllDialects: { [key: string]: string } = {
  [DIALECTS.AFRIKAANS]:
    'ui.event-management.events.create.event-language.afrikaans_south_africa',
  [DIALECTS.BAHASA]:
    'ui.event-management.events.create.event-language.bahasa_indonesia',
  [DIALECTS.BULGARIAN]:
    'ui.event-management.events.create.event-language.bulgarian_bulgaria',
  [DIALECTS.CHINESE_SIMPLIFIED]:
    'ui.event-management.events.create.event-language.chinese_simplified',
  [DIALECTS.CHINESE_TRADITIONAL_HK]:
    'ui.event-management.events.create.event-language.chinese_traditional',
  [DIALECTS.CROATIAN]:
    'ui.event-management.events.create.event-language.croatian_croatia',
  [DIALECTS.CZECH]:
    'ui.event-management.events.create.event-language.czech_czech_republic',
  [DIALECTS.DANISH]:
    'ui.event-management.events.create.event-language.danish_denmark',
  [DIALECTS.DUTCH]:
    'ui.event-management.events.create.event-language.dutch_netherlands',
  [DIALECTS.ENGLISH]:
    'ui.event-management.events.create.event-language.english_united_kingdom',
  [DIALECTS.ENGLISH_SOUTH_AFRICA]:
    'ui.event-management.events.create.event-language.english_south_africa',
  [DIALECTS.ESTONIAN]:
    'ui.event-management.events.create.event-language.estonian_estonia',
  [DIALECTS.FINNISH]:
    'ui.event-management.events.create.event-language.finnish_finland',
  [DIALECTS.FRENCH]:
    'ui.event-management.events.create.event-language.french_france',
  [DIALECTS.FRENCH_CANADA]:
    'ui.event-management.events.create.event-language.french_canada',
  [DIALECTS.GEORGIAN]:
    'ui.event-management.events.create.event-language.georgian_georgia',
  [DIALECTS.GERMAN]:
    'ui.event-management.events.create.event-language.german_germany',
  [DIALECTS.GREEK]:
    'ui.event-management.events.create.event-language.greek_greece',
  [DIALECTS.HUNGARIAN]:
    'ui.event-management.events.create.event-language.hungarian_hungary',
  [DIALECTS.ITALIAN]:
    'ui.event-management.events.create.event-language.italian_italy',
  [DIALECTS.JAPANESE]:
    'ui.event-management.events.create.event-language.japanese_japan',
  [DIALECTS.KOREAN]:
    'ui.event-management.events.create.event-language.korean_korea',
  [DIALECTS.LITHUANIAN]:
    'ui.event-management.events.create.event-language.lithuanian_lithuania',
  [DIALECTS.NORWEGIAN_BOKMAL]:
    'ui.event-management.events.create.event-language.norwegian_norway',
  [DIALECTS.POLISH]:
    'ui.event-management.events.create.event-language.polish_poland',
  [DIALECTS.PORTUGUESE_BRAZIL]:
    'ui.event-management.events.create.event-language.portuguese_brazil',
  [DIALECTS.PORTUGUESE_PORTUGAL]:
    'ui.event-management.events.create.event-language.portuguese_portugal',
  [DIALECTS.ROMANIAN]:
    'ui.event-management.events.create.event-language.romanian_romania',
  [DIALECTS.RUSSIAN]:
    'ui.event-management.events.create.event-language.russian_russian_federation',
  [DIALECTS.SERBIAN_LATIN]:
    'ui.event-management.events.create.event-language.serbian_bosnia_herzegovina_latin',
  [DIALECTS.SLOVAKIAN]:
    'ui.event-management.events.create.event-language.slovak_slovakia',
  [DIALECTS.SLOVENIAN]:
    'ui.event-management.events.create.event-language.slovenian_slovenia',
  [DIALECTS.SPANISH_MEXICO]:
    'ui.event-management.events.create.event-language.spanish_mexico',
  [DIALECTS.SPANISH_SPAIN]:
    'ui.event-management.events.create.event-language.spanish_spain',
  [DIALECTS.SWEDISH]:
    'ui.event-management.events.create.event-language.swedish_sweden',
  [DIALECTS.TURKISH]:
    'ui.event-management.events.create.event-language.turkish_turkey',
  [DIALECTS.UKRAINIAN]:
    'ui.event-management.events.create.event-language.ukrainian_ukraine',
  [DIALECTS.VIETNAMESE]:
    'ui.event-management.events.create.event-language.vietnamese_vietnam',
};

export const useGetApplicationPreferredDialects = (isBetaEnabled: boolean) => {
  const { t } = useTranslation();

  const supportedDialects = isBetaEnabled
    ? (BETA_SUPPORTED_DIALECTS as unknown as PreferredDialect)
    : (SUPPORTED_DIALECTS as unknown as PreferredDialect);

  const { supported, unsupported } = (
    Object.keys(LOCALES) as BaseDialects[]
  ).reduce(
    (curr, key) => {
      const isSupported = supportedDialects.includes(key);

      if (!isSupported) {
        // eslint-disable-next-line no-param-reassign
        curr.unsupported[key] = t(
          'ui.event-management.events.preferred-language.unsupported-dialect',
          { dialect: t(LOCALES[key]) },
        );
      } else {
        // eslint-disable-next-line no-param-reassign
        curr.supported[key] = LOCALES[key];
      }

      return curr;
    },
    { supported: {}, unsupported: {} } as Record<
      'supported' | 'unsupported',
      Record<Dialects, string>
    >,
  );

  return { ...supported, ...unsupported };
};

export function isPreferredDialect(
  value?: Dialects,
  isBetaEnabled?: boolean,
): value is PreferredDialect {
  const supportedDialects = isBetaEnabled
    ? (BETA_SUPPORTED_DIALECTS as readonly Dialects[])
    : (SUPPORTED_DIALECTS as readonly Dialects[]);

  return !!value && supportedDialects.includes(value);
}
