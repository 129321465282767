import React, { useState } from 'react';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import LockIcon from '@mui/icons-material/Lock';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  GroupResponse,
  OrganisationResponse,
  TeamVisibility,
  useAddTeam,
} from 'api';
import { P, RadioGroupControl } from '@insights-ltd/design-library/components';
import FormHeader from 'components/FormHeader';
import UsersIcon from 'components/Svgs/icons/Users';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import { ARBITRARY_MAX_LENGTH } from 'variables';
import { User } from 'types/types';
import OrganisationInfoCard from 'components/OrganisationInfoCard/OrganisationInfoCard';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';

type Props = {
  group: GroupResponse;
  organisation: OrganisationResponse;
  learners: User[];
};

export type TeamFormData = {
  name: string;
  learners: User[];
  visibility: TeamVisibility;
  organisationId: string;
};

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > * + *': {
    marginTop: theme.spacing(spacingSizeMap.M),
  },
}));
const StyledTeamIcon = styled(UsersIcon)(({ theme }) => ({
  fill: theme.palette.blue.main,
  height: '20px',
}));
const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  fill: theme.palette.orange.main,
  height: '20px',
}));
const StyledLockIcon = styled(LockIcon)(({ theme }) => ({
  fill: theme.palette.pink.dark,
  height: '20px',
}));

const StyledP = styled(P)({
  fontWeight: '600 !important',
  lineHeight: '28px !important',
  marginTop: '0 !important',
  marginBottom: '16px !important',
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: `${theme.spacing(spacingSizeMap.S)} !important`,
  fontWeight: theme.typography.fontWeightBold,
}));

const AddToTeamForm = ({ group, organisation, learners }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TeamFormData>({
    defaultValues: { visibility: 'PRIVATE', organisationId: organisation.id },
  });
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [selectedVisibility, setSelectedVisibility] =
    useState<TeamVisibility>('PRIVATE');
  const { openErrorModal } = useRequestErrorContext();

  const { mutate: addTeam, isPending: isAdding } = useAddTeam(queryClient);

  const onSubmit = (formData: TeamFormData) => {
    let formDataChecked = { ...formData };
    formDataChecked = {
      ...formDataChecked,
      learners,
      visibility: selectedVisibility,
    };
    addTeam(
      {
        organisationId: organisation.id,
        formData: formDataChecked,
      },
      {
        onSuccess: (newTeam) => navigate(`/teams/${newTeam.id}/created`),
        onError: () => {
          openErrorModal();
        },
      },
    );
  };

  const handleVisibilityChange = (value: string) => {
    setSelectedVisibility(value as TeamVisibility);
  };

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormHeader
          icon={StyledOrganisationIcon}
          title={t('ui.event-management.users.create.organisation')}
          color="orange"
        />
        <OrganisationInfoCard
          key={organisation.id}
          id={organisation.id}
          name={organisation.name}
          group={group}
          icon={null}
        />
      </div>
      <div>
        <FormHeader
          icon={StyledTeamIcon}
          title={t('ui.event-management.teams.team-info')}
          showMargin={false}
          color="blue"
        />
        <StyledTextField
          id="name"
          label={t('ui.event-management.teams.team-name')}
          placeholder={t('ui.event-management.teams.team-name-placeholder')}
          required
          variant="outlined"
          fullWidth
          type="text"
          inputProps={{ maxLength: ARBITRARY_MAX_LENGTH }}
          error={Boolean(errors.name)}
          helperText={
            errors.name &&
            t(
              'ui.event-management.events.create.error.team-name-value-required',
            )
          }
          {...register('name', { required: true })}
        />
      </div>
      {/* Visibility permissions */}
      <div>
        <FormHeader
          icon={StyledLockIcon}
          title={t('ui.event-management.teams.view-permissions')}
          showMargin={false}
        />
        <StyledP variant="body-bold">
          {t('ui.event-management.teams.view-permissions.view-help-text')}
        </StyledP>
        <RadioGroupControl
          options={[
            {
              value: 'PRIVATE',
              label: t('ui.event-management.teams.permissions.option-private'),
            },
            {
              value: 'ORGANISATION',
              label: t(
                'ui.event-management.teams.permissions.option-organisation',
              ),
            },
          ]}
          name="visibility"
          title={t('ui.event-management.users.create.permission-roles')}
          value={selectedVisibility}
          onChange={handleVisibilityChange}
          error={Boolean(errors.visibility)}
          variant="boxed"
        />
      </div>
      {/* Submit button */}
      <Grid container justifyContent="flex-end">
        <Button
          data-testid="add-to-team"
          type="submit"
          variant="contained"
          color="primary"
          disabled={isAdding}
          endIcon={
            isAdding && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )
          }
        >
          {t('ui.event-management.teams.create-team')}
        </Button>
      </Grid>
    </StyledForm>
  );
};

export default AddToTeamForm;
