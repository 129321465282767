import { NavBarItem } from 'components/Header/types';

export type Resource = 'learners' | 'teams' | 'organisations' | 'groups';

const isPathActive = (pathname: string) => (item: NavBarItem) =>
  pathname.includes(item.to) ||
  (item.to.includes('learner') && pathname.includes('teams')) ||
  (item.to.includes('organisations') && pathname.includes('groups'));

export default isPathActive;
