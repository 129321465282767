import React from 'react';
import { useTranslation } from 'react-i18next';
import EventDetailsIcon from '@mui/icons-material/DateRange';
import { EventDetailsData, EventDetailsForm } from 'components/EventForms';
import FormHeader from 'components/FormHeader';
import EditFormNavigation from './EditFormNavigation';
import { EditProps } from './types';

const EditEventDetails = ({
  eventForm,
  onCancel,
  saveEvent,
  saving,
  invitedCount = 0,
}: EditProps) => {
  const { t } = useTranslation();

  const onSubmit = (formData: EventDetailsData) => {
    saveEvent(formData);
  };

  return (
    <>
      <FormHeader
        icon={EventDetailsIcon}
        title={t('ui.event-management.events.create.event-details')}
      />
      <EventDetailsForm
        event={eventForm}
        onSubmit={onSubmit}
        invitedCount={invitedCount}
      >
        <EditFormNavigation onCancel={onCancel} saving={saving} />
      </EventDetailsForm>
    </>
  );
};

export default EditEventDetails;
