import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgGroup = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={26}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="#B3B3B3"
      d="M23.917 17.083h-.045a.583.583 0 0 1-.526-.288L17.68 7.167a.583.583 0 0 1-.021-.556c.277-.551.423-1.16.424-1.778a4.083 4.083 0 1 0-8.166 0c.002.616.147 1.223.424 1.774a.584.584 0 0 1-.02.56l-5.668 9.628a.584.584 0 0 1-.525.288h-.045a4.083 4.083 0 1 0 3.66 5.869.583.583 0 0 1 .524-.327h11.466a.583.583 0 0 1 .524.327 4.084 4.084 0 1 0 3.66-5.869ZM8.266 19.707a.583.583 0 0 1-.523-.323 3.93 3.93 0 0 0-.416-.667.584.584 0 0 1-.04-.651l5.28-8.975a.584.584 0 0 1 .63-.275 3.455 3.455 0 0 0 1.606 0 .584.584 0 0 1 .63.274l5.279 8.973a.584.584 0 0 1-.04.651c-.16.208-.299.43-.415.664a.583.583 0 0 1-.523.325l-11.468.004Z"
    />
  </svg>
);

export default SvgGroup;
