import { ValidateResult } from 'react-hook-form';

// Chip states
export enum ErrorState {
  INVALID,
  VALID,
  NEUTRAL,
}

export const determineTagState = (
  value: string,
  isRequired: ValidateResult,
  isSuccess?: ValidateResult,
): ErrorState => {
  const isColoured = value.length > 0;
  if (isRequired) {
    return ErrorState.INVALID;
  }
  if (!isColoured) {
    return ErrorState.NEUTRAL;
  }
  if (isSuccess) {
    return ErrorState.VALID;
  }
  return ErrorState.INVALID;
};
