import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import CoreDetailsIcon from '@mui/icons-material/Assignment';
import { styled, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import { P } from '@insights-ltd/design-library/components';
import {
  canChangeEventType,
  eventTypesAreCompatible,
  isDFCExperience,
  isIdtlExperience,
} from 'domain/event';
import FormHeader from 'components/FormHeader';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import OrganisationAutocomplete from 'components/OrganisationPicker/OrganisationAutocomplete';
import { OrganisationResponse } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { EventType } from 'types/types';
import { CoreDetailsData, EventForm } from './types';
import { Select } from './Select';
import useGetCoreDetailsData from './useGetCoreDetailsData';
import { getErrorMessage } from './validation';

const ARBITRARY_MAX_LENGTH = 100;

type Props = {
  event: EventForm;
  isCreating?: boolean;
  onSubmit: (formData: CoreDetailsData) => void;
  children: ReactNode;
  invitedCount: number;
};

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  fill: theme.palette.pink.dark,
  height: '20px',
}));

const StyledCoreDetailsIcon = styled(CoreDetailsIcon)(({ theme }) => ({
  fill: theme.palette.pink.dark,
  height: '20px',
}));

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const StyledFormHeader = styled(FormHeader)(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.L),
}));

const StyledDiv = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
    spacingSizeMap.XS,
  )} ${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(spacingSizeMap.S)}`,
  background: theme.palette.blue.light,
  borderRadius: '4px',
}));

const CoreDetailsForm = ({
  event,
  isCreating = false,
  onSubmit,
  children,
  invitedCount,
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<CoreDetailsData>({
    defaultValues: event,
  });
  const [eventType] = watch(['eventType']);

  const { t } = useTranslation();

  const {
    isPending,
    isError,
    setMyOrganisation,
    organisations,
    myOrganisation,
    showOrganisationDropdown,
    eventTypes,
  } = useGetCoreDetailsData(isCreating);

  useEffect(() => {
    if (setMyOrganisation) {
      register('organisation', { required: true });
    }
  }, [register, setMyOrganisation]);

  useEffect(() => {
    if (setMyOrganisation && myOrganisation) {
      setValue('organisation', myOrganisation);
    }
  }, [setValue, setMyOrganisation, myOrganisation]);

  if (isPending) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (isError) {
    return (
      <FullScreenError
        message={t('ui.event-management.events.overview.error-loading-event')}
      />
    );
  }

  const eventTypeEditable =
    isCreating || (canChangeEventType(event.eventType) && invitedCount === 0);

  const filteredEventTypes = isCreating
    ? eventTypes
    : Object.fromEntries(
        Object.entries(eventTypes).filter(([otherEventType]) =>
          eventTypesAreCompatible(
            event.eventType as EventType,
            otherEventType as EventType,
          ),
        ),
      );

  if (
    isCreating &&
    eventType === 'INSIGHTS_DISCOVERY_ACCREDITATION' &&
    !eventTypes?.INSIGHTS_DISCOVERY_ACCREDITATION
  ) {
    setValue('eventType', '');
  }

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {!!organisations?.length && showOrganisationDropdown && (
        <>
          <FormHeader
            icon={StyledOrganisationIcon}
            color="pink"
            title={t('ui.event-management.users.create.organisation')}
            showMargin={false}
          />
          <Controller
            name="organisation"
            defaultValue={
              setMyOrganisation && myOrganisation ? myOrganisation : undefined
            }
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange: onReactHookFormChange, value } }) => {
              return (
                <OrganisationAutocomplete
                  options={organisations}
                  onSelect={(organisation: OrganisationResponse) => {
                    const org =
                      organisation.name === '' ? undefined : organisation;
                    if (org) onReactHookFormChange(organisation);
                  }}
                  selectedValue={value?.id}
                  loading={false}
                  disableClearable
                  error={Boolean(errors.organisation)}
                  helperText={t(getErrorMessage(errors.organisation))}
                  required
                />
              );
            }}
          />
        </>
      )}
      <StyledFormHeader
        icon={StyledCoreDetailsIcon}
        color="pink"
        title={t('ui.event-management.events.create.core-details')}
      />
      <TextField
        id="name"
        label={t('ui.event-management.events.create.label.event-name')}
        required
        variant="outlined"
        fullWidth
        type="text"
        {...register('name', { required: true })}
        inputProps={{ maxLength: ARBITRARY_MAX_LENGTH }}
        error={Boolean(errors.name)}
        helperText={t(getErrorMessage(errors.name))}
        sx={(theme) => ({
          marginTop: theme.spacing(spacingSizeMap.M),
        })}
        autoComplete="new-password"
      />
      <Select
        disabled={!eventTypeEditable}
        id="eventType"
        name="eventType"
        label={t('ui.event-management.events.create.label.event-type')}
        control={control}
        error={Boolean(errors.eventType)}
        rules={{ required: true }}
        errorMessage={t(getErrorMessage(errors.eventType))}
        sx={(theme: Theme) => ({
          marginTop: theme.spacing(spacingSizeMap.L),
        })}
      >
        {Object.entries(filteredEventTypes).map(([value, { textKey }]) => (
          <MenuItem key={value} value={value}>
            {t(textKey)}
          </MenuItem>
        ))}
      </Select>
      {isIdtlExperience(eventType) && (
        <StyledDiv>
          <P variant="body-bold">
            {t(
              'ui.event-management.events.create.event-type.insights-discovery-transformational-leadership-profile-message',
            )}
          </P>
        </StyledDiv>
      )}
      {isDFCExperience(eventType) && (
        <StyledDiv>
          <P variant="body-bold">
            {t(
              'ui.event-management.events.create.event-type.insights-discovery-full-circle-profile-message',
            )}
          </P>
        </StyledDiv>
      )}
      {children}
    </StyledForm>
  );
};

export default CoreDetailsForm;
