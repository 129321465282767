import { TeamLearnerResponse, TeamType, useRemoveTeamLearner } from 'api';
import React, { useState } from 'react';
import {
  ColourEnergyIndicator,
  DataTable,
  P,
  usePagination,
} from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useQueryClient } from '@tanstack/react-query';
import { booleanizeCheckedItems } from 'utils/mappers/entityMappers';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import LinkEmail from 'components/LinkEmail/LinkEmail';
import { LatestProfileText } from 'utils/LatestProfileText';
import { isIDTLSupported } from 'variables/dialects';
import DownloadProgressDialog from 'components/DownloadProgressDialog';
import downloadProfile from 'api/downloadProfile';
import { Dialects } from 'types/dialects';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import { TransformedLearnerResponse } from './learnerType';
import LearnersListEmptyMsg from './LearnersListEmptyMsg';

const StyledDiv = styled('div')({
  '> div': {
    height: '35px',
  },
});

const StyledListItemWrapper = styled('div')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.XS),
  },
}));

const StyledTable = styled(DataTable)({
  'tr td': {
    verticalAlign: 'top',
  },
  'tr > td:nth-of-type(1) > span > span': {
    paddingTop: 0,
  },
  'tr > td:last-of-type': {
    paddingTop: '18px',
    paddingBottom: '18px',
  },
  'tr > td button': {
    paddingBlock: 0,
  },
});

const ITEMS_PER_PAGE = 10;

type TeamLearnerResponseDataStructure = Partial<
  Record<keyof TeamLearnerResponse & 'colourScores' & 'organisation', string>
>;

type LearnersListProps = {
  evaluationIds: string[];
  learners: TransformedLearnerResponse[];
  teamId: string;
  onDelete?: () => void;
  checkedItems?: Record<string, TransformedLearnerResponse>;
  setCheckedItems?: (item: Record<string, TransformedLearnerResponse>) => void;
  migrated: boolean;
  selectable: boolean;
  type?: TeamType;
  onPageChange?: ({
    pageNumber,
    lastPage,
  }: {
    pageNumber: number;
    lastPage?: boolean;
  }) => void;
};

const RemoveTeamMember = ({
  id,
  onClick,
  disabled = false,
}: {
  id: string;
  onClick: (id: string) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Button
      aria-label={t(
        'ui.event-management.events.add-learners-and-contributors.contributor.delete.label',
      )}
      data-testid="remove-user"
      sx={() => ({
        padding: 0,
        minWidth: 0,
      })}
      onClick={() => onClick(id)}
      disabled={disabled}
    >
      <P variant="body-bold" color="error">
        {t('ui.event-management.invite-list.remove-user')}
      </P>
    </Button>
  );
};

const TeamLearners = ({
  learners,
  teamId,
  onDelete,
  checkedItems = {},
  setCheckedItems,
  migrated,
  selectable,
  type = 'SINGLE',
  onPageChange,
}: LearnersListProps) => {
  const [showDownloadProfile, setShowDownloadProfileDialog] = useState(false);
  const { openErrorModal } = useRequestErrorContext();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync: removeLearner } = useRemoveTeamLearner(
    queryClient,
    teamId,
  );
  const handleRemoveTeamMember = async (evaluationId: string) => {
    const learnerId =
      learners.find(({ id }) => id === evaluationId)?.learnerId ?? '';
    await removeLearner({ learnerId, teamId });
    if (onDelete) {
      onDelete();
    }
  };

  const onDownloadProfile = async (
    learnerId: string,
    profileId: string,
    dialect: Dialects,
  ) => {
    try {
      setShowDownloadProfileDialog(true);
      await downloadProfile(learnerId, profileId, dialect, 'NAMED');
      setShowDownloadProfileDialog(false);
    } catch (e) {
      setShowDownloadProfileDialog(false);
      openErrorModal();
    }
  };

  const dataStructure: TeamLearnerResponseDataStructure =
    type === 'SINGLE'
      ? {
          fullName: t('ui.event-management.learners.heading.full-name'),
          colourScores: t(
            'ui.event-management.learners.heading.colour-energies',
          ),
          primaryEmail: t('ui.event-management.learners.heading.email'),
          latestProfile: t(
            'ui.event-management.learners.heading.latest-profile',
          ),
        }
      : {
          fullName: t('ui.event-management.learners.heading.full-name'),
          primaryEmail: t('ui.event-management.learners.heading.email'),
          latestProfile: t(
            'ui.event-management.learners.heading.latest-profile',
          ),
          organisation: t('ui.event-management.learners.heading.organisation'),
        };

  const singleOrgColumnPredicate = (
    columnName: keyof (TeamLearnerResponse & {
      learnerId: string;
      colourScores: string;
      organisation: string;
    }),
    columnValue: string | number,
    props: Record<any, any>,
  ) => {
    const { fullName, learnerId, latestProfile, latestEvaluation } =
      props as TeamLearnerResponse & {
        learnerId: string;
      };

    switch (columnName) {
      case 'fullName':
        return (
          <Link
            component={RouterLink}
            data-testid="learner-detail-link"
            to={`/learners/${latestEvaluation.organisation.id}/${learnerId}`}
          >
            <Box display="flex">
              <P variant="body-bold" color="primary">
                {fullName}
              </P>
            </Box>
          </Link>
        );
      case 'colourScores':
        return (
          <ColourEnergyIndicator
            {...latestProfile?.colourScores?.conscious}
            data-testid="colour-energies"
          />
        );
      case 'primaryEmail':
        return (
          <LinkEmail
            sx={(theme) => ({
              fontWeight: `${theme.typography.fontWeightMedium} !important`,
            })}
            email={columnValue as string}
          />
        );

      case 'latestProfile':
        return (
          <LatestProfileText
            migrated={migrated}
            latestProfile={latestProfile}
            latestEvaluation={latestEvaluation}
            onClick={() => {
              onDownloadProfile(
                props.learnerId,
                latestProfile?.id ?? '',
                latestProfile?.profileType !== 'IDTL_PROFILE' ||
                  isIDTLSupported(props.preferredDialect)
                  ? props.preferredDialect
                  : 'en-GB',
              );
            }}
          />
        );
      case 'organisation':
        return (
          <Link
            component={RouterLink}
            to={`/organisations/${latestEvaluation?.organisation.id}`}
          >
            <Box display="flex">
              <P variant="body-bold" color="primary">
                {latestEvaluation?.organisation.name}
              </P>
            </Box>
          </Link>
        );

      default:
        return columnValue;
    }
  };

  const multipleOrgColumnPredicate = (
    columnName: keyof (TeamLearnerResponse & {
      learnerId: string;
      colourScores: string;
      organisation: string;
    }),
    columnValue: string | number,
    props: Record<any, any>,
  ) => {
    const { fullName, learnerId, latestProfile, latestEvaluation } =
      props as TeamLearnerResponse & {
        learnerId: string;
      };

    switch (columnName) {
      case 'fullName':
        return (
          <StyledDiv data-testid="full-name-colour-energy">
            <Link
              data-testid="learner-detail-link"
              component={RouterLink}
              to={`/learners/${latestEvaluation.organisation.id}/${learnerId}`}
            >
              <Box display="flex">
                <P variant="body-bold" color="primary">
                  {fullName}
                </P>
              </Box>
            </Link>
            <ColourEnergyIndicator
              {...latestProfile?.colourScores?.conscious}
              data-testid="colour-energies"
            />
          </StyledDiv>
        );
      case 'primaryEmail':
        return (
          <LinkEmail
            sx={(theme) => ({
              fontWeight: `${theme.typography.fontWeightMedium} !important`,
            })}
            email={columnValue as string}
          />
        );

      case 'latestProfile':
        return (
          <LatestProfileText
            latestProfile={latestProfile}
            latestEvaluation={latestEvaluation}
            migrated={migrated}
            onClick={() => {
              onDownloadProfile(
                props.learnerId,
                latestProfile?.id ?? '',
                latestProfile?.profileType !== 'IDTL_PROFILE' ||
                  isIDTLSupported(props.preferredDialect)
                  ? props.preferredDialect
                  : 'en-GB',
              );
            }}
          />
        );
      case 'organisation':
        return (
          <Link
            component={RouterLink}
            to={`/organisations/${latestEvaluation.organisation.id}`}
          >
            <Box display="flex">
              <P variant="body-bold" color="primary">
                {latestEvaluation?.organisation.name}
              </P>
            </Box>
          </Link>
        );
      default:
        return columnValue;
    }
  };

  const columnPredicate =
    type === 'SINGLE' ? singleOrgColumnPredicate : multipleOrgColumnPredicate;

  const handleSetCheckedItems = (checkedItemsMap: Record<string, boolean>) => {
    const selectedLearners = Object.entries(checkedItemsMap).reduce(
      (prev, [itemId]) => {
        const { ...learner } = learners.find(({ id }) => id === itemId) ?? {};

        return {
          ...prev,
          [itemId]: learner,
        };
      },
      {},
    );
    if (selectedLearners && setCheckedItems) setCheckedItems(selectedLearners);
  };

  const dataTableActions = migrated
    ? []
    : [
        <RemoveTeamMember
          id=""
          key="remove-button"
          onClick={handleRemoveTeamMember}
        />,
      ];

  const notSelectableStyles = {
    'tr > th:first-of-type': {
      verticalAlign: 'top',
    },

    'tr > td:first-of-type': {
      verticalAlign: 'top',
    },
  };
  const selectableStyles = {
    'tr > th:first-of-type': {
      verticalAlign: 'top',
      paddingRight: '0',
      paddingLeft: '0',
      minWidth: '0',
      maxWidth: '42px',
      width: '42px',
    },
    'tr > th:nth-of-type(2)': {
      paddingLeft: '0',
    },
    'tr > td:first-of-type': {
      verticalAlign: 'top',
      paddingRight: '0',
      paddingLeft: '0',
    },
    'tr > td:nth-of-type(2)': {
      paddingLeft: '0',
    },
  };
  return (
    <>
      <StyledTable
        sx={selectable ? selectableStyles : notSelectableStyles}
        items={learners}
        columnPredicate={(column, columnValue, props) =>
          columnPredicate(
            column as
              | 'learnerId'
              | keyof TeamLearnerResponse
              | 'colourScores'
              | 'organisation',
            columnValue,
            props,
          )
        }
        checkedItems={booleanizeCheckedItems(checkedItems ?? {})}
        onCheckedItems={handleSetCheckedItems}
        dataStructure={dataStructure}
        selectable={selectable}
        actions={dataTableActions}
        onPageChange={onPageChange}
      />
      <DownloadProgressDialog open={showDownloadProfile} />
    </>
  );
};

const LearnersList = ({
  learners,
  teamId,
  onDelete,
  checkedItems,
  setCheckedItems,
  evaluationIds,
  migrated,
  type = 'SINGLE',
  selectable,
  onPageChange,
}: LearnersListProps) => {
  const { pagedItems: pagedLearners } = usePagination(learners, ITEMS_PER_PAGE);
  if (pagedLearners.length > 0) {
    return (
      <TeamLearners
        evaluationIds={evaluationIds}
        learners={learners}
        teamId={teamId}
        onDelete={onDelete}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
        migrated={migrated}
        selectable={selectable}
        type={type}
        onPageChange={onPageChange}
      />
    );
  }

  return (
    <StyledListItemWrapper>
      <LearnersListEmptyMsg />
    </StyledListItemWrapper>
  );
};

export default LearnersList;
