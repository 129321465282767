import React, { useCallback, useEffect, useState } from 'react';
import BulkUploadTeam from 'components/BulkUploadLearners/BulkUploadLearners';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import {
  OrganisationResponse,
  TeamVisibility,
  useAddMultiOrgTeam,
  useAddTeam,
} from 'api';
import useLearnerOrgSearch from 'components/hooks/useLearnerOrgSearch';
import {
  P,
  RadioGroupControl,
  SvgLock2Bold,
  SvgMultipleUsers2Bold,
} from '@insights-ltd/design-library/components';
import CircularProgress from '@mui/material/CircularProgress';
import { useQueryClient } from '@tanstack/react-query';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FormHeader from 'components/FormHeader';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { User } from 'types/types';
import {
  getMuiTheme,
  spacingSizeMap,
} from '@insights-ltd/design-library/themes';
import { TeamVariant, useGetViewPermissionOptions } from './CreateTeam.helper';
import { TeamFormData } from './types';
import {
  OrgsOptionsSelector,
  StyledIcon,
  OrgSelector,
} from './CreateMultiOrgTeam';

type Props = {
  isPermitted: boolean;
  organisations: OrganisationResponse[];
  organisationsInGroups?: GroupOrganisationHashMap;
  myOrganisation: OrganisationResponse;
  showOrganisationDropdown: boolean;
};

const StyledP = styled(P)({
  fontWeight: '600 !important',
  lineHeight: '28px !important',
  marginTop: '0 !important',
  marginBottom: '8px !important',
});

const ARBITRARY_MAX_LENGTH = 100;

const StyledForm = styled('form')(({ theme }) => ({
  '> div:nth-of-type(3) > fieldset > div > div > div': {
    paddingTop: `${theme.spacing(spacingSizeMap.XS)} !important`,
  },
  '> div:nth-of-type(3) > fieldset > div > div > div:nth-of-type(2)': {
    paddingLeft: `${theme.spacing(spacingSizeMap.XS)} !important`,
  },
}));

const CreateTeamForm = ({
  isPermitted,
  organisations,
  myOrganisation,
  organisationsInGroups,
  showOrganisationDropdown,
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    trigger,
    reset,
    watch,
  } = useForm<TeamFormData>({
    defaultValues: { visibility: 'PRIVATE', selectedOrgs: [] },
  });
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [learnerList, setLearnerList] = useState<User[]>([]);
  const [teamType, setTeamType] = useState<TeamVariant>('single-org');
  const [selectedVisibility, setSelectedVisibility] =
    useState<TeamVisibility>('PRIVATE');
  const localTheme = getMuiTheme();
  const [organisationId, selectedOrgs = []] = watch([
    'organisationId',
    'selectedOrgs',
  ]);
  const { mutate: addTeam, isPending: isAdding } = useAddTeam(queryClient);
  const { mutate: addMultiOrgTeam, isPending: isAddingMultiOrg } =
    useAddMultiOrgTeam(queryClient);
  const { setSearchOrganisation, clearSearch } = useLearnerOrgSearch(undefined);

  register('learners', { required: false });
  register('organisationId', { required: false });

  const getIsMultiOrg = () => {
    return !!(teamType === 'multi-org');
  };
  const setOrganisationId = useCallback(
    (value: string) => {
      setValue('organisationId', value);
    },
    [setValue],
  );
  const setSelectedOrgs = (value: any) => {
    if (!selectedOrgs.find((org: any) => org?.id === value?.id)) {
      const orgs = [...selectedOrgs, value];
      setValue('selectedOrgs', orgs);
    }
  };
  const removeOrgs = (orgToRemove: any) => {
    const filteredOrgs = selectedOrgs?.filter(
      (org: any) => org?.id !== orgToRemove,
    );
    setValue('selectedOrgs', filteredOrgs);
  };

  const onSubmit = (formData: TeamFormData) => {
    let formDataChecked = { ...formData };
    if (
      !Object.getOwnPropertyDescriptor(formDataChecked, 'learners') ||
      formDataChecked.learners === undefined
    ) {
      formDataChecked = {
        ...formDataChecked,
        learners: [],
        visibility: selectedVisibility,
      };
    }
    formDataChecked = {
      ...formDataChecked,
      visibility: selectedVisibility,
    };
    if (!getIsMultiOrg()) {
      addTeam(
        {
          organisationId: organisationId!,
          formData: formDataChecked,
        },
        {
          onSuccess: (newTeam) => {
            navigate(`/teams/${newTeam.id}/created`);
          },
        },
      );
    } else {
      const body = {
        organisations:
          formDataChecked.selectedOrgs?.map((org: any) => ({
            id: org?.id,
          })) ?? [],
        name: formDataChecked.name,
      };
      addMultiOrgTeam(body, {
        onSuccess: (newTeam) => {
          navigate(`/teams/${newTeam?.id}/created`);
        },
      });
    }
  };
  const onLearnersChange = (data: User[]) => {
    const learnersData = data.map(({ id }) => ({ id }));
    setValue('learners', learnersData as User[]);
    trigger('learners').then();
    setLearnerList(data);
    clearSearch();
  };
  const onBulkAddLearners = (data: User[]) => {
    const deDupedLearners = data.filter(
      (row) => !learnerList.find((item) => item.id === row.id),
    );
    onLearnersChange([...learnerList, ...deDupedLearners]);
  };
  const handleOrganisationChange = (value: string) => {
    setOrganisationId(value);
    setSearchOrganisation(value);
    trigger('organisationId').then();

    setValue('learners', []);
    trigger('learners').then();
    setLearnerList([]);
    clearSearch();
  };
  const handleVisibilityChange = (value: string) => {
    setSelectedVisibility(value as TeamVisibility);
  };

  const teamVisibiltyOptions = useGetViewPermissionOptions(getIsMultiOrg());

  useEffect(() => {
    if (!isPermitted && myOrganisation?.id) {
      setOrganisationId(myOrganisation.id);
      setSearchOrganisation(myOrganisation.id);
      setValue('organisationId', myOrganisation.id);
      trigger('organisationId').then();
    }
  }, [
    setValue,
    myOrganisation,
    isPermitted,
    trigger,
    setSearchOrganisation,
    setOrganisationId,
  ]);

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/* Organisation */}
      <OrgsOptionsSelector
        setTeamType={setTeamType}
        teamType={teamType}
        reset={reset}
      />
      <OrgSelector
        handleOrganisationChange={handleOrganisationChange}
        setSelectedOrgs={setSelectedOrgs}
        removeOrgs={removeOrgs}
        showOrganisationDropdown={showOrganisationDropdown}
        organisationsInGroups={organisationsInGroups}
        selectedOrgs={selectedOrgs}
        organisations={organisations}
        isMultiOrg={getIsMultiOrg()}
        errors={errors}
        organisationId={organisationId}
      />

      {selectedOrgs.length || organisationId ? (
        <>
          {/* Team name */}
          <div>
            <FormHeader
              icon={StyledIcon(
                SvgMultipleUsers2Bold,
                localTheme.palette.blue.dark,
              )}
              title={t('ui.event-management.teams.team-info')}
              color="blue"
              showMargin={false}
            />
            <TextField
              id="name"
              data-testid="team-name-field"
              label={t('ui.event-management.teams.team-name')}
              placeholder={t('ui.event-management.teams.team-name-placeholder')}
              required
              variant="outlined"
              fullWidth
              type="text"
              sx={{ marginTop: '0 !important' }}
              inputProps={{ maxLength: ARBITRARY_MAX_LENGTH }}
              error={Boolean(errors.name)}
              helperText={
                errors.name &&
                t(
                  'ui.event-management.events.create.error.team-name-value-required',
                )
              }
              {...register('name', { required: true })}
            />
          </div>

          {/* Visibility permissions */}
          <div>
            <FormHeader
              icon={StyledIcon(SvgLock2Bold, localTheme.palette.pink.dark)}
              title={t('ui.event-management.teams.view-permissions')}
              color="pink"
              showMargin={false}
            />
            <StyledP variant="body-bold">
              {t('ui.event-management.teams.view-permissions.view-help-text')}
            </StyledP>
            <RadioGroupControl
              options={teamVisibiltyOptions}
              name="visibility"
              title={t('ui.event-management.users.create.permission-roles')}
              value={selectedVisibility}
              onChange={handleVisibilityChange}
              error={Boolean(errors.visibility)}
              variant="boxed"
            />
          </div>

          {/* Learners */}
          {(selectedOrgs.length || organisationId) &&
            teamType === 'single-org' && (
              <div>
                <BulkUploadTeam
                  organisationId={organisationId}
                  onBulkAddLearners={onBulkAddLearners}
                />
              </div>
            )}

          <Grid container justifyContent="flex-end">
            <Button
              sx={(theme) => ({ marginTop: theme.spacing(spacingSizeMap.L) })}
              data-testid="create-team"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isAdding || isAddingMultiOrg}
              endIcon={
                (isAdding || isAddingMultiOrg) && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )
              }
            >
              {t('ui.event-management.teams.create-team')}
            </Button>
          </Grid>
        </>
      ) : null}
    </StyledForm>
  );
};

export default CreateTeamForm;
