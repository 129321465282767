import {
  ChapterStatus,
  EventResponse,
  EventResponseWithChapters,
  InviteeResponse,
  ProfileStatus,
} from 'api';
import { DateTime } from 'luxon';
import { Contributor, InviteeListOptions } from 'types/types';

export const mockYear = 1970;
export const mockMonth = 1;
export const mockDay = 1;
export const locale = 'en-GB';

export const mockCurrentDate: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay);
  return dateTime?.toISO() ?? '';
})();

export const mockStartsAt: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay)
    .plus({ days: 14 })
    .set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  return dateTime?.toISO() ?? '';
})();

export const mockEndsAt: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay)
    .plus({ days: 14 })
    .set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
  return dateTime?.toISO() ?? '';
})();

export const mockDeadlineAt: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay)
    .plus({ days: 7 })
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  return dateTime?.toISO() ?? '';
})();

export const mockReminderAt: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay)
    .plus({ days: 5 })
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  return dateTime?.toISO() ?? '';
})();

export const mockCancelledAt: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay)
    .plus({ days: 14 })
    .set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  return dateTime?.toISO() ?? '';
})();

export const mockCurrentDateAfterDeadline: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay).plus({ days: 9 });
  return dateTime?.toISO() ?? '';
})();

export const mockCurrentDateAfterExperience: string = (() => {
  const dateTime = DateTime.utc(mockYear, mockMonth, mockDay).plus({
    days: 21,
  });
  return dateTime?.toISO() ?? '';
})();

export const someEvent: EventResponse = {
  id: 'some-id',
  name: 'some name',
  dialect: 'en-GB',
  timezone: 'GMT',
  startsAt: mockStartsAt,
  endsAt: mockEndsAt,
  deadlineAt: mockDeadlineAt,
  notificationFrequency: 'NEVER',
  eventStatus: 'ACTIVE',
  eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
  productType: 'DISCOVERY_PERSONAL_PROFILE',
  organisation: {
    id: 'insights',
    name: 'Insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  location: {
    type: 'REMOTE',
    meetingLink: 'https://someurl.com',
    details: 'some details',
  },
  practitioners: [
    {
      id: 'an-id-1',
      fullName: 'Mister Practitioner',
      emailAddress: 'email@practitioner.com',
      roles: ['STANDARD', 'SUPER_ADMIN'],
      leadingColourEnergy: 'CoolBlue',
      jobTitle: 'ADMIN',
      enabled: true,
      licensedProducts: [
        'DISCOVERY_SELF_AWARE_PROFILE',
        'DISCOVERY_PERSONAL_PROFILE',
        'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
        'DISCOVERY_FULL_CIRCLE_PROFILE',
      ],
    },
  ],
};

export const someEventWithChapters: EventResponseWithChapters = {
  id: 'some-id',
  name: 'some name',
  dialect: 'en-GB',
  timezone: 'GMT',
  startsAt: mockStartsAt,
  endsAt: mockEndsAt,
  deadlineAt: mockDeadlineAt,
  notificationFrequency: 'NEVER',
  eventStatus: 'ACTIVE',
  eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
  productType: 'DISCOVERY_PERSONAL_PROFILE',
  organisation: {
    id: 'insights',
    name: 'Insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  location: {
    type: 'REMOTE',
    meetingLink: 'https://someurl.com',
    details: 'some details',
  },
  practitioners: [
    {
      id: 'an-id-1',
      fullName: 'Mister Practitioner',
      emailAddress: 'email@practitioner.com',
      roles: ['STANDARD', 'SUPER_ADMIN'],
      leadingColourEnergy: 'CoolBlue',
      jobTitle: 'ADMIN',
      enabled: true,
      licensedProducts: [
        'DISCOVERY_SELF_AWARE_PROFILE',
        'DISCOVERY_PERSONAL_PROFILE',
        'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
        'DISCOVERY_FULL_CIRCLE_PROFILE',
      ],
    },
  ],
  chapters: [
    {
      name: 'FOUNDATION',
      cost: 100,
    },
    {
      name: 'EFFECTIVE_SELLING',
      cost: 200,
    },
    {
      name: 'MANAGEMENT',
      cost: 150,
    },
    {
      name: 'PERSONAL_ACHIEVEMENT',
      cost: 150,
    },
    {
      name: 'INTERVIEW',
      cost: 150,
    },
    {
      name: 'EXPLORE',
      cost: 10,
    },
  ],
};

export const someExploreEventWithBalance: EventResponseWithChapters = {
  id: 'explore-id',
  name: 'some name',
  dialect: 'en-GB',
  startsAt: '2030-09-02T08:00:00Z',
  endsAt: '2030-09-02T09:00:00Z',
  deadlineAt: '2030-09-01T22:59:59.999Z',
  eventType: 'INSIGHTS_EXPLORE',
  productType: 'DISCOVERY_EXPLORE_PROFILE',
  timezone: 'GMT',
  eventStatus: 'ACTIVE',
  location: {
    type: 'REMOTE',
    meetingLink: '',
    details: '',
  },
  notificationFrequency: 'NEVER',
  practitioners: [
    {
      id: 'an-id-1',
      fullName: 'Mister Practitioner',
      emailAddress: 'email@practitioner.com',
      roles: ['STANDARD', 'SUPER_ADMIN'],
      leadingColourEnergy: 'CoolBlue',
      jobTitle: 'ADMIN',
      enabled: true,
      licensedProducts: [
        'DISCOVERY_SELF_AWARE_PROFILE',
        'DISCOVERY_PERSONAL_PROFILE',
        'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
        'DISCOVERY_FULL_CIRCLE_PROFILE',
        'DISCOVERY_EXPLORE_PROFILE',
      ],
    },
  ],
  organisation: {
    id: 'insights',
    name: 'insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  customEmailMessage: '',
  wallet: {
    availableUnits: 1,
    id: 'wallet-id',
  },
  budget: {
    total: 1,
    potentialSpend: 0,
    spent: 0,
  },
  chapters: [
    {
      name: 'EXPLORE',
      cost: 10,
    },
  ],
};

export const someExploreEventWithBalanceWithMultiplePractitioners: EventResponseWithChapters =
  {
    id: 'explore-id',
    name: 'some name',
    dialect: 'en-GB',
    startsAt: '2030-09-02T08:00:00Z',
    endsAt: '2030-09-02T09:00:00Z',
    deadlineAt: '2030-09-01T22:59:59.999Z',
    eventType: 'INSIGHTS_EXPLORE',
    productType: 'DISCOVERY_EXPLORE_PROFILE',
    timezone: 'GMT',
    eventStatus: 'ACTIVE',
    location: {
      type: 'REMOTE',
      meetingLink: '',
      details: '',
    },
    notificationFrequency: 'NEVER',
    practitioners: [
      {
        id: 'an-id-1',
        fullName: 'Mister Practitioner Number One',
        emailAddress: 'email@practitioner.com',
        roles: ['STANDARD', 'SUPER_ADMIN'],
        leadingColourEnergy: 'CoolBlue',
        jobTitle: 'ADMIN',
        enabled: true,
        licensedProducts: [
          'DISCOVERY_SELF_AWARE_PROFILE',
          'DISCOVERY_PERSONAL_PROFILE',
          'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
          'DISCOVERY_FULL_CIRCLE_PROFILE',
          'DISCOVERY_EXPLORE_PROFILE',
        ],
      },
      {
        id: 'an-id-2',
        fullName: 'Mister Practitioner Number Two',
        emailAddress: 'second@practitioner.com',
        roles: ['STANDARD', 'SUPER_ADMIN'],
        leadingColourEnergy: 'CoolBlue',
        jobTitle: 'ADMIN',
        enabled: true,
        licensedProducts: [
          'DISCOVERY_SELF_AWARE_PROFILE',
          'DISCOVERY_PERSONAL_PROFILE',
          'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
          'DISCOVERY_FULL_CIRCLE_PROFILE',
          'DISCOVERY_EXPLORE_PROFILE',
        ],
      },
    ],
    organisation: {
      id: 'insights',
      name: 'insights',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
    customEmailMessage: '',
    wallet: {
      availableUnits: 1,
      id: 'wallet-id',
    },
    budget: {
      total: 1,
      potentialSpend: 0,
      spent: 0,
    },
    chapters: [
      {
        name: 'EXPLORE',
        cost: 10,
      },
    ],
  };

export const someExploreEventNoBalance: EventResponseWithChapters = {
  id: 'explore-id',
  name: 'some name',
  dialect: 'en-GB',
  startsAt: '2030-09-02T08:00:00Z',
  endsAt: '2030-09-02T09:00:00Z',
  deadlineAt: '2030-09-01T22:59:59.999Z',
  eventType: 'INSIGHTS_EXPLORE',
  productType: 'DISCOVERY_EXPLORE_PROFILE',
  timezone: 'GMT',
  eventStatus: 'ACTIVE',
  location: {
    type: 'REMOTE',
    meetingLink: '',
    details: '',
  },
  notificationFrequency: 'NEVER',
  practitioners: [
    {
      id: 'an-id-1',
      fullName: 'Mister Practitioner',
      emailAddress: 'email@practitioner.com',
      roles: ['STANDARD', 'SUPER_ADMIN'],
      leadingColourEnergy: 'CoolBlue',
      jobTitle: 'ADMIN',
      enabled: true,
      licensedProducts: [
        'DISCOVERY_SELF_AWARE_PROFILE',
        'DISCOVERY_PERSONAL_PROFILE',
        'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
        'DISCOVERY_FULL_CIRCLE_PROFILE',
        'DISCOVERY_EXPLORE_PROFILE',
      ],
    },
  ],
  organisation: {
    id: 'insights',
    name: 'insights',
    dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
  },
  customEmailMessage: '',
  wallet: {
    availableUnits: 0,
    id: 'wallet-id',
  },
  budget: {
    total: 0,
    potentialSpend: 0,
    spent: 0,
  },
  chapters: [
    {
      name: 'EXPLORE',
      cost: 10,
    },
  ],
};

export const someLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    {
      id: 'some-learner-id',
      fullName: 'some name added',
      email: 'email@example.com',
      status: 'ADDED',
      profileStatus: 'NONE',
      chapters: [],
      profileReusePreference: 'NONE',
      dialect: 'en-GB',
      pronoun: 'HE',
      profile: {
        id: 'some-profile-id',
      },
    },
    {
      id: 'some-other-learner-id',
      fullName: 'some name invited',
      email: 'email@example.com',
      status: 'INVITE_SENT',
      profileStatus: 'NONE',
      chapters: [],
      profileReusePreference: 'NONE',
      dialect: 'en-GB',
      pronoun: 'HE',
      profile: {
        id: 'some-profile-id',
      },
    },
  ],
};

export const noInvitedLearners = {
  invitees: [],
};

export const uninvitedLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...someLearners.invitees.map((invitee) => ({
      ...invitee,
      status: 'ADDED' as InviteeListOptions,
    })),
  ],
};

export const inviteInitiatedLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...someLearners.invitees.map((invitee) => ({
      ...invitee,
      status: 'INVITE_REQUESTED' as InviteeListOptions,
    })),
  ],
};

export const foundationLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...someLearners.invitees.map((invitee) => ({
      ...invitee,
      chapters: [{ name: 'FOUNDATION', status: 'PENDING' as ChapterStatus }],
    })),
  ],
};

export const invitedLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...uninvitedLearners.invitees.map((invitee) => ({
      ...invitee,
      status: 'INVITE_SENT' as InviteeListOptions,
    })),
  ],
};

export const evaluatedLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...uninvitedLearners.invitees.map((invitee) => ({
      ...invitee,
      status: 'READY_TO_PURCHASE' as InviteeListOptions,
    })),
  ],
};

export const someEvaluatedLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...evaluatedLearners.invitees,
    {
      id: 'some-extra-learner-id',
      fullName: 'some name invited',
      email: 'email@example.com',
      status: 'INVITE_SENT',
      profileStatus: 'NONE',
      chapters: [],
      profileReusePreference: 'NONE',
      dialect: 'en-GB',
      pronoun: 'HE',
      profile: {
        id: 'some-profile-id',
      },
    },
  ],
};

export const purchasedLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...someLearners.invitees.map((invitee) => ({
      ...invitee,
      chapters: [{ name: 'FOUNDATION', status: 'PURCHASED' as ChapterStatus }],
      profileStatus: 'PURCHASED' as ProfileStatus,
    })),
  ],
};

export const reusedLearners: { invitees: InviteeResponse[] } = {
  invitees: [
    ...someLearners.invitees.map((invitee) => ({
      ...invitee,
      chapters: [{ name: 'FOUNDATION', status: 'PURCHASED' as ChapterStatus }],
      profileStatus: 'REUSED' as ProfileStatus,
    })),
  ],
};

export const dfcContributor: Contributor = {
  id: 'contributor-1',
  fullName: 'Homer Simpson Contributor',
  status: 'ADDED',
  email: 'homer.simpson@insights.com',
  discoveryProfile: {
    createdAt: 'some date',
  },
};

export const evaluatorCompleteLearner: { invitees: InviteeResponse[] } = {
  invitees: [
    {
      id: 'some-learner-id',
      fullName: 'some name added',
      email: 'email@example.com',
      status: 'READY_TO_PURCHASE',
      profileStatus: 'PURCHASED',
      chapters: [],
      profileReusePreference: 'NONE',
      dialect: 'en-GB',
      pronoun: 'HE',
      profile: {
        id: 'some-profile-id',
      },
    },
  ],
};
