import React, { ChangeEvent, useRef } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MuiSelect from '@mui/material/Select';
import { Span } from '@insights-ltd/design-library/components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type SelectProps<T extends FieldValues> = {
  id: string;
  name: Path<T>;
  label: string;
  control: Control<T>;
  error: boolean;
  errorMessage: string;
  rules?: any;
  onChange?: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
  labelOptionalText?: string;
  children: React.ReactNode[];
  sx?: any;
};

type InputLabelProps = {
  required: boolean;
  label: string;
  labelOptionalText: string;
};

const InputLabelContent = ({
  required,
  label,
  labelOptionalText,
}: InputLabelProps) => {
  return (
    <>
      {label}{' '}
      {!required && <Span color="textSecondary">{labelOptionalText}</Span>}
    </>
  );
};

export const Select = <T extends FieldValues>({
  id,
  name,
  label,
  control,
  error,
  errorMessage,
  children,
  rules = {},
  onChange = () => {},
  disabled,
  required = true,
  labelOptionalText = '',
  sx,
}: SelectProps<T>) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  return (
    <FormControl
      variant="outlined"
      error={error}
      required={required}
      fullWidth
      sx={sx}
    >
      <InputLabel id={`${id}-label`}>
        <InputLabelContent
          label={label}
          required={required}
          labelOptionalText={labelOptionalText}
        />
      </InputLabel>
      <Controller
        render={({
          field: { onChange: onReactHookFormChange, onBlur, value },
        }) => (
          <MuiSelect
            onChange={(e) => {
              onReactHookFormChange(e as ChangeEvent);
              onChange(String(e.target.value));
            }}
            onBlur={(e) => {
              onBlur();
              onChange(String(e.target.value));
            }}
            label={
              <InputLabelContent
                label={label}
                required={required}
                labelOptionalText={labelOptionalText}
              />
            }
            value={value}
            name={name}
            id={name}
            labelId={`${id}-label`}
            error={error}
            inputProps={{ ref: inputRef }}
            SelectDisplayProps={{
              'aria-describedby': error ? `{id}-error-label` : '',
              'aria-labelledby': `${id}-label`,
            }}
            disabled={disabled}
            displayEmpty={!required}
            IconComponent={KeyboardArrowDownIcon}
          >
            {children}
          </MuiSelect>
        )}
        name={name}
        rules={rules}
        control={control}
      />
      {error && (
        <FormHelperText id={`${id}-error-label`}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};
