import { LicensedProductOption } from 'types/types';
import { LICENSED_PRODUCTS } from 'variables';

const {
  DISCOVERY_SELF_AWARE_PROFILE,
  DISCOVERY_PERSONAL_PROFILE,
  DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE,
  DISCOVERY_FULL_CIRCLE_PROFILE,
  DISCOVERY_EXPLORE_PROFILE,
} = LICENSED_PRODUCTS;

const useGetProducts = (): Partial<LicensedProductOption> => {
  const products = {
    DISCOVERY_SELF_AWARE_PROFILE,
    DISCOVERY_PERSONAL_PROFILE,
    DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE,
    DISCOVERY_FULL_CIRCLE_PROFILE,
    DISCOVERY_EXPLORE_PROFILE,
  } as Partial<typeof LICENSED_PRODUCTS>;

  return products;
};

export default useGetProducts;
