import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { H2, P } from '@insights-ltd/design-library/components';
import PermissionGuard from 'components/PermissionGuard';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { useQueryClient } from '@tanstack/react-query';
import SearchCount from 'components/SearchCount/SearchCount';
import { useDeleteUserInvite, useReinviteUser, UserInvite } from 'api';
import { isoToLocaleString } from 'utils/dates';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';

type OrganisationInvitesProps = {
  invites: UserInvite[];
  title: string;
  organisationId: string;
};

type InviteRowProps = {
  invite: UserInvite;
  organisationId: string;
};

const StyledDiv = styled('div')(({ theme }) => ({
  '> :first-of-type': {
    marginBottom: theme.spacing(spacingSizeMap.M),
  },
  '& > * + *': {
    marginBottom: theme.spacing(spacingSizeMap.XS),
  },
}));

const StyledP = styled(P)(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.L),
  textAlign: 'center',
}));

const InviteRow = ({ invite, organisationId }: InviteRowProps) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const queryClient = useQueryClient();
  const {
    mutate: deleteInvite,
    isError: deleteInviteError,
    reset: resetDeleteInvite,
  } = useDeleteUserInvite(organisationId, queryClient);
  const {
    mutate: reinvite,
    isError: reinviteUserError,
    reset: resetReinviteUser,
  } = useReinviteUser(organisationId, queryClient);
  const isError = deleteInviteError || reinviteUserError;
  const reset = () => {
    resetReinviteUser();
    resetDeleteInvite();
  };
  const navigate = useNavigate();

  const resendInvite = async () => {
    const data = {
      dialect: 'en-GB',
      emailAddress: invite.emailAddress,
    };
    try {
      await reinvite(data);
      navigate(`/organisations/${organisationId}`, {
        replace: true,
        state: {
          showInvitedDialog: true,
          showInviteDeletedDialog: false,
        },
      });
    } catch {
      /* No need to deal with error here, handled via useInviteUser hook */
    }
  };
  const deleteUserInvite = () => {
    const data = { inviteeEmailAddress: invite.emailAddress };
    deleteInvite(data, {
      onSuccess: () =>
        navigate(`/organisations/${organisationId}`, {
          replace: true,
          state: {
            showInvitedDialog: false,
            showInviteDeletedDialog: true,
          },
        }),
    });
  };
  return (
    <Paper variant="outlined">
      <Grid
        container
        alignItems="center"
        sx={(theme) => ({
          padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
            spacingSizeMap.M,
          )}`,
          wordBreak: 'break-word',
        })}
      >
        <Grid item xs={6}>
          <P variant="body-bold" color="primary">
            {invite.emailAddress}
          </P>
        </Grid>
        <Grid container item xs={1}>
          <Button onClick={resendInvite} color="primary">
            {t('ui.event-management.invite-list.resend-invite')}
          </Button>
        </Grid>
        <Grid container item xs={3} justifyContent="flex-end">
          <P color="textSecondary">
            {t('ui.event-management.invite-list.invite-sent-at', {
              sentAt: isoToLocaleString(invite.sentAt, locale),
            })}
          </P>
        </Grid>
        <Grid container item xs={2} justifyContent="center">
          <Button onClick={deleteUserInvite} color="error">
            {t('ui.event-management.invite-list.delete-invite')}
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={isError} autoHideDuration={6000} onClose={reset}>
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          {t(
            reinviteUserError
              ? 'ui.event-management.invite-list.invite-practitioner-error'
              : 'ui.event-management.invite-list.delete-invite-practitioner-error',
          )}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

const OrganisationInvites = ({
  invites,
  title,
  organisationId,
}: OrganisationInvitesProps) => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile();
  const navigate = useNavigate();

  return (
    <StyledDiv>
      <Grid container alignItems="baseline" justifyContent="space-between">
        <H2 variant="h3">
          {title}
          <SearchCount sx={{ marginLeft: '0.5rem' }} count={invites.length} />
        </H2>
        <PermissionGuard
          requiredPermissions={[
            {
              action: 'Organisation_InvitePractitioner',
              scope: 'Global',
            },
          ]}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth={!aboveMobile}
            onClick={() =>
              navigate('/organisations/invite-user', {
                state: { organisationId },
              })
            }
          >
            {t('ui.event-management.organisation.user-invite')}
          </Button>
        </PermissionGuard>
      </Grid>
      {invites.length ? (
        <>
          <Paper
            elevation={0}
            sx={(theme) => ({
              padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
                spacingSizeMap.M,
              )}`,
            })}
          >
            <Grid container>
              <Grid item xs={4}>
                <P variant="body-bold" color="textSecondary">
                  {t('ui.event-management.invite-list.heading.email-title')}
                </P>
              </Grid>
            </Grid>
          </Paper>
          {invites.map((invite) => (
            <InviteRow
              organisationId={organisationId}
              key={invite.emailAddress}
              invite={invite}
            />
          ))}
        </>
      ) : (
        <StyledP variant="h3" color="textSecondary">
          {t('ui.event-management.invite-list.empty')}
        </StyledP>
      )}
    </StyledDiv>
  );
};

export default OrganisationInvites;
