import {
  GroupResponse,
  OrganisationResponse,
  PractitionerData,
} from 'api/httpEntities';
import { queryStatus } from 'utils/queryStatus';
import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { useMemo } from 'react';
import { sortOrgs } from 'utils/mappers/sorting';
import { useGetPractitionerGroups, useGetPractitionerOrganisations } from 'api';
import { QueryStatus } from '@tanstack/react-query';

const useGetPractitionerGroupsAndOrgs = (
  practitioner?: PractitionerData | null,
  config: { enabled?: boolean } = {},
): {
  status: QueryStatus;
  orgCount: number;
  groupCount: number | undefined;
  groups: GroupResponse[] | undefined;
  organisations: OrganisationResponse[];
  orgsAndGroups: (OrganisationResponse | GroupResponse)[];
  organisationInGroups: Record<string, GroupResponse>;
  isPending: boolean;
} => {
  const {
    status: groupStatus,
    data: groups,
    isPending: groupPending,
  } = useGetPractitionerGroups(practitioner?.id ?? '', config);
  const {
    status: organisationStatus,
    data: rawOrganisations,
    isPending: organisationsPending,
  } = useGetPractitionerOrganisations(practitioner?.id ?? '', config);

  const status = queryStatus(organisationStatus, groupStatus);

  const organisationInGroups = useMemo(
    () => createGroupOrganisationHashMap(groups ?? []),
    [groups],
  );

  const filteredOrgs =
    rawOrganisations?.filter(({ id }) => !organisationInGroups[id]) ?? [];

  const groupOrganisations =
    groups?.flatMap(({ organisations }) => organisations) ?? [];

  const organisations = Array.from(
    new Set([...filteredOrgs, ...groupOrganisations]),
  );

  const orgsAndGroups = [...(groups ?? []), ...filteredOrgs].sort(sortOrgs);

  const orgCount = organisations.length ?? 0;
  const groupCount = groups?.length;

  return {
    status,
    orgCount,
    groupCount,
    groups,
    organisations,
    orgsAndGroups,
    organisationInGroups,
    isPending: organisationsPending || groupPending,
  };
};

export default useGetPractitionerGroupsAndOrgs;
