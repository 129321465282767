import { RequestErrorBody } from 'api';

export const errorResponse = ({
  ts = '2022-02-24T15:27:30.954671981Z',
  status = 400,
  message = 'Some error',
  errors = [],
}: Partial<RequestErrorBody> = {}): RequestErrorBody => ({
  ts,
  status,
  message,
  errors,
});
