import React from 'react';
import { useTranslation } from 'react-i18next';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { ActionBar } from '@insights-ltd/design-library/components';

export type Props = {
  count: number;
  onShare: () => void;
  onDownload: () => void;
};

const DownloadSelectionBar = ({ count, onShare, onDownload }: Props) => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile();

  return (
    <ActionBar
      barText={t(
        'ui.event-management.events.download-profiles.learners-selected',
        { count },
      )}
      isAboveMobile={aboveMobile}
      primaryButtonObj={{
        primaryButtonText: t(
          'ui.event-management.events.download-profiles.share-profiles',
          { count },
        ),
        primaryButtonOnClick: onShare,
        primaryButtonDisabled: count === 0,
      }}
      secondaryButtonObj={{
        secondaryButtonText: t(
          'ui.event-management.events.download-profiles.download-profiles',
          { count },
        ),
        secondaryButtonOnClick: onDownload,
        secondaryButtonDisabled: count === 0,
      }}
    />
  );
};

export default DownloadSelectionBar;
