import { TextV2 } from '@insights-ltd/design-library/components';
import React from 'react';
import { Trans } from 'react-i18next';
import { isoToDuration } from 'utils/dates';

const DataRetentionPolicy = ({
  dataRetentionPolicy,
}: {
  dataRetentionPolicy?: { maximumInactivityPeriod?: string };
}) => {
  if (!dataRetentionPolicy?.maximumInactivityPeriod) {
    return null;
  }

  const { years } = isoToDuration(dataRetentionPolicy.maximumInactivityPeriod);

  return (
    <div>
      <Trans
        i18nKey="ui.event-management.organisation.data-retention-period"
        components={{
          light: (
            <TextV2 color="textSecondary" variant="bodyBold" component="span" />
          ),
        }}
        values={{ years }}
      />
    </div>
  );
};

export default DataRetentionPolicy;
