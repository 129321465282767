import { Group, GroupResponse, OrganisationResponse } from 'api';
import isGroupResponse from './typeGuards/isGroupResponse';

export const allFilteringCategories = [
  'ALL',
  'ORGANISATIONS',
  'GROUPS',
] as const;
export type FilterCategory = (typeof allFilteringCategories)[number];
type GroupOrganisationHashMap = Record<string, Group>;

export const isMatchedToOrgFilter =
  (
    searchTerm: string,
    orgsHashMap: GroupOrganisationHashMap,
    category?: FilterCategory,
    minCharacters = 0,
  ) =>
  (organisation: OrganisationResponse | GroupResponse) => {
    if (isGroupResponse(organisation) && category === 'ORGANISATIONS') {
      return false;
    }

    if (!isGroupResponse(organisation) && category === 'GROUPS') {
      return false;
    }

    if (category === 'ALL') {
      if (
        !isGroupResponse(organisation) &&
        orgsHashMap[organisation.id] &&
        orgsHashMap[organisation.id].name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
    }
    if (searchTerm && searchTerm.length >= minCharacters) {
      return organisation.name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return true;
  };
