import { styled } from '@mui/material';

interface StyledLabelProps {
  optional: boolean;
}

export const StyledLabel = styled('label')<StyledLabelProps>(
  ({ optional, theme }) => ({
    display: 'flex',
    gap: '0.25rem',
    marginBottom: '0.25rem',
    fontWeight: optional ? 100 : 600,
    fontSize: '1rem',
    lineHeight: '1.75rem',
    color: optional
      ? `${theme.palette.text.secondary}`
      : `${theme.palette.text.primary}`,
  }),
);
