import React from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { P, Span } from '@insights-ltd/design-library/components';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import {
  AutocompleteChangeReason,
  styled,
  useAutocomplete,
} from '@mui/material';
import { User } from 'types/types';

type Props = {
  options: User[];
  onSelect: (value: User) => void;
  loading: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  selectedValue?: string;
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  isNarrow?: boolean;
};

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + 8,
    },
  });
};

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// eslint-disable-next-line react/display-name
export const ListBoxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props: React.PropsWithChildren<any>, ref) => {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const itemCount = Array.isArray(children) ? children.length : 0;

  const gridRef = useResetCache(itemCount);
  return (
    <div ref={ref as any}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          ref={gridRef}
          height={380}
          width="100%"
          outerElementType={OuterElementType}
          itemCount={itemCount}
          itemData={itemData}
          itemSize={() => 60}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledP = styled(P)({
  marginTop: '-7px !important',
  width: '298px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const UserAutocomplete = ({
  options,
  onSelect,
  loading,
  disableClearable = false,
  disabled,
  selectedValue,
  label,
  placeholder,
  error = false,
  helperText,
  required = false,
  isNarrow = false,
}: Props) => {
  const { t } = useTranslation();

  const getSelectedItem = () =>
    options?.length && selectedValue
      ? (options.find(({ id }) => id === selectedValue) as User) || ''
      : null;

  interface TextFieldParamsProps extends AutocompleteRenderInputParams {
    InputLabelProps: Omit<
      ReturnType<ReturnType<typeof useAutocomplete>['getInputLabelProps']>,
      'children'
    >;
  }

  return (
    <Autocomplete
      id="user-search"
      data-testid="learners-autocomplete"
      disableClearable={disableClearable}
      autoSelect={disableClearable}
      options={options}
      value={getSelectedItem()}
      fullWidth
      disabled={disabled || undefined}
      loadingText={t(
        'ui.event-management.events.edit.practitioners.loading-text',
      )}
      noOptionsText={t(
        'ui.event-management.events.edit.practitioners.no-options-text',
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, value, reason) => {
        if (
          reason === ('selectOption' as AutocompleteChangeReason) &&
          value !== null
        ) {
          onSelect(value);
        }
      }}
      filterOptions={createFilterOptions({
        stringify: (option: User) =>
          `${option.fullName} ${option.emailAddress}`,
      })}
      getOptionLabel={(option) => option.fullName}
      renderOption={(fullProps, val) => {
        const { color, ...props } = fullProps;
        return (
          <li {...props} key={val.id} data-testid={`user-option-${val.id}`}>
            <Span variant="body-bold" color="primary">
              {val.fullName} {!isNarrow && ` - ${val.emailAddress}`}
            </Span>
            {isNarrow && <StyledP>{val.emailAddress}</StyledP>}
          </li>
        );
      }}
      loading={loading}
      ListboxComponent={ListBoxComponent as any}
      renderInput={(params: TextFieldParamsProps) => (
        <TextField
          {...params}
          error={error}
          required={required}
          helperText={helperText}
          label={
            label ||
            t(
              'ui.event-management.events.edit.practitioners.select-practitioner',
            )
          }
          variant="outlined"
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default UserAutocomplete;
