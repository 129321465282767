import { Group, GroupResponse } from 'api';

export type GroupOrganisationHashMap = Record<string, Group>;

export const createGroupOrganisationHashMap = (
  groups: GroupResponse[],
): Record<string, GroupResponse> => {
  const hashMap: Record<string, GroupResponse> = {};
  groups.forEach(({ organisations, name, id, type }) => {
    organisations.forEach(({ id: orgId }) => {
      hashMap[orgId] = { id, name, type, organisations };
    });
  });
  return hashMap;
};
