import {
  useCallback,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useAuth } from 'contexts/AuthContext';
import { AllDialectOptions, ProfileDialectStrategies } from 'types/dialects';
import arePronounsSupportedByDialect from 'utils/arePronounsSupportedByDialect';
import useSelection from 'components/hooks/useSelection';
import {
  QueryStatus,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import { queryStatus } from 'utils/queryStatus';
import { getEvaluatorDialects, isDFCExperience } from 'domain/event';
import { readyToPurchase } from 'domain/invitee';
import useSortBy from 'components/hooks/useSortBy';
import { DIALECT_CODES, getDialect } from 'variables';
import {
  InviteeResponse,
  useEmailEventProfiles,
  useEventInvitees,
  useGetEvent,
  useGetPractitionerWallet,
  usePurchaseEventProfiles,
  useGetInviteeContributors,
  WalletResponse,
  EventResponseWithChapters,
  ChaptersStatus,
  useSendProfileLinkToPractitioner,
} from 'api';
import { LearnerContributors } from 'api/organisationGroups/organisationGroupsTypes';
import { AnonymisationType } from 'types/types';

const IncompleteProfileChapterLanguages = [
  DIALECT_CODES.CHINESE_TRADITIONAL_HK,
  DIALECT_CODES.CZECH,
  DIALECT_CODES.ESTONIAN,
  DIALECT_CODES.GREEK,
  DIALECT_CODES.KOREAN,
];

const canBeDisplayedForPurchase = (invitee: InviteeResponse) =>
  readyToPurchase(invitee) &&
  invitee.chapters.some(
    ({ status: chapterStatus }) => chapterStatus === 'PENDING',
  );

const canBeDisplayedForDownload = (inviteeResponse: InviteeResponse) => {
  return (
    readyToPurchase(inviteeResponse) &&
    inviteeResponse.chapters.some(
      ({ status: chapterStatus }) => chapterStatus === 'PURCHASED',
    )
  );
};

const canBeDisplayedForPurchaseDFC = (
  learnerContributors: LearnerContributors,
) =>
  learnerContributors.contributors.some(
    ({ status: constributorStatus }) =>
      constributorStatus === 'READY_TO_PURCHASE',
  );

const canBeDisplayedForDownloadDFC = (
  learnerContributors: LearnerContributors,
) => {
  return learnerContributors.learner.profileStatus === 'PURCHASED';
};

const useDownloadProfiles = (
  eventId: string,
): {
  wallet: WalletResponse | undefined;
  isPending: boolean;
  isError: boolean;
  anonymisationType: AnonymisationType;
  setShowChoosingDistDialog: Dispatch<SetStateAction<boolean>>;
  showChoosingDistDialog: boolean;
  showDownloadingDialog: boolean;
  showEmailingDialog: boolean;
  onCloseEmailing: () => void;
  isSelectedForPurchase: (id: string) => boolean;
  setSelectedDialect: Dispatch<SetStateAction<AllDialectOptions>>;
  selectForPurchase: (id: string) => void;
  selectAllForPurchase: () => void;
  deselectForPurchase: (idToDeselect: string) => void;
  deselectAllForPurchase: () => void;
  allAreSelectedForPurchase: boolean;
  isSelectedForDownload: (id: string) => boolean;
  selectForDownload: (id: string) => void;
  selectAllForDownload: () => void;
  deselectForDownload: (idToDeselect: string) => void;
  deselectAllForDownload: () => void;
  allAreSelectedForDownload: boolean;
  showErrorDialog: boolean;
  showChapterWarning: boolean;
  isPurchaseDisabled: boolean;
  isDownloadDisabled: boolean;
  event: EventResponseWithChapters | undefined;
  noOfEvaluated: number;
  sortedInvitees: InviteeResponse[] | undefined;
  inviteesInPurchaseSection: InviteeResponse[];
  isDownloadSelected: boolean;
  selectedDialect: AllDialectOptions;
  dialects: { textKey: string; value: AllDialectOptions }[];
  dialectString: string | undefined;
  allSelectableIdsForDownload: string[];
  handleDownload: (getAll?: boolean) => void;
  handleSendEmail: (getAll?: boolean) => void;
  handleSendLink: (getAll?: boolean) => void;
  showSelectAllForDownloadButton: boolean;
  inviteesInDownloadSection: InviteeResponse[];
  isPurchaseSelected: boolean;
  canBeSelectedForDownload: ({ dialect, pronoun }: InviteeResponse) => boolean;
  isPurchaseError: boolean;
  selectedInviteesForPurchase: string[];
  handleConfirmPurchase: () => void;
  handleChooseDistMethod: (getAll: boolean) => void;
  chaptersCostSummary: {
    chapterName: string;
    unitCost: number;
    count: number;
  }[];
  chaptersForPurchase: ChaptersStatus[];
  selectedInviteesForDownload: string[];
  purchaseError: unknown;
  purchaseReset: () => void;
  setShowErrorDialog: Dispatch<SetStateAction<boolean>>;
  showPronounsWarning: boolean;
  dfcLearnerContributorData: LearnerContributors[] | undefined;
  dfcInviteesInPurchaseSection: LearnerContributors[];
  dfcInviteesInDownloadSection: LearnerContributors[];
  dfcPurchaseContributorsCount: number;
  changeContributorOption: (value: string) => void;
  dialectSelectionDisabled: boolean;
  profileCount: number;
  onCloseDownloadProgress: () => void;
} => {
  const { user } = useAuth();

  const { status: eventStatus, data: event } = useGetEvent(eventId);
  const { status: inviteesStatus, data: invitees } = useEventInvitees(eventId);
  const { status: walletStatus, data: wallet } = useGetPractitionerWallet(
    user!.id,
  );

  const isDFC = isDFCExperience(event?.eventType ?? '');

  const dfcLearnerContributorApiResponse: UseQueryResult<
    LearnerContributors[],
    unknown
  > = useGetInviteeContributors(eventId);
  const dfcLearnerContributorData: LearnerContributors[] | undefined =
    dfcLearnerContributorApiResponse.data;

  const sortedInvitees = useSortBy(invitees, 'fullName');

  const responseStatus: QueryStatus = queryStatus(
    eventStatus,
    inviteesStatus,
    walletStatus,
    dfcLearnerContributorApiResponse!.status,
  );

  const [showDownloadingDialog, setShowDownloadingDialog] = useState(false);
  const [showEmailingDialog, setShowEmailingDialog] = useState(false);
  const [showChoosingDistDialog, setShowChoosingDistDialog] = useState(false);
  const [downloadAll, setDownloadAll] = useState(false);
  const [selectedDialect, setSelectedDialect] = useState<AllDialectOptions>(
    DIALECT_CODES.EVALUATION_DIALECT as AllDialectOptions,
  );
  const [anonymisationType, setAnonymisationType] = useState<AnonymisationType>(
    'NAMED' as AnonymisationType,
  );

  const changeContributorOption = (value: string) => {
    setAnonymisationType(value as AnonymisationType);
  };

  const inviteesInPurchaseSection = useMemo(
    () => (sortedInvitees || []).filter(canBeDisplayedForPurchase),
    [sortedInvitees],
  );

  const dfcInviteesInPurchaseSection = useMemo(
    () =>
      (dfcLearnerContributorData || []).filter(canBeDisplayedForPurchaseDFC),
    [dfcLearnerContributorData],
  );

  const allSelectableIdsForPurchase = useMemo(() => {
    return event?.eventType !== 'INSIGHTS_DISCOVERY_FULL_CIRCLE'
      ? inviteesInPurchaseSection.map(({ id }) => id)
      : dfcInviteesInPurchaseSection.map(({ learner }) => learner.id);
  }, [
    dfcInviteesInPurchaseSection,
    event?.eventType,
    inviteesInPurchaseSection,
  ]);

  const {
    selectedIds: selectedInviteesForPurchase,
    isSelected: isSelectedForPurchase,
    select: selectForPurchase,
    selectAll: selectAllForPurchase,
    deselect: deselectForPurchase,
    deselectAll: deselectAllForPurchase,
    allAreSelected: allAreSelectedForPurchase,
    selecting: isPurchaseSelected,
  } = useSelection(allSelectableIdsForPurchase);

  const inviteesInDownloadSection = useMemo(
    () => (sortedInvitees || []).filter(canBeDisplayedForDownload),
    [sortedInvitees],
  );

  const dfcInviteesInDownloadSection = useMemo(
    () =>
      (dfcLearnerContributorData || []).filter(canBeDisplayedForDownloadDFC),
    [dfcLearnerContributorData],
  );

  const canBeSelectedForDownload = useCallback(
    ({ dialect: inviteeDialect, pronoun }: InviteeResponse) => {
      if (
        selectedDialect === DIALECT_CODES.EVALUATION_DIALECT &&
        !getDialect(inviteeDialect)
      ) {
        return false;
      }
      return arePronounsSupportedByDialect({
        pronoun,
        dialect: selectedDialect,
      });
    },
    [selectedDialect],
  );

  const allSelectableIdsForDownload = useMemo(() => {
    const canBeSelectedForDownloadDFC = ({
      learner,
      contributors,
    }: LearnerContributors) =>
      arePronounsSupportedByDialect({
        pronoun: learner.pronoun,
        dialect: selectedDialect,
      }) && contributors.length > 0;

    return isDFC
      ? dfcInviteesInDownloadSection
          .filter(canBeSelectedForDownloadDFC)
          .map(({ learner }) => learner.id)
      : inviteesInDownloadSection
          .filter(canBeSelectedForDownload)
          .map(({ id }) => id);
  }, [
    canBeSelectedForDownload,
    dfcInviteesInDownloadSection,
    inviteesInDownloadSection,
    isDFC,
    selectedDialect,
  ]);

  const {
    selectedIds: selectedInviteesForDownload,
    isSelected: isSelectedForDownload,
    select: selectForDownload,
    selectAll: selectAllForDownload,
    deselect: deselectForDownload,
    deselectAll: deselectAllForDownload,
    allAreSelected: allAreSelectedForDownload,
    selecting: isDownloadSelected,
  } = useSelection(allSelectableIdsForDownload);

  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const showChapterWarning =
    IncompleteProfileChapterLanguages.includes(selectedDialect);

  const queryClient = useQueryClient();
  const {
    isError: isPurchaseError,
    error: purchaseError,
    reset: purchaseReset,
    mutate,
  } = usePurchaseEventProfiles(queryClient);

  const { mutate: downloadProfiles } = useSendProfileLinkToPractitioner();
  const { mutate: emailProfiles } = useEmailEventProfiles();

  const chaptersCostSummary =
    event?.chapters?.map(({ name, cost }) => ({
      chapterName: name,
      unitCost: cost,
      count: 0,
    })) || [];

  const handleChooseDistMethod = (getAll: boolean) => {
    setDownloadAll(getAll);
    setShowChoosingDistDialog(true);
  };
  function generateUsing() {
    return (
      selectedDialect === DIALECT_CODES.EVALUATION_DIALECT
        ? 'EVALUATION_DIALECT'
        : 'SINGLE_DIALECT'
    ) as ProfileDialectStrategies;
  }

  function dialect() {
    return (
      selectedDialect === DIALECT_CODES.EVALUATION_DIALECT
        ? DIALECT_CODES.ENGLISH
        : selectedDialect
    ) as AllDialectOptions;
  }

  const handleSendEmail = (getAll?: boolean) => {
    setShowChoosingDistDialog(false);
    setShowEmailingDialog(true);
    emailProfiles({
      eventId,
      inviteeIds:
        getAll || downloadAll
          ? allSelectableIdsForDownload
          : selectedInviteesForDownload,
      dialect: dialect(),
      generateUsing: generateUsing(),
      format: 'attachment',
      anonymisationType,
    });
  };

  const handleSendLink = (getAll?: boolean) => {
    setShowChoosingDistDialog(false);
    setShowEmailingDialog(true);
    emailProfiles({
      eventId,
      inviteeIds:
        getAll || downloadAll
          ? allSelectableIdsForDownload
          : selectedInviteesForDownload,
      dialect: dialect(),
      generateUsing: generateUsing(),
      format: 'link',
      anonymisationType,
    });
  };

  const onCloseEmailing = () => {
    setShowEmailingDialog(false);
  };

  const handleDownload = (getAll?: boolean) => {
    setShowChoosingDistDialog(false);
    setShowDownloadingDialog(true);

    const inviteeIds = getAll
      ? allSelectableIdsForDownload
      : selectedInviteesForDownload;

    const profileIds = inviteesInDownloadSection
      .filter(({ id }) => inviteeIds.includes(id))
      .map((invitee) => invitee.profile.id);

    if (!profileIds.length) return;
    downloadProfiles({
      eventId,
      profileIds,
      dialect: dialect(),
      generateUsing: generateUsing(),
      anonymisationType,
    });
  };

  const handleConfirmPurchase = () =>
    mutate({
      eventId,
      inviteeIds: selectedInviteesForPurchase,
    });

  const noOfEvaluated = sortedInvitees?.filter(readyToPurchase).length || 0;

  const chaptersForPurchase = inviteesInPurchaseSection
    .filter((invitee) => selectedInviteesForPurchase.includes(invitee.id))
    .flatMap((invitee) => invitee.chapters);

  const isDownloadDisabled =
    isPurchaseSelected || inviteesInDownloadSection.length === 0;
  const isPurchaseDisabled =
    isDownloadSelected || inviteesInPurchaseSection.length === 0;

  const dialects = event ? getEvaluatorDialects(event) : [];

  useEffect(() => {
    if (event && event?.eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE') {
      setSelectedDialect('en-GB');
    }
  }, [event, setSelectedDialect]);

  if (dialects.length === 1 && selectedDialect !== dialects[0].value) {
    setSelectedDialect(dialects[0].value);
  }

  const dialectString =
    selectedDialect === DIALECT_CODES.EVALUATION_DIALECT
      ? undefined
      : dialects.find((dl) => dl.value === selectedDialect)?.textKey;
  const showSelectAllForDownloadButton = event
    ? !isDFCExperience(event.eventType)
    : false;
  const showPronounsWarning: boolean =
    allSelectableIdsForDownload.length < inviteesInDownloadSection.length;

  const dfcPurchaseContributorsCount = dfcInviteesInPurchaseSection
    .filter((invitee) =>
      selectedInviteesForPurchase.includes(invitee.learner.id),
    )
    .reduce(
      (contributorsTotalCount, learnerContributors) =>
        contributorsTotalCount +
        learnerContributors.contributors.filter(
          (contributor) => contributor.status === 'READY_TO_PURCHASE',
        ).length,
      0,
    );

  const profileCount = allSelectableIdsForDownload.length;
  const dialectSelectionDisabled = allSelectableIdsForDownload.length === 0;

  const onCloseDownloadProgress = () => {
    setShowDownloadingDialog(false);
  };
  return {
    wallet,
    isPending: responseStatus === 'pending',
    isError: responseStatus === 'error',
    anonymisationType,
    setShowChoosingDistDialog,
    showChoosingDistDialog,
    showDownloadingDialog,
    showEmailingDialog,
    onCloseEmailing,
    isSelectedForPurchase,
    setSelectedDialect,
    selectForPurchase,
    selectAllForPurchase,
    deselectForPurchase,
    deselectAllForPurchase,
    allAreSelectedForPurchase,
    isSelectedForDownload,
    selectForDownload,
    selectAllForDownload,
    deselectForDownload,
    deselectAllForDownload,
    allAreSelectedForDownload,
    showErrorDialog,
    showChapterWarning,
    isPurchaseDisabled,
    isDownloadDisabled,
    event,
    noOfEvaluated,
    sortedInvitees,
    inviteesInPurchaseSection,
    isDownloadSelected,
    selectedDialect,
    dialects,
    dialectString,
    allSelectableIdsForDownload,
    handleDownload,
    handleSendEmail,
    handleSendLink,
    showSelectAllForDownloadButton,
    inviteesInDownloadSection,
    isPurchaseSelected,
    canBeSelectedForDownload,
    isPurchaseError,
    selectedInviteesForPurchase,
    handleConfirmPurchase,
    handleChooseDistMethod,
    chaptersCostSummary,
    chaptersForPurchase,
    selectedInviteesForDownload,
    purchaseError,
    purchaseReset,
    setShowErrorDialog,
    showPronounsWarning,
    dfcLearnerContributorData,
    dfcInviteesInPurchaseSection,
    dfcInviteesInDownloadSection,
    dfcPurchaseContributorsCount,
    changeContributorOption,
    dialectSelectionDisabled,
    profileCount,
    onCloseDownloadProgress,
  };
};
export default useDownloadProfiles;
