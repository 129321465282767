import { getBetaAvailable } from 'features';
import { parse as parseContentDisposition } from 'content-disposition-header';
import { RequestError } from './httpEntities';

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export interface RequestResponseBody<T extends Record<string, any>> {
  success: boolean;
  headers: Headers;
  status: HttpStatusCode;
  blob: () => Promise<Blob>;
  json: () => Promise<T>;
}

const handleError = (error: any) => {
  if (error.status === HttpStatusCode.UNAUTHORIZED) {
    window.location.reload();
  }
  throw new RequestError(`${error.status} - ${error.message}`, error);
};

export const handleContentDisposition = async ({
  blob: blobFn,
  headers,
}: RequestResponseBody<any>) => {
  const blob = await blobFn();
  const contentDispositionHeader = headers.get('Content-Disposition');
  if (!contentDispositionHeader) {
    throw new Error('Expected Content-Disposition header, got null');
  }
  const { filename } = parseContentDisposition(
    contentDispositionHeader,
  ).parameters;

  return { blob, filename };
};

export const authenticatedFetch = async <T extends Record<string, any> = any>(
  path: string,
  requestInit?: RequestInit,
): Promise<RequestResponseBody<T>> => {
  const betaAvailable = getBetaAvailable();
  let extraHeaders: HeadersInit | undefined;
  if (betaAvailable) {
    const beta = localStorage.getItem('beta') === 'true' || false;
    extraHeaders = beta ? { 'insights-beta': 'true' } : undefined;
  }

  const response = await fetch(import.meta.env.VITE_API_PREFIX + path, {
    ...requestInit,
    ...(extraHeaders && {
      headers: { ...requestInit?.headers, ...extraHeaders },
    }),
  });

  const { ok, status, headers } = response;

  const json = async () => {
    let data: any;

    try {
      if (status === 401) {
        throw Error('Unauthorized');
      }
      data = await response.json();
    } catch (error: any) {
      data = {};
      data.status = error?.status ?? status;
      data.message = error?.message ?? JSON.stringify(error);
      if (status >= 400) {
        data.errors = error?.errors ?? [];
      }
    }

    if (data?.errors) {
      handleError(data);
    }

    return data;
  };

  if (!ok) {
    await json();
  }

  return {
    success: ok,
    headers,
    status,
    blob: async () => response.blob(),
    json,
  };
};

export const unauthenticatedFetch = async (
  path: string,
  requestInit?: RequestInit,
) => {
  const response = await fetch(
    import.meta.env.VITE_API_PREFIX + path,
    requestInit,
  );

  return response;
};
