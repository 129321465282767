import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import { P } from '@insights-ltd/design-library/components';
import LearnersIcon from 'components/Svgs/icons/Learners';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type Props = {
  noOfInvitees: number;
  disabled: boolean;
};

const AddLearnersCard = ({ noOfInvitees, disabled }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        height: '100%',
        border: disabled ? '0' : `1px solid ${theme.palette.grey[500]}`,
        borderBottom: disabled
          ? 'undefined'
          : `0.2em solid ${theme.palette.primary.main}`,
        opacity: disabled ? '0.5' : 'undefined',
      })}
    >
      <CardActionArea
        disabled={disabled}
        onClick={() => navigate(`${pathname}/learners`)}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          height: '100%',
          fontFamily: 'inherit',
        }}
        aria-labelledby="add-learners-title"
      >
        <CardContent>
          <LearnersIcon />
          <Box
            mb={(theme) => theme.spacing(spacingSizeMap.S)}
            mt={(theme) => theme.spacing(spacingSizeMap.S)}
          >
            <P
              id="add-learners-title"
              variant="h3"
              color={disabled ? 'textPrimary' : 'primary'}
            >
              {noOfInvitees === 0
                ? t('ui.event-management.events.summary.add-learners')
                : t('ui.event-management.events.summary.manage-learners')}
            </P>
          </Box>
          <P variant="body-bold" color="textSecondary">
            {noOfInvitees === 0
              ? t('ui.event-management.events.summary.get-started')
              : t(
                  'ui.event-management.events.summary.overview.manage-learners.no-of-learners',
                  {
                    count: noOfInvitees,
                  },
                )}{' '}
          </P>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AddLearnersCard;
