import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library/components';

export type Props = {
  open: boolean;
  onClose: () => void;
};

const ErrorFeedbackDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <P variant="h4">
          {t('ui.event-management.events.download-profiles.error')}
        </P>
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ minHeight: '13rem' }}>
        <DialogContentText
          id="error-dialog-description"
          color="textSecondary"
          variant="body1"
        >
          {t('ui.event-management.events.download-profiles.error-body')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="primary" variant="outlined">
          {t('ui.event-management.events.download-profiles.error-ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorFeedbackDialog;
