import React from 'react';
import { Link } from 'react-router-dom';
import { SxProps, Theme, styled } from '@mui/material';

export type LinkEmailProps = {
  email: string;
  sx?: SxProps<Theme>;
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightBold,
  maxWidth: '200px',
  '&:hover': {
    color: `${theme.palette.blue.dark} !important`,
    textDecoration: 'underline',
  },
}));

const LinkEmail = ({ email, sx }: LinkEmailProps) => {
  return (
    <StyledLink sx={sx} to={`mailto:${email}`} target="_blank">
      {email}
    </StyledLink>
  );
};

export default LinkEmail;
