import React from 'react';
import { SvgComponentProps } from './types';

const SvgPaperPlane = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={133}
    height={118}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="#DB3F7E"
      fillRule="evenodd"
      d="M132.308 58.747c0 32.445-26.302 58.748-58.748 58.748S14.81 91.192 14.81 58.747C14.811 26.3 41.114-.002 73.56-.002s58.748 26.303 58.748 58.749Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8E6E0"
      fillRule="evenodd"
      d="M77.929 55.32 49.502 79.449l-5.82-28.636h10.556l23.691 4.509Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8E6E0"
      fillRule="evenodd"
      d="M131.667 25.496 76.773 76.302 41.558 52.139l8.065-5.036a705.626 705.626 0 0 0 82.044-21.607Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFFEFE"
      fillRule="evenodd"
      d="M131.667 25.496 25.852 47.544l18.612 9.577 87.203-31.625Z"
      clipRule="evenodd"
    />
    <path
      fill="#C0C2BD"
      fillRule="evenodd"
      d="m49.502 79.448 11.352-9.634-8.474-6.577h-2.878v16.211Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFFEFE"
      fillRule="evenodd"
      d="m50.763 64.008 31.844 18.321 49.06-56.833-80.904 38.512Z"
      clipRule="evenodd"
    />
    <path
      stroke="#B1B3AF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.519}
      d="m37.267 114.74 20.804-26.706M1.366 70.312l26.06-9.851-26.06 9.85Zm6.161 22.293 25.199-16.549-25.199 16.55Z"
    />
    <path
      stroke="#B1B3AF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.656}
      d="m121.812 27.344 8.867-1.848-5.657 7.697"
    />
  </svg>
);

export default SvgPaperPlane;
