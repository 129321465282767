import { useGetAllOrganisations } from 'api';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ManageUnitsForm from './ManageUnitsForm';

const ManageUnits = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const locationState: any = state;

  const { status, data: organisations } = useGetAllOrganisations();

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisations.error-loading')}
      />
    );
  }

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.manage-units"
      activeTextKey="ui.event-management.title.manage-units"
      crumbs={{
        '/': t('ui.event-management.dashboard.home.title'),
        '/account': t('ui.event-management.my-account.title'),
      }}
      headingKey="ui.event-management.title.manage-units"
    >
      <ManageUnitsForm
        organisations={organisations}
        initialOrganisationId={locationState?.organisationId}
        initialPractitionerId={locationState?.practitionerId}
      />
    </BreadcrumbLayout>
  );
};

export default ManageUnits;
