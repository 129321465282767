import React, { useState } from 'react';
import { PermissionRole, ROLE_MAP } from 'pages/AddUser/AddUserForm/types';
import { JungianColours, LicensedProduct, Role } from 'types/types';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import RolePermissionV2 from './RolePermissionV2';

type EditRolePermissionProps = {
  roleChangeIsPermitted: boolean | undefined;
  isSuperAdmin: boolean;
  register: UseFormRegister<{
    fullName: string;
    emailAddress: string;
    leadingColourEnergy?: JungianColours | undefined;
    licensedProducts: LicensedProduct[];
    jobTitle: string;
    roles: Role[];
  }>;
  formData: {
    fullName: string;
    emailAddress: string;
    leadingColourEnergy?: JungianColours;
    licensedProducts: LicensedProduct[];
    jobTitle: string;
    roles: Role[];
  };
  setValue: UseFormSetValue<{
    fullName: string;
    emailAddress: string;
    leadingColourEnergy?: JungianColours | undefined;
    licensedProducts: LicensedProduct[];
    jobTitle: string;
    roles: Role[];
  }>;
  showRoles: boolean | undefined;
};

const arraysAreEqual = (a: Role[], b: string[]) =>
  JSON.stringify(a.sort()) === JSON.stringify(b.sort());

const getKeyByValue = (
  object: Record<string, Role[]>,
  value: string[] | undefined,
) =>
  Object.keys(object).find((key) => arraysAreEqual(object[key], value || [])) ||
  'STANDARD';

const EditRolePermission = ({
  roleChangeIsPermitted,
  isSuperAdmin,
  register,
  formData,
  setValue,
  showRoles,
}: EditRolePermissionProps) => {
  const defaultRoleType = getKeyByValue(ROLE_MAP, formData.roles);

  const [permissionRole, setPermissionRole] = useState<PermissionRole>(
    defaultRoleType as PermissionRole,
  );

  const changeRoleType = (value: string) => {
    setPermissionRole(value as PermissionRole);
    setValue('roles', ROLE_MAP[value as PermissionRole]);
  };

  register('roles', { required: true });

  let roleComponent = null;

  if (showRoles) {
    roleComponent = (
      <RolePermissionV2
        changeRoleType={changeRoleType}
        isSuperAdmin={isSuperAdmin}
        roleChangeIsPermitted={roleChangeIsPermitted}
        permissionRole={permissionRole}
      />
    );
  }

  return <div data-testid="edit-role-permission">{roleComponent}</div>;
};

export default EditRolePermission;
