import Grid from '@mui/material/Grid';
import { P } from '@insights-ltd/design-library/components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Group, PractitionerData } from 'api';
import OrganisationGroupLink from 'components/OrganisationGroupLink/OrganisationGroupLink';
import { roleDescription } from 'pages/Organisation/OrganisationPractitioners/helper';
import {
  BoldDataText,
  GroupDescription,
  IconWrapper,
  OrganisationCardIcon,
  OrganisationIconHeader,
  OrganisationTitle,
  RoleText,
} from './StyledComponents';

type Props = {
  id: string;
  name: string;
  group?: Group;
  practitioner?: PractitionerData;
  icon?: OrganisationCardIcon | null;
};

const OrganisationInfoCard: React.FC<Props> = ({
  id,
  name,
  group,
  practitioner,
  icon = 'Organisation',
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      sx={(theme) => ({
        display: 'flex',
        margin: `0 ${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
          spacingSizeMap.XS,
        )} 0`,
        padding: `${theme.spacing(spacingSizeMap.S)}`,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '0.25rem',
        flexDirection: 'column',
      })}
      xs={12}
      md={5}
      key={id}
    >
      <OrganisationIconHeader>
        <IconWrapper iconType={icon} />
        <OrganisationTitle variant="body-large">{name}</OrganisationTitle>
      </OrganisationIconHeader>
      <Grid item>
        <GroupDescription>
          <span>
            {group ? <OrganisationGroupLink group={group} kind="bold" /> : null}
          </span>
        </GroupDescription>
        <div>
          {practitioner ? (
            <P>
              <Trans
                i18nKey="ui.event-management.account.role"
                components={{
                  '1': <BoldDataText />,
                  '2': <RoleText />,
                }}
                values={{ role: t(roleDescription(practitioner!.roles)) }}
              />
            </P>
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
};

export default OrganisationInfoCard;
