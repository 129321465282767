import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import { Group, GroupType, RequestError } from 'api/httpEntities';
import { Trans, useTranslation } from 'react-i18next';
import { ARBITRARY_MAX_LENGTH } from 'variables';
import GroupSvg from 'components/Svgs/icons/Hierarchy';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { P, RadioGroupControl } from '@insights-ltd/design-library/components';

type GroupFormData = Omit<Group, 'id'>;

type Props = {
  isSaving?: boolean;
  onSubmit: (data: GroupFormData) => Promise<void>;
};

const StyledForm = styled('form')({ width: '100%', flexGrow: 1 });
const StyledP = styled(P)(({ theme }) => ({
  marginBottom: `${theme.spacing(spacingSizeMap.XS)} !important`,
}));

const GroupDescription = ({ textKey }: { textKey: string }) => (
  <Trans i18nKey={textKey} />
);

const CreateGroupForm = ({ isSaving, onSubmit }: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
    setValue,
  } = useForm<GroupFormData>();
  const [groupType, setGroupType] = useState<GroupType>('CUSTOMER');

  useMemo(() => {
    register('type', { required: true });
    setValue('type', 'CUSTOMER');
  }, [register, setValue]);

  const changeGroupType = (type: string) => {
    setGroupType(type as GroupType);
    setValue('type', type as GroupType);
  };

  const labels: { value: GroupType; label: string }[] = [
    {
      value: 'CUSTOMER',
      label: t('ui.event-management.create-group.group-type.customer'),
    },
    {
      value: 'PARTNER',
      label: t('ui.event-management.create-group.group-type.partner'),
    },
  ];

  const groupText: Record<GroupType, string> = {
    CUSTOMER: t(
      'ui.event-management.create-group.group-type.customer.description',
    ),
    PARTNER: t(
      'ui.event-management.create-group.group-type.partner.description',
    ),
  };

  const onSubmitHandler = handleSubmit((formData) => {
    const processedData = {
      ...formData,
      name: formData.name.trim(),
    };

    setValue('name', processedData.name);

    if (processedData.name === '') {
      return setError('name', { type: 'required' });
    }

    return onSubmit(processedData).catch((error: RequestError) => {
      if (error.errorCodes?.includes('ORGANISATION_GROUP_NAME_ALREADY_TAKEN')) {
        setError('name', { type: 'notUnique' });
      }
    });
  });

  const errorMessages: Record<keyof GroupFormData, Record<string, string>> = {
    name: {
      required: t('ui.event-management.create-group.group-name.error.required'),
      notUnique: t(
        'ui.event-management.create-group.group-name.error.not-unique',
      ),
      pattern: t('ui.event-management.create-group.group-name.error.no-spaces'),
    },
    type: {},
  };

  const getErrorMessage = (
    field: keyof GroupFormData,
    formErrors: DeepMap<Partial<Group>, FieldError>,
  ) => {
    if (!formErrors[field]) return undefined;

    return errorMessages[field][formErrors[field]!.type];
  };

  return (
    <StyledForm
      sx={(theme) => ({
        paddingBottom: theme.spacing(spacingSizeMap.M),
        marginBottom: theme.spacing(spacingSizeMap.M),
      })}
      onSubmit={onSubmitHandler}
    >
      <Grid sx={{ height: '100%' }} container justifyContent="space-between">
        <Grid item xs={12}>
          <Grid
            sx={(theme) => ({
              marginBottom: theme.spacing(spacingSizeMap.M),
            })}
            container
            alignItems="center"
          >
            <Avatar
              sx={(theme) => ({
                backgroundColor: theme.palette.orange.light,
                marginRight: theme.spacing(spacingSizeMap.S),
              })}
            >
              <GroupSvg />
            </Avatar>
            <P variant="body-large">
              {t('ui.event-management.create-group.group-details')}
            </P>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              sx={(theme) => ({
                '& > div': {
                  backgroundColor: theme.palette.common.white,
                },
              })}
              id="name"
              label={t('ui.event-management.create-group.group-name.label')}
              placeholder={t(
                'ui.event-management.create-group.group-name.placeholder',
              )}
              variant="outlined"
              fullWidth
              type="text"
              inputProps={{ maxLength: ARBITRARY_MAX_LENGTH }}
              error={Boolean(errors.name)}
              helperText={getErrorMessage('name', errors)}
              {...register('name', {
                required: true,
              })}
            />
          </Grid>
          <Grid
            sx={(theme) => ({ marginTop: theme.spacing(spacingSizeMap.S) })}
            xs={12}
            sm={8}
            item
          >
            <StyledP variant="body-bold">
              {t('ui.event-management.create-group.group-type')}
            </StyledP>
            <RadioGroupControl
              options={labels}
              name="type"
              variant="boxed"
              title={t('ui.event-management.create-group.group-type')}
              value={groupType}
              onChange={changeGroupType}
              required
            />
            <StyledP
              sx={(theme) => ({
                marginTop: `${theme.spacing(spacingSizeMap.M)} !important`,
                marginBottom: `${theme.spacing(spacingSizeMap.M)} !important`,
                whiteSpace: 'pre-line',
              })}
            >
              <GroupDescription textKey={groupText[groupType]} />
            </StyledP>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={8}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'end' }}
          item
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            endIcon={
              isSaving && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )
            }
          >
            {t('ui.event-management.create-group.submit-button')}
          </Button>
        </Grid>
      </Grid>
    </StyledForm>
  );
};

export default CreateGroupForm;
