import React, { useState } from 'react';
import UserDetails from './ForgottenPasswordForm/UserDetails';
import Confirmation from './ForgottenPasswordForm/Confirmation';
import { FormData } from './types';

const ForgottenPassword = () => {
  const INITIAL_DATA = {
    emailAddress: '',
  };
  const [form, setForm] = useState(INITIAL_DATA);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const updateForm = (formData: FormData) => {
    setForm((previousState: FormData) => ({
      ...previousState,
      ...formData,
    }));
  };
  return showConfirmation ? (
    <Confirmation emailAddress={form.emailAddress} />
  ) : (
    <UserDetails
      setShowConfirmation={setShowConfirmation}
      update={updateForm}
    />
  );
};

export default ForgottenPassword;
