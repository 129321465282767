import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { P } from '@insights-ltd/design-library/components';
import { EventResponse, useSendInvites } from 'api';
import { isDFCExperience } from 'domain/event';
import EmailProgressDialog from 'components/EmailProgressDialog';
import { InviteeListOptions } from 'types/types';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';

type HasStatus = {
  status: InviteeListOptions;
};

type SendInviteesCardProps = {
  event: EventResponse;
  invitees: HasStatus[];
  disabled: boolean;
  addUnitsWarning: boolean;
};

const StyledP = styled(P)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const SendInvitesCard = ({
  event,
  invitees,
  disabled,
  addUnitsWarning,
}: SendInviteesCardProps) => {
  const { t } = useTranslation();
  const [openEmailProgressDialog, setOpenEmailProgressDialog] =
    useState<boolean>(false);
  const { mutate } = useSendInvites();
  const handleOnClose = () => setOpenEmailProgressDialog(false);
  const invitedCount = invitees.filter(
    (invitee) => invitee.status !== 'ADDED',
  ).length;

  const inviteesCount = invitees.length;
  const invitesToSend = inviteesCount - invitedCount;
  const isDisabled = disabled || invitesToSend === 0;
  const inactive = inviteesCount === 0;
  const isDFC = isDFCExperience(event.eventType);
  const { eventType } = event;

  const handleSendInvites = () => {
    setOpenEmailProgressDialog(true);
    mutate({ eventId: event.id });
  };
  return (
    <>
      <Card
        elevation={0}
        sx={(theme) => ({
          height: '100%',
          border: addUnitsWarning
            ? `2px solid ${theme.palette.error.main}`
            : `1px solid ${theme.palette.grey[500]}`,
          opacity: inactive ? '0.5' : 'undefined',
        })}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            justifyContent: 'flex-start',
          }}
        >
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <P variant="body-bold">
              {isDFC
                ? t(
                    'ui.event-management.events.dfc.learners.headers.invites-sent',
                  )
                : t('ui.event-management.events.learners.headers.invites-sent')}
            </P>
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <P variant="h2">{`${invitedCount} / ${inviteesCount}`}</P>
          </Box>
          <Grid container alignItems="flex-start" direction="column">
            {!isDFC &&
              (addUnitsWarning ? (
                <P variant="body-bold" color="error">
                  {t(
                    'ui.event-management.events.overview.learners.headers.add.units',
                  )}
                </P>
              ) : (
                <StyledP variant="body-bold">
                  {t(
                    'ui.event-management.events.overview.learners.headers.invites-to-be-sent',
                    { count: invitesToSend },
                  )}
                </StyledP>
              ))}
            <P>&nbsp;</P>
          </Grid>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
          <Button
            onClick={handleSendInvites}
            fullWidth
            variant="contained"
            color="primary"
            disabled={isDisabled}
          >
            {isDFC
              ? t('ui.event-management.events.dfc.learners.button.send-invites')
              : t(
                  'ui.event-management.events.learners.button.send-learner-invites',
                )}
          </Button>
        </CardContent>
      </Card>
      <EmailProgressDialog
        open={openEmailProgressDialog}
        onClose={handleOnClose}
        learnerInvites
        eventType={eventType}
      />
    </>
  );
};
export default SendInvitesCard;
