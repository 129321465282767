import { PractitionerData, OrganisationResponse, GroupResponse } from 'api';
import {
  isAdvancedUser,
  isGroupManager,
  isStandardUser,
  isSuperAdmin,
} from './role';
import { createGroupOrganisationHashMap } from './createGroupOrganisationMap';

const isAbleToInvite = (
  {
    user: inviter,
    organisations: inviterOrganisations,
  }: { user?: PractitionerData; organisations: OrganisationResponse[] },
  {
    user: invitee,
    organisations: inviteeOrganisations,
  }: { user?: PractitionerData; organisations: OrganisationResponse[] },
  group?: GroupResponse,
): boolean => {
  if (!inviter) {
    return false;
  }

  if (
    (isSuperAdmin(inviter.roles) && isSuperAdmin(invitee?.roles)) ||
    (isGroupManager(inviter.roles) && isGroupManager(invitee?.roles)) ||
    (isGroupManager(inviter.roles) && isSuperAdmin(invitee?.roles)) ||
    (isAdvancedUser(inviter.roles) && isGroupManager(invitee?.roles)) ||
    (isAdvancedUser(inviter.roles) && isSuperAdmin(invitee?.roles))
  ) {
    return false;
  }
  let inviteeSet = new Set(inviteeOrganisations.map(({ id }) => id));

  if (
    ((isGroupManager(inviter.roles) && isAdvancedUser(invitee?.roles)) ||
      (isAdvancedUser(inviter.roles) && isAdvancedUser(invitee?.roles)) ||
      (isGroupManager(inviter.roles) && isStandardUser(invitee?.roles)) ||
      (isAdvancedUser(inviter.roles) && isStandardUser(invitee?.roles))) &&
    group
  ) {
    const hashMap = createGroupOrganisationHashMap([group]);
    inviteeSet = new Set(
      inviteeOrganisations.map(({ id }) => id).filter((id) => !!hashMap[id]),
    );

    return !inviterOrganisations
      .filter(({ id }) => !!hashMap[id])
      .every(({ id }) => inviteeSet.has(id));
  }

  return !inviterOrganisations.every(({ id }) => inviteeSet.has(id));
};

export default isAbleToInvite;
