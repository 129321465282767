import React from 'react';
import { Dialog, Span, Text } from '@insights-ltd/design-library/components';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import MailSentIcon from 'components/Svgs/icons/MailSent';

export type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  header: string;
  body: string;
};

export const EmailProgressDialogV2 = ({
  open,
  onClose,
  title,
  header,
  body,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      content={
        <Box margin={1} textAlign="center">
          <Box marginBottom={2}>
            <MailSentIcon width={120} />
          </Box>
          <Box marginBottom={2}>
            <Text variant="h4">{header}</Text>
          </Box>
          <Box marginBottom={2}>
            <Span>{body}</Span>
          </Box>
        </Box>
      }
      secondaryButtonText={t(
        'ui.event-management.events.download-or-share-profiles.confirmation-modal.close',
      )}
    />
  );
};
