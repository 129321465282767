import React, { useState } from 'react';
import { Box, Button, Paper, styled } from '@mui/material';
import { Text } from '@insights-ltd/design-library/components';
import { OrganisationAutocompleteV2 } from 'components/OrganisationPicker/OrganisationAutocomplete';
import { Group, OrganisationResponse } from 'api';

const StyledText = styled(Text)({
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  fontSize: '1rem !important',
});

type OrgListRenderProps = {
  name: string;
  actionText: string;
  onRemove: (id: string) => void;
  id: string;
};

export const OrgListRender = ({
  name,
  actionText,
  onRemove,
  id,
}: OrgListRenderProps) => {
  return (
    <Box
      sx={{
        padding: '0.75rem 1rem 0.75rem 1.25rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <StyledText color="textPrimary" variant="body-bold">
        {name}
      </StyledText>
      <div>
        <Button
          onClick={() => onRemove(id)}
          sx={{ minWidth: 0, padding: 0 }}
          color="error"
        >
          {actionText}
        </Button>
      </div>
    </Box>
  );
};

type OrgsinGroupSearchProps = {
  onSelect: (value: OrganisationResponse) => void;
  loading: boolean;
  options: OrganisationResponse[];
  labelKey: string;
  selectedMultiOrgs: OrganisationResponse[] | [];
  selectedGroup: Group | null;
  t: (v: string) => string;
  onRemoveOrg: (id: string) => void;
  showOrgList?: boolean;
  dataTestId?: string;
};

export const OrgsInGroupSearch = ({
  onSelect,
  loading,
  options,
  labelKey,
  selectedMultiOrgs,
  selectedGroup,
  t,
  onRemoveOrg,
  showOrgList,
  dataTestId,
}: OrgsinGroupSearchProps) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const onSelectHandler = (value: OrganisationResponse) => {
    if (value?.id) {
      onSelect(value);
    } else setSelectedValue(null);
  };
  return (
    <Box
      sx={{
        marginTop: '1rem',
      }}
    >
      {selectedGroup ? (
        <Box
          sx={{
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Text variant="body-bold">
            {t(
              'ui.event-management.users.invite.select-user-option.multi-org.label',
            )}
          </Text>
        </Box>
      ) : null}
      {(selectedGroup || showOrgList) &&
      selectedMultiOrgs.length &&
      selectedMultiOrgs.filter((org) => !!org?.id).length ? (
        <Paper
          elevation={0}
          variant="outlined"
          sx={{
            '>:last-child': { borderBottom: 'none' },
          }}
        >
          {selectedMultiOrgs.map((org: OrganisationResponse) => (
            <OrgListRender
              key={org.id}
              name={org.name}
              id={org.id}
              actionText={t(
                'ui.event-management.organisations.edit.select-group.remove',
              )}
              onRemove={onRemoveOrg}
            />
          ))}
        </Paper>
      ) : null}
      <Paper
        variant="outlined"
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[200],
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          marginTop: '-2px',
        })}
      >
        <Box
          sx={{
            paddingTop: '12px',
            paddingBottom: '24px',
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <OrganisationAutocompleteV2
            dataTestId={dataTestId}
            options={options.filter(
              (option) =>
                !selectedMultiOrgs.find(
                  (selectedOrg) => selectedOrg?.id === option?.id,
                ),
            )}
            onSelect={onSelectHandler}
            loading={loading}
            labelKey={labelKey}
            clearOnSelect
            selectedValue={selectedValue}
          />
        </Box>
      </Paper>
    </Box>
  );
};
