import React from 'react';
import { ButtonBase } from '@mui/material';

type Props = {
  removeCheckedItem?: (id: string) => void;
  latestEvaluationId: string;
  t: (key: string) => string;
};

export const RemoveButton = ({
  removeCheckedItem,
  latestEvaluationId,
  t,
}: Props) => {
  return removeCheckedItem ? (
    <ButtonBase
      disableTouchRipple
      sx={(theme) => ({
        fontSize: '1rem',
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover, &:focus': {
          textDecoration: 'underline',
        },
      })}
      onClick={() => {
        removeCheckedItem(latestEvaluationId);
      }}
    >
      {t(
        'ui.event-management.learners.purchase-profiles.your-basket.remove-button.label',
      )}
    </ButtonBase>
  ) : null;
};
