import { _delete, get, post } from '@insights-ltd/design-library/utils';
import { GetContributorsResponse, RequestErrorBody } from 'api';
import { API_ROOT } from 'mocks/handlers/common';

export const contributors = {
  all: (eventId: string) =>
    get<GetContributorsResponse>(`${API_ROOT}/events/${eventId}/contributors`),
  create: (eventId: string) =>
    post<undefined | RequestErrorBody>(
      `${API_ROOT}/events/${eventId}/contributors`,
    ),
  delete: (eventId: string, contributorId: string) =>
    _delete<undefined>(
      `${API_ROOT}/events/${eventId}/invitees/${contributorId}`,
    ),
};
