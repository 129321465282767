export const measureSVGTextElement = (element: SVGTextElement): number =>
  element.getComputedTextLength();

export type SvgInHtml = HTMLElement &
  SVGElement & {
    width: { baseVal: { value: number } };
    height: { baseVal: { value: number } };
  };

export const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob> =>
  new Promise<Blob>((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to generate blob!'));
        }
      });
    } catch (e) {
      reject(e);
    }
  });

export const saveCanvasToFile = (
  canvas: HTMLCanvasElement,
  fileName: string,
): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    try {
      const a = document.createElement('a');
      a.setAttribute('href', canvas.toDataURL('image/png'));
      a.setAttribute('target', 'download');
      a.setAttribute('download', fileName);
      a.click();
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });

export const renderCanvasToBlob = (
  svgData: string,
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  width: number,
  height: number,
): Promise<Blob> =>
  new Promise<Blob>((resolve, reject) => {
    try {
      const img = document.createElement('img');
      const svg = new Blob([svgData], {
        type: 'image/svg+xml;',
      });

      img.onload = () => {
        ctx?.drawImage(img, 0, 0, width, height);
        canvasToBlob(canvas)
          .catch(() => reject(new Error('Render canvas to blob failed!')))
          .then((blob) => resolve(blob as Blob));
      };
      img.onerror = (error) => reject(error);
      img.onabort = (error) => reject(error);
      const src = window.URL.createObjectURL(svg);
      if (src) {
        img.src = src;
      }
    } catch (e) {
      reject(e);
    }
  });

export const openNewWindow = () => {
  window.open('', 'download');
};

export const renderCanvasToFile = (
  svgData: string,
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  width: number,
  height: number,
  fileName: string,
): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    try {
      const img = document.createElement('img');
      const svg = new Blob([svgData], {
        type: 'image/svg+xml;',
      });

      img.onload = () => {
        ctx?.drawImage(img, 0, 0, width, height);
        saveCanvasToFile(canvas, fileName).then();
        resolve(true);
      };
      img.onerror = (error) => reject(error);
      img.onabort = (error) => reject(error);
      const src = window.URL.createObjectURL(svg);
      if (src) {
        img.src = src;
      }
    } catch (e) {
      reject(e);
    }
  });

export const getSvgElementById = (id: string): SvgInHtml =>
  document.getElementById(id) as SvgInHtml;

export const svgDataCanvasAndContextFromSvgElement = (
  svgElement: SvgInHtml,
  width: number,
  height: number,
) => {
  const $clone = svgElement.cloneNode(true) as SvgInHtml;

  $clone.setAttribute('width', String(width));
  $clone.setAttribute('height', String(height));
  $clone.setAttribute(
    'font-family',
    `Noto Sans, Noto Sans Balinese, Noto Sans Japanese,
        Noto Sans Korean, Noto Sans Traditional Chinese,
        Noto Sans Simplified Chinese, sans-serif`,
  );

  const isTeamWheel = !!$clone?.children[7] && !!$clone.children[7].children[1];
  if (isTeamWheel) {
    $clone?.children[7].children[1].setAttribute('font-size', '15');
  }

  const svgData = new XMLSerializer().serializeToString($clone);
  const canvas = document.createElement('canvas');

  canvas.setAttribute('width', String(width));
  canvas.setAttribute('height', String(height));

  const ctx = canvas.getContext('2d');

  return {
    svgData,
    canvas,
    ctx,
  };
};
