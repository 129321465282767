export const chapterNameMap: any = {
  FOUNDATION: 'ui.event-management.events.assign-chapters.foundation',
  MANAGEMENT: 'ui.event-management.events.assign-chapters.management',
  EFFECTIVE_SELLING:
    'ui.event-management.events.assign-chapters.effective-selling',
  PERSONAL_ACHIEVEMENT:
    'ui.event-management.events.assign-chapters.personal-achievement',
  INTERVIEW: 'ui.event-management.events.assign-chapters.interview',
  IDTL: 'ui.event-management.events.assign-chapters.insights-discovering-leadership-effectiveness',
};
