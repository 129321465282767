import { EventTypes } from 'types/types';
import { EVENT_TYPES } from 'variables';
import { PractitionerData } from 'api';
import { isSuperAdmin } from 'utils/role';

const {
  INSIGHTS_SELF_AWARE_LEADER,
  INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS,
  INSIGHTS_DISCOVERY_ACCREDITATION,
  INSIGHTS_DISCOVERY_FULL_CIRCLE,
  INSIGHTS_DISCOVERY_WORKSHOP,
  INSIGHTS_EXPLORE,
} = EVENT_TYPES;

export const useGetEventTypes = (
  practitioner?: PractitionerData,
): Partial<EventTypes> => {
  const idtlEnabled = practitioner?.licensedProducts.includes(
    'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
  );
  const dfcEnabled = practitioner?.licensedProducts.includes(
    'DISCOVERY_FULL_CIRCLE_PROFILE',
  );
  const exploreEnabled = practitioner?.licensedProducts.includes(
    'DISCOVERY_EXPLORE_PROFILE',
  );
  const isAccreditationEnabled = isSuperAdmin(practitioner?.roles);

  return {
    INSIGHTS_DISCOVERY_WORKSHOP,
    ...(isAccreditationEnabled && { INSIGHTS_DISCOVERY_ACCREDITATION }),
    ...(idtlEnabled && {
      INSIGHTS_SELF_AWARE_LEADER,
      INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS,
    }),
    ...(dfcEnabled && { INSIGHTS_DISCOVERY_FULL_CIRCLE }),
    ...(exploreEnabled && { INSIGHTS_EXPLORE }),
  };
};

export default useGetEventTypes;
