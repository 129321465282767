import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionBar } from '@insights-ltd/design-library/components';
import { DownloadShareModal } from './DownloadOrshareModal/DownloadOrShareProfileModal';

type LearnerSelectionBarProps = {
  evaluationIds: string[];
  learnerIds: string[];
  teamId?: string;
  setCheckedItems?: (arg: any) => void;
};

const LearnerSelectionBar = ({
  evaluationIds,
  learnerIds,
  teamId,
  setCheckedItems,
}: LearnerSelectionBarProps) => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const [openDownloadShareModal, setOpenDownloadShareModal] = useState(false);
  const navigateToPurchaseProfiles = () => {
    navigate('purchase-profiles', { state: evaluationIds });
  };
  const isTeam = location.pathname.includes('/teams');
  return (
    <>
      {evaluationIds.length > 0 && !isTeam && (
        <ActionBar
          barText={t(
            'ui.event-management.events.download-profiles.learners-selected',
            { count: evaluationIds.length },
          )}
          isAboveMobile={aboveMobile}
          primaryButtonObj={{
            primaryButtonText: t(
              'ui.event-management.evaluator-links.teams.add-to-team',
            ),
            primaryButtonDisabled: evaluationIds.length === 0,
            primaryButtonOnClick: () => navigate('add-to-team'),
          }}
          secondaryButtonObj={{
            secondaryButtonText: t(
              'ui.event-management.events.purchase-profiles.purchase-button',
            ),
            secondaryButtonDisabled: evaluationIds.length === 0,
            secondaryButtonOnClick: navigateToPurchaseProfiles,
          }}
        />
      )}
      {isTeam && evaluationIds.length && (
        <>
          <DownloadShareModal
            isOpen={openDownloadShareModal}
            setIsOpen={setOpenDownloadShareModal}
            teamId={teamId || ''}
            learnerIds={learnerIds}
            setCheckedItems={setCheckedItems}
          />
          <ActionBar
            barText={t(
              'ui.event-management.events.download-profiles.learners-selected',
              { count: evaluationIds.length },
            )}
            isAboveMobile={aboveMobile}
            primaryButtonObj={{
              primaryButtonText: t(
                'ui.event-management.events.download-or-share-profiles',
              ),
              primaryButtonOnClick: () => setOpenDownloadShareModal(true),
            }}
            secondaryButtonObj={{
              secondaryButtonText: t(
                'ui.event-management.events.purchase-profiles.purchase-button',
              ),
              secondaryButtonOnClick: navigateToPurchaseProfiles,
              secondaryButtonDisabled: evaluationIds.length === 0,
            }}
          />
        </>
      )}
    </>
  );
};

export default LearnerSelectionBar;
