import Pptxgen from 'pptxgenjs';
import TextPropsOptions = Pptxgen.TextPropsOptions;
import renderSVGToBlob from './renderSVGToBlob';
import renderBlobToBase64 from './renderBlobToBase64';

type RenderToPptxType = {
  name: string;
  copyright: string;
  numberOfTables: number;
  scoreType: string;
};

const renderToPptx = async ({
  name,
  copyright,
  numberOfTables,
  scoreType,
}: RenderToPptxType) => {
  const pptx = new Pptxgen();
  const fileName = `${name}_${scoreType}_powerpoint`
    .replace(/[^a-z\d]/gi, '_')
    .toLowerCase()
    .concat('.pptx');

  const footerImage: TextPropsOptions = {
    x: '0%',
    y: '98%',
    w: '100%',
    h: '2%',
  };

  const footerText: TextPropsOptions = {
    x: '0%',
    y: '90%',
    w: '100%',
    h: '10%',
    inset: 0.25,
    valign: 'middle',
    fontFace: 'Arial',
    fontSize: 7,
    color: '58585A',
  };
  const baseUrl =
    import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL;
  pptx.defineSlideMaster({
    title: 'MASTER_SLIDE',
    background: { color: 'FFFFFF' },
    objects: [
      {
        image: {
          ...footerImage,
          path: `${baseUrl}/img/powerPointFooter.png`,
        },
      },
      {
        text: {
          text: fileName,
          options: { ...footerText, align: 'left' },
        },
      },
      {
        text: {
          text: `© ${copyright}`,
          options: { ...footerText, align: 'right' },
        },
      },
    ],
  });

  for (let i = 0; i < numberOfTables; i += 1) {
    const slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    // eslint-disable-next-line no-await-in-loop
    const teamWheelSvg = await renderSVGToBlob('PPTX_TEAM_WHEEL');
    // eslint-disable-next-line no-await-in-loop
    const teamTableSvg = await renderSVGToBlob(
      `PPTX_TEAM_TABLE_${i + 1}`,
      true,
    );

    const obj: HTMLElement | null = document.getElementById(
      `PPTX_TEAM_TABLE_${i + 1}`,
    );
    const svgWidth: number = obj ? obj.clientWidth : 1;
    const svgHeight: number = obj ? obj.clientHeight : 1;
    let width: number;
    let height: number;

    if (svgWidth >= svgHeight) {
      width = 1;
      height = svgHeight / svgWidth;
    } else {
      width = svgWidth / svgHeight;
      height = 1;
    }

    if (i === 0) {
      slide1.addText(name, {
        x: '5%',
        y: '5%',
        w: '90%',
        h: '10%',
        fontFace: 'Arial',
        fontSize: 36,
        align: 'left',
        color: '1673A3',
      });
    }

    slide1.addImage({
      x: '5%',
      y: '18%',
      w: '40%',
      h: '70%',
      // eslint-disable-next-line no-await-in-loop
      data: (await renderBlobToBase64(teamWheelSvg)) as string,
    });

    slide1.addImage({
      x: '48%',
      y: '18%',
      w: width,
      h: height,
      sizing: { type: 'contain', w: '45%', h: '70%' },
      // eslint-disable-next-line no-await-in-loop
      data: (await renderBlobToBase64(teamTableSvg)) as string,
    });
  }

  let dominantId = 'Conscious_Dominant_Energies';
  let preferredId = 'Conscious_Preferred_Energies';

  if (scoreType === 'LESS_CONSCIOUS') {
    dominantId = 'LessConscious_Dominant_Energies';
    preferredId = 'LessConscious_Preferred_Energies';
  }

  const slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  const dominantEnergies = await renderSVGToBlob(dominantId, false, 1440, 1170);
  const preferredEnergies = await renderSVGToBlob(
    preferredId,
    false,
    1440,
    1170,
  );

  slide2.addImage({
    x: '5%',
    y: '10%',
    w: '42.5%',
    h: '60%',
    data: (await renderBlobToBase64(dominantEnergies)) as string,
  });

  slide2.addImage({
    x: '52.5%',
    y: '10%',
    w: '42.5%',
    h: '60%',
    data: (await renderBlobToBase64(preferredEnergies)) as string,
  });

  try {
    await pptx.writeFile({ fileName });
  } catch {
    throw new Error(fileName);
  }
};

export default renderToPptx;
