import React from 'react';
import { Button, Link, styled } from '@mui/material';
import { PractitionerData, RequestError } from 'api';
import {
  Alert,
  InputText,
  InputType,
} from '@insights-ltd/design-library/components';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { VALID_EMAIL_REGEX } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useInviteContext } from 'pages/AddUser/InviteProvider';
import { isAdvancedUser, isGroupManager } from 'utils/role';
import { useAuth } from 'contexts/AuthContext';

const EMAIL_ERROR = 'EMAIL_ERROR';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: `${theme.spacing(spacingSizeMap.S)} 0`,
  button: {
    width: '8rem',
    outline: `2px solid ${theme.palette.blue.main}`,
    alignSelf: 'flex-end',
  },
}));

const StyledDisplayData = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
    spacingSizeMap.S,
  )}`,
  borderRadius: '4px',
  backgroundColor: `${theme.palette.background.default}`,
  width: ' 100%',
  marginRight: ' 0.5rem',
}));

const StyledSpan = styled('span')({
  fontWeight: 'bold',
});

const StyledInputText = styled(InputText)(({ theme }) => ({
  width: '100%',
  marginRight: '0.5rem',
  '& > label': {
    color: theme.palette.text.primary,
  },
}));

const StyledUserSelect = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'bottom',

  button: {
    height: '44px',
    marginTop: '32px',
    outline: `2px solid ${theme.palette.blue.main}`,
  },
}));

const StyledDiv = styled('div')({
  display: 'flex',
  gap: '1rem',
});

const StyledAlert = styled(Alert)({
  borderRadius: '4px',

  '& > div': {
    colour: 'white',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
});

const DisplayUser = ({
  existingUser,
  email,
  onClear,
  error,
  userAlreadyHasAccessToAll,
  userAlreadyHasAccess,
}: {
  error: any;
  existingUser?: PractitionerData | null;
  email: string;
  onClear: () => void;
  userAlreadyHasAccessToAll: boolean;
  userAlreadyHasAccess?: boolean;
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const isUserNotFoundInGroupError = error?.errorCodes?.includes('NOT_FOUND');
  const getErrorMessage = () => {
    if (isUserNotFoundInGroupError) {
      return (
        <Trans
          i18nKey="ui.event-management.users.invite.not-found-error"
          components={{
            styledtext: (
              <Link
                href="mailto:customerservice@insights.com"
                color="primary"
              />
            ),
          }}
        />
      );
    }
    if (existingUser) {
      if (userAlreadyHasAccessToAll) {
        return t(
          'ui.event-management.users.create.email.validation.exist-in-all',
        );
      }
      if (userAlreadyHasAccess) {
        return t(
          'ui.event-management.users.create.email.validation.already-added',
        );
      }

      if (isGroupManager(user?.roles) || isAdvancedUser(user?.roles)) {
        return t(
          'ui.event-management.users.invite.email.validation.existing-user-in-group',
        );
      }
      return t('ui.event-management.users.invite.email.validation.exists');
    }
    return t('ui.event-management.users.invite.does-not-exist');
  };

  return (
    <>
      <StyledContainer>
        {existingUser ? (
          <StyledDisplayData>
            <StyledDiv>
              <div>
                <StyledSpan>{existingUser?.fullName}</StyledSpan>
                &nbsp;-&nbsp;
                <span>{existingUser?.emailAddress}</span>
              </div>
            </StyledDiv>
          </StyledDisplayData>
        ) : (
          <StyledInputText
            id="emailAddress"
            label={t('ui.event-management.users.create.email')}
            placeholder={email}
            type={InputType.EMAIL}
            required
            fullWidth
            disabled
          />
        )}
        <Button onClick={onClear}>
          {t('ui.event-management.users.create.email.change')}
        </Button>
      </StyledContainer>
      <StyledAlert
        kind={
          isUserNotFoundInGroupError ||
          userAlreadyHasAccess ||
          userAlreadyHasAccessToAll
            ? 'error'
            : 'info'
        }
        iconType={
          isUserNotFoundInGroupError ||
          !!userAlreadyHasAccess ||
          !!userAlreadyHasAccessToAll
            ? 'warning'
            : undefined
        }
      >
        {getErrorMessage()}
      </StyledAlert>
    </>
  );
};

type UserSelectProps = {
  emailValue?: string;
  onEmailSelected: (emailAddress?: string) => void;
  onClear: () => void;
  formErrors: any;
  error: any;
  userAlreadyHasAccess?: boolean;
};

const UserSelect = ({
  emailValue,
  onEmailSelected,
  onClear,
  formErrors,
  error,
  userAlreadyHasAccess,
}: UserSelectProps) => {
  const { t } = useTranslation();
  const {
    formState: { existingPractitioner },
    canInviteCurrentPractitioner,
  } = useInviteContext();

  const {
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors: localErrors },
    register,
  } = useForm<{
    emailAddress: string;
  }>();

  const onSubmit = handleSubmit(({ emailAddress }) => {
    onEmailSelected(emailAddress);
  });

  const practitionerNotFoundError = error?.errorCodes?.includes('NOT_FOUND');
  const isError = !!localErrors.emailAddress || !!formErrors.emailAddress;
  const combinedErrors = {
    emailAddress: isError
      ? {
          ...formErrors.emailAddress,
          ...localErrors.emailAddress,
        }
      : undefined,
  };

  const emailValidationError =
    error &&
    error instanceof RequestError &&
    error?.errorCodes.find((code) => code === 'INVALID_EMAIL_ADDRESS');

  const emailHelperText =
    (combinedErrors.emailAddress?.type === EMAIL_ERROR &&
      t('ui.event-management.users.create.email-error.details')) ||
    ((combinedErrors.emailAddress || emailValidationError) &&
      t('ui.event-management.users.create.error.value-email-required'));

  const checkEmailText = t(
    'ui.event-management.users.invite.email.check-email',
  );
  const clearUser = () => {
    onEmailSelected(undefined);
    reset();
    clearErrors();
    onClear();
  };

  return (existingPractitioner === undefined && !practitionerNotFoundError) ||
    emailValue === undefined ? (
    <StyledUserSelect>
      <StyledInputText
        data-testid="practitioner-email"
        id="emailAddress"
        label={t('ui.event-management.users.create.email')}
        placeholder={t('ui.event-management.users.create.email.placeholder')}
        type={InputType.EMAIL}
        helperText={emailHelperText}
        error={combinedErrors.emailAddress || emailValidationError || error}
        required
        fullWidth
        {...register('emailAddress', {
          required: true,
          pattern: VALID_EMAIL_REGEX,
        })}
      />
      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
        type="submit"
        data-testid="check-email-button"
        sx={{
          ...(combinedErrors.emailAddress && { marginBottom: 'auto' }),
          maxWidth: '300px',
          width: `${checkEmailText.length * 13}px`,
        }}
      >
        {checkEmailText}
      </Button>
    </StyledUserSelect>
  ) : (
    <DisplayUser
      existingUser={existingPractitioner}
      error={error}
      email={emailValue}
      onClear={clearUser}
      userAlreadyHasAccess={userAlreadyHasAccess}
      userAlreadyHasAccessToAll={!canInviteCurrentPractitioner}
    />
  );
};

export default UserSelect;
