import { setupWorker } from 'msw/browser';
import { badRequest, ok } from '@insights-ltd/design-library/utils';
import {
  DFC_EVENT_RESPONSE_WITH_CHAPTERS,
  DFC_INVITEES_RESPONSE,
  EXPLORE_EVENT_RESPONSE,
  INVITEES_RESPONSE,
} from 'test/constants';
import { PractitionersHttpResponse } from 'test/types';
import { errorResponse } from 'test/responses';
import { learnersData } from 'test/learners';
import teamsData from 'pages/Learners/mock-data/teamsData';
import {
  generateMockOrgsResponse,
  singleOrganisationResponse,
  mockSearchEvaluationResponse,
} from './mock-data/organisations';
import {
  contributors,
  evaluatorLinks,
  events,
  groups,
  learners,
  organisationGroups,
  organisations,
  practitioners,
  teams,
} from './handlers';
import {
  ErrorInsufficientUnitsResponse,
  EventWithChapterCosts,
  scoresResponse,
} from './mock-data/events';
import {
  PractitionerOrganisationsResponse,
  PractitionerSummaryResponse,
} from './mock-data/practitioners';
import {
  generateMockGroupResponses,
  getGroupResponse,
  getGroupsResponse,
  GROUP_TEST_ID_0,
  GROUP_TEST_ID_1,
} from './mock-data/groups';
import {
  profilesResponse,
  LearnerResponse,
  LearnersSearchResponse,
  filteredLearners,
  orgLearners,
} from './mock-data/learners';
import {
  generateMockEvaluationsData,
  mockData,
  mockLink,
  mockLinks,
} from './mock-data/evaluatorLinks';
import {
  generateMockTeamResponses,
  teamColourCountsMock,
  teamWheelSummaryMock,
} from './mock-data/teams';

const eventHandlers = [
  events.get('0fcf5121-83d5-4a96-9bc8-b1d38754ee13')(ok(EventWithChapterCosts)),
  events.get('dfc-event-id')(
    ok({
      ...DFC_EVENT_RESPONSE_WITH_CHAPTERS,
      id: 'dfc-event-id',
      chapters: [],
    }),
  ),
  events.get('explore-id')(
    ok({
      ...EXPLORE_EVENT_RESPONSE,
      id: 'explore-id',
      chapters: [],
    }),
  ),
  events.invitees.all('dfc-event-id')(ok(DFC_INVITEES_RESPONSE)),
  events.invitees.all('dfc-event-id')(ok(INVITEES_RESPONSE)),
  events.profiles.purchase('580ec9ff-3768-481e-bd43-257f5c6f4074')(
    badRequest(ErrorInsufficientUnitsResponse),
  ),
  events.scores.all('7878fd98-92fb-46eb-aaba-7a6d1a151be8')(ok(scoresResponse)),
  events.invitees.chapters.update('67a2b0d2-793d-11ec-90d6-0242ac120003')(ok()),
];

const learnerHandlers = [
  learners.all(ok(LearnersSearchResponse)),
  learners.profiles.all(
    'insights',
    'dec1bfa0-c84a-4def-8efb-2d15f4a1ec6d',
  )(ok(profilesResponse)),
  learners.get(
    'insights',
    'dec1bfa0-c84a-4def-8efb-2d15f4a1ec6d',
  )(ok(LearnerResponse)),
  learners.search(ok(filteredLearners)),
  learners.getOrganisationLearners('insights')(ok(orgLearners)),
  learners.getLearnerTeams('dec1bfa0-c84a-4def-8efb-2d15f4a1ec6d')(
    ok(teamsData),
  ),
];

const contributorHandlers = [
  contributors.all('dfc-event-id')(
    ok({
      invitees: [
        {
          id: 'dfc-learner-id',
          contributorInvitees: [
            {
              id: 'contributor-1',
              fullName: 'Homer Simpson Contributor',
              status: 'ADDED',
              pronoun: 'HE',
              email: 'homer.simpson@insights.com',
              discoveryProfilePurchased: true,
            },
            {
              id: 'contributor-2',
              fullName: 'Marge Simpson Contributor',
              status: 'ADDED',
              pronoun: 'SHE',
              email: 'marge.simpson@insights.com',
              discoveryProfilePurchased: false,
            },
          ],
        },
      ],
    }),
  ),
  contributors.create('dfc-event-id')(ok()),
  contributors.delete('dfc-event-id', 'contributor-1')(ok()),
];

const practitionerHandlers = [
  practitioners.organisations.all('e1de2ac5-092b-49c4-81db-d60b3e8bb7e9')(
    ok(PractitionerOrganisationsResponse),
  ),
];

const groupHandlers = [
  groups.all(ok(getGroupsResponse)),
  groups.get(GROUP_TEST_ID_0)(ok(getGroupResponse)),
  groups.get(GROUP_TEST_ID_1)(ok(generateMockGroupResponses(2, 0)[1])),
  groups.create(ok({})),
  groups.delete(GROUP_TEST_ID_0)(ok({})),
  groups.update(GROUP_TEST_ID_0)(ok({})),
  groups.createOrganisation(GROUP_TEST_ID_0)(ok({})),
  groups.editOrganisation()(ok(getGroupResponse)),
  groups.editOrganisationGroup(
    GROUP_TEST_ID_0,
    '44824058-0bf0-4881-9b83-4fd0e23a16ba',
  )(ok(getGroupResponse)),
];

const evaluatorLinkHandlers = [
  evaluatorLinks.all('insights')(ok({ evaluatorLinks: mockLinks })),
  evaluatorLinks.get('2d02476f-b038-425c-a5fd-595fae268d98')(ok(mockLink)),
  evaluatorLinks.get('772bbb8e-a30b-473b-97dd-55c05acf12e5')(
    ok({ ...mockLink, id: '772bbb8e-a30b-473b-97dd-55c05acf12e5' }),
  ),
  evaluatorLinks.evaluations.all('2d02476f-b038-425c-a5fd-595fae268d98')(
    ok({ evaluations: mockData }),
  ),
  evaluatorLinks.evaluations.all('2d02476f-b038-425c-a5fd-595fae268d98')(
    ok({ evaluations: mockData }),
  ),
  evaluatorLinks.evaluations.all('772bbb8e-a30b-473b-97dd-55c05acf12e5')(
    ok({ evaluations: generateMockEvaluationsData(300), limitExceeded: true }),
  ),
  evaluatorLinks.delete('2d02476f-b038-425c-a5fd-595fae268d98')(ok()),
  evaluatorLinks.profiles.purchase(
    'insights',
    'e86efbba-9819-4949-be42-f60305b68892',
  )(badRequest(ErrorInsufficientUnitsResponse)),
  evaluatorLinks.profiles.purchase(
    'insights',
    'a5523bbb-203e-448f-8ec3-d25eedb81758',
  )(badRequest()),
  evaluatorLinks.profiles.purchase('insights', 'evaluatorLinkId')(ok()),
];
const mockProfileIdsResponse = {
  profileIds: ['id1', 'id2', 'sdsfaerdsfasd'],
};

const organisationHandlers = [
  organisations.get('2e8986e4-03cd-415c-88bc-2b318c3024e3')(
    ok(singleOrganisationResponse),
  ),
  organisations.all(ok({ organisations: generateMockOrgsResponse(5) })),
  organisations.practitioners.delete(
    '9fadfe64-5ce0-40de-8325-4a44cd8ae4ea',
    'e1de2ac5-092b-49c4-81db-d60b3e8bb7e9',
  )(ok()),
  organisations.practitioners.delete(
    '9fadfe64-5ce0-40de-8325-4a44cd8ae4ea',
    'e1de2ac5-092b-49c4-81db-d60b3e8bb7e9',
  )(
    badRequest(
      errorResponse({ errors: [{ code: 'USER_MUST_BELONG_TO_AN_ORG' }] }),
    ),
  ),
  organisations.practitioners.delete(
    '9fadfe64-5ce0-40de-8325-4a44cd8ae4ea',
    'ade4200d-9277-4c45-947b-307a928c5e80',
  )(badRequest()),
  organisations.practitioners.all('bea2bba7-713e-48a8-93c8-5a5e70ce097e')(
    ok(PractitionerSummaryResponse),
  ),
  organisations.practitioners.all('2e8986e4-03cd-415c-88bc-2b318c3024e3')(
    ok({
      practitioners: [
        {
          fullName: 'Marjorie Bouvier Simpson',
          emailAddress: 'm.simpson@insights.com',
          id: 'cc23dede-1a65-43d7-9823-a50de51090f7',
          jobTitle: 'ADMIN',
          leadingColourEnergy: 'CoolBlue',
          enabled: true,
          licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
          roles: [],
        },
      ],
    } as PractitionersHttpResponse),
  ),
  organisations.events.all('2e8986e4-03cd-415c-88bc-2b318c3024e3')(
    ok({ events: [] }),
  ),
  organisations.practitioners.invites.all(
    '2e8986e4-03cd-415c-88bc-2b318c3024e3',
  )(ok({ invites: [] })),
  organisations.evaluations.search('insights')(
    ok(mockSearchEvaluationResponse),
  ),
];

const organisationGroupsHandlers = [
  organisationGroups.addExistingPractitioner(
    GROUP_TEST_ID_0,
    'cc23dede-1a65-43d7-9823-a50de51090f7',
  )(ok({})),
  organisationGroups.organisations.create(GROUP_TEST_ID_0)(
    badRequest(
      errorResponse({
        errors: [{ code: 'GROUPED_ORGANISATION_NAME_ALREADY_TAKEN' }],
      }),
    ),
  ),
  organisationGroups.organisations.addToGroup(
    GROUP_TEST_ID_0,
    'test-org-4',
  )(
    badRequest(
      errorResponse({
        status: 400,
        errors: [{ code: 'GROUPED_ORGANISATION_NAME_ALREADY_TAKEN' }],
      }),
    ),
  ),
  organisationGroups.organisations.removeFromGroup(
    GROUP_TEST_ID_0,
    'test-org-0',
  )(
    badRequest(
      errorResponse({
        status: 400,
        errors: [{ code: 'UNGROUPED_ORGANISATION_NAME_ALREADY_TAKEN' }],
      }),
    ),
  ),
];
const mockTeamHandlerId = '2e8986e4-03cd-415c-88bc-2b318c3024e3';

const teamHandlers = [
  teams.get(mockTeamHandlerId)(
    ok({
      ...generateMockTeamResponses(2, undefined, 151)[1],
      id: mockTeamHandlerId,
    }),
  ),
  teams.learners.get(mockTeamHandlerId)(
    ok({
      learners: learnersData,
      limitExceeded: false,
    }),
  ),
  teams.learners.scores.all(mockTeamHandlerId)(ok({ scores: [] })),
  teams.learners.scores.getTeamScoreSummary(mockTeamHandlerId)(
    ok(teamWheelSummaryMock),
  ),
  teams.learners.scores.getTeamColourCountsByOrganisations('*')(
    ok(teamColourCountsMock),
  ),
  teams.learners.getProfiles(mockTeamHandlerId)(ok(mockProfileIdsResponse)),
  teams.learners.shareProfiles(mockTeamHandlerId)(ok()),
];

const handlers = [
  ...eventHandlers,
  ...learnerHandlers,
  ...contributorHandlers,
  ...practitionerHandlers,
  ...groupHandlers,
  ...evaluatorLinkHandlers,
  ...organisationHandlers,
  ...organisationGroupsHandlers,
  ...teamHandlers,
];

export const worker = setupWorker(...handlers);
