import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export type LocationState = Record<string, any> | undefined;

export type LocationStateContextType = [
  LocationState,
  (newState: LocationState) => void,
];

const LocationStateContext = createContext<
  LocationStateContextType | undefined
>(undefined);

export const useLocationState = () => {
  const context = useContext(LocationStateContext);
  if (context === undefined) {
    throw new Error(
      'useLocationState must be used within a LocationStateProvider',
    );
  }
  return context;
};

type HistoryState<T> = Record<string, T | undefined>;

export const LocationStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [localState, setLocalState] = useState<LocationState>(
    location.state as LocationState,
  );

  const setState = useCallback(
    (newState: LocationState) =>
      setLocalState((oldState) => ({ ...oldState, ...newState })),
    [],
  );

  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: localState,
    });
  }, [localState, location.pathname, navigate]);

  const value = useMemo<LocationStateContextType>(
    () => [localState, setState],
    [localState, setState],
  );
  return (
    <LocationStateContext.Provider value={value}>
      {children}
    </LocationStateContext.Provider>
  );
};

const useHistoryState = <T,>(
  key: string,
): [T | undefined, (newData: T) => void] => {
  const [locationState, setLocationState] = useLocationState();
  const state = locationState as HistoryState<T> | undefined;
  const currentValue = useMemo(() => state && state[key], [key, state]);
  return [
    currentValue,
    useCallback(
      (newData: T) => setLocationState({ [key]: newData }),
      [key, setLocationState],
    ),
  ];
};

export default useHistoryState;
