import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgDataTransferHorizontal = ({ title, ...props }: SvgComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    {title ? <title>{title}</title> : null}
    <g
      fill="none"
      stroke="#1673a3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={0.75}
        d="M5.25 7.5h6.375M10.125 6l1.5 1.5-1.5 1.5M6.75 4.5H.375M1.875 6l-1.5-1.5 1.5-1.5"
      />
    </g>
  </svg>
);

export default SvgDataTransferHorizontal;
