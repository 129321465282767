import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@mui/material/DialogContentText';
import { Contributor } from 'types/types';
import { Alert, Dialog, P } from '@insights-ltd/design-library/components';

type DeleteLearnerDialogProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmDelete: () => void;
  deleting: boolean;
  contributor?: Contributor[];
};

const DeleteLearnerDialog = ({
  open,
  onClose,
  handleConfirmDelete,
  deleting,
  contributor,
}: DeleteLearnerDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onSubmit={handleConfirmDelete}
      onClose={onClose}
      open={open}
      content={
        !contributor || contributor.length === 0 ? (
          <DialogContentText>
            {t('ui.event-management.events.invitee.confirm-delete.body-text')}
          </DialogContentText>
        ) : (
          <>
            <DialogContentText sx={{ marginBottom: '15px' }}>
              {t('ui.event-management.events.invitee.confirm-delete.body-text')}
            </DialogContentText>
            <Alert kind="error" iconType="warning">
              <P color="error" variant="body-bold">
                {t(
                  'ui.event-management.events.invitee.confirm-delete.body-text-with-contributor-warning',
                )}
              </P>
            </Alert>
          </>
        )
      }
      primaryButtonText={t(
        'ui.event-management.events.invitee.confirm-delete.confirm-button',
      )}
      secondaryButtonText={t(
        'ui.event-management.events.invitee.confirm-delete.cancel-button',
      )}
      title={t('ui.event-management.events.invitee.confirm-delete.title')}
      isLoading={deleting}
    />
  );
};

export default DeleteLearnerDialog;
