import React from 'react';
import { Trans } from 'react-i18next';

// https://stackoverflow.com/a/58335085
const UnescapedTrans: typeof Trans = (props) => (
  <Trans
    {...props}
    shouldUnescape
    tOptions={{ interpolation: { escapeValue: true } }}
  />
);

export default UnescapedTrans;
