import { Grid, Icon } from '@mui/material';
import { P } from '@insights-ltd/design-library/components';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDiscoveryProfile, getIDTLProfile } from './helper';
import { RemoveButton } from './RemoveButton';

export const LearnerErrorList = ({
  latestProfiles,
  fullName,
  primaryEmail,
  removeCheckedItem,
  latestEvaluationId,
}: {
  fullName: string;
  primaryEmail: string;
  latestProfiles: any;
  removeCheckedItem?: (id: string) => void;
  latestEvaluationId: string;
}) => {
  const { t } = useTranslation();
  let errorMessage = '';
  if (!getDiscoveryProfile(latestProfiles)) {
    errorMessage = t(
      'ui.event-management.learners.purchase-profiles.idtl-purchase.needs-discovery',
    );
  }
  if (getIDTLProfile(latestProfiles)) {
    errorMessage = t(
      'ui.event-management.learners.purchase-profiles.idtl-purchase.already-has-idtl',
    );
  }

  return (
    <Grid
      container
      alignItems="baseline"
      sx={{
        backgroundColor: '#F4F6F7',
        paddingLeft: '16px',
        paddingTop: '12px',
        height: '84px',
        borderRadius: '4px',
      }}
    >
      <Grid
        item
        xs={3}
        sx={() => ({
          '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          paddingTop: `0 !important`,
        })}
      >
        <P variant="body-bold">{fullName}</P>
        <P color="textSecondary">{primaryEmail}</P>
      </Grid>
      <Grid item xs={1}>
        <RemoveButton
          removeCheckedItem={removeCheckedItem}
          latestEvaluationId={latestEvaluationId}
          t={t}
        />
      </Grid>
      <Grid>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Icon color="error">
              <CancelIcon />
            </Icon>
          </Grid>
          <Grid item>
            <P color="textPrimary" variant="body-bold">
              {errorMessage}
            </P>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
