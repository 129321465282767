import {
  Group,
  GroupResponse,
  OrganisationResponse,
  PractitionerData,
} from 'api';
import { useGetAllOrganisationsAndGroups } from 'components/hooks/useGetAllOrganisationsAndGroups';
import useGetPractitionerGroupsAndOrgs from 'components/hooks/useGetPractitionerGroupsAndOrgs';
import { useAuth } from 'contexts/AuthContext';
import React, { useContext, useMemo, useReducer } from 'react';
import { InviteUserOrgOptions } from 'types/types';
import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import isAbleToInvite from 'utils/isAbleToInvite';
import { getUniqueObjectsById } from 'utils/mappers/entityMappers';
import { isAdvancedUser, isGroupManager, isSuperAdmin } from 'utils/role';
import { PermissionRole } from './AddUserForm/types';

export type FormState = {
  permissionRole: PermissionRole;
  selectedGroup: GroupResponse | null;
  selectedOrgs: OrganisationResponse[];
  orgType: InviteUserOrgOptions;
  currentOrg: OrganisationResponse | undefined;
  existingPractitioner: (PractitionerData & { errors?: any }) | null;
  currentGroup?: GroupResponse;
};

export type InviteState = {
  practitionerOrganisations: (OrganisationResponse | GroupResponse)[];
  rawPractitionerOrganisations: OrganisationResponse[];
  practitionerGroupCount?: number;
  practitionerOrgCount?: number;
  rawOrganisations: OrganisationResponse[];
  groups: GroupResponse[];
  canInviteCurrentPractitioner: boolean;
  practitionerOrgsInGroups: Record<string, Group>;
  organisationsInGroups: Record<string, Group>;
  isPending: boolean;
  currentGroup?: GroupResponse;
  formState: FormState;
  setFormState: React.Dispatch<Partial<FormState>>;
};

const InviteContext = React.createContext<InviteState | undefined>(undefined);

export const useInviteContext = () => {
  const context = useContext(InviteContext);

  if (context === undefined) {
    throw Error(
      'context was undefined. Please ensure useInviteContext is used within a InviteProvider.',
    );
  }

  return context;
};

export const InviteProvider = ({
  children,
  overwrite,
}: {
  children: React.ReactNode;
  overwrite?: Partial<InviteState>;
}) => {
  const { user } = useAuth();

  const [formState, setFormState] = useReducer(
    (data: FormState, partialData: Partial<FormState>) => ({
      ...data,
      ...partialData,
    }),
    {
      permissionRole: 'STANDARD',
      selectedGroup: null,
      selectedOrgs: [],
      currentOrg: undefined,
      orgType: InviteUserOrgOptions.ONE_ORG,
      existingPractitioner: null,
      currentGroup: undefined,
    },
  );

  const {
    organisations: rawOrganisations,
    groups: rawGroups,
    organisationsInGroups,
  } = useGetAllOrganisationsAndGroups();
  const {
    groupCount: practitionerGroupCount,
    orgCount: practitionerOrgCount,
    organisations: rawPractitionerOrganisations,
    orgsAndGroups: practitionerOrganisations,
    organisationInGroups: practitionerOrgsInGroups,
    isPending,
  } = useGetPractitionerGroupsAndOrgs(formState.existingPractitioner, {
    enabled: !!formState.existingPractitioner,
  });

  const groups = useMemo(() => {
    if (!isSuperAdmin(user?.roles)) {
      return rawGroups.filter(({ type }) => type === 'CUSTOMER');
    }

    return rawGroups;
  }, [user?.roles, rawGroups]);

  const { organisations: auOrganisations, groups: auGroups } = useMemo(() => {
    const findPractitionerOrganisationGroups = (
      practitionerOrgs: OrganisationResponse[] | undefined,
      inviteeOrgs: OrganisationResponse[] | undefined,
    ) => {
      const inviteeOrgIds = inviteeOrgs?.map(({ id }) => id);

      const entries = Object.entries(organisationsInGroups).filter(
        ([orgId]) => {
          return inviteeOrgIds?.includes(orgId);
        },
      );

      const inviteeGroups =
        getUniqueObjectsById(
          entries.map(([, group]) => {
            return group;
          }),
        ).filter(
          ({ organisations }) =>
            !organisations.every(
              ({ id }: OrganisationResponse) => inviteeOrgIds?.includes(id),
            ),
        ) ?? [];

      const inviteeGroupHashMap =
        inviteeGroups.length > 0
          ? createGroupOrganisationHashMap(inviteeGroups)
          : {};

      const availableOrganisationsToInvite = practitionerOrgs?.filter(
        ({ id }) => !!inviteeGroupHashMap[id],
      );

      return {
        organisations: availableOrganisationsToInvite,
        groups: inviteeGroups,
      };
    };

    if (isAdvancedUser(user?.roles) || isGroupManager(user?.roles)) {
      return findPractitionerOrganisationGroups(
        rawOrganisations,
        rawPractitionerOrganisations,
      );
    }
    return {
      organisations: undefined,
      groups: undefined,
    };
  }, [
    rawPractitionerOrganisations,
    rawOrganisations,
    user,
    organisationsInGroups,
  ]);

  const canInviteCurrentPractitioner = useMemo(
    () =>
      isAbleToInvite(
        {
          user: user!,
          organisations: auOrganisations ?? rawOrganisations,
        },
        {
          user: formState.existingPractitioner!,
          organisations: rawPractitionerOrganisations,
        },
        formState.currentGroup,
      ),
    [
      user,
      formState.existingPractitioner,
      rawPractitionerOrganisations,
      rawOrganisations,
      auOrganisations,
      formState.currentGroup,
    ],
  );

  const value: InviteState = useMemo(
    () => ({
      canInviteCurrentPractitioner,
      rawPractitionerOrganisations,
      practitionerGroupCount,
      practitionerOrgCount,
      practitionerOrganisations,
      practitionerOrgsInGroups,
      organisationsInGroups,
      rawOrganisations: auOrganisations ?? rawOrganisations,
      groups: auGroups ?? groups,
      isPending,
      formState,
      setFormState,
      ...overwrite,
    }),
    [
      overwrite,
      canInviteCurrentPractitioner,
      practitionerOrganisations,
      rawPractitionerOrganisations,
      practitionerGroupCount,
      practitionerOrgCount,
      practitionerOrgsInGroups,
      organisationsInGroups,
      rawOrganisations,
      groups,
      isPending,
      auOrganisations,
      auGroups,
      formState,
      setFormState,
    ],
  );

  return (
    <InviteContext.Provider value={value}>{children}</InviteContext.Provider>
  );
};
