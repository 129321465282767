import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import {
  DataTable,
  P,
  Span,
  Tag,
  SvgLock2Bold,
  SvgHierarchy8Bold,
} from '@insights-ltd/design-library/components';
import { stringTruncate } from '@insights-ltd/design-library/utils';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { TeamResponse, useDeleteTeam } from 'api';
import DeleteTeamDialog from 'pages/Team/DeleteTeamDialog';
import { useQueryClient } from '@tanstack/react-query';
import ErrorDialog from 'pages/Team/ErrorDialog';
import { Box, ButtonBase, styled, Tooltip, Typography } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const StyledHierarchy8Bold = styled(SvgHierarchy8Bold)(({ theme }) => ({
  width: '1rem',
  fill: theme.palette.blue.dark,
  marginRight: '.5rem',
  marginTop: '.1rem',
  transform: 'scale(120%)',
}));

type TeamsListProps = {
  teamsResponse: TeamResponse[];
  alwaysShowControls?: boolean;
  allowDelete?: boolean;
  handleItemDelete?: () => void;
  handlePageChange?: ({ lastPage }: { lastPage?: boolean }) => void;
};

const StyledLock = styled(SvgLock2Bold)(({ theme }) => ({
  fill: theme.palette.grey[600],
  transform: 'scale(67%)',
  marginRight: '1px',
}));

const Container = styled('div')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.XS),
  },
  marginTop: '0',
}));

const RemoveTeamButton = ({
  id,
  key,
  onClick,
}: {
  id: string;
  key: string;
  onClick: (id: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <ButtonBase key={key} disableTouchRipple onClick={() => onClick(id)}>
      <P variant="body-bold" color="error">
        {t('ui.event-management.learners.teams.delete')}
      </P>
    </ButtonBase>
  );
};

const TeamsList = ({
  handleItemDelete,
  teamsResponse,
  handlePageChange,
  alwaysShowControls = true,
  allowDelete = true,
}: TeamsListProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isPending, reset } = useDeleteTeam(queryClient);
  const [errorDialogOpen, setOpenErrorDialog] = useState(false);
  const [deleteTeam, setDeleteTeam] = useState<TeamResponse | null>(null);

  const handleToDeleteTeam = (id: string) => {
    setDeleteTeam(
      teamsResponse.find(({ id: teamId }) => {
        return teamId === id;
      }) ?? null,
    );
  };

  const handleConfirmDelete = (): void => {
    if (deleteTeam) {
      mutate(deleteTeam.id, {
        onSuccess: () => {
          if (handleItemDelete) {
            handleItemDelete();
          }
        },
        onError: () => setOpenErrorDialog(true),
        onSettled: () => setDeleteTeam(null),
      });
    }
  };

  const handleConfirmError = () => {
    reset();
    setOpenErrorDialog(false);
  };

  const dataStructure = {
    name: t('ui.event-management.teams.heading.name'),
    learnerCount: t('ui.event-management.teams.heading.total-learners'),
    organisationContext: t('ui.event-management.teams.organisation'),
  };

  const columnPredicate = (
    columnName: keyof TeamResponse,
    columnValue: string | number,
    props: Record<any, any>,
  ) => {
    const organisationCount =
      props.organisationContext?.organisations?.length ?? 1;
    const organisationCountText = t(
      'ui.event-management.users.invite.organisations-plural',
      { count: organisationCount },
    );
    switch (columnName) {
      case 'name':
        return (
          <Box
            sx={{
              display: 'flex',
              maxWidth: '400px',
            }}
          >
            {organisationCount > 1 && <StyledHierarchy8Bold />}
            <Link component={RouterLink} to={`/teams/${props.id}`}>
              <P variant="body-bold" color="primary">
                {props.name}
              </P>
            </Link>
            {props.visibility === 'PRIVATE' && (
              <Grid
                item
                sx={(theme) => ({
                  paddingLeft: theme.spacing(spacingSizeMap.XS),
                  '> div': {
                    padding: `${theme.spacing(spacingSizeMap.XS)} 10px`,
                  },
                })}
              >
                <Tag
                  icon={<StyledLock />}
                  label={t(
                    'ui.event-management.teams.permissions.label-private',
                  )}
                  variant="default"
                  color="grey"
                />
              </Grid>
            )}
          </Box>
        );
      case 'learnerCount':
        return (
          <Box
            sx={{
              '& > div': {
                marginLeft: '4px',
              },
            }}
          >
            <Span variant="body-bold" color="textSecondary">
              {props.learnerCount === 1
                ? t('ui.event-management.teams.learners-count', {
                    count: props.learnerCount,
                  })
                : t('ui.event-management.teams.learners-count_plural', {
                    count: props.learnerCount,
                  })}
            </Span>
            {props.migrated && (
              <>
                <Tag
                  label={t(
                    'ui.event-management.teams.permissions.label-migrated',
                  )}
                  variant="default"
                  color="pink"
                />

                {props.location && (
                  <Grid
                    item
                    sx={{ flex: '1', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    xs="auto"
                  >
                    <Tooltip placement="top-end" title={props.location}>
                      <Typography aria-label={props.location}>
                        {stringTruncate(props.location, 24, 9)}
                      </Typography>
                    </Tooltip>
                  </Grid>
                )}
              </>
            )}
          </Box>
        );
      case 'organisationContext':
        return organisationCount > 1 ? (
          <P>{organisationCountText}</P>
        ) : (
          <Link
            component={RouterLink}
            to={`/organisations/${props.organisationContext.organisations[0].id}`}
          >
            <P variant="body-bold" color="primary">
              {props.organisationContext?.organisations[0]?.name ?? ''}
            </P>
          </Link>
        );
      default:
        return columnValue;
    }
  };

  const actions = [
    <RemoveTeamButton id="" key="" onClick={handleToDeleteTeam} />,
  ];

  return (
    <Container data-testid="teamslist-row">
      <Grid
        container
        alignItems="center"
        sx={{
          display: 'flex',
          wordBreak: 'break-word',
        }}
        spacing={2}
      >
        <DataTable
          items={teamsResponse}
          dataStructure={dataStructure}
          columnPredicate={columnPredicate}
          actions={allowDelete ? actions : undefined}
          onPageChange={handlePageChange}
          alwaysShowControls={alwaysShowControls}
        />
        <DeleteTeamDialog
          key="delete-dialog"
          team={deleteTeam}
          open={!!deleteTeam}
          onClose={() => setDeleteTeam(null)}
          onConfirmDelete={handleConfirmDelete}
          deleting={isPending}
        />
        <ErrorDialog
          open={errorDialogOpen}
          onClose={handleConfirmError}
          title={t('ui.event-management.learners.teams.error-dialog.title')}
          body={t('ui.event-management.learners.teams.error-dialog.body', {
            teamName: deleteTeam?.name,
          })}
        />
      </Grid>
    </Container>
  );
};
export default TeamsList;
