import React, { useEffect } from 'react';
import { MutateOptions, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import {
  PractitionerData,
  useAddExistingUserToGroupOrgs,
  useAddPractitionerToOrganisations,
  useGetAllOrganisations,
  useGetAllSupportedRoles,
  useInviteUserV2,
} from 'api';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useIsPermitted from 'components/hooks/useIsPermitted';
import { queryStatus } from 'utils/queryStatus';
import { DEFAULT_ROUTE, QUERY_STATUS } from 'variables';
import { isGroupManager, isSuperAdmin } from 'utils/role';
import { useAuth } from 'contexts/AuthContext';
import AddUserForm from './AddUserForm/AddUserForm';
import { FormData } from './AddUserForm/types';
import { InviteProvider } from './InviteProvider';

const AddUser = ({
  initialPractitioner,
}: {
  initialPractitioner?: PractitionerData;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const locationState = state;
  const { status: getAllOrgsStatus } = useGetAllOrganisations();
  const { data: supportedRoleData } = useGetAllSupportedRoles();
  const {
    groupId: currentGroupId,
    groupName: currentGroupName,
    organisationId,
  } = locationState ?? {};
  const {
    mutate: inviteNewUser,
    isError: isInviteUserError,
    error: inviteUserError,
    status: inviteUserV2Status,
    reset: resetInviteMutation,
  } = useInviteUserV2('', queryClient);

  const {
    mutate: addPractitionerToOrgs,
    isError: isAddUserError,
    error: addUserError,
    status: addUserStatus,
    reset: resetAddMutation,
  } = useAddPractitionerToOrganisations();

  const {
    mutate: addPractitionerToGroupOrgs,
    isError: addToGroupOrgsError,
    reset: resetAddToGroupOrgMutation,
  } = useAddExistingUserToGroupOrgs();

  const handleUser = (
    formData: FormData,
    config?: MutateOptions<any, any, any>,
  ) => {
    const { organisationIds, id, groupId } = formData;
    if (id) {
      if (isSuperAdmin(user?.roles)) {
        return addPractitionerToOrgs(
          {
            practitionerId: id,
            organisationIds,
          },
          config,
        );
      }
      return addPractitionerToGroupOrgs(
        {
          partnerGroupId: currentGroupId ?? groupId,
          practitionerId: id,
          payload: { organisationIds },
        },
        config,
      );
    }
    return inviteNewUser(formData, config);
  };

  const handleReset = (existingUser = false) => {
    if (existingUser) {
      resetAddMutation();
      resetAddToGroupOrgMutation();
    } else {
      resetInviteMutation();
    }
  };

  const { status: isPermittedStatus, isPermitted } = useIsPermitted(
    [
      {
        action: 'Organisation_InvitePractitioner',
        resourceId: organisationId,
      },
      {
        action: 'Organisation_Add_User',
        scope: 'Global',
      },
    ],
    {
      enabled: Boolean(locationState?.organisationId),
    },
    'OR',
  );

  const headingKey =
    currentGroupName && !isSuperAdmin(user?.roles)
      ? t('ui.event-management.users.group.invite.title', {
          groupName: currentGroupName,
        })
      : t('ui.event-management.users.invite.title');

  const status = queryStatus(isPermittedStatus, getAllOrgsStatus);

  useEffect(() => {
    if (
      isPermittedStatus === QUERY_STATUS.SUCCESS &&
      !isPermitted &&
      organisationId
    ) {
      navigate(DEFAULT_ROUTE);
    }
  }, [isPermittedStatus, isPermitted, navigate, organisationId]);

  if (
    status === QUERY_STATUS.PENDING ||
    inviteUserV2Status === QUERY_STATUS.PENDING ||
    addUserStatus === QUERY_STATUS.PENDING
  ) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  let breadcrumbs = {
    '/': t('ui.event-management.dashboard.home.title'),
    '/organisations': t('ui.event-management.organisations.title'),
  };

  if (currentGroupName && currentGroupId) {
    breadcrumbs = {
      ...breadcrumbs,
      [`/group/${currentGroupId}`]: currentGroupName,
    };
  }

  if (status === QUERY_STATUS.ERROR) {
    return (
      <FullScreenError
        message={t('ui.event-management.organisations.error-loading')}
      />
    );
  }

  return (
    <InviteProvider>
      <BreadcrumbLayout
        titleKey="ui.event-management.title.add-user"
        activeTextKey={
          isGroupManager(user?.roles)
            ? 'ui.event-management.users.invite.organisation.add-user.crumb'
            : 'ui.event-management.users.create.crumb'
        }
        crumbs={breadcrumbs}
        headingKey={headingKey}
      >
        <AddUserForm
          mutate={handleUser}
          resetMutation={handleReset}
          isInviteUserError={
            isInviteUserError || isAddUserError || addToGroupOrgsError
          }
          inviteUserError={inviteUserError || addUserError}
          currentOrgId={organisationId}
          currentGroupId={currentGroupId}
          initialPractitioner={initialPractitioner}
          supportedRoles={supportedRoleData ?? []}
        />
      </BreadcrumbLayout>
    </InviteProvider>
  );
};

export default AddUser;
