import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { LicensedProduct } from 'types/types';

type Props = {
  productValue: LicensedProduct;
  onChange: (value: LicensedProduct) => void;
  disabledValues?: LicensedProduct[];
  practitionerProducts: LicensedProduct[];
};

const ProductRadioOptions = ({
  productValue = 'DISCOVERY_SELF_AWARE_PROFILE',
  onChange,
  disabledValues = [],
  practitionerProducts = ['DISCOVERY_SELF_AWARE_PROFILE'],
}: Props) => {
  const { t } = useTranslation();
  const handleOnChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    onChange(value as LicensedProduct);
  };

  return (
    <RadioGroup
      value={productValue}
      aria-label="productType"
      onChange={handleOnChange}
    >
      <FormControlLabel
        sx={(theme) => ({
          '& > span': {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.grey[700],
            '& > span > svg:first-of-type': {
              color: disabledValues.includes('DISCOVERY_SELF_AWARE_PROFILE')
                ? theme.palette.grey[300]
                : theme.palette.blue.main,
            },
          },
          '& > span:first-of-type': {
            padding: ' 8px 9px 6px',
          },
        })}
        value="DISCOVERY_SELF_AWARE_PROFILE"
        control={<Radio color="primary" />}
        label={t('ui.event-management.product-types.insights-discovery')}
      />
      {practitionerProducts.includes(
        'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
      ) ? (
        <FormControlLabel
          sx={(theme) => ({
            '& > span': {
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.grey[700],
              '& > span > svg:first-of-type': {
                color: disabledValues.includes(
                  'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
                )
                  ? theme.palette.grey[300]
                  : theme.palette.blue.main,
              },
            },
            '& > span:first-of-type': {
              padding: '6px 9px',
            },
          })}
          value="DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE"
          disabled={disabledValues.includes(
            'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
          )}
          control={
            <Radio
              disabled={disabledValues.includes(
                'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
              )}
              color="primary"
            />
          }
          label={t(
            'ui.event-management.product-types.insights-discovery-transformational-leadership',
          )}
        />
      ) : null}
    </RadioGroup>
  );
};

export default ProductRadioOptions;
