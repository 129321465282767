import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  Avatar,
  AvatarColor,
  H2,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type Props = {
  icon: React.ComponentType;
  title: string;
  showMargin?: boolean;
  color?: AvatarColor;
  marginTop?: string;
  marginBottom?: string;
};

const FormHeader = ({
  marginTop = '1.5rem',
  marginBottom = '1.5rem',
  color,
  icon: Icon,
  title,
  showMargin = true,
}: Props) => {
  return (
    <>
      <Grid sx={{ marginTop, marginBottom }} container alignItems="center">
        <Avatar color={color}>
          <Icon />
        </Avatar>
        <Box mr={(theme) => theme.spacing(spacingSizeMap.S)} />
        <H2 variant="body-large">{title}</H2>
      </Grid>
      {showMargin && <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />}
    </>
  );
};

export default FormHeader;
