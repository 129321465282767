import { DIALECTS, PRONOUNS_SINGULAR_INCLUSIVE, PRONOUN_THEY } from 'variables';
import { AllDialectOptions, Pronoun } from 'types/dialects';

type ArePronounsSupportedByDialectProps = {
  dialect: AllDialectOptions;
  pronoun?: Pronoun;
};

const arePronounsSupportedByDialect = ({
  dialect,
  pronoun,
}: ArePronounsSupportedByDialectProps): boolean => {
  if (pronoun?.toUpperCase() !== PRONOUN_THEY) {
    return true;
  }

  return DIALECTS[dialect]?.pronouns.singular === PRONOUNS_SINGULAR_INCLUSIVE;
};

export default arePronounsSupportedByDialect;
