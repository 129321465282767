import React from 'react';
import { PermissionRequirement } from 'permissions';
import useIsPermitted from './hooks/useIsPermitted';

interface PermissionProps {
  requiredPermissions: PermissionRequirement[];
  children: React.ReactNode;
}

const PermissionGuard = ({
  requiredPermissions,
  children,
}: PermissionProps) => {
  const { isPermitted } = useIsPermitted(requiredPermissions);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isPermitted && children}</>;
};

export default PermissionGuard;
