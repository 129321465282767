import { useMemo } from 'react';

const useSortBy = <T, K extends keyof T>(
  items: T[] | undefined,
  sortField: K,
): T[] | undefined =>
  useMemo(
    () =>
      items?.sort((a, b) =>
        // locale field set to undefined uses the browser's language by default
        String(a[sortField]).localeCompare(String(b[sortField]), undefined, {
          sensitivity: 'base',
        }),
      ),
    [items, sortField],
  );

export default useSortBy;
