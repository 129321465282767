import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { QUERY_KEY_ROOTS } from 'variables';
import { QueryOptionsConfig } from 'api/hooks';
import {
  EvaluatorLink,
  GetCompletedEvaluationResponse,
} from 'api/httpEntities';
import { EvaluatorSearchFilter } from './evaluatorLinksApiTypes';
import {
  deleteEvaluatorLink,
  getCompletedEvaluators,
  getEvaluatorLink,
} from './evaluatorLinks';

export const useDeleteEvaluatorLink = (): UseMutationResult<
  void,
  unknown,
  string,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteEvaluatorLink,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_ROOTS.ORGANISATION_EVALUATOR_LINKS],
      });
    },
  });
};

export const useGetEvaluatorLink = (
  id: string,
  config: QueryOptionsConfig<EvaluatorLink> = {},
): UseQueryResult<EvaluatorLink, Error> =>
  useQuery<EvaluatorLink, Error>({
    queryKey: [QUERY_KEY_ROOTS.ORGANISATION_EVALUATOR_LINKS, id],
    queryFn: async () => getEvaluatorLink(id),
    ...config,
  });

export const useGetCompletedEvaluators = (
  id: string,
  searchTerm = '',
  filter: EvaluatorSearchFilter = 'ALL',
  config: QueryOptionsConfig<GetCompletedEvaluationResponse> = {},
): UseQueryResult<GetCompletedEvaluationResponse, Error> =>
  useQuery<GetCompletedEvaluationResponse, Error>({
    queryKey: [
      QUERY_KEY_ROOTS.ORGANISATION_EVALUATOR_LINK_EVALUATIONS,
      id,
      searchTerm,
      filter,
    ],
    queryFn: async () => getCompletedEvaluators(id, searchTerm, filter),
    ...config,
  });
