import { ScoreCount } from 'api';

export const getKeyAndLabel = (
  wheelPosition: ScoreCount,
  scoreType: string,
  index: number,
) => {
  const { conscious, lessConscious } = wheelPosition.scoreCount;
  const elementKey =
    scoreType === 'CONSCIOUS'
      ? `${conscious}-${scoreType}-${index}`
      : `${lessConscious}-${scoreType}-${index}`;
  const numberofLearnerOnPosition =
    scoreType === 'CONSCIOUS' ? conscious : lessConscious;

  return {
    key: elementKey,
    label: `${numberofLearnerOnPosition}`,
    duplicateNumber: numberofLearnerOnPosition,
  };
};
