import { useEventInvitees, useGetContributors, useGetEvent } from 'api';
import useIsPermitted from 'components/hooks/useIsPermitted';
import { combineQueryResults } from 'utils/combineQueryStatus';

export const useEventData = (eventId: string) => {
  const eventQuery = useGetEvent(eventId);
  const inviteesQuery = useEventInvitees(eventId);
  const contributorsQuery = useGetContributors(
    eventId,
    inviteesQuery.data || [],
    Boolean(
      eventQuery.data?.eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE' &&
        inviteesQuery.data?.length &&
        inviteesQuery.isRefetching === false,
    ),
  );
  const permissionsQuery = useIsPermitted([
    {
      action: 'Experience_ReadExpired',
      scope: 'Local',
    },
  ]);
  return combineQueryResults(
    [
      eventQuery,
      inviteesQuery,
      {
        isLoading: permissionsQuery.status !== 'success',
        data: permissionsQuery.isPermitted,
        status: permissionsQuery.status,
      },
    ],
    [contributorsQuery],
  );
};
