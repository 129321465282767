import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { ActionBar } from '@insights-ltd/design-library/components';

type Props = {
  chapter: string;
  count: number;
  updating: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const SaveSelectionBar = ({
  chapter,
  count,
  updating,
  onCancel,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile();

  return (
    <ActionBar
      primaryButtonObj={{
        primaryButtonText: t(
          'ui.event-management.events.assign-chapters.save-selection',
        ),
        primaryButtonOnClick: onSave,
        primaryButtonDisabled: updating,
        endIcon: updating && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ),
      }}
      secondaryButtonObj={{
        secondaryButtonText: t(
          'ui.event-management.events.assign-chapters.cancel',
        ),
        secondaryButtonOnClick: onCancel,
      }}
      isAboveMobile={aboveMobile}
      barText={t(
        'ui.event-management.events.assign-chapters.save-bar.assign-to-invitees',
        { chapter, count },
      )}
    />
  );
};

export default SaveSelectionBar;
