import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, styled } from '@mui/material';
import { EventSummaryResponse, OrganisationResponse } from 'api';
import { DataTable, P, Tag } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { EVENT_TYPES } from 'variables';
import { EventType } from 'types/types';
import { isoToLocaleString } from 'utils/dates';

export type EventsListProps = {
  events?: EventSummaryResponse[];
  showOrgs?: boolean;
  experienceView?: string;
  noResultsText?: string;
  setMargin?: boolean;
};

const StyledDiv = styled('div')(({ theme }) => ({
  '& > :first-of-type': {
    marginBottom: `${theme.spacing(spacingSizeMap.M)}`,
  },
  '& > * + *': {
    marginBottom: `${theme.spacing(spacingSizeMap.XS)}`,
  },
}));

const StyledTag = styled(Tag)({
  maxWidth: '100%',
});

const StyledP = styled(P)(({ theme }) => ({
  marginTop: `${theme.spacing(spacingSizeMap.S)} !important`,
  // marginLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
  fontWeight: theme.typography.fontWeightBold,
}));

type DataStructure =
  | {
      name: string;
      startsAt: string;
      eventType: string;
      organisation: string;
      cancelledAt?: string;
    }
  | {
      name: string;
      startsAt: string;
      eventType: string;
    };

const EventsList = ({
  events,
  showOrgs,
  experienceView,
  noResultsText,
  setMargin = false,
}: EventsListProps) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToExperiences = (id: string) => {
    navigate(`/experiences/${id}`, {
      state: {
        prev: pathname,
      },
    });
  };
  const cancelledDataStructure: DataStructure = showOrgs
    ? {
        name: t('ui.event-management.events.heading.event-title'),
        startsAt: t('ui.event-management.events.heading.date'),
        eventType: t('ui.event-management.events.heading.product-type'),
        organisation: t('ui.event-management.learners.heading.organisation'),
        cancelledAt: t('ui.event-management.events.heading.cancelled'),
      }
    : {
        name: t('ui.event-management.events.heading.event-title'),
        startsAt: t('ui.event-management.events.heading.date'),
        eventType: t('ui.event-management.events.heading.product-type'),
        cancelledAt: t('ui.event-management.events.heading.cancelled'),
      };
  const defaultDataStructure = showOrgs
    ? {
        name: t('ui.event-management.events.heading.event-title'),
        startsAt: t('ui.event-management.events.heading.date'),
        eventType: t('ui.event-management.events.heading.product-type'),
        organisation: t('ui.event-management.learners.heading.organisation'),
      }
    : {
        name: t('ui.event-management.events.heading.event-title'),
        startsAt: t('ui.event-management.events.heading.date'),
        eventType: t('ui.event-management.events.heading.product-type'),
      };

  const dataStructure =
    !!experienceView && experienceView === 'cancelled'
      ? cancelledDataStructure
      : defaultDataStructure;

  const columnPredicate = (
    columnName: keyof EventSummaryResponse,
    columnValue: string | number | EventType | OrganisationResponse,
    props: Record<any, any>,
  ) => {
    switch (columnName) {
      case 'name':
        return (
          <Button
            color="primary"
            variant="text"
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: theme.palette.grey[300],
              },
              padding: '0 !important',
              justifyContent: 'flex-start',
              maxWidth: '15rem',
              paddingLeft: 0,
              textAlign: 'left',
              wordBreak: 'break-word',
              ':first-of-type': {
                marginLeft: 0,
              },
            })}
            onClick={() => navigateToExperiences(props.id)}
          >
            {columnValue as React.ReactNode}
          </Button>
        );
      case 'startsAt':
        return <>{isoToLocaleString(columnValue as string, language)}</>;
      case 'eventType': {
        const { color, textKey } = EVENT_TYPES[columnValue as EventType];
        return <StyledTag color={color} label={t(textKey)} />;
      }
      case 'organisation':
        return (
          <Button
            color="primary"
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: theme.palette.grey[300],
              },
              padding: '0 !important',
              justifyContent: 'flex-start',
              maxWidth: '15rem',
              paddingLeft: 0,
              textAlign: 'left',
              wordBreak: 'break-word',
              ':first-of-type': {
                marginLeft: 0,
              },
            })}
            component={RouterLink}
            to={`/organisations/${(columnValue as OrganisationResponse)?.id}`}
          >
            {(columnValue as OrganisationResponse)?.name}
          </Button>
        );
      case 'cancelledAt':
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {columnValue && isoToLocaleString(columnValue as string, language)}
          </>
        );
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{columnValue}</>;
    }
  };

  return (
    <StyledDiv>
      {events && events.length > 0 ? (
        <DataTable
          items={events}
          dataStructure={dataStructure}
          alwaysShowControls={false}
          itemsPerPage={12}
          columnPredicate={columnPredicate}
        />
      ) : (
        <StyledP
          color="textSecondary"
          variant="body-bold"
          sx={(theme) => ({
            marginLeft: setMargin
              ? `${theme.spacing(spacingSizeMap.S)} !important`
              : 0,
          })}
        >
          {noResultsText}
        </StyledP>
      )}
    </StyledDiv>
  );
};
export default EventsList;
