import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Span } from '@insights-ltd/design-library/components';
import UnescapedTrans from 'components/i18n/UnescapedTrans';
import { User } from 'types/types';

type Props = {
  users: User[];
  setUsers: (newUsers: User[]) => void;
};
const UsersList = ({ users, setUsers }: Props) => {
  const { t } = useTranslation();
  const deleteUser = (user: User) => {
    setUsers(users.filter((item) => item.id !== user.id));
  };
  return (
    <>
      {users.map((user: User) => {
        return (
          <TableRow key={user.id}>
            <TableCell
              scope="row"
              sx={{
                overflowX: 'hidden',
                wordBreak: 'break-all',
              }}
              data-testid={`additional-practitioner-${user.id}`}
            >
              <Span>
                <UnescapedTrans
                  i18nKey="ui.event-management.events.edit.practitioners.practitioner.bold"
                  values={{
                    fullName: user.fullName,
                    emailAddress: user.emailAddress,
                  }}
                  components={{
                    bold: <Span variant="body-bold" />,
                  }}
                />
              </Span>
            </TableCell>
            <TableCell align="right">
              {users.length > 1 && (
                <Button
                  sx={(theme) => ({
                    minWidth: 'unset',
                    color: theme.palette.error.main,
                  })}
                  onClick={() => deleteUser(user)}
                >
                  <strong>
                    {t('ui.event-management.events.edit.practitioners.delete')}
                  </strong>
                </Button>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default UsersList;
