import React from 'react';
import { Box, styled } from '@mui/material';
import FormHeader from 'components/FormHeader';
import MultiOrSingleOrgSelect from 'components/MultiOrSingleOrgSelect';
import {
  RadioGroupControl,
  Text,
} from '@insights-ltd/design-library/components';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import { InviteUserOrgOptions } from 'types/types';
import { useTranslation } from 'react-i18next';
import { Group, GroupResponse, OrganisationResponse } from 'api';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  fill: theme.palette.orange.dark,
  height: '20px',
}));

const AddUserOptions = ({
  selectedOrgs,
  setSelectedOrgs,
  orgType,
  setOrgType,
  organisations,
  groups,
  organisationsInGroups,
  onRemoveOrg,
  setSelectedGroup,
  selectedGroup,
  titleKey,
  options,
  groupOptions,
  existingUser = false,
}: {
  options: { label: string; value: InviteUserOrgOptions }[];
  onRemoveOrg: (id: string) => void;
  selectedOrgs: any;
  setSelectedOrgs: (org: OrganisationResponse | null) => void;
  orgType: InviteUserOrgOptions;
  setOrgType: (type: InviteUserOrgOptions) => void;
  organisations: OrganisationResponse[];
  groups: GroupResponse[];
  organisationsInGroups: GroupOrganisationHashMap;
  selectedGroup: Group | null;
  setSelectedGroup: (value: GroupResponse | null) => void;
  titleKey?: string;
  groupOptions: OrganisationResponse[];
  existingUser?: boolean;
}) => {
  const { t } = useTranslation();
  const title = titleKey ?? 'ui.event-management.users.create.organisation';

  return (
    <>
      <FormHeader
        marginTop={existingUser ? '1.5rem' : '40px'}
        icon={StyledOrganisationIcon}
        color="orange"
        title={t(title)}
      />
      <Text variant="body-bold">
        {t('ui.event-management.users.invite.select-option.label')}
      </Text>
      <RadioGroupControl
        name="org type"
        onChange={(newValue: any) => {
          setOrgType(newValue);
          setSelectedOrgs(null);
        }}
        title={t('ui.event-management.users.invite.select-option.title')}
        variant="list"
        value={orgType}
        options={options}
      />
      <Box mt={2} mb={2}>
        <MultiOrSingleOrgSelect
          orgType={orgType}
          selectedOrgs={selectedOrgs}
          setSelectedOrgs={setSelectedOrgs}
          organisations={organisations}
          groups={groups}
          organisationsInGroups={organisationsInGroups}
          onRemoveOrg={onRemoveOrg}
          setSelectedGroup={setSelectedGroup}
          selectedGroup={selectedGroup}
          groupOptions={groupOptions}
        />
      </Box>
    </>
  );
};

export default AddUserOptions;
