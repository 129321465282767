import {
  EvaluationsProfilesResponse,
  OrganisationResponse,
  OrganisationSupportedRole,
} from 'api/httpEntities';

export const myMultiOrganisationResponse = {
  organisations: [
    {
      name: 'Standard User Organisation',
      id: 'standard-user-org',
    },
    {
      name: 'New organisation',
      id: '2e8986e4-03cd-415c-88bc-2b318c3024e3',
    },
  ],
};

export const singleOrganisationResponse: OrganisationResponse = {
  name: 'New organisation',
  id: '2e8986e4-03cd-415c-88bc-2b318c3024e3',
  dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
};

export const generateMockOrgsResponse = (
  numberOfOrgs = 1,
): OrganisationResponse[] => {
  const orgs: OrganisationResponse[] = [];
  for (let i = 0; i < numberOfOrgs; i += 1) {
    orgs.push({
      id: `test-org-${i}`,
      name: `Test Organisation ${i}`,
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    });
  }

  return orgs;
};

export const generateMockSupportedRoles = (
  numberOfOrgs = 1,
): { organisationSupportedRoles: OrganisationSupportedRole[] } => {
  const roles: { organisationSupportedRoles: OrganisationSupportedRole[] } = {
    organisationSupportedRoles: [],
  };
  for (let i = 0; i < numberOfOrgs; i += 1) {
    roles.organisationSupportedRoles.push({
      organisationId: `test-org-${i}`,
      supportedRoles: ['STANDARD'],
    });
  }

  return roles;
};

export const mockOrganisations: { organisations: OrganisationResponse[] } = {
  organisations: [
    {
      id: '9b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Eating Stuff',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
    {
      id: 'd75193bd-5962-47db-b151-b66f695df5ce',
      name: 'Buying Dog',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
    {
      id: '9c9c102d-d9fd-419f-bda5-17d32d839114',
      name: 'Making people laugh',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
    {
      id: '9b27c610-1e2f-4e79-a211-a0679345345i',
      name: 'Being the best boss',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
    {
      id: 'd75193bd-5962-47db-b151-nfjkwi399kkj',
      name: 'Microflops',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
    {
      id: '9c9c102d-d9fd-419f-bda5-234jkljhgjjm',
      name: 'Insighting',
      dataRetentionPolicy: { maximumInactivityPeriod: 'P5Y3M2D' },
    },
  ],
};

export const mockSupportedRoles: {
  supportedRoles: OrganisationSupportedRole[];
} = {
  supportedRoles: [
    {
      organisationId: '9b27c610-1e2f-4e79-a211-a067946a6e2b',
      supportedRoles: ['STANDARD'],
    },
    {
      organisationId: 'd75193bd-5962-47db-b151-b66f695df5ce',
      supportedRoles: ['STANDARD'],
    },
    {
      organisationId: '9c9c102d-d9fd-419f-bda5-17d32d839114',
      supportedRoles: ['STANDARD'],
    },
    {
      organisationId: '9b27c610-1e2f-4e79-a211-a0679345345i',
      supportedRoles: ['STANDARD'],
    },
    {
      organisationId: 'd75193bd-5962-47db-b151-nfjkwi399kkj',
      supportedRoles: ['STANDARD'],
    },
    {
      organisationId: '9c9c102d-d9fd-419f-bda5-234jkljhgjjm',
      supportedRoles: ['STANDARD'],
    },
  ],
};

export const mockSearchEvaluationResponse: EvaluationsProfilesResponse = {
  evaluations: [
    {
      id: 'd6d90e76-956d-4751-8ec5-22f8e1a3f438',
      submittedOn: '2023-06-06T09:48:18.135088Z',
      learner: {
        id: '4cc00d76-6af5-476a-89f2-a3b067233b86',
        fullName: 'Nicola Golden',
        knownAs: 'Nic',
        primaryEmail: 'nicolagolden1@testenv.email',
      },
      latestProfile: {
        id: '35a08a60-acfb-4e66-8864-ad3c4c053c04',
        createdAt: '2023-06-06T09:48:30.165511Z',
        chapters: ['EFFECTIVE_SELLING', 'FOUNDATION', 'MANAGEMENT'],
        profileType: 'DISCOVERY_PROFILE',
      },
      latestProfiles: [
        {
          id: '35a08a60-acfb-4e66-8864-ad3c4c053c04',
          createdAt: '2023-06-06T09:48:30.165511Z',
          chapters: ['EFFECTIVE_SELLING', 'FOUNDATION', 'MANAGEMENT'],
          profileType: 'DISCOVERY_PROFILE',
        },
        {
          id: '2c622c5e-d010-4f3d-b6fe-fd4e63d9b783',
          createdAt: '2023-06-06T11:46:29.299588Z',
          chapters: ['IDTL'],
          profileType: 'IDTL_PROFILE',
        },
      ],
    },
  ],
};
