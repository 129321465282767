import {
  _delete,
  get,
  post,
  put,
  mkStatus,
  ok,
} from '@insights-ltd/design-library/utils';
import {
  GetGroupResponse,
  GroupResponse,
  RequestErrorBody,
} from 'api/httpEntities';
import { API_ROOT, NoResponse } from 'mocks/handlers/common';

export const groups = {
  all: get<GetGroupResponse>(`${API_ROOT}/organisation-groups`),
  get: (groupId: string) =>
    get<GroupResponse>(`${API_ROOT}/organisation-groups/${groupId}`),
  create: post<NoResponse>(`${API_ROOT}/organisation-groups`),
  delete: (groupId: string) =>
    _delete<NoResponse>(`${API_ROOT}/organisation-groups/${groupId}`),
  update: (groupId: string) =>
    put<NoResponse>(`${API_ROOT}/organisation-groups/${groupId}`),
  addOrganisation: (groupId: string, organisationId: string) =>
    put<NoResponse>(
      `${API_ROOT}/organisation-groups/${groupId}/organisation/${organisationId}`,
    )(ok({})),
  addOrganisationError: (groupId: string, organisationId: string) =>
    put<RequestErrorBody>(
      `${API_ROOT}/organisation-groups/${groupId}/organisation/${organisationId}`,
    )(
      mkStatus(500)({
        ts: '2023-01-17T10:17:12.115520300Z',
        status: 500,
        message: 'Unable to add Organisation',
        errors: [
          {
            code: 'INTERNAL_SERVER_ERROR',
          },
        ],
      }),
    ),
  createOrganisation: (groupId: string) =>
    put<NoResponse>(`${API_ROOT}/organisation-groups/${groupId}/organisations`),
  editOrganisation: () => put<GroupResponse>(`${API_ROOT}/organisation-groups`),
  editOrganisationGroup: (groupId: string, organisationId: string) =>
    put<GroupResponse | RequestErrorBody>(
      `${API_ROOT}/organisation-groups/${groupId}/organisations/${organisationId}`,
    ),
};
