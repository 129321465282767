import React from 'react';
import { SvgComponentProps } from './types';

const SvgChart = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="#000"
      d="M1.042 13.958h2.916a.208.208 0 0 0 .209-.208v-1.667a.833.833 0 0 0-.834-.833H1.667a.833.833 0 0 0-.834.833v1.667a.208.208 0 0 0 .209.208ZM6.667 7.083a.833.833 0 0 0-.834.834v5.833a.208.208 0 0 0 .209.208h2.916a.207.207 0 0 0 .209-.208V7.917a.833.833 0 0 0-.834-.834H6.667ZM11.667 8.75a.833.833 0 0 0-.834.833v4.167a.207.207 0 0 0 .209.208h2.916a.208.208 0 0 0 .209-.208V9.583a.833.833 0 0 0-.834-.833h-1.666ZM16.042 13.958h2.916a.208.208 0 0 0 .209-.208v-10a.833.833 0 0 0-.834-.833h-1.666a.833.833 0 0 0-.834.833v10a.206.206 0 0 0 .209.208ZM.833 16.667h18.334a.833.833 0 0 0 0-1.667H.833a.833.833 0 1 0 0 1.667Z"
    />
  </svg>
);

export default SvgChart;
