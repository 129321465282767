import React from 'react';
import { H2 } from '@insights-ltd/design-library/components';
import { CircularProgress, Fade, styled } from '@mui/material';

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'inline',
})<{ inline?: boolean }>(({ inline = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: inline ? '300px' : 'calc(100vh - 155px)',
}));

const FullScreenSpinner = ({
  message,
  inline,
}: {
  message?: string;
  inline?: boolean;
}) => (
  <Fade in style={{ transitionDelay: '800ms' }} unmountOnExit>
    <StyledDiv inline={inline}>
      <CircularProgress size={50} color="secondary" />
      {message && <H2 variant="body">{message}</H2>}
    </StyledDiv>
  </Fade>
);

export default FullScreenSpinner;
