import useHistoryState from 'components/hooks/useHistoryState';

const useTabHistory = <T extends string>(): {
  tabValue?: T;
  persistTab: (newData: T) => void;
} => {
  const [tabValue, persistActiveTabToHistory] = useHistoryState<T>('activeTab');
  return {
    tabValue,
    persistTab: persistActiveTabToHistory,
  };
};

export default useTabHistory;
