import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgOrganisation = ({ title, ...props }: SvgComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -2 38 28" {...props}>
    {title ? <title>{title}</title> : null}
    <path d="M32 1a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h1a.667.667 0 0 1 .667.667v22.666a.667.667 0 0 0 .666.667H12a.666.666 0 0 0 .667-.667V22a3.333 3.333 0 1 1 6.666 0v3.333A.667.667 0 0 0 20 26h8.667a.667.667 0 0 0 .666-.667V2.667A.667.667 0 0 1 30 2h1a1 1 0 0 0 1-1ZM12.333 16H7a1 1 0 0 1 0-2h5.333a1 1 0 0 1 0 2Zm0-5.333H7a1 1 0 0 1 0-2h5.333a1 1 0 1 1 0 2Zm0-5.334H7a1 1 0 1 1 0-2h5.333a1 1 0 1 1 0 2ZM25 16h-5.333a1 1 0 0 1 0-2H25a1 1 0 0 1 0 2Zm0-5.333h-5.333a1 1 0 0 1 0-2H25a1 1 0 1 1 0 2Zm1-6.334a1 1 0 0 1-1 1h-5.333a1 1 0 1 1 0-2H25a1 1 0 0 1 1 1Z" />
  </svg>
);

export default SvgOrganisation;
