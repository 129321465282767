import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  styled,
} from '@mui/material';
import PermissionGuard from 'components/PermissionGuard';
import { H1, P } from '@insights-ltd/design-library/components';
import React from 'react';
import DefaultIcon from 'components/Svgs/icons/Organisation';
import { Link as RouterLink } from 'react-router-dom';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { useTranslation } from 'react-i18next';
import { Group } from 'api';
import { Role } from 'types/types';
import { isSuperAdmin } from 'utils/role';
import ParentGroupLink from './ParentGroupLink';

type OrganisationType = 'master' | 'customer' | 'partner';
type OrganisationParent = Group;

type Props = {
  id: string;
  name: string;
  showBanner: boolean;
  parent?: OrganisationParent;
  supportedRoles?: Role[];
};

interface StyledDivProps {
  orgType?: OrganisationType;
}

const StyledSpan = styled('span')({ marginTop: '8px' });

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'orgType',
})<StyledDivProps>(({ theme, orgType }) => ({
  width: '100%',
  height: theme.spacing(spacingSizeMap.L),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: `-${theme.spacing(spacingSizeMap.L)}`,
  borderRadius: '0px 0px 4px 4px',
  borderBottom: `1px solid ${theme.palette.grey[500]}`,
  borderLeft: `1px solid ${theme.palette.grey[500]}`,
  borderRight: `1px solid ${theme.palette.grey[500]}`,
  fontWeight: theme.typography.fontWeightBold,
  color: orgType === 'master' ? theme.palette.blue.dark : undefined,
  backgroundColor: orgType === 'master' ? theme.palette.blue.light : undefined,
}));

const StyledDefaultIcon = styled(DefaultIcon)(({ theme }) => ({
  position: 'relative',
  top: '24px',
  width: '48px',
  height: '48px',
  padding: '5px 4px',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: '5px',
  fill: theme.palette.grey[500],
}));

const StyledH1 = styled(H1)(({ theme }) => ({
  margin: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
    spacingSizeMap.S,
  )} 0  !important`,
  wordBreak: 'break-word',
}));

const StyledP = styled(P)(({ theme }) => ({
  color: `${theme.palette.blue.main} !important`,
  '&:hover': {
    color: `${theme.palette.blue.dark} !important`,
  },
  '&:active': {
    color: `${theme.palette.text.primary} !important`,
  },
}));

const OrganisationCard: React.FC<Props> = ({
  id,
  name,
  showBanner,
  parent,
  supportedRoles,
}) => {
  const { t } = useTranslation();
  const typeTranslations: Record<OrganisationType, string> = {
    master: t('ui.event-management.organisations.type.master'),
    customer: t('ui.event-management.organisations.type.customer'),
    partner: t('ui.event-management.organisations.type.partner'),
  };

  const orgType: OrganisationType | null = isSuperAdmin(supportedRoles)
    ? 'master'
    : null;

  return (
    <Grid item xs={12} md={4} key={id}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'flex-start',
          textAlign: 'center',
          marginTop: 0,
        }}
      >
        <StyledDefaultIcon
          title={t('ui.event-management.organisations.alt.icon')}
        />
      </Box>
      <Card
        variant="outlined"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100% - 24px)',
          padding: `0 ${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
            spacingSizeMap.S,
          )} ${theme.spacing(spacingSizeMap.XS)}`,
          borderColor: theme.palette.grey[500],
        })}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
              textAlign: 'center',
              marginTop: 0,
            }}
            mt={2}
          >
            <StyledH1 variant="h3">{name}</StyledH1>
            {parent ? (
              <>
                <ParentGroupLink
                  group={parent}
                  sx={{ lineHeight: '28px !important', margin: '0.5rem 0 0' }}
                />
                <Divider
                  sx={(theme) => ({
                    border: `0.5px solid ${theme.palette.grey[400]}`,
                    width: '5rem',
                    margin: `12px 0 0`,
                  })}
                />
              </>
            ) : null}
            <StyledSpan>
              <Link
                component={RouterLink}
                to={`/organisations/${id}`}
                state={{ activeTab: 'practitioners' }}
              >
                <StyledP
                  variant="body-bold"
                  sx={{ lineHeight: '28px !important' }}
                >
                  {t('ui.event-management.organisations.link.users')}
                </StyledP>
              </Link>
              <Link
                component={RouterLink}
                to={`/organisations/${id}`}
                state={{ activeTab: 'upcomingEvents' }}
              >
                <StyledP
                  variant="body-bold"
                  sx={{ lineHeight: '28px !important' }}
                >
                  {t('ui.event-management.organisations.link.experiences')}
                </StyledP>
              </Link>
              <PermissionGuard
                requiredPermissions={[
                  {
                    action: 'Organisation_Add_User',
                    scope: 'Local',
                  },
                ]}
              >
                <Link
                  component={RouterLink}
                  to={`/organisations/${id}`}
                  state={{ activeTab: 'invites' }}
                >
                  <StyledP
                    variant="body-bold"
                    sx={{ lineHeight: '28px !important' }}
                  >
                    {t('ui.event-management.organisations.link.invites')}
                  </StyledP>
                </Link>
              </PermissionGuard>
            </StyledSpan>
          </Box>
        </CardContent>
      </Card>
      {showBanner && orgType ? (
        <StyledDiv orgType={orgType}>{typeTranslations[orgType]}</StyledDiv>
      ) : null}
    </Grid>
  );
};

export default OrganisationCard;
