import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type PaginationType =
  | 'page'
  | 'first'
  | 'last'
  | 'next'
  | 'previous'
  | 'start-ellipsis'
  | 'end-ellipsis';
const DEFAULT_ITEMS_PER_PAGE = 12;

export type Paginator<T> = {
  totalCount: number;
  pageNumber: number;
  pageCount: number;
  pagedItems: T[];
  handlePageChange: (page: number) => void;
  getPaginationAriaLabel: (
    type: PaginationType,
    page: number,
    selected: boolean,
  ) => string;
};

const useGetPaginationAriaLabel = (): ((
  type: PaginationType,
  page: number,
  selected: boolean,
) => string) => {
  const { t } = useTranslation();

  return (type: PaginationType, page: number, selected: boolean) => {
    if (type === 'page') {
      return t(
        selected
          ? 'ui.event-management.pagination.selected-page'
          : 'ui.event-management.pagination.unselected-page',
        { page },
      );
    }
    if (type === 'next') {
      return t('ui.event-management.pagination.next-page');
    }
    return '';
  };
};

const usePagination = <T>(
  items: T[],
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
): Paginator<T> => {
  const getPaginationAriaLabel = useGetPaginationAriaLabel();
  const [pageNumber, setPageNumber] = useState(1);

  const firstItem = (pageNumber - 1) * itemsPerPage;
  const lastItem = firstItem + itemsPerPage;
  const pagedItems = items.slice(firstItem, lastItem);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const totalCount = items.length;

  return {
    totalCount,
    pageNumber,
    pageCount,
    pagedItems,
    handlePageChange: setPageNumber,
    getPaginationAriaLabel,
  };
};

export default usePagination;
