import React from 'react';
import { SvgComponentProps } from './types';

const SvgWheel = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect width={48} height={48} fill="#E5F2F7" rx={4} />
    <path
      fill="#1673A3"
      d="M22.5 34.8A10.512 10.512 0 0 0 33 24.3a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 0-.5-.5 10.5 10.5 0 0 0 0 21Z"
    />
    <path
      fill="#1673A3"
      d="M25.5 10.8a.5.5 0 0 0-.5.5v9.5a1 1 0 0 0 1 1h9.5a.5.5 0 0 0 .5-.5 10.512 10.512 0 0 0-10.5-10.5Z"
    />
  </svg>
);

export default SvgWheel;
