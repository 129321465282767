import { PermissionScope } from 'permissions';

/**
 * Returns True if `currScope` is suffienct to meet the requirements of `requiredScope`.
 * @param currScope The current scope that will be tested against the required scope
 * @param requiredScope The required scope that will determine whether or not the currScope is sufficient
 * @returns
 */
const isSufficientScope = (
  currScope: PermissionScope,
  requiredScope: PermissionScope,
): boolean => {
  if (!['None', 'Self', 'Local', 'Global'].includes(currScope)) {
    throw new Error(`Unrecognized current scope. Got: ${currScope}`);
  }
  switch (requiredScope) {
    case 'Global':
      return currScope === 'Global';

    case 'Local':
      return currScope === 'Local' || currScope === 'Global';

    case 'Self':
      return (
        currScope === 'Self' || currScope === 'Local' || currScope === 'Global'
      );

    case 'None':
      return true;
    default:
      throw new Error(`Unrecognized required scope. Got: ${requiredScope}`);
  }
};

export default isSufficientScope;
