import React, { useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library/components';
import { InviteeBulkResult, useAddInvitees } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import ConfirmUploadDialog from './ConfirmUploadDialog';
import { BulkUploadInviteeData, processFile } from './bulkUploadUtils';
import BulkUploadStatusDialog from './BulkUploadStatusDialog';

const ACCEPTED_MIME_TYPES = [
  'text/comma-separated-values',
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
];

type BulkUploadProps = {
  eventId: string;
};

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.M),
  marginBottom: theme.spacing(spacingSizeMap.L),
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.XS),
  },
}));

const BulkUpload = ({ eventId }: BulkUploadProps) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const [inviteeList, setInviteeList] = useState<BulkUploadInviteeData[]>([]);
  const [confirmUploadOpen, setConfirmUploadOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [bulkResultModalOpen, setBulkResultModalOpen] =
    useState<boolean>(false);
  const [bulkResults, setBulkResults] = useState<InviteeBulkResult[]>([]);
  const { mutate: addInvitees, isPending } = useAddInvitees(eventId);
  const inputRef = useRef<HTMLInputElement>(null);

  const resetFormValues = () => {
    setFileName('');
    setInviteeList([]);
    const element = inputRef.current!;
    element.value = '';
  };

  const addAllInvitees = () =>
    addInvitees(
      inviteeList.filter(
        (invitee) => invitee.validEmail && invitee.validFullName,
      ),
      {
        onSuccess: (results) => {
          setConfirmUploadOpen(false);
          setBulkResultModalOpen(true);
          setBulkResults(results);
          resetFormValues();
        },
      },
    );

  const cancelUpload = () => {
    setConfirmUploadOpen(false);
    resetFormValues();
  };

  const handleSelectFile = (element: HTMLInputElement) => {
    if (!element || !element.files || !element.files[0]) {
      setErrorMessage(
        t('ui.event-management.events.upload-error.no-file-error'),
      );
      return;
    }
    const fileToLoad = element.files[0];
    const fileReader = new FileReader();

    if (!ACCEPTED_MIME_TYPES.includes(fileToLoad.type)) {
      setErrorMessage(
        t('ui.event-management.events.upload-error.file-error', {
          fileName: fileToLoad.name,
        }),
      );
      return;
    }

    setErrorMessage(null);
    setInviteeList([]);
    setFileName(fileToLoad.name);

    fileReader.onerror = () => {
      setErrorMessage(
        t('ui.event-management.events.upload-error.file-error', {
          fileName: fileToLoad.name,
        }),
      );
    };

    fileReader.onload = (fileLoadedEvent) => {
      try {
        const invitees = processFile(fileLoadedEvent);
        setInviteeList(invitees);
        setConfirmUploadOpen(true);
      } catch (error) {
        if (error instanceof Error) setErrorMessage(t(error.message));
      }
    };
    fileReader.readAsText(fileToLoad);
  };

  return (
    <Box
      mt={(theme) => theme.spacing(spacingSizeMap.M)}
      mb={(theme) => theme.spacing(spacingSizeMap.L)}
    >
      <Box mb={(theme) => theme.spacing(spacingSizeMap.M)} />
      <Grid container spacing={2} direction="column">
        <Grid sx={{ flexBasis: 'auto' }} item xs={12} md={8}>
          <P>{t('ui.event-management.events.bulk-upload.guidance')}</P>
        </Grid>
        <Grid sx={{ flexBasis: 'auto' }} item xs={12} md={4}>
          <input
            id="bulk-upload"
            ref={inputRef}
            data-testid="bulk-upload"
            name={t('ui.event-management.events.add-learners.tab.upload')}
            style={{ display: 'none' }}
            type="file"
            onChange={(evt) => handleSelectFile(evt.target)}
            accept=".csv"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="bulk-upload">
            <TextField
              value={fileName}
              variant="outlined"
              disabled
              type="text"
              fullWidth
              placeholder={t(
                'ui.event-management.events.add-learners.placeholder',
              )}
              InputProps={{
                startAdornment: (
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    sx={(theme) => ({
                      marginRight: theme.spacing(spacingSizeMap.XS),
                      adoredStart: {
                        paddingLeft: theme.spacing(spacingSizeMap.XS),
                      },
                    })}
                  >
                    {t('ui.event-management.events.add-learners.upload-file')}
                  </Button>
                ),
              }}
            />
          </label>
        </Grid>
      </Grid>
      <BulkUploadStatusDialog
        open={bulkResultModalOpen}
        onClose={() => setBulkResultModalOpen(false)}
        results={bulkResults}
      />
      <ConfirmUploadDialog
        open={confirmUploadOpen}
        onClose={cancelUpload}
        onConfirm={addAllInvitees}
        inviteeList={inviteeList}
        isLoading={isPending}
      />
      <StyledDiv>
        {errorMessage && (
          <Alert
            severity="error"
            icon={false}
            sx={(theme) => ({
              backgroundColor: `${theme.palette.error.light}`,
              color: `${theme.palette.error.dark}`,
            })}
          >
            <P variant="body-bold">{errorMessage}</P>
          </Alert>
        )}
      </StyledDiv>
    </Box>
  );
};

export default BulkUpload;
