import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { P, Span } from '@insights-ltd/design-library/components';
import { PhysicalLocation, RemoteLocation } from 'api';
import { styled } from '@mui/material';

const StyledP1 = styled(P)({
  textAlign: 'right',
});

const StyledP2 = styled(P)({
  textAlign: 'right',
  overflowWrap: 'anywhere',
});

export const RemoteDetails = ({ location }: { location: RemoteLocation }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledP1 variant="body-bold">
        {t('ui.event-management.events.location.type.remote-label')}
        {location.meetingLink && (
          <>
            &nbsp;-&nbsp;
            <Span variant="body-large" color="primary">
              <Link href={location.meetingLink}>
                {t('ui.event-management.events.location.meeting-link-label')}
              </Link>
            </Span>
          </>
        )}
      </StyledP1>
      <StyledP2 variant="body-bold" color="textSecondary">
        {location.details}
      </StyledP2>
    </>
  );
};

export const PhysicalDetails = ({
  location,
}: {
  location: PhysicalLocation;
}) => {
  const { addressLine1, addressLine2, city, postcode, state, country } =
    location;
  const line3 = `${city}, ${postcode}`;

  const addressLines = [
    addressLine1,
    addressLine2,
    line3,
    state,
    country,
  ].filter((line) => line.length > 0);

  return (
    <>
      {addressLines.map((line) => (
        <StyledP2 key={line} variant="body-bold">
          {line}
        </StyledP2>
      ))}
    </>
  );
};
