import { MutationStatus } from '@tanstack/react-query';
import { COLOUR_SCORE_COLOURS } from 'api/httpEntities';
import {
  Chapter,
  ChapterPositions,
  ColourEnergy,
  DatadogRumConfig,
  EventTypes,
  FrequencyType,
  InviteeOptions,
  InviteeStatuses,
  JobTitles,
  LicensedProductOption,
  ProductTypes,
  ProfileTypes,
  QueryKeyRoots,
  RolePositions,
} from 'types/types';

export const CHAPTER_POSITION: ChapterPositions = [
  'FOUNDATION',
  'MANAGEMENT',
  'EFFECTIVE_SELLING',
  'PERSONAL_ACHIEVEMENT',
  'INTERVIEW',
  'IDTL',
].reduce((acc, chapter, idx) => ({ ...acc, [chapter]: idx }), {});

export const CHAPTERS: Chapter[] = [
  {
    id: 'FOUNDATION',
    titleKey: 'ui.event-management.events.assign-chapters.foundation',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: true,
  },
  {
    id: 'MANAGEMENT',
    titleKey: 'ui.event-management.events.assign-chapters.management',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'EFFECTIVE_SELLING',
    titleKey: 'ui.event-management.events.assign-chapters.effective-selling',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'PERSONAL_ACHIEVEMENT',
    titleKey: 'ui.event-management.events.assign-chapters.personal-achievement',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'INTERVIEW',
    titleKey: 'ui.event-management.events.assign-chapters.interview',
    productTypes: [
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    mandatory: false,
  },
  {
    id: 'IDTL',
    titleKey:
      'ui.event-management.events.assign-chapters.insights-discovering-leadership-effectiveness',
    productTypes: ['DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE'],
    mandatory: true,
  },
  {
    id: 'DFC',
    titleKey:
      'ui.event-management.events.assign-chapters.insights-discovery-full-circle',
    productTypes: ['DISCOVERY_FULL_CIRCLE_PROFILE'],
    mandatory: true,
  },
];

export const COLOUR_ENERGY: ColourEnergy = {
  CoolBlue: 'ui.event-management.colour.blue.name',
  EarthGreen: 'ui.event-management.colour.green.name',
  FieryRed: 'ui.event-management.colour.red.name',
  SunshineYellow: 'ui.event-management.colour.yellow.name',
};

export const COUNTRIES: string[] = [
  'ui.common.country.albania',
  'ui.common.country.algeria',
  'ui.common.country.argentina',
  'ui.common.country.australia',
  'ui.common.country.austria',
  'ui.common.country.azerbaijan',
  'ui.common.country.bahrain',
  'ui.common.country.belarus',
  'ui.common.country.belgium',
  'ui.common.country.belize',
  'ui.common.country.bolivia',
  'ui.common.country.bosnia-and-herzegovina',
  'ui.common.country.brazil',
  'ui.common.country.brunei-darussalam',
  'ui.common.country.bulgaria',
  'ui.common.country.canada',
  'ui.common.country.chile',
  'ui.common.country.china',
  'ui.common.country.colombia',
  'ui.common.country.costa-rica',
  'ui.common.country.croatia',
  'ui.common.country.czech-republic',
  'ui.common.country.denmark',
  'ui.common.country.dominican-republic',
  'ui.common.country.ecuador',
  'ui.common.country.egypt',
  'ui.common.country.el-salvador',
  'ui.common.country.estonia',
  'ui.common.country.faroe-islands',
  'ui.common.country.finland',
  'ui.common.country.france',
  'ui.common.country.germany',
  'ui.common.country.greece',
  'ui.common.country.greenland',
  'ui.common.country.guatemala',
  'ui.common.country.honduras',
  'ui.common.country.hong-kong',
  'ui.common.country.hungary',
  'ui.common.country.iceland',
  'ui.common.country.india',
  'ui.common.country.indonesia',
  'ui.common.country.iran-islamic-republic-of',
  'ui.common.country.iraq',
  'ui.common.country.ireland',
  'ui.common.country.israel',
  'ui.common.country.italy',
  'ui.common.country.jamaica',
  'ui.common.country.japan',
  'ui.common.country.jordan',
  'ui.common.country.kazakhstan',
  'ui.common.country.kenya',
  'ui.common.country.korea-republic-of',
  'ui.common.country.kuwait',
  'ui.common.country.kyrgyzstan',
  'ui.common.country.latvia',
  'ui.common.country.lebanon',
  'ui.common.country.libyan-arab-jamahiriya',
  'ui.common.country.liechtenstein',
  'ui.common.country.lithuania',
  'ui.common.country.luxembourg',
  'ui.common.country.macao',
  'ui.common.country.macedonia',
  'ui.common.country.malaysia',
  'ui.common.country.malta',
  'ui.common.country.mexico',
  'ui.common.country.monaco',
  'ui.common.country.mongolia',
  'ui.common.country.morocco',
  'ui.common.country.netherlands',
  'ui.common.country.new-zealand',
  'ui.common.country.nicaragua',
  'ui.common.country.nigeria',
  'ui.common.country.norway',
  'ui.common.country.oman',
  'ui.common.country.pakistan',
  'ui.common.country.panama',
  'ui.common.country.paraguay',
  'ui.common.country.peru',
  'ui.common.country.philippines',
  'ui.common.country.poland',
  'ui.common.country.portugal',
  'ui.common.country.puerto-rico',
  'ui.common.country.qatar',
  'ui.common.country.romania',
  'ui.common.country.russian-federa.',
  'ui.common.country.rwanda',
  'ui.common.country.saudi-arabia',
  'ui.common.country.senegal',
  'ui.common.country.serbia-and-montenegro',
  'ui.common.country.singapore',
  'ui.common.country.slovakia',
  'ui.common.country.slovenia',
  'ui.common.country.south-africa',
  'ui.common.country.spain',
  'ui.common.country.sweden',
  'ui.common.country.switzerland',
  'ui.common.country.syrian-arab-republic',
  'ui.common.country.taiwan',
  'ui.common.country.tajikistan',
  'ui.common.country.thailand',
  'ui.common.country.trinidad-and-tobago',
  'ui.common.country.tunisia',
  'ui.common.country.turkey',
  'ui.common.country.turkmenistan',
  'ui.common.country.ukraine',
  'ui.common.country.united-arab-emirates',
  'ui.common.country.united-kingdom',
  'ui.common.country.united-states',
  'ui.common.country.uruguay',
  'ui.common.country.uzbekistan',
  'ui.common.country.venezuela',
  'ui.common.country.vietnam',
  'ui.common.country.yemen',
  'ui.common.country.zimbabwe',
];

export const CSV_ROW_LIMIT = 500;

export const DATADOG_RUM_CONFIGS: DatadogRumConfig[] = [
  {
    hostname: 'test.practitioner.insights.com',
    applicationId: '5ad9f839-08cc-4322-b89f-4bd1f89a019b',
    clientToken: 'pub9831b15b9248253e77a7d60af5e38aee',
  },
  {
    hostname: 'demo.practitioner.insights.com',
    applicationId: '3e218273-d1ce-44de-a9c8-f8484c70f13e',
    clientToken: 'pub886026adb6604abd6f925467caf26381',
  },
  {
    hostname: 'practitioner.insights.com',
    applicationId: 'bb107e60-e829-4a04-86b2-e9a987a219da',
    clientToken: 'pub2f1ec5c2e52e88004d250f4fe27f21ac',
  },
];

export const DEFAULT_ROUTE = '/dashboard';

export const EVENT_TYPES: EventTypes = {
  INSIGHTS_DISCOVERY_WORKSHOP: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovery-workshop',
    color: 'pink',
  },
  INSIGHTS_DISCOVERY_ACCREDITATION: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovery-accreditation',
    color: 'orange',
  },
  INSIGHTS_SELF_AWARE_LEADER: {
    textKey:
      'ui.event-management.events.create.event-type.insights-self-aware-leader',
    color: 'blue',
  },
  INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovering-leadership-effectiveness',
    color: 'blue',
  },
  INSIGHTS_DISCOVERY_FULL_CIRCLE: {
    textKey:
      'ui.event-management.events.create.event-type.insights-discovery-full-circle',
    color: 'darkOrange',
  },
  INSIGHTS_EXPLORE: {
    textKey: 'ui.event-management.events.create.event-type.insights-explore',
    color: 'blue',
  },
};

export const FREQUENCY_OPTIONS: FrequencyType[] = [
  {
    textKey: 'ui.event-management.events.create.frequency-type.daily',
    value: 'DAILY',
  },
  {
    textKey: 'ui.event-management.events.create.frequency-type.weekly',
    value: 'WEEKLY',
  },
  {
    textKey: 'ui.event-management.events.create.frequency-type.biweekly',
    value: 'BIWEEKLY',
  },
  {
    textKey: 'ui.event-management.events.create.frequency-type.never',
    value: 'NEVER',
  },
];

export const INSIGHTS_ORGANISATION_ID = 'insights';

export const INVITEE_OPTIONS: InviteeOptions = {
  ADDED: {
    textKey: 'ui.event-management.events.invitee.status.not-invited',
    color: 'grey',
  },
  INVITE_SENT: {
    textKey: 'ui.event-management.events.invitee.status.invited',
    color: 'orange',
  },
  READY_TO_PURCHASE: {
    textKey: 'ui.event-management.events.invitee.status.ready-to-purchase',
    color: 'pink',
  },
  PROFILE_GENERATED: {
    textKey: 'ui.event-management.events.invitee.status.profile-created',
    color: 'darkGreen',
  },
};

export const INVITEE_STATUSES: InviteeStatuses = {
  ...INVITEE_OPTIONS,
  INVITE_REQUESTED: {
    textKey: 'ui.event-management.events.invitee.status.not-invited',
    color: 'grey',
  },
};

export const JOB_TITLES: JobTitles = {
  CLIENT_PRACTITIONER: 'ui.event-management.job-title.client-practitioner.name',
  LICENSED_PRACTITIONER:
    'ui.event-management.job-title.licensed-practitioner.name',
  ADMIN: 'ui.event-management.job-title.admin.name',
  INSIGHTS_ADMIN: 'ui.event-management.job-title.super-admin.name',
};

export const ROLES = {
  SUPER_ADMIN: 'ui.event-management.users.roles.client-services',
  GROUP_MANAGER: 'ui.event-management.users.roles.group-manager',
  ADVANCED_USER: 'ui.event-management.users.roles.advanced-user',
  STANDARD: 'ui.event-management.users.roles.standard',
} as const;

export const ROLE_POSITION: RolePositions = [
  'SUPER_ADMIN',
  'GROUP_MANAGER',
  'ADVANCED_USER',
  'STANDARD',
].reduce((acc, role, idx) => ({ ...acc, [role]: idx }), {});

export const LICENSED_PRODUCTS: LicensedProductOption = {
  DISCOVERY_SELF_AWARE_PROFILE: {
    value: 'DISCOVERY_SELF_AWARE_PROFILE',
    label: 'ui.event-management.products.discovery-self-aware-profile',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_PERSONAL_PROFILE: {
    value: 'DISCOVERY_PERSONAL_PROFILE',
    label: 'ui.event-management.products.discovery-personal-profile',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE: {
    value: 'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
    label:
      'ui.event-management.products.discovery-transformational-leadership-profile',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_FULL_CIRCLE_PROFILE: {
    value: 'DISCOVERY_FULL_CIRCLE_PROFILE',
    label: 'ui.event-management.products.discovery-full-circle',
    disabled: true,
    defaultSelected: true,
  },
  DISCOVERY_EXPLORE_PROFILE: {
    value: 'DISCOVERY_EXPLORE_PROFILE',
    label: 'ui.event-management.products.explore-profile',
    disabled: true,
    defaultSelected: true,
  },
};

export const LICENSED_PRODUCTS_ABREV: ProfileTypes = {
  DISCOVERY_PERSONAL_PROFILE:
    'ui.event-management.product-types.insights-discovery.abrev',
  DISCOVERY_PROFILE:
    'ui.event-management.product-types.insights-discovery.abrev',
  IDTL_PROFILE:
    'ui.event-management.product-types.insights-discovery-transformational-leadership.abrev',
  DFC_PROFILE:
    'ui.event-management.product-types.insights-discovery-full-circle.abrev',
  EXPLORE_PROFILE: 'ui.event-management.product-types.insights-explore.abrev',
  API_PROFILE: 'ui.event-management.product-types.insights-api.abrev',
};

export const PRIVACY_POLICY_URL =
  'https://www.insights.com/general-privacy-notice/';

export const PRODUCT_TYPES: ProductTypes = {
  DISCOVERY_SELF_AWARE_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-self-aware-profile',
  DISCOVERY_PERSONAL_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-personal-profile',
  DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-transformational-leadership-profile',
  DISCOVERY_FULL_CIRCLE_PROFILE:
    'ui.event-management.events.create.product-type.insights-full-circle-profile',
  DISCOVERY_EXPLORE_PROFILE:
    'ui.event-management.events.create.product-type.insights-explore-profile',
};

export const PROFILE_TYPES: ProfileTypes = {
  DISCOVERY_PERSONAL_PROFILE:
    'ui.event-management.profiles.discovery-personal-profile',
  DISCOVERY_PROFILE: 'ui.event-management.profiles.discovery-personal-profile',
  IDTL_PROFILE:
    'ui.event-management.events.create.product-type.insights-discovery-transformational-leadership-profile',
  DFC_PROFILE:
    'ui.event-management.events.create.product-type.insights-full-circle-profile',
  EXPLORE_PROFILE:
    'ui.event-management.events.create.product-type.insights-explore-profile',
  API_PROFILE: 'ui.event-management.profiles.api-profile',
};

export const QUERY_KEY_ROOTS: QueryKeyRoots = {
  EVENT_DATA: 'eventData',
  EVENTS_DATA: 'eventsData',
  INVITEES_DATA: 'inviteesData',
  EVENT_TEAM_SCORES_DATA: 'eventTeamScoresData',
  EVENT_REMINDER_DATA: 'eventReminderData',
  PRACTITIONER_DATA: 'practitionerData',
  PRACTITIONERS_DATA: 'practitionersData',
  PRACTITIONER_CANCELLED_EVENTS: 'practitionerCancelledEvents',
  PRACTITIONER_ORGANISATION_GROUP_DATA: 'PractitionerOrganisationGroupsData',
  OPEN_PRACTITIONER_INVITES_DATA: 'openPractitionerInvitesData',
  PRACTITIONERS_SUMMARY_DATA: 'practitionersSummaryData',
  MY_TRANSACTIONS_DATA: 'myTransactionsData',
  MY_LATEST_TRANSACTIONS_DATA: 'myLatestTransactionsData',
  PRACTITIONER_PRIMARY_WALLET_DATA: 'practitionerPrimaryWalletData',
  TEAM_DATA: 'teamData',
  TEAM_LEARNERS_DATA: 'teamLearnersData',
  TEAMS_DATA: 'teamsData',
  MY_TEAMS_DATA: 'myTeamsData',
  TEAM_SCORES_DATA: 'teamScoresData',
  ORGANISATIONS_DATA: 'organisationsData',
  ORGANISATION_DATA: 'organisationData',
  ORGANISATION_EVENT_DATA: 'organisationEventsData',
  ORGANISATION_EVALUATOR_LINKS: 'organisationEvaluatorLinks',
  ORGANISATION_EVALUATOR_LINK_EVALUATIONS:
    'organisationEvaluatorLinkEvaluations',
  MY_ORGANISATION_DATA: 'myOrganisationData',
  LEARNER_DATA: 'learnerData',
  LEARNERS_DATA: 'learnersData',
  LEARNER_TEAM_DATA: 'learnerTeamData',
  PROFILES_DATA: 'profilesData',
  EVALUATOR_PROFILES: 'evaluatorProfiles',
  ORGANISATION_GROUP_DATA: 'organisationGroupData',
  ORGANISATION_GROUPS_DATA: 'organisationGroupsData',
  ORGANISATIONS_ORGANISATION_GROUP_DATA: 'organisationsOrganisationGroupsData',
  CONTRIBUTORS_DATA: 'contributorsData',
  TEAM_WHEEL_SUMMARY: 'teamWheelSummary',
  SUPPORTED_ROLES_DATA: 'supportedRolesData',
  TEAM_COLOUR_COUNTS: 'teamColourCounts',
};

export const COLOUR_SCORE_COLOUR_POSITION: Record<string, number> =
  COLOUR_SCORE_COLOURS.reduce(
    (acc, colour, idx) => ({
      ...acc,
      [colour]: idx,
    }),
    {},
  );

export const TIMEZONES: string[] = [
  'GMT',
  'GMT+01:00',
  'GMT+02:00',
  'GMT+03:00',
  'GMT+03:30',
  'GMT+04:00',
  'GMT+05:00',
  'GMT+05:30',
  'GMT+06:00',
  'GMT+07:00',
  'GMT+08:00',
  'GMT+09:00',
  'GMT+09:30',
  'GMT+10:00',
  'GMT+11:00',
  'GMT+12:00',
  'GMT-11:00',
  'GMT-10:00',
  'GMT-09:00',
  'GMT-08:00',
  'GMT-07:00',
  'GMT-06:00',
  'GMT-05:00',
  'GMT-04:00',
  'GMT-03:00',
  'GMT-02:00',
  'GMT-01:00',
];

export const VALID_FULL_NAME_REGEX = /.+/;
export const VALID_EMAIL_REGEX = /^[!-~]+@\S+\.[a-zA-Z0-9]+$/;
export const NO_SPECIAL_CHARACTERS_REGEX = /^[^!#$%&()*+/:;<=>?@[\]^_`{|}~]*$/;
export const NUMERICAL_ONLY_REGEX = /^[0-9]+$/;

export const ARBITRARY_MAX_LENGTH = 100;

export const QUERY_STATUS: Record<Uppercase<MutationStatus>, MutationStatus> = {
  SUCCESS: 'success',
  PENDING: 'pending',
  ERROR: 'error',
  IDLE: 'idle',
};

export const TEAM_WHEEL_CENTER_COORDS = { x: 260, y: 260 };

export const TEAM_WHEEL_SCORE_THRESHOLD = 150;

export const MINIMUM_SEARCH_CHARACTER_LIMIT = 2;
