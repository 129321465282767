import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { H3, P } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type DashboardCardProps = {
  disabled?: boolean;
  href: string;
  Icon: React.FunctionComponent;
  textPrimary: string;
  textSecondary?: string;
  children?: ReactNode;
};

const DashboardCard = ({
  disabled = false,
  href,
  Icon,
  textPrimary,
  textSecondary,
  children,
}: DashboardCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        height: '100%',
        border: `1px solid ${theme.palette.grey[500]}`,
        borderBottom: `0.2em solid ${theme.palette.primary.main}`,
        opacity: disabled ? '0.5' : 'undefined',
        pointerEvents: disabled ? 'none' : 'undefined',
      })}
    >
      <CardActionArea
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
            spacingSizeMap.L,
          )} ${theme.spacing(spacingSizeMap.L)} ${theme.spacing(
            spacingSizeMap.XS,
          )}`,
        })}
        component={Link}
        to={href}
      >
        <CardContent>
          <Icon />
          <Box
            mb={(theme) => theme.spacing(spacingSizeMap.S)}
            mt={(theme) => theme.spacing(spacingSizeMap.S)}
          >
            <H3 color="primary">{textPrimary}</H3>
          </Box>
          {textSecondary && (
            <P variant="body-bold" color="textSecondary">
              {textSecondary}
            </P>
          )}
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DashboardCard;
