import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {
  LearnerPurchaseRequest,
  TeamLearnerResponse,
  useGetTeam,
  usePurchaseProfilesForTeam,
  useDeleteTeam,
  useGetTeamLearners,
} from 'api';
import {
  AddRemoveModal,
  H2,
  P,
  Alert,
} from '@insights-ltd/design-library/components';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  FullScreenError as PageError,
  FullScreenSpinner as PageSpinner,
} from 'components/FullScreen';
import Notification from 'components/Notification';
import useClearHistoryState from 'components/hooks/useClearHistoryState';
import useSortBy from 'components/hooks/useSortBy';
import { Button, styled } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
import useLearnerOrgSearch from 'components/hooks/useLearnerOrgSearch';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { SearchFilterV2 as SearchFilter } from 'components/SearchFilter/SearchFilter';
import { EvaluatorSearchFilter } from 'api/evaluatorLinks/evaluatorLinksApiTypes';
import PurchasePage from 'components/PurchasePage/PurchasePage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useQueryClient } from '@tanstack/react-query';
import Helmet from 'react-helmet';
import { useDebounceValue } from 'components/hooks/useDebouncedValue';
import {
  getSearchResultMessage,
  SearchResultsMessages,
} from 'utils/getSearchResultMessage';
import SearchResultInfo from 'components/SearchResultInfo/SearchResultInfo';
import { MINIMUM_SEARCH_CHARACTER_LIMIT } from 'variables';
import { TeamHeader, useAddRemoveModal } from './Team.helper';
import Actions from './Actions';
import LearnersList from './LearnersList/LearnersList';
import { TransformedLearnerResponse } from './LearnersList/learnerType';
import FullHeight from '../../components/FullHeight';
import LearnerSelectionBar from '../CompletedEvaluators/LearnerSelectionBar/LearnerSelectionBar';

type TeamProps = {
  createSuccess?: boolean;
};

const StyledCount = styled('span', {
  shouldForwardProp: (prop) => prop !== 'filteredResults',
})<{ filteredResults: number }>(({ filteredResults = 0, theme }) => ({
  padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
  color: theme.palette.grey[100],
  backgroundColor:
    filteredResults > 0 ? theme.palette.pink.main : theme.palette.grey[500],
  borderRadius: '4px',
}));

const StyledSuccessMsgBox = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
    spacingSizeMap.XS,
  )} ${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(spacingSizeMap.S)}`,
  background: theme.palette.primary.light,
  borderRadius: '4px',
}));

const StyledSuccessMsg = styled(P)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const transformTeamLearners = (
  learners: TeamLearnerResponse[],
): (TeamLearnerResponse & { learnerId: string })[] =>
  learners.map(({ id, latestEvaluation, latestProfile, ...rest }) => {
    return {
      ...rest,
      latestEvaluation,
      latestProfile,
      id: latestEvaluation?.id ?? id,
      learnerId: id,
    };
  });

const Team = ({ createSuccess = false }: TeamProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { state, pathname } = useLocation();
  const removed = state?.remove;
  useClearHistoryState(removed);
  const { teamId } = useParams<{ teamId: string }>();

  const [checkedItems, setCheckedItems] = useState<{
    [id: string]: TransformedLearnerResponse;
  }>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [filter, setFilter] = useState<EvaluatorSearchFilter>('ALL');

  const searchFilterRef = useRef<HTMLInputElement>(null);

  const debouncedSearchTerm = useDebounceValue(searchTerm);
  const isPurchaseRoute = pathname.includes('purchase-profiles');
  const parentLink = `/teams/${teamId}`;

  const resetSearchCriteria = async () => {
    setSearchTerm('');
    setFilter('ALL');
  };

  const onPageChange = ({ lastPage }: { lastPage?: boolean }) => {
    setIsLastPage(!!lastPage);
  };

  const focusSearchField = () => {
    searchFilterRef.current?.focus();
  };

  const {
    mutate,
    error,
    isError: isPurchaseError,
    isPending: isPurchaseLoading,
    reset: resetMutate,
  } = usePurchaseProfilesForTeam(queryClient);

  const {
    isPending: isDeleting,
    reset: resetDelete,
    mutate: deleteTeam,
  } = useDeleteTeam(queryClient);
  const {
    isPending,
    isError,
    data: team,
    refetch,
  } = useGetTeam(teamId || '', { enabled: !isDeleting });

  const {
    isError: isLearnerError,
    data: { learners: rawTeamLearners, limitExceeded } = {
      learners: [],
      limitExceeded: false,
    },
    isLoading,
  } = useGetTeamLearners(
    {
      teamId: teamId ?? '',
      searchTerm: debouncedSearchTerm,
      purchaseStatus: filter,
    },
    { enabled: !!teamId },
  );

  // This was added to filter DFC contributors please remove after https://insightsgroup.atlassian.net/browse/NP-7639 is completed.
  const teamLearners = rawTeamLearners?.filter(
    ({ latestEvaluation }) => !!latestEvaluation,
  );

  const teamOrgIds = team?.organisationContext?.organisations.map(
    ({ id }) => id,
  ) ?? [''];

  const filterOptions: { label: string; value: EvaluatorSearchFilter }[] = [
    {
      label: t(
        'ui.event-management.evaluator-links.completed-evaluators.search-filter.label.all',
      ),
      value: 'ALL',
    },
    {
      label: t(
        'ui.event-management.evaluator-links.completed-evaluators.search-filter.label.purchased',
      ),
      value: 'PURCHASED',
    },
    {
      label: t(
        'ui.event-management.evaluator-links.completed-evaluators.search-filter.label.unpurchased',
      ),
      value: 'UNPURCHASED',
    },
  ];
  const filterProps = {
    filterOptions,
    initialSearchFilter: filter,
    handleFilterChange: (value: EvaluatorSearchFilter) => {
      setFilter(value);
    },
  };

  const filteredItems = transformTeamLearners(teamLearners);
  const sortedItems = useSortBy(filteredItems ?? [], 'fullName');
  const {
    data: { learners },
    handleLearnerInputChange,
    clearSearch,
    setSearchOrganisation,
    searchInputValue,
  } = useLearnerOrgSearch(team);
  const filteredResults = sortedItems;
  const { learnerCount: teamLearnerCount = 0 } = team || {
    organisation: { learnerCount: 0 },
  };

  const availableLearnerCount = team?.organisationContext?.distinctLearnerCount
    ? team.organisationContext.distinctLearnerCount - teamLearnerCount
    : 0;

  const {
    onAdd,
    onRemove,
    availableItems,
    itemsInGroup,
    onClose,
    isOpen,
    toggleAddRemoveModal,
    translations,
    onAddAll,
    onRemoveAll,
    isAddingAll,
    isError: isAddRemoveError,
  } = useAddRemoveModal(
    team,
    learners,
    teamLearners,
    refetch,
    searchInputValue,
    availableLearnerCount,
  );

  const evaluationIds = Object.keys(checkedItems);
  const title =
    searchTerm.length < MINIMUM_SEARCH_CHARACTER_LIMIT
      ? t('ui.event-management.learners.teams.learners')
      : t('ui.event-management.learners.teams.learner-results');
  const pageTitle = t(
    'ui.event-management.learners.purchase-profiles.page-title',
  );

  const { learnerName, teamName } = removed ?? {};
  const notification = removed
    ? t('ui.event-management.learners.teams.delete-learner-success', {
        learnerName,
        teamName,
      })
    : null;

  const removeCheckedItems = (removeItems: string[]) => {
    const checkedItemsMap = { ...checkedItems };
    removeItems.forEach((id) => {
      delete checkedItemsMap[id];
    });
    setCheckedItems(checkedItemsMap);

    if (Object.keys(checkedItemsMap).length === 0 && isPurchaseRoute) {
      navigate(parentLink);
    }
  };

  const handlePurchase = (
    requestData: LearnerPurchaseRequest,
    onSuccess: () => void,
    onFailure: () => void,
  ) =>
    mutate(
      {
        teamId: teamId!,
        request: requestData,
      },
      {
        onSuccess: () => {
          onSuccess();
          removeCheckedItems(evaluationIds);
          navigate(`/teams/${teamId}`);
          refetch().then();
        },
        onError: () => {
          onFailure();
        },
      },
    );

  const handleConfirmDelete = (onError: () => void, onSettled: () => void) =>
    deleteTeam(team!.id, {
      onSuccess: () =>
        navigate('/learners', {
          state: {
            activeTab: 'teams',
            deletedTeam: team!.name,
          },
        }),
      onError,
      onSettled,
    });

  if (isPending) {
    return <PageSpinner message={t('ui.event-management.loading')} />;
  }

  if (isError || isLearnerError) {
    return (
      <PageError
        message={t('ui.event-management.learners.error-loading-teams')}
      />
    );
  }

  const teamLinkCrumb: Record<string, string> = {
    '/': t('ui.event-management.events.nav.home'),
    '/Learners & teams': t('ui.event-management.events.nav.learners-and-teams'),
    [parentLink]: team.name,
  };

  const searchResultsMessages: SearchResultsMessages = {
    lastPageKey: 'ui.event-management.learners.result.limit-exceeded.last-page',
    searchTermKey:
      'ui.event-management.learners.result.limit-exceeded.search-term',
    defaultKey: 'ui.event-management.learners.result.limit-exceeded',
  };

  const searchResultMessage = getSearchResultMessage(
    searchResultsMessages,
    isLastPage,
    searchTerm,
    MINIMUM_SEARCH_CHARACTER_LIMIT,
  );

  const learnerIds = Object.keys(checkedItems).map(
    (key) => checkedItems[key].learnerId,
  );

  return (
    <>
      <Helmet>
        <title>{team.name}</title>
      </Helmet>
      <FullHeight
        sx={{
          backgroundColor: isPurchaseRoute ? 'white' : 'white | grey',
          paddingRight: '0 !important',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'calc(100% - 100px)',
            paddingRight: '0 !important',
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              marginBottom: !evaluationIds.length ? '0 !important' : '4rem',
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
              height: '100%',
            }}
          >
            <PurchasePage
              backgroundColor={isPurchaseRoute ? 'white' : 'grey'}
              onPurchase={handlePurchase}
              title={pageTitle}
              indexRoute={
                <>
                  <Container
                    maxWidth={false}
                    sx={(theme) => ({ background: theme.palette.blue.light })}
                  >
                    <Container maxWidth="lg">
                      <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
                        <Breadcrumbs
                          crumbs={{
                            '/': t('ui.event-management.dashboard.home.title'),
                            '/learners': t(
                              'ui.event-management.learners-and-teams.title',
                            ),
                          }}
                          activeText={team!.name}
                        />
                      </Box>
                      <Box pb={(theme) => theme.spacing(spacingSizeMap.XS)} />
                      <Link
                        component={RouterLink}
                        to="/learners"
                        state={{ activeTab: 'teams' }}
                      >
                        <Box
                          alignItems="center"
                          display="flex"
                          mb=".5rem"
                          ml="-8px"
                        >
                          <ChevronLeftIcon />
                          <P variant="body-bold" color="primary">
                            {t('ui.event-management.learners.back-to-teams')}
                          </P>
                        </Box>
                      </Link>
                      <Box
                        display="flex"
                        alignItems="top"
                        justifyContent="space-between"
                      >
                        <Grid item md={7}>
                          <TeamHeader team={team} />
                        </Grid>
                        <Actions
                          team={team}
                          isDeleting={isDeleting}
                          onDelete={handleConfirmDelete}
                          reset={resetDelete}
                        />
                      </Box>
                      <Box pb={(theme) => theme.spacing(spacingSizeMap.L)} />
                      <Box pb={(theme) => theme.spacing(spacingSizeMap.L)} />
                    </Container>
                  </Container>
                  <Container maxWidth="lg">
                    <Box mt={-7}>
                      <SearchFilter
                        label={t('ui.event-management.list-filter.search')}
                        fieldName={t('ui.event-management.list-filter.by-name')}
                        placeholder={t(
                          'ui.event-management.learners.search.placeholder',
                        )}
                        inputState={searchTerm}
                        handleTermChange={setSearchTerm}
                        showButton={false}
                        ref={searchFilterRef}
                        minCharacters={2}
                        categoryOptions={filterProps.filterOptions}
                        handleFilterChange={filterProps.handleFilterChange}
                        initialSearchFilter={filterProps.initialSearchFilter}
                      />
                    </Box>
                  </Container>
                  {team.migrated && (
                    <Container maxWidth="lg">
                      <Box mt={1} mb={5} sx={{ maxWidth: '61%' }}>
                        <Alert kind="warning">
                          {t('ui.event-management.team.migrated-teams.warning')}
                        </Alert>
                      </Box>
                    </Container>
                  )}
                  {createSuccess && (
                    <Container maxWidth="lg">
                      <Grid item xs={12} md={8}>
                        <StyledSuccessMsgBox>
                          <StyledSuccessMsg>
                            {t(
                              'ui.event-management.learners.teams.create-team-success',
                              {
                                teamName: team!.name,
                              },
                            )}
                          </StyledSuccessMsg>
                        </StyledSuccessMsgBox>
                      </Grid>
                    </Container>
                  )}
                  <Container maxWidth="lg">
                    <Box mt={(theme) => theme.spacing(spacingSizeMap.M)} mb={0}>
                      {notification && <Notification message={notification} />}
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm="auto"
                          sx={{ paddingTop: '0 !important' }}
                        >
                          {!isLoading && (
                            <H2 variant="h3">
                              {title}
                              &nbsp;
                              <StyledCount
                                filteredResults={filteredResults?.length ?? 0}
                              >
                                {filteredResults?.length ?? 0}
                                {limitExceeded && '+'}
                              </StyledCount>
                            </H2>
                          )}
                        </Grid>
                        {!team.migrated && (
                          <Grid>
                            <Button
                              data-testid="add-remove-learners"
                              variant="contained"
                              disabled={false}
                              startIcon={<PlusIcon />}
                              onClick={() => {
                                resetSearchCriteria();
                                toggleAddRemoveModal();
                              }}
                            >
                              {t(
                                'ui.event-management.team.add-remove-modal.toggle-modal',
                              )}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    {limitExceeded && !isLoading && (
                      <Box
                        mt={(theme) => theme.spacing(spacingSizeMap.M)}
                        mb={1.5}
                      >
                        <SearchResultInfo
                          i18nKey={searchResultMessage}
                          onClick={focusSearchField}
                        />
                      </Box>
                    )}
                    {!isLoading ? (
                      <LearnersList
                        evaluationIds={evaluationIds}
                        teamId={teamId!}
                        learners={filteredResults!}
                        onDelete={() => refetch()}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        migrated={!!team.migrated}
                        type={team.organisationContext?.type}
                        onPageChange={onPageChange}
                        selectable={!team.migrated}
                      />
                    ) : (
                      <PageSpinner />
                    )}
                    <AddRemoveModal
                      onRemove={onRemove}
                      onAdd={onAdd}
                      onClose={onClose}
                      onAddAll={onAddAll}
                      onRemoveAll={onRemoveAll}
                      isAddLoading={isAddingAll}
                      availableItems={availableItems!}
                      itemsInGroup={itemsInGroup!}
                      isOpen={isOpen}
                      filterBy={['fullName', 'emailAddress']}
                      onSearchTermChange={(availableLearnerSearchTerm) => {
                        setSearchOrganisation(teamOrgIds[0]);
                        handleLearnerInputChange(availableLearnerSearchTerm);
                      }}
                      clearSearch={clearSearch}
                      translations={translations}
                      showAddAll={!!availableLearnerCount}
                      showRemoveAll={!!team.learnerCount}
                      showError={!!isAddRemoveError}
                    />
                  </Container>
                </>
              }
              breadcrumbs={teamLinkCrumb}
              organisationIds={teamOrgIds}
              removeCheckedItems={removeCheckedItems}
              parentLink={parentLink}
              evaluationIds={evaluationIds}
              onReset={resetMutate}
              isError={isPurchaseError}
              isPurchasing={isPurchaseLoading}
              error={error}
              type="Teams"
            />
          </Container>
          {evaluationIds.length > 0 && !isPurchaseRoute ? (
            <LearnerSelectionBar
              evaluationIds={evaluationIds}
              learnerIds={learnerIds}
              teamId={teamId}
              setCheckedItems={setCheckedItems}
            />
          ) : null}
        </Box>
      </FullHeight>
    </>
  );
};

export default Team;
