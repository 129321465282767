import React from 'react';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CrossIcon from '@mui/icons-material/Cancel';
import { Dialog, P } from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import { InviteeBulkResult, InviteeRequest, RequestError } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type Props = {
  open: boolean;
  onClose: () => void;
  results: InviteeBulkResult[];
};

const StatusRow = ({
  invitee,
  error,
}: {
  invitee: InviteeRequest;
  error?: RequestError;
}) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid container justifyContent="space-between" item xs={12}>
        <Grid item>
          <P color="textSecondary" variant="body-bold">
            {invitee.fullName}
          </P>
        </Grid>
        <Grid item>
          {error ? (
            <Grid container spacing={1}>
              <Grid item>
                <CrossIcon
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                  })}
                />
              </Grid>
              <Grid item>
                <P color="error" variant="body-bold">
                  {t(
                    'ui.event-management.events.bulk-upload.result-modal.not-added',
                  )}
                </P>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item>
                <CheckIcon
                  sx={(theme) => ({
                    color: theme.palette.success.main,
                  })}
                />
              </Grid>
              <Grid item>
                <P variant="body-bold">
                  {t(
                    'ui.event-management.events.bulk-upload.result-modal.added',
                  )}
                </P>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <P color="error">
            {t(
              error.getRequestErrorKeys(
                'ui.event-management.generic.error',
                {},
                {
                  NO_PROFILE_AVAILABLE:
                    'ui.event-management.events.bulk-upload.result-modal.error.idtl-no-profile',
                  EMAIL_ADDRESS_ALREADY_TAKEN:
                    'ui.event-management.events.bulk-upload.result-modal.error.email-already-in-use',
                  INVALID_EMAIL_ADDRESS:
                    'ui.event-management.events.create.error.value-email-required',
                },
              ),
            )}
          </P>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider
          sx={(theme) => ({
            marginTop: theme.spacing(spacingSizeMap.XS),
          })}
        />
      </Grid>
    </Grid>
  );
};

const BulkUploadStatusDialog = ({ open, onClose, results }: Props) => {
  const { t } = useTranslation();
  const successCount = results.reduce<number>(
    (acc, bulkResult) => acc + (bulkResult.type === 'SUCCESS' ? 1 : 0),
    0,
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('ui.event-management.events.bulk-upload.result-modal.title')}
      titleProps={{
        id: 'dialog-title',
      }}
      primaryButtonText={t(
        'ui.event-management.events.bulk-upload.result-modal.confirm',
      )}
      content={
        <Grid container spacing={2}>
          <Grid item>
            <P color="textSecondary">
              {t(
                'ui.event-management.events.bulk-upload.result-modal.message',
                {
                  successCount,
                  totalCount: results.length,
                },
              )}
            </P>
          </Grid>
          {results.map((bulkResult) => (
            <Grid item key={bulkResult.inviteeData.email} xs={12}>
              <StatusRow
                invitee={bulkResult.inviteeData}
                error={
                  bulkResult.type === 'FAILURE' ? bulkResult.error : undefined
                }
              />
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

export default BulkUploadStatusDialog;
