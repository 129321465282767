import React from 'react';
import { GroupResponse, GroupType } from 'api/httpEntities';
import { Box, Card, CardContent, Grid, Link, styled } from '@mui/material';
import GroupSvg from 'components/Svgs/icons/Group';
import { P } from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

type Props = { group: GroupResponse };

const StyledGroupSvg = styled(GroupSvg)(({ theme }) => ({
  fill: theme.palette.grey[500],
}));

const StyledP = styled(P)({});
const StyledDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  top: '24px',
  width: '48px',
  height: '48px',
  backgroundColor: theme.palette.common.white,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  display: 'flex',
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: '5px',
}));

const GroupCard = ({ group }: Props) => {
  const { id, name, type, organisations } = group;
  const { t } = useTranslation();

  const groupTypeLabel: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.group.label.customer'),
    PARTNER: t('ui.event-management.organisations.group.label.partner'),
  };

  return (
    <Grid
      data-testid={`group-card-${id}`}
      item
      xs={12}
      md={4}
      key={id}
      sx={{
        minHeight: '260px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: 0,
        }}
      >
        <StyledDiv>
          <StyledGroupSvg
            title={t('ui.event-management.organisations.group.alt.icon')}
          />
        </StyledDiv>
      </Box>
      <Card
        variant="outlined"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100% - 24px)',
          padding: '0',
          borderColor: theme.palette.grey[500],
        })}
      >
        <CardContent
          sx={{
            padding: '0 !important',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              backgroundColor:
                type === 'PARTNER'
                  ? theme.palette.green.light
                  : theme.palette.orange.light,
              width: '100%',
              minHeight: '107px',
              margin: '0',
              padding: '2rem 1rem 1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            })}
          >
            <Link
              component={RouterLink}
              to={`/groups/${id}`}
              sx={(theme) => ({
                width: '100%',
                fontSize: '1.5rem',
                lineHeight: '30px !important',
                fontWeight: theme.typography.fontWeightBold,
                color: `${theme.palette.blue.main} !important`,
                '&:hover': {
                  color: `${theme.palette.blue.dark} !important`,
                },
                '&:active': {
                  color: `${theme.palette.text.primary} !important`,
                },
                wordBreak: 'break-word',
              })}
            >
              {name}
            </Link>
            <StyledP
              sx={{ lineHeight: '28px !important' }}
              color="textSecondary"
              variant="body-bold"
            >
              {groupTypeLabel[type]}
            </StyledP>
          </Box>
          <Grid justifyContent="center" container direction="column">
            <StyledP
              variant="body-bold"
              color="primary"
              sx={{
                marginTop: '12px !important',
                lineHeight: '28px !important',
              }}
            >
              {t(
                organisations.length === 1
                  ? 'ui.event-management.organisations.group.organisation-count.singular'
                  : 'ui.event-management.organisations.group.organisation-count.plural',
                {
                  count: organisations.length,
                },
              )}
            </StyledP>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GroupCard;
