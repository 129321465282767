import { AllDialectOptions } from 'types/dialects';
import { DIALECT_CODES, DIALECT_MAP } from 'variables/dialects';

export const textKeyForDialect = (dialect: string) => {
  const selectedDialect = DIALECT_MAP.find(
    (d) => d.value.toLocaleLowerCase() === dialect.toLocaleLowerCase(),
  );
  if (!selectedDialect) throw new Error(`Dialect '${dialect}' not recognised`);
  return selectedDialect.textKey;
};

export const notEnglish = (dialect: AllDialectOptions) =>
  dialect !== DIALECT_CODES.ENGLISH;
