import { DateTime, Duration } from 'luxon';

const USER_CREATED_DATE_FORMAT = 'dd MMM yyyy';

export const isoToLocaleString = (isoDate: string, locale = 'en-GB') =>
  DateTime.fromISO(isoDate).toFormat(USER_CREATED_DATE_FORMAT, { locale });

export const isoToDuration = (retentionPeriod: string): Duration => {
  const duration = Duration.fromISO(retentionPeriod);

  return duration;
};
