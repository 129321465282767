import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import FormHeader from 'components/FormHeader';
import { PractitionersForm, Practitioner } from 'components/EventForms';
import EditFormNavigation from './EditFormNavigation';
import { EditProps } from './types';

const EditPractitioners = ({
  eventForm,
  onCancel,
  saveEvent,
  saving,
}: EditProps) => {
  const { t } = useTranslation();
  const onSubmit = (formData: Practitioner[]) => {
    saveEvent({ practitioners: formData });
  };
  return (
    <>
      <FormHeader
        icon={PersonIcon}
        title={t('ui.event-management.events.edit.practitioners.header')}
      />
      <PractitionersForm event={eventForm} onSubmit={onSubmit}>
        <EditFormNavigation onCancel={onCancel} saving={saving} />
      </PractitionersForm>
    </>
  );
};

export default EditPractitioners;
