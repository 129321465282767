import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '@insights-ltd/design-library/components';
import { DIALECT_CODES, DialectMap } from 'variables';
import { MenuItem, Select, styled } from '@mui/material';

const StyledSelect = styled(Select)(({ theme }) => ({
  height: '3rem',
  boxShadow: `inset 0 0 0 1px ${theme.palette.grey[600]}`,
  backgroundColor: theme.palette.grey[100],
  width: '100%',
  maxWidth: '30rem',
}));

const StyledDiv = styled('div')({
  marginLeft: '1rem',
  '@media screen and (max-width: 768px)': {
    marginLeft: '0',
  },
});

type Props = {
  dialects: DialectMap[];
  handleChange: any;
};

const LanguageSelector = ({ dialects, handleChange }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledDiv>
      <FormLabel
        label={t('ui.event-management.evaluator-links.name.select-language')}
        htmlFor=""
        sx={{ marginBottom: '4px' }}
      />
      <StyledSelect
        id="select-language"
        data-testid="language-select-container"
        onChange={(e) => handleChange(e.target.value)}
        defaultValue={DIALECT_CODES.ENGLISH}
        variant="outlined"
        inputProps={{ 'data-testid': 'language-select' }}
      >
        {dialects.map(({ textKey, value }) => (
          <MenuItem key={textKey} value={value}>
            {t(textKey)}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledDiv>
  );
};
export default LanguageSelector;
