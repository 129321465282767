import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgSearch = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.36 14.625a8.59 8.59 0 1 0 15.81-6.72 8.59 8.59 0 0 0-15.81 6.72v0ZM17.317 17.338l6.662 6.663"
    />
  </svg>
);

export default SvgSearch;
