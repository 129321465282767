import React from 'react';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation, Trans } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { P, Tag, Span } from '@insights-ltd/design-library/components';
import { formatDateStyle2 } from '@insights-ltd/design-library/utils';
import { Box, styled, useTheme } from '@mui/material';
import { Dialects, Pronoun } from 'types/dialects';
import PronounsDialectLabel from 'components/PronounsDialectLabel';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

export type DfcProps = {
  className?: string;
  id?: string;
  fullName?: string;
  email?: string;
  editMode: boolean;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  disabled?: boolean;
  pronoun?: Pronoun;
  dialect?: Dialects;
  showPronounsAndDialect?: boolean;
  profileLastSentAt?: string;
  showLastSharedDate?: boolean;
  contributors?: { contributorsCompleted: number; contributersTotal: number };
  showNewContributors?: boolean;
  newContributors?: number;
  showContributorsIncluded?: boolean;
  contributorsIncluded?: number;
  showCost?: boolean;
  cost?: number;
};

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  padding: '0 !important',
  borderTop: `2px solid ${theme.palette.blue.main}`,
  borderLeft: `2px solid ${theme.palette.blue.main}`,
  borderRight: `2px solid ${theme.palette.blue.main}`,
}));

const ReusedProfileCheck = styled(P)({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  svg: {
    marginLeft: '-2px',
  },
});

const StyledSpan = styled(Span)({});

interface LastSharedInfoProps {
  checked: boolean;
  disabled: boolean;
  editMode: boolean;
  cost: number;
}

const LastSharedInfo = styled('div', {
  shouldForwardProp: (prop) => prop !== 'checked' && prop !== 'editMode',
})<LastSharedInfoProps>(({ checked, disabled, editMode, cost, theme }) => {
  let background = theme.palette.grey[300];
  const disabledBackground = theme.palette.grey[200];
  let border = theme.palette.blue.main;

  if (editMode && checked) {
    border = theme.palette.blue.dark;
  }

  if (disabled) {
    background = disabledBackground;
    border = 'transparent !important';
  }

  const paddingValue =
    cost <= 0 || cost
      ? `1.5rem ${theme.spacing(spacingSizeMap.S)}`
      : `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
          spacingSizeMap.M,
        )}`;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    width: '100%',
    height: theme.spacing(spacingSizeMap.L),
    marginTop: -theme.spacing(spacingSizeMap.L),
    padding: paddingValue,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.875rem',
    backgroundColor: background,
    borderRight: `2px solid ${border}`,
    borderBottom: `2px solid ${border}`,
    borderLeft: `2px solid ${border}`,
    borderRadius: '0 0 4px 4px',
  };
});

const LastSharedDate = styled('span')({
  marginLeft: '5px',
});

const InviteeDFCCard = ({
  id,
  fullName,
  email,
  editMode,
  checked,
  setChecked,
  className,
  disabled = false,
  pronoun,
  dialect,
  showPronounsAndDialect,
  showLastSharedDate = false,
  profileLastSentAt = undefined,
  contributors,
  showContributorsIncluded = false,
  contributorsIncluded = NaN,
  showNewContributors = false,
  newContributors = NaN,
  showCost = false,
  cost = NaN,
}: DfcProps) => {
  const { t } = useTranslation();
  const globalTheme = useTheme();

  return (
    <>
      <StyledCard
        variant="outlined"
        sx={(theme) => {
          const disabledBackground = theme.palette.grey[200];
          let background;
          let border;
          let borderBottom;
          let borderRadius;
          let height;

          if (editMode && checked) {
            background = theme.palette.blue.light;
            border = theme.palette.blue.dark;
          }

          if (disabled) {
            background = disabledBackground;
            border = 'transparent !important';
          }

          if ((showLastSharedDate && profileLastSentAt) || showCost) {
            borderBottom = `0px`;
            borderRadius = '4px 4px 0px 0px';
            height = theme.spacing(spacingSizeMap.L * 8);
          } else {
            borderBottom = `2px solid ${theme.palette.blue.main}`;
            borderRadius = '4px';
            height = theme.spacing(spacingSizeMap.L * 9);
          }

          return {
            height,
            padding: `0 ${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
              spacingSizeMap.S,
            )} ${theme.spacing(spacingSizeMap.XS)}`,
            backgroundColor: background,
            borderColor: border,
            borderRadius,
            borderBottom,
          };
        }}
        className={className}
      >
        <CardContent
          sx={(theme) => ({
            paddingBottom: theme.spacing(spacingSizeMap.XS),
          })}
        >
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              <P variant="body-bold">{fullName}</P>
              <P
                color="textSecondary"
                aria-label={`invitee-card-label-email-${email}`}
              >
                {email}
              </P>
            </Grid>
            <Grid item>
              {editMode ? (
                <Checkbox
                  onChange={(e) => setChecked(e.target.checked)}
                  checked={checked}
                  name={id}
                  color="primary"
                  disabled={disabled}
                  inputProps={{
                    'aria-label': fullName,
                  }}
                />
              ) : (
                <Tag label="green" />
              )}
            </Grid>
          </Grid>
          {showPronounsAndDialect && pronoun && dialect && (
            <PronounsDialectLabel pronoun={pronoun} dialect={dialect} />
          )}
        </CardContent>
        <Divider />
        <CardContent>
          {contributors && contributors?.contributersTotal > 0 && (
            <P>
              {t('ui.event-management.events.download-profiles.dfc.completed', {
                readyToPurchase: contributors?.contributorsCompleted,
                totalContributors: contributors?.contributersTotal,
              })}
            </P>
          )}
          {showContributorsIncluded && (
            <P variant="body-bold">
              {t(
                'ui.event-management.events.download-profiles.dfc.included-contributors',
                { count: contributorsIncluded },
              )}
            </P>
          )}
          {showNewContributors && newContributors && (
            <ReusedProfileCheck
              variant="body-bold"
              sx={{
                color: `${globalTheme.palette.success.main} !important`,
              }}
            >
              {t(
                'ui.event-management.events.purchase-profiles.dfc.new-contributor',
                {
                  count: newContributors,
                },
              )}
            </ReusedProfileCheck>
          )}
        </CardContent>
        <Box mt={5} mb={5} />
      </StyledCard>
      {showCost && (
        <LastSharedInfo
          checked={checked}
          disabled={disabled}
          editMode={editMode}
          cost={cost}
        >
          {cost > 0 && (
            <LastSharedDate>
              <Trans
                i18nKey="ui.event-management.events.purchase-profiles.purchase-cost.bold"
                components={{ bold: <Span variant="body-bold" /> }}
                values={{
                  cost,
                }}
              />
            </LastSharedDate>
          )}
          {cost <= 0 && (
            <LastSharedDate>
              <StyledSpan
                variant="body-bold"
                color="textSecondary"
                sx={{ fontSize: '0.875rem !important' }}
              >
                {t(
                  'ui.event-management.events.purchase-profiles.purchase-no-cost.bold',
                )}
              </StyledSpan>{' '}
              <StyledSpan variant="body-bold" color="textPrimary">
                {t(
                  'ui.event-management.events.purchase-profiles.purchase-no-unit-cost.bold',
                  { cost },
                )}
              </StyledSpan>{' '}
              <StyledSpan variant="body-bold" color="textSecondary">
                {t(
                  'ui.event-management.events.purchase-profiles.purchase-profile-no-cost.bold',
                )}
              </StyledSpan>
            </LastSharedDate>
          )}
        </LastSharedInfo>
      )}
      {showLastSharedDate && profileLastSentAt && (
        <LastSharedInfo
          checked={checked}
          disabled={disabled}
          editMode={editMode}
          cost={cost}
        >
          {t('ui.event-management.invite-list.profile-last-shared', {
            profileLastSentAt: formatDateStyle2(profileLastSentAt),
          })}
        </LastSharedInfo>
      )}
    </>
  );
};

export default InviteeDFCCard;
