import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DataTable, H2, P, Tag } from '@insights-ltd/design-library/components';
import { Role } from 'types/types';
import { GroupResponse, PractitionerSummaryData } from 'api';
import useSearchFilter from 'components/hooks/useSearchFilter';
import SearchCount from 'components/SearchCount/SearchCount';
import LeadingColourEnergyIndicator from 'components/LeadingColourEnergyIndicator';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import SearchFilter from 'components/SearchFilter/SearchFilter';
import { isoToLocaleString } from 'utils/dates';
import LinkEmail from 'components/LinkEmail/LinkEmail';
import { roleDescription } from './helper';
import { InviteUserAction } from './InviteUserAction';

type OrganisationUsersProps = {
  practitioners: PractitionerSummaryData[];
  title: string;
  organisationId: string;
  organisationGroup: GroupResponse | null | undefined;
};

const PRACTITIONER_SEARCH_FIELD_NAME = 'fullName';
const PRACTITIONER_SEARCH_FIELD_EMAIL_ADDRESS = 'emailAddress';

const ListItemWrapperDiv = styled('div')(({ theme }) => ({
  '& > :first-of-type': {
    marginBottom: `${theme.spacing(spacingSizeMap.M)}`,
  },
  '& > * + *': {
    marginBottom: `${theme.spacing(spacingSizeMap.XS)} !important`,
  },
}));

const EmptyTextP = styled(P)(({ theme }) => ({
  marginTop: `${theme.spacing(spacingSizeMap.L)} !important`,
  textAlign: 'center',
}));

const sortByDate = (items: PractitionerSummaryData[]) =>
  items.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

const OrganisationPractitioners = ({
  practitioners,
  title,
  organisationId,
  organisationGroup,
}: OrganisationUsersProps) => {
  const { filteredItems, handleTermChange, searchTerm } = useSearchFilter(
    practitioners,
    [PRACTITIONER_SEARCH_FIELD_NAME, PRACTITIONER_SEARCH_FIELD_EMAIL_ADDRESS],
  );
  const sortedPractitioners = sortByDate(filteredItems!);

  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;

  const dataStructure = {
    fullName: t('ui.event-management.organisation.practitioners.list.name'),
    emailAddress: t(
      'ui.event-management.organisation.practitioners.list.email',
    ),
    roles: t('ui.event-management.organisation.practitioners.list.role'),
    createdAt: t(
      'ui.event-management.organisation.practitioners.list.date-created',
    ),
    totalEvents: t(
      'ui.event-management.organisation.practitioners.list.total-experiences',
    ),
  };

  const columnPredicate = (
    columnName: keyof PractitionerSummaryData,
    columnValue: string | number | Role[],
    props: Record<any, any>,
  ) => {
    switch (columnName) {
      case 'fullName':
        return (
          <Button
            color="primary"
            sx={() => ({
              justifyContent: 'flex-start',
              maxWidth: '15rem',
              paddingLeft: 0,
              textAlign: 'left',
              wordBreak: 'break-word',
              '& > :first-of-type': {
                marginLeft: `0 !important`,
              },
              padding: `0 !important`,
            })}
            component={RouterLink}
            to={`/organisations/${organisationId}/practitioners/${props.id}`}
          >
            <LeadingColourEnergyIndicator
              leadingColourEnergy={props.leadingColourEnergy}
            />
            {columnValue}
            {!props.enabled && (
              <Tag
                label={t('ui.event-management.access.no-access')}
                color="grey"
              />
            )}
          </Button>
        );
      case 'emailAddress':
        return <LinkEmail email={columnValue as string} />;
      case 'roles': {
        return t(roleDescription(columnValue as Role[]));
      }

      case 'createdAt':
        return <>{isoToLocaleString(columnValue as string, locale)}</>;
      default:
        return columnValue;
    }
  };

  return (
    <ListItemWrapperDiv>
      <SearchFilter
        label={t('ui.event-management.list-filter.search')}
        placeholder={t(
          'ui.event-management.organisation.practitioners.list.search.placeholder',
        )}
        fieldNameKey="ui.event-management.list-filter.by-name-email"
        handleTermChange={handleTermChange}
        inputState={searchTerm}
        showButton={false}
        allowEmptySearches={false}
      />
      <Grid container alignItems="baseline" justifyContent="space-between">
        <Grid item xs={12} sm="auto" sx={{ marginBottom: '10px !important' }}>
          <H2 variant="h3">
            {title}
            <SearchCount
              sx={{ marginLeft: '0.5rem' }}
              count={filteredItems!.length}
            />
          </H2>
        </Grid>

        <Grid item xs={12} sm="auto">
          <InviteUserAction
            organisationId={organisationId}
            organisationGroup={organisationGroup}
          />
        </Grid>
      </Grid>
      {sortedPractitioners.length ? (
        <DataTable
          items={sortedPractitioners}
          dataStructure={dataStructure}
          itemsPerPage={12}
          columnPredicate={columnPredicate}
        />
      ) : (
        <EmptyTextP variant="h3" color="textSecondary">
          {t(
            practitioners.length < 1
              ? 'ui.event-management.organisation.practitioners.list.empty'
              : 'ui.event-management.organisation.practitioners.list.filtered',
          )}
        </EmptyTextP>
      )}
    </ListItemWrapperDiv>
  );
};

export default OrganisationPractitioners;
