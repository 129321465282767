import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgAdd = ({ title, ...props }: SvgComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    {title ? <title>{title}</title> : null}
    <g
      fill="none"
      stroke="#1673a3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path strokeWidth={0.75} d="M.375 6h11.25M6 .375v11.25" />
    </g>
  </svg>
);

export default SvgAdd;
