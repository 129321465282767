import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import SentIllustration from 'components/Svgs/PaperPlane';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type DialogType = 'invite' | 'delete';

type Props = {
  open: boolean;
  onClose: () => void;
  dialogType: DialogType;
};

const textKeys: Record<DialogType, { [key: string]: string }> = {
  invite: {
    title: 'ui.event-management.invite-list.invite-sent-dialog.title',
    body: 'ui.event-management.invite-list.invite-sent-dialog.body',
    button: 'ui.event-management.invite-list.invite-sent-dialog.button',
  },
  delete: {
    title: 'ui.event-management.invite-list.invite-deleted-dialog.title',
    body: 'ui.event-management.invite-list.invite-deleted-dialog.body',
    button: 'ui.event-management.invite-list.invite-deleted-dialog.button',
  },
};

const PractitionerInvitedDialog = ({ open, onClose, dialogType }: Props) => {
  const { t } = useTranslation();
  const { title, body, button } = textKeys[dialogType];

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="practitioner-invited-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="practitioner-invited-dialog-title"
        sx={{ textAlign: 'center' }}
      >
        <Grid container direction="column" alignItems="center">
          <SentIllustration aria-hidden />
          <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
          {t(title)}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" align="center">
          {t(body)}
        </DialogContentText>
        <Button variant="contained" color="primary" fullWidth onClick={onClose}>
          {t(button)}
        </Button>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
      </DialogContent>
    </MuiDialog>
  );
};

export default PractitionerInvitedDialog;
