import React from 'react';
import { SvgComponentProps } from './types';

const SvgExclamation = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="#3C3C3C"
      d="M19.494 8.78 11.22.505a1.726 1.726 0 0 0-2.44 0L.504 8.78a1.726 1.726 0 0 0 0 2.441l8.274 8.274a1.726 1.726 0 0 0 2.441 0l8.274-8.274a1.726 1.726 0 0 0 0-2.441ZM9.195 5.18a.863.863 0 0 1 1.727 0v5.179a.863.863 0 1 1-1.727 0V5.18Zm.907 9.935h-.024a1.32 1.32 0 0 1-1.313-1.269 1.273 1.273 0 0 1 1.25-1.32h.024a1.318 1.318 0 0 1 1.313 1.268 1.273 1.273 0 0 1-1.25 1.32Z"
    />
  </svg>
);

export default SvgExclamation;
