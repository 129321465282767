import { ColourScoreWithPreference, ScoreCount } from 'api';
import { DotType, ScoreType, ScoresGroupingType } from 'types/types';
import { LabelType } from '../hooks/useWheelContext';
import { InitialledScore, dedupedInitialFromScore } from '../scoreUtils';
import {
  getColourFromScore,
  getTextColourFromScore,
  getQuadrantColourFromWheelPosition,
  getQuadrantTextColourFromWheelPosition,
} from './teamWheelUtils';
import { getKeyAndLabel } from './TeamWheelSummaryHelper.helper';

export type TeamWheelDotProps = {
  key: string;
  activeScore?: ColourScoreWithPreference;
  dotKey: string;
  wheelPosition: number;
  duplicateScore: number;
  duplicateNumber: number;
  label: string;
  colour: string;
  textColour: string;
};

export const getSummaryWheelProps = (
  wheelPositions: Record<number, ScoreCount>,
  scoreType: ScoreType,
  wheelContext: {
    dotType: DotType;
  },
): TeamWheelDotProps[] => {
  const { dotType } = wheelContext;
  return Object.keys(wheelPositions).map(
    (wheelPositionKey: string, index: number) => {
      const wheelPosition = parseInt(wheelPositionKey, 10);
      const currentWheelPotition =
        wheelPositions[
          wheelPositionKey as unknown as keyof typeof wheelPositions
        ];
      const { key, label } = getKeyAndLabel(
        currentWheelPotition,
        scoreType,
        index,
      );
      const duplicateNumber =
        scoreType === 'CONSCIOUS'
          ? currentWheelPotition.scoreCount.conscious
          : currentWheelPotition.scoreCount.lessConscious;
      const colour =
        dotType === '8-COLOUR'
          ? getColourFromScore(wheelPosition)
          : getQuadrantColourFromWheelPosition(wheelPosition);
      const textColour =
        dotType === '8-COLOUR'
          ? getTextColourFromScore(wheelPosition)
          : getQuadrantTextColourFromWheelPosition(wheelPosition);
      return {
        key,
        wheelPosition,
        label,
        dotKey: key,
        duplicateScore: 0,
        duplicateNumber,
        colour,
        textColour,
      };
    },
  );
};

export const getWheelProps = (
  scores: InitialledScore[],
  duplicateScoreMap: Record<string, number>,
  wheelContext: {
    scoreType: ScoreType;
    labelType: LabelType;
    scoresGroupingType: ScoresGroupingType;
    dotType: DotType;
  },
): TeamWheelDotProps[] => {
  const { scoreType, labelType, scoresGroupingType, dotType } = wheelContext;
  const showDuplicates =
    labelType === 'INITIALS' || scoresGroupingType === 'AGGREGATED';

  return scores.map((score: InitialledScore) => {
    const { conscious, lessConscious } = score.wheelPositions;
    const mapKey =
      scoresGroupingType === 'AGGREGATED'
        ? `${conscious}-${scoreType}`
        : `${score.initials}-${score.duplicateNumber}-${scoreType}`;
    const wheelPosition = scoreType === 'CONSCIOUS' ? conscious : lessConscious;
    const dedupedInitial = dedupedInitialFromScore(score);
    const duplicateScore = duplicateScoreMap[dedupedInitial];
    const label = showDuplicates ? score.initials : String(score.index + 1);
    const activeScore =
      scoreType === 'CONSCIOUS'
        ? score.colourScores.conscious
        : score.colourScores.lessConscious;
    const colour =
      dotType === '8-COLOUR'
        ? getColourFromScore(wheelPosition)
        : getQuadrantColourFromWheelPosition(wheelPosition);
    const textColour =
      dotType === '8-COLOUR'
        ? getTextColourFromScore(wheelPosition)
        : getQuadrantTextColourFromWheelPosition(wheelPosition);
    return {
      key: mapKey,
      dotKey: mapKey,
      wheelPosition,
      duplicateScore,
      label,
      activeScore,
      duplicateNumber: showDuplicates ? score.duplicateNumber : 0,
      colour,
      textColour,
    };
  });
};
