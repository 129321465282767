import {
  OrganisationResponse,
  useGetAllGroups,
  useGetAllOrganisations,
} from 'api';
import { useAuth } from 'contexts/AuthContext';
import { queryStatus } from 'utils/queryStatus';
import { isGroupManager } from 'utils/role';
import { FetchStatus, QueryStatus } from '@tanstack/react-query';

const useGetMyOrganisations = (
  { enabled } = { enabled: true },
): {
  allMyOrganisations: OrganisationResponse[] | undefined;
  hasMultiOrgs: boolean | undefined;
  status: QueryStatus;
  fetchStatus: FetchStatus;
  isLoading: boolean;
  hasMultiOrgsWithoutGroup: boolean;
} => {
  const { user } = useAuth();

  const {
    data: allMyOrganisations,
    status: allOrgsStatus,
    fetchStatus,
    isLoading,
  } = useGetAllOrganisations({ enabled });

  const groupEnabled =
    enabled && user?.permissions.Organisation_Group_ReadAll !== 'None';

  const { data: allGroups, status: groupsStatus } = useGetAllGroups({
    enabled: groupEnabled,
  });

  const allGroupStatus = groupEnabled ? groupsStatus : 'success';

  const responseStatus = queryStatus(allOrgsStatus, allGroupStatus);

  let hasMultiOrgs: boolean | undefined =
    user?.permissions.Organisation_Create === 'Global' ||
    (isGroupManager(user?.roles) &&
      user?.permissions.Organisation_Group_Create_Organisation_In_Group ===
        'Global') ||
    (allMyOrganisations && allMyOrganisations?.length > 1);
  const hasMultiOrgsWithoutGroup =
    user?.permissions.Organisation_Create === 'Global' ||
    !!(allMyOrganisations && allMyOrganisations?.length > 1);

  if (groupEnabled) {
    hasMultiOrgs = hasMultiOrgs || (allGroups && allGroups.length > 0);
  }

  return {
    allMyOrganisations,
    hasMultiOrgs,
    hasMultiOrgsWithoutGroup,
    status: responseStatus,
    fetchStatus,
    isLoading,
  };
};

export default useGetMyOrganisations;
