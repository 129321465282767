import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgHierarchy = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="#D46914"
      d="M17.083 11.917h-.032a.418.418 0 0 1-.376-.206l-4.047-6.878a.418.418 0 0 1-.015-.396c.198-.394.302-.83.303-1.27a2.917 2.917 0 0 0-5.833 0c.001.44.105.873.303 1.266a.417.417 0 0 1-.015.4l-4.048 6.878a.416.416 0 0 1-.375.206h-.032a2.916 2.916 0 1 0 2.614 4.191.417.417 0 0 1 .374-.233h8.19a.417.417 0 0 1 .375.233 2.916 2.916 0 1 0 2.614-4.191ZM5.903 13.79a.417.417 0 0 1-.373-.231 2.812 2.812 0 0 0-.297-.477.416.416 0 0 1-.029-.465l3.771-6.41a.417.417 0 0 1 .451-.196c.377.09.77.09 1.147 0a.417.417 0 0 1 .45.196l3.77 6.409a.416.416 0 0 1-.028.465 2.897 2.897 0 0 0-.296.474.417.417 0 0 1-.374.232l-8.192.003Z"
    />
  </svg>
);

export default SvgHierarchy;
