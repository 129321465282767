import React from 'react';
import { SvgComponentProps } from './types';

const SvgProfiles = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect width={48} height={48} fill="#FCE5F0" rx={4} />
    <path
      fill="#DE0072"
      d="M13.25 27.55h3.5a.248.248 0 0 0 .25-.25v-2a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v2a.248.248 0 0 0 .25.25ZM20 19.3a1 1 0 0 0-1 1v7a.248.248 0 0 0 .25.25h3.5a.248.248 0 0 0 .25-.25v-7a1 1 0 0 0-1-1h-2ZM26 21.3a1 1 0 0 0-1 1v5a.248.248 0 0 0 .25.25h3.5a.248.248 0 0 0 .25-.25v-5a1 1 0 0 0-1-1h-2ZM31.25 27.55h3.5a.248.248 0 0 0 .25-.25v-12a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v12a.248.248 0 0 0 .25.25ZM13 30.8h22a1 1 0 0 0 0-2H13a1 1 0 0 0 0 2Z"
    />
  </svg>
);

export default SvgProfiles;
