import { TeamLearnerResponse } from 'api';

export const learnersData: TeamLearnerResponse[] = [
  {
    id: 'learner-1-id',
    fullName: 'Homer Simpson',
    knownAs: 'Homer',
    primaryEmail: 'homer.simpson@insights.com',
    latestProfile: {
      id: 'learner-1-profile-id',
      createdAt: '2021-05-28T08:26:42.421606',
      leadingColourEnergy: 'FieryRed',
      profileType: 'DISCOVERY_PROFILE',
      colourScores: {
        conscious: {
          blue: 0,
          green: 130,
          yellow: 400,
          red: 600,
          preferred: ['yellow', 'red'],
        },
      },
      evaluation: {
        id: 'evaluation-1-id',
      },
      organisation: {
        id: 'test',
        name: 'Test organisation',
      },
    },
    latestEvaluation: {
      id: 'evaluation-1-id',
      submittedOn: '2021-05-28T08:26:42.421606',
      organisation: {
        id: 'test',
        name: 'Test organisation',
      },
    },
    preferredDialect: 'en-GB',
  },
  {
    id: 'learner-2-id',
    fullName: 'Seymour Skinner',
    knownAs: 'Seymour',
    primaryEmail: 'seymour.skinner@insights.com',
    latestProfile: {
      id: 'learner-2-profile-id',
      createdAt: '2021-05-29T12:14:35.361373',
      leadingColourEnergy: 'SunshineYellow',
      profileType: 'DISCOVERY_PROFILE',
      colourScores: {
        conscious: {
          blue: 0,
          green: 130,
          yellow: 400,
          red: 600,
          preferred: ['yellow', 'red'],
        },
      },
      evaluation: {
        id: 'evaluation-2-id',
      },
      organisation: {
        id: 'test',
        name: 'Test organisation',
      },
    },
    latestEvaluation: {
      id: 'evaluation-2-id',
      submittedOn: '2021-05-29T12:14:35.361373',
      organisation: {
        id: 'test',
        name: 'Test organisation',
      },
    },
    preferredDialect: 'en-GB',
  },
  {
    id: 'learner-3-id',
    fullName: 'Apu Nahasapeemapetilon',
    knownAs: 'Apu',
    primaryEmail: 'apu.nahasapeemapetilon@insights.com',
    latestEvaluation: {
      id: 'evaluation-3-id',
      submittedOn: '2021-05-29T12:14:35.361373',
      organisation: {
        id: 'test',
        name: 'Test organisation',
      },
    },
    preferredDialect: 'en-GB',
  },
];
