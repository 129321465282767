import { ROLES } from 'variables';

export type RoleOptions = { value: string; label: string }[];
export const getRolePermissionV2Options = (
  t: (key: string) => string,
  isInsightsUser: boolean | undefined,
): RoleOptions => {
  const options = [
    {
      value: 'STANDARD',
      label: t(ROLES.STANDARD),
    },
  ];
  if (isInsightsUser) {
    options.push({
      value: 'CLIENT_SERVICES',
      label: t(ROLES.SUPER_ADMIN),
    });
  } else {
    options.push(
      {
        value: 'ADVANCED_USER',
        label: t(ROLES.ADVANCED_USER),
      },
      {
        value: 'GROUP_MANAGER',
        label: t(ROLES.GROUP_MANAGER),
      },
    );
  }
  return options;
};
