import {
  P,
  SvgMultipleUsers2Bold,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, Grid, styled } from '@mui/material';
import { TeamResponse } from 'api';
import TeamsList from 'pages/Learners/TeamsTab/TeamsList';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledIcon = styled(SvgMultipleUsers2Bold)(({ theme }) => ({
  height: '20px',
  fill: theme.palette.common.black,
  color: theme.palette.text.primary,
}));
const StyledP = styled(P)(() => ({
  marginLeft: '0',
  paddingLeft: '0',
}));

export const LearnerTeamsList = ({
  learnerTeams,
}: {
  learnerTeams?: TeamResponse[];
}) => {
  const { t } = useTranslation();
  const heading = learnerTeams?.length
    ? t('ui.event-management.learner.teams', {
        count: learnerTeams?.length ?? 0,
      })
    : t('ui.event-management.learner.teams.section-heading.no-result');
  return (
    <Grid item container spacing={2} ml={0}>
      <Grid
        sx={() => ({
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          paddingLeft: '0 !important',
        })}
        item
        xs={12}
      >
        <StyledIcon />
        <Box mr={(theme) => theme.spacing(spacingSizeMap.S)} />
        <P variant="body-large">{heading}</P>
      </Grid>
      {learnerTeams?.length ? (
        <Grid item xs={12}>
          <TeamsList
            teamsResponse={learnerTeams}
            alwaysShowControls={false}
            allowDelete={false}
          />
        </Grid>
      ) : (
        <Grid sx={(theme) => ({ marginTop: theme.spacing(spacingSizeMap.M) })}>
          <StyledP color="textSecondary" variant="body-bold">
            {t('ui.event-management.learner.teams.no-result')}
          </StyledP>
        </Grid>
      )}
    </Grid>
  );
};
