import React from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

import { RequestError } from 'api';

const PurchaseError = ({
  isError,
  error,
  reset,
}: {
  isError: boolean;
  error: unknown | null;
  reset: () => void;
}) => {
  const { t } = useTranslation();

  if (isError && error instanceof RequestError) {
    const unitBalanceError = error.errorCodes.find(
      (code) => code === 'INSUFFICIENT_UNITS',
    );

    return (
      <Snackbar open={isError} autoHideDuration={6000} onClose={reset}>
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          <AlertTitle>
            {unitBalanceError ? (
              <>
                {t(
                  'ui.event-management.events.purchase-profiles.error-balance',
                )}{' '}
                {t(
                  'ui.event-management.events.purchase-profiles.error-balance-action-link',
                )}
              </>
            ) : (
              <>
                {t(
                  'ui.event-management.events.purchase-profiles.purchase-error',
                )}
              </>
            )}
          </AlertTitle>
        </Alert>
      </Snackbar>
    );
  }
  return null;
};

export default PurchaseError;
