import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, styled } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import {
  H1,
  H2,
  P,
  RadioGroupControl,
} from '@insights-ltd/design-library/components';
import InviteeCard from 'components/InviteeCard';
import InviteeDFCCard from 'components/InviteeCard/InviteeDFCCard';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import SelectDialectControl from 'components/SelectDialectControl';
import Divider from '@mui/material/Divider';
import { experienceSupportsChapters } from 'domain/event';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import DistributionMethodDialog from 'components/DistributionMethodDialog';
import EmailProgressDialog from 'components/EmailProgressDialog';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import BackButton from 'components/BackButton/BackButton';
import { backButtonStyles } from 'components/layout/BreadcrumbLayout/BreadcrumbLayout';
import DownloadingProfilesDialog from 'components/DownloadingToS3Dialog/DownloadingToS3Dialog';
import { AnonymisationType } from 'types/types';
import { DIALECT_CODES } from 'variables';
import { notEnglish } from 'utils/languageUtils';
import { disabledDfcLearnerPronounProfile } from 'utils/evaluatorUtils';
import DownloadSelectionBar from './DownloadSelectionBar';
import ErrorFeedbackDialog from './ErrorFeedbackDialog';
import PurchaseSelectionBar from './PurchaseSelectionBar';
import PurchaseError from './PurchaseError';
import useDownloadProfiles from './useDownloadProfiles';

interface StyledFullHeightProps {
  isPurchaseDisabled?: boolean;
  isDownloadDisabled?: boolean;
}

interface StyledInviteeCardProps {
  isPurchaseDisabled?: boolean;
  isDownloadDisabled?: boolean;
}

const StyledFullHeight = styled(FullHeight, {
  shouldForwardProp: (prop) =>
    prop !== 'isPurchaseDisabled' && prop !== 'isDownloadDisabled',
})<StyledFullHeightProps>(
  ({ isPurchaseDisabled, isDownloadDisabled, theme }) => ({
    paddingBottom:
      isPurchaseDisabled || isDownloadDisabled ? '0 !important' : undefined,
    backgroundColor: theme.palette.background.default,
  }),
);

const StyledInviteeCard = styled(InviteeCard, {
  shouldForwardProp: (prop) =>
    prop !== 'isPurchaseDisabled' && prop !== 'isDownloadDisabled',
})<StyledInviteeCardProps>(({ isPurchaseDisabled, isDownloadDisabled }) => ({
  opacity: isPurchaseDisabled || isDownloadDisabled ? '0.5' : undefined,
}));

const StyledInviteeDFCCard = styled(InviteeDFCCard, {
  shouldForwardProp: (prop) =>
    prop !== 'isPurchaseDisabled' && prop !== 'isDownloadDisabled',
})<StyledInviteeCardProps>(({ isPurchaseDisabled, isDownloadDisabled }) => ({
  opacity: isPurchaseDisabled || isDownloadDisabled ? '0.5' : undefined,
}));

const WarningContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        mt: 2,
        maxWidth: '76ch',
        bgcolor: 'blue.light',
        p: 1.5,
        borderRadius: 1,
      }}
    >
      <P variant="body-bold">{children}</P>
    </Box>
  );
};

const DownloadProfiles = () => {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const hook = useDownloadProfiles(eventId!);

  const options: { value: AnonymisationType; label: string }[] = [
    {
      value: 'NAMED',
      label: t('ui-event-management.events.select-contributor-option-named'),
    },
    {
      label: t(
        'ui-event-management.events.select-contributor-option-anonymised',
      ),
      value: 'ANONYMISED',
    },
  ];

  if (hook.isPending) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (hook.isError) {
    return (
      <FullScreenError
        message={t(
          'ui.event-management.events.download-profiles.error-loading-learners',
        )}
      />
    );
  }

  let readyToPurchase = 0;
  let showNewContributors = false;

  hook.dfcInviteesInPurchaseSection.forEach((learnerAndContributor) => {
    readyToPurchase = learnerAndContributor.contributors.filter((c) => {
      return c.status === 'READY_TO_PURCHASE';
    }).length;
    showNewContributors =
      learnerAndContributor.learner.profileStatus === 'PURCHASED' &&
      readyToPurchase > 0;
  });

  const parentLink = `/experiences/${eventId}`;

  const dfcSelectedDialect =
    hook.selectedDialect === DIALECT_CODES.EVALUATION_DIALECT
      ? DIALECT_CODES.ENGLISH
      : hook.selectedDialect;

  const isDfcEvent = hook.event?.eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE';

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.download-profiles')}</title>
      </Helmet>
      <StyledFullHeight
        sx={{ backgroundColor: 'white' }}
        isPurchaseDisabled={!hook.isPurchaseDisabled}
        isDownloadDisabled={!hook.isDownloadDisabled}
      >
        <Box
          sx={{
            height: 'calc(100% - 100px)',
            '@supports ( -moz-appearance:none )': {
              height: 'calc(100% - 72px)',
            },
            '@supports (background: -webkit-canvas(safari))': {
              height: 'calc(100% - 72px)',
            },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              marginBottom:
                !hook.isPurchaseDisabled || !hook.isDownloadDisabled
                  ? '0 !important'
                  : '4rem',
              height: '100%',
            }}
          >
            <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
              <Breadcrumbs
                crumbs={{
                  '/': t('ui.event-management.events.nav.home'),
                  '/experiences': t('ui.event-management.events.title'),
                  [`/experiences/${eventId}`]: hook.event!.name,
                }}
                activeText={t('ui.event-management.events.download-profiles')}
              />
            </Box>
            <BackButton
              parentLink={parentLink}
              i18nKey="ui.event-management.events.add-learners-and-contributors.learner.back-to-experience-overview"
              sx={backButtonStyles}
            />
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} md="auto">
                <H1 variant="h2">
                  {t('ui.event-management.events.download-profiles')}
                </H1>
              </Grid>
            </Grid>
            {!isDfcEvent ? (
              <Box my={(theme) => theme.spacing(spacingSizeMap.S)}>
                <P variant="body-large" color="textSecondary">
                  {t('ui.event-management.events.download-profiles.completed', {
                    noOfEvaluated: hook.noOfEvaluated,
                    noOfInvitees: hook.sortedInvitees!.length,
                  })}
                </P>
              </Box>
            ) : null}
            {hook.inviteesInPurchaseSection.length > 0 ||
            hook.dfcInviteesInPurchaseSection.length > 0 ? (
              <>
                <Box my={(theme) => theme.spacing(spacingSizeMap.L)}>
                  <Box
                    mb={(theme) => theme.spacing(spacingSizeMap.S)}
                    mt={(theme) => theme.spacing(spacingSizeMap.S)}
                  >
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Grid item xs={12} md="auto">
                        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                          <H2 variant="h3">
                            {t(
                              experienceSupportsChapters(hook.event!.eventType)
                                ? 'ui.event-management.events.purchase-profiles.select-profiles'
                                : 'ui.event-management.events.purchase-profiles.without-chapters.select-profiles',
                            )}
                          </H2>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md="auto">
                        <Button
                          sx={
                            hook.isPurchaseDisabled
                              ? (theme) => ({
                                  color: theme.palette.text.secondary,
                                })
                              : null
                          }
                          variant="outlined"
                          color="primary"
                          fullWidth
                          disabled={hook.isPurchaseDisabled}
                          onClick={
                            hook.allAreSelectedForPurchase
                              ? hook.deselectAllForPurchase
                              : hook.selectAllForPurchase
                          }
                        >
                          {hook.allAreSelectedForPurchase
                            ? t(
                                'ui.event-management.events.chapter-learners-list.deselect-all',
                              )
                            : t(
                                'ui.event-management.events.chapter-learners-list.select-all',
                              )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    mb={(theme) => theme.spacing(spacingSizeMap.L)}
                    mt={(theme) => theme.spacing(spacingSizeMap.S)}
                  >
                    <Grid container spacing={2}>
                      {!isDfcEvent
                        ? hook.inviteesInPurchaseSection.map((invitee) => {
                            return (
                              <Grid key={invitee.id} item xs={12} md={4}>
                                <StyledInviteeCard
                                  {...invitee}
                                  isPurchaseDisabled={hook.isPurchaseDisabled}
                                  disabled={hook.isDownloadSelected}
                                  editMode
                                  checked={hook.isSelectedForPurchase(
                                    invitee.id,
                                  )}
                                  setChecked={(checked) =>
                                    checked
                                      ? hook.selectForPurchase(invitee.id)
                                      : hook.deselectForPurchase(invitee.id)
                                  }
                                  chapterStatusToDisplay={['PENDING']}
                                />
                              </Grid>
                            );
                          })
                        : hook.dfcInviteesInPurchaseSection?.map(
                            (learnerAndcontributor) => {
                              const completedContributors =
                                learnerAndcontributor.contributors.filter(
                                  (c) => {
                                    return (
                                      c.status === 'READY_TO_PURCHASE' ||
                                      c.status === 'PROFILE_GENERATED'
                                    );
                                  },
                                ).length;
                              readyToPurchase =
                                learnerAndcontributor.contributors.filter(
                                  (c) => {
                                    return c.status === 'READY_TO_PURCHASE';
                                  },
                                ).length;
                              const totalContributors =
                                learnerAndcontributor.contributors.length;

                              showNewContributors =
                                learnerAndcontributor.learner.profileStatus ===
                                  'PURCHASED' && readyToPurchase > 0;
                              return (
                                <Grid
                                  key={learnerAndcontributor.learner.id}
                                  item
                                  xs={12}
                                  md={4}
                                >
                                  <StyledInviteeDFCCard
                                    {...learnerAndcontributor.learner}
                                    contributors={{
                                      contributorsCompleted:
                                        completedContributors,
                                      contributersTotal: totalContributors,
                                    }}
                                    setChecked={(checked) =>
                                      checked
                                        ? hook.selectForPurchase(
                                            learnerAndcontributor.learner.id,
                                          )
                                        : hook.deselectForPurchase(
                                            learnerAndcontributor.learner.id,
                                          )
                                    }
                                    editMode
                                    disabled={hook.isDownloadSelected}
                                    checked={hook.isSelectedForPurchase(
                                      learnerAndcontributor.learner.id,
                                    )}
                                    showNewContributors={showNewContributors}
                                    newContributors={readyToPurchase}
                                    showCost
                                    cost={
                                      learnerAndcontributor.learner
                                        .profileStatus === 'PURCHASED'
                                        ? 0
                                        : hook.event!.chapters[0].cost
                                    }
                                  />
                                </Grid>
                              );
                            },
                          )}
                    </Grid>
                  </Box>
                </Box>
                <Divider />
              </>
            ) : null}
            <Box
              my={(theme) => theme.spacing(spacingSizeMap.L)}
              sx={
                hook.isDownloadDisabled
                  ? {
                      opacity: '0.5',
                    }
                  : null
              }
            />
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <P variant="h3">
                  {t('ui.event-management.events.download-profiles-in')}
                </P>
                <Box mb={(theme) => theme.spacing(spacingSizeMap.XS)} />
                {isDfcEvent ? (
                  <SelectDialectControl
                    dialect={dfcSelectedDialect}
                    setDialect={hook.setSelectedDialect}
                    fullWidth
                    options={hook.dialects}
                    disabled={hook.dialectSelectionDisabled}
                  />
                ) : (
                  <SelectDialectControl
                    dialect={hook.selectedDialect}
                    setDialect={hook.setSelectedDialect}
                    fullWidth
                    disabled={
                      hook.isDownloadDisabled || hook.dialects.length === 1
                    }
                    options={hook.dialects}
                  />
                )}
              </Grid>
              {isDfcEvent && (
                <Grid item xs={12} md="auto">
                  <Box
                    data-testid="select-contributor-option-box"
                    sx={(theme) => ({
                      [theme.breakpoints.up('md')]: {
                        paddingLeft: '2.3rem',
                      },
                    })}
                  >
                    <P variant="h3">
                      {t(
                        'ui-event-management.events.select-contributor-option',
                      )}
                    </P>
                    <RadioGroupControl
                      disabled={
                        hook.profileCount === 0 ||
                        hook.allSelectableIdsForDownload.length === 0
                      }
                      options={options}
                      name="contributor"
                      title={t(
                        'ui-event-management.events.select-contributor-option',
                      )}
                      value={t(hook.anonymisationType)}
                      onChange={hook.changeContributorOption}
                      variant="boxed"
                      sx={(theme) => ({
                        marginTop: '0.8rem',
                        '& > div > div > div': {
                          border: `2px solid ${theme.palette.primary.main}`,
                          '& > label > span:not(:first-of-type)': {
                            fontWeight: 600,
                          },
                        },
                        '& > div > div:not(:first-of-type)': {
                          paddingLeft: '0.5rem !important',
                        },
                      })}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md="auto">
                <P variant="h3">
                  {t('ui.event-management.events.share-or-download')}
                </P>
                <Box height={(theme) => theme.spacing(spacingSizeMap.XS)} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => hook.handleChooseDistMethod(true)}
                      disabled={
                        hook.profileCount === 0 ||
                        hook.allSelectableIdsForDownload.length === 0
                      }
                    >
                      {t(
                        'ui.event-management.events.download-profiles.share-all',
                        {
                          count: hook.profileCount,
                        },
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => hook.handleDownload(true)}
                      disabled={
                        hook.profileCount === 0 ||
                        hook.allSelectableIdsForDownload.length === 0
                      }
                    >
                      {t(
                        'ui.event-management.events.download-profiles.download-all',
                        {
                          count: hook.profileCount,
                        },
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                {hook.showChapterWarning && (
                  <WarningContainer>
                    {t(
                      'ui.event-management.events.download-profiles.missing_chapter_warning',
                    )}
                  </WarningContainer>
                )}
                {!isDfcEvent &&
                  !hook.showChapterWarning &&
                  hook.showPronounsWarning && (
                    <WarningContainer>
                      {t(
                        'ui.event-management.events.download-profiles.invalid_pronouns_warning',
                      )}
                    </WarningContainer>
                  )}
                {isDfcEvent && notEnglish(dfcSelectedDialect) && (
                  <WarningContainer>
                    {t('ui.event-management.events.pronoun-note')}
                  </WarningContainer>
                )}
              </Grid>
            </Grid>
            <Box
              mb={(theme) => theme.spacing(spacingSizeMap.S)}
              mt={(theme) => theme.spacing(spacingSizeMap.S)}
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={12} md="auto">
                  <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                    <H2 variant="h3">
                      {t(
                        'ui.event-management.events.download-profiles.select-profiles',
                      )}
                    </H2>
                  </Box>
                </Grid>
                {hook.showSelectAllForDownloadButton && (
                  <Grid item xs={12} md="auto">
                    <Button
                      sx={
                        hook.isDownloadDisabled
                          ? (theme) => ({
                              color: theme.palette.text.secondary,
                            })
                          : null
                      }
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled={hook.isDownloadDisabled}
                      onClick={
                        hook.allAreSelectedForDownload
                          ? hook.deselectAllForDownload
                          : hook.selectAllForDownload
                      }
                    >
                      {hook.allAreSelectedForDownload
                        ? t(
                            'ui.event-management.events.chapter-learners-list.deselect-all',
                          )
                        : t(
                            'ui.event-management.events.chapter-learners-list.select-all',
                          )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box
              mb={(theme) => theme.spacing(spacingSizeMap.L)}
              mt={(theme) => theme.spacing(spacingSizeMap.S)}
            >
              <Grid container spacing={2}>
                {!isDfcEvent
                  ? hook.inviteesInDownloadSection.map((invitee) => (
                      <Grid key={invitee.id} item xs={12} md={4}>
                        <StyledInviteeCard
                          isDownloadDisabled={hook.isDownloadDisabled}
                          {...invitee}
                          chapterStatusToDisplay={['PURCHASED']}
                          showPronounsAndDialect
                          editMode
                          disabled={
                            hook.isPurchaseSelected ||
                            !hook.canBeSelectedForDownload(invitee)
                          }
                          checked={hook.isSelectedForDownload(invitee.id)}
                          setChecked={(checked) =>
                            checked
                              ? hook.selectForDownload(invitee.id)
                              : hook.deselectForDownload(invitee.id)
                          }
                          showLastSharedDate
                        />
                      </Grid>
                    ))
                  : hook.dfcInviteesInDownloadSection.map(
                      (learnerAndcontributor) => {
                        const completedContributors =
                          learnerAndcontributor.contributors.filter(
                            (c) =>
                              c.status === 'READY_TO_PURCHASE' ||
                              c.status === 'PROFILE_GENERATED',
                          ).length;
                        const contributorsIncludedCount =
                          learnerAndcontributor.contributors.filter((c) => {
                            return c.status === 'PROFILE_GENERATED';
                          }).length;

                        return (
                          <Grid
                            key={learnerAndcontributor.learner.id}
                            item
                            xs={12}
                            md={4}
                          >
                            <StyledInviteeDFCCard
                              {...learnerAndcontributor.learner}
                              contributors={{
                                contributorsCompleted: completedContributors,
                                contributersTotal:
                                  learnerAndcontributor.contributors.length,
                              }}
                              setChecked={(checked) =>
                                checked
                                  ? hook.selectForDownload(
                                      learnerAndcontributor.learner.id,
                                    )
                                  : hook.deselectForDownload(
                                      learnerAndcontributor.learner.id,
                                    )
                              }
                              editMode
                              disabled={
                                hook.isPurchaseSelected ||
                                contributorsIncludedCount === 0 ||
                                disabledDfcLearnerPronounProfile(
                                  learnerAndcontributor.learner,
                                  hook.selectedDialect,
                                )
                              }
                              checked={hook.isSelectedForDownload(
                                learnerAndcontributor.learner.id,
                              )}
                              showPronounsAndDialect
                              showLastSharedDate
                              showContributorsIncluded
                              contributorsIncluded={contributorsIncludedCount}
                            />
                          </Grid>
                        );
                      },
                    )}
              </Grid>
            </Box>
          </Container>
          {hook.isPurchaseSelected && !hook.isPurchaseError ? (
            <PurchaseSelectionBar
              unitBalance={hook.wallet!.availableUnits}
              inviteeIds={hook.selectedInviteesForPurchase}
              handleConfirmPurchase={hook.handleConfirmPurchase}
              handleSuccessClose={() => {
                hook.deselectAllForPurchase();
                hook.deselectAllForDownload();
              }}
              chapterSummaries={hook.chaptersCostSummary}
              purchaseChapters={hook.chaptersForPurchase}
              contributorsCount={hook.dfcPurchaseContributorsCount}
              event={hook.event?.eventType}
              deselectAllForPurchase={hook.deselectAllForPurchase}
              newContributors={readyToPurchase}
              showNewContributors={showNewContributors}
            />
          ) : null}
          {hook.isDownloadSelected ? (
            <DownloadSelectionBar
              count={hook.selectedInviteesForDownload.length}
              onShare={() => hook.handleChooseDistMethod(false)}
              onDownload={() => hook.handleDownload(false)}
            />
          ) : null}
        </Box>
        <PurchaseError
          error={hook.purchaseError}
          isError={hook.isPurchaseError}
          reset={hook.purchaseReset}
        />
      </StyledFullHeight>
      <ErrorFeedbackDialog
        open={hook.showErrorDialog}
        onClose={() => hook.setShowErrorDialog(false)}
      />
      <DownloadingProfilesDialog
        open={hook.showDownloadingDialog}
        onClose={hook.onCloseDownloadProgress}
      />
      <EmailProgressDialog
        open={hook.showEmailingDialog}
        onClose={hook.onCloseEmailing}
        dialect={hook.dialectString}
      />
      <DistributionMethodDialog
        open={hook.showChoosingDistDialog}
        profileCount={
          hook.isDownloadSelected
            ? hook.selectedInviteesForDownload.length
            : hook.allSelectableIdsForDownload.length
        }
        onSendViaLink={hook.handleSendLink}
        onSendViaEmail={hook.handleSendEmail}
        onCancel={() => hook.setShowChoosingDistDialog(false)}
      />
    </>
  );
};

export default DownloadProfiles;
