import { Box } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import LearnerListRow from 'components/PurchaseProfiles/LearnerListRow/LearnerListRow';
import { LearnerForPurchase } from 'components/PurchaseProfiles/types';
import {
  isUnableToPurchaseIDTL,
  hasPurchaseIDTLError,
  hasPurchaseDiscoveryError,
} from 'components/PurchaseProfiles/LearnerListRow/helper';

type Props = {
  purchaseItems: LearnerForPurchase[];
  removeCheckedItems?: (items: string[]) => void;
  productName: string;
  setPurchaseError: Dispatch<SetStateAction<boolean>>;
  purchaseError: boolean;
};

const ProfileBasket = ({
  purchaseItems,
  removeCheckedItems,
  productName,
  setPurchaseError,
  purchaseError,
}: Props) => {
  const isIDTLproduct =
    productName === 'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE';

  const cantPurchaseIDTL = hasPurchaseIDTLError(purchaseItems);
  const cantPurchaseDiscovery = hasPurchaseDiscoveryError(purchaseItems);

  useEffect(() => {
    if (
      ((cantPurchaseIDTL && isIDTLproduct) || cantPurchaseDiscovery) &&
      !purchaseError
    ) {
      setPurchaseError(true);
    }
    if (
      ((purchaseError && !cantPurchaseIDTL) || !isIDTLproduct) &&
      !cantPurchaseDiscovery
    ) {
      setPurchaseError(false);
    }
  }, [
    cantPurchaseIDTL,
    setPurchaseError,
    isIDTLproduct,
    purchaseError,
    productName,
    cantPurchaseDiscovery,
  ]);

  return (
    <Box
      sx={{
        '& div': {
          marginLeft: '0 !important',
        },
        '& div:last-child': {
          marginBottom: '0',
        },
      }}
    >
      {purchaseItems.map((learner) => {
        return (
          <LearnerListRow
            cantPurchaseIDTL={
              isUnableToPurchaseIDTL(learner.latestEvaluation) && isIDTLproduct
            }
            key={learner.latestEvaluation.id}
            {...learner}
            removeCheckedItem={
              removeCheckedItems
                ? (itemId) => removeCheckedItems([itemId])
                : undefined
            }
          />
        );
      })}
    </Box>
  );
};

export default ProfileBasket;
