import React from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { INVITEE_OPTIONS } from 'variables';
import { InviteeListOptions } from 'types/types';

type LearnersStatusFilterProps = {
  onChange: (value: InviteeListOptions) => void;
  value: InviteeListOptions | '';
};
const LearnersStatusFilter = ({
  onChange,
  value,
}: LearnersStatusFilterProps) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="status-filter-label" htmlFor="status-filter">
        {t('ui.event-management.events.learners-list.filter-by-status')}
      </InputLabel>
      <Select
        id="status-filter"
        name="status-filter"
        value={value}
        label={t('ui.event-management.events.learners-list.filter-by-status')}
        labelId="status-filter-label"
        onChange={(event) => onChange(event.target.value as InviteeListOptions)}
      >
        <MenuItem value="">
          {t('ui.event-management.events.select.none')}
        </MenuItem>
        {Object.entries(INVITEE_OPTIONS).map(([status, { textKey }]) => (
          <MenuItem key={status} value={status}>
            {t(textKey)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default LearnersStatusFilter;
