import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

type Props = {
  onCancel: () => void;
  saving: boolean;
};

const EditFormNavigation = ({ onCancel, saving }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <Grid container justifyContent="space-between">
        <Button variant="outlined" color="primary" onClick={onCancel}>
          {t('ui.event-management.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          endIcon={
            saving && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )
          }
        >
          {t('ui.event-management.events.edit.save-event')}
        </Button>
      </Grid>
    </>
  );
};

export default EditFormNavigation;
