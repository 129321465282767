import React, { PropsWithChildren } from 'react';
import { Avatar, Box, Divider, Grid, Link, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { P, Span } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Theme } from '@mui/material/styles';

type Props = PropsWithChildren<{
  disabled: boolean;
  headerIcon: any;
  headerTitle: string;
  editPath: string;
}>;

type EventDetailsGridProps = {
  details: {
    title: string;
    value: string;
  }[];
};

const EventDetailsWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.L),
}));

const EventDetailGridP = styled(P)({
  textAlign: 'right',
  overflowWrap: 'anywhere',
});

export const EventDetailGrid = ({ details }: EventDetailsGridProps) => {
  return (
    <>
      {details.map((detail) => (
        <Grid item container key={detail.title}>
          <Grid item xs={6}>
            <P
              data-testid="event-detail-key"
              variant="body-bold"
              color="textSecondary"
            >
              {detail.title}
            </P>
          </Grid>
          <Grid item xs={6}>
            <EventDetailGridP
              data-testid="event-detail-value"
              variant="body-bold"
            >
              {detail.value}
            </EventDetailGridP>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const EventDetail = ({
  disabled,
  headerIcon: HeaderIcon,
  headerTitle,
  editPath,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <EventDetailsWrapper>
      <Grid container alignItems="center">
        <Grid item xs={8} container alignItems="center">
          <Avatar
            sx={(theme: Theme) => ({
              color: theme.palette.pink.main,
              backgroundColor: theme.palette.pink.light,
            })}
          >
            <HeaderIcon />
          </Avatar>
          <Box
            sx={(theme: Theme) => ({
              marginRight: theme.spacing(spacingSizeMap.S),
            })}
          />
          <P variant="body-large">{headerTitle}</P>
        </Grid>
        {!disabled && (
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Span variant="body-large" color="primary">
              <Link component={RouterLink} to={editPath}>
                {t('ui.event-management.events.summary.button.edit')}
              </Link>
            </Span>
          </Grid>
        )}
      </Grid>
      <Divider
        sx={(theme: Theme) => ({
          marginBottom: theme.spacing(spacingSizeMap.S),
        })}
      />
      {children}
    </EventDetailsWrapper>
  );
};

export default EventDetail;
