import { useMutation, UseQueryOptions } from '@tanstack/react-query';

import { sendContactForm } from './api';

export type QueryOptionsConfig<T> = Omit<
  UseQueryOptions<T, Error, T>,
  'queryKey' | 'queryFn'
>;

export const useSendContactForm = () =>
  useMutation({ mutationFn: sendContactForm });
