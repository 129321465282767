import { cond } from '@insights-ltd/design-library/utils';
import { useAuth, User } from 'contexts/AuthContext';
import {
  EventSummaryResponse,
  GroupResponse,
  LearnerAndInviteeResponse,
  OrganisationResponse,
  PractitionerData,
  useGetAllEvents,
  useGetAllGroups,
  useGetAllLearnersAndInvitees,
  useGetAllOrganisations,
  useGetPractitioners,
} from 'api';
import {
  PermissionActions,
  PermissionRequirement,
  PermissionScope,
  PermissionsResource,
} from 'permissions';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';
import { QueryStatus } from '@tanstack/react-query';

export type UseGetRequiredScopesResult = {
  user?: User;
  status: QueryStatus | 'idle';
  scopedRequirements?: PermissionRequirement[];
};

const getResourceFromAction = (
  action: PermissionActions,
): PermissionsResource => {
  const match = action.match(/\w+(?=_\w+)/)?.[0];
  return match! as PermissionsResource;
};

const containsUnscopedResource = (
  requirements: PermissionRequirement[],
  resource: PermissionsResource,
) =>
  requirements.some(
    (requirement) =>
      getResourceFromAction(requirement.action) === resource &&
      !requirement.scope,
  );

const getRequiredExperienceScope = (
  requirement: PermissionRequirement,
  eventData: EventSummaryResponse[],
): PermissionScope =>
  eventData!.some((event) => event.id === requirement.resourceId)
    ? 'Local'
    : 'Global';

const getRequiredPractitionerScope = (
  requirement: PermissionRequirement,
  practitioners: PractitionerData[],
  user: User,
): PermissionScope =>
  cond([
    [
      [
        'Practitioner_UpdateRoles',
        'Practitioner_UpdateLicensedProducts',
        'Practitioner_ReadAllLicensedProducts',
      ].includes(requirement.action),
      'Global',
    ],
    [user?.id === requirement.resourceId, 'Self'],
    [
      !practitioners.some(
        (practitioner) => practitioner.id === requirement.resourceId,
      ),
      'Global',
    ],
    [
      [
        'Practitioner_ReadAll',
        'Practitioner_ReadSingle',
        'Practitioner_Update',
      ].includes(requirement.action),
      'Local',
    ],
  ]);

const getRequiredOrganisationScope = (
  requirement: PermissionRequirement,
  myOrgs: OrganisationResponse[],
): PermissionScope =>
  cond([
    [!myOrgs.find(({ id }) => id === requirement.resourceId), 'Global'],
    [
      [
        'Organisation_Create',
        'Organisation_Delete',
        'Organisation_InvitePractitioner',
        'Organisation_ReadAll',
        'Organisation_Update',
        'Organisation_Insights_Update',
      ].includes(requirement.action),
      'Global',
    ],
    [requirement.action === 'Organisation_ReadSingle', 'Local'],
  ]);

const getRequiredOrganisationGroupScope = (
  requirement: PermissionRequirement,
  myGroups: GroupResponse[],
): PermissionScope =>
  cond([
    [!myGroups.find(({ id }) => id === requirement.resourceId), 'Global'],
    [
      [
        'Organisation_Group_ReadSingle',
        'Organisation_Group_ReadAll',
        'Organisation_Group_Create',
        'Organisation_Group_Update',
        'Organisation_Group_Delete',
      ].includes(requirement.action),
      'Global',
    ],
    [requirement.action === 'Organisation_ReadSingle', 'Local'],
  ]);

const getRequiredUnitScope = (
  requirement: PermissionRequirement,
): PermissionScope =>
  cond([
    [requirement.action === 'Unit_Transfer', 'Local'],
    [requirement.action === 'Unit_Update', 'Global'],
    [requirement.action === 'Unit_ReadAll', 'Global'],
  ]);

const getRequiredLearnerScope = (
  requirement: PermissionRequirement,
  learners: LearnerAndInviteeResponse[],
): PermissionScope =>
  learners.some((learner) => learner.id === requirement.resourceId)
    ? 'Local'
    : 'Global';

export type UseGetRequiredScopesConfig = {
  enabled?: boolean;
};

const useGetRequiredScopes = (
  requirements: PermissionRequirement[],
  config: UseGetRequiredScopesConfig = {},
): UseGetRequiredScopesResult => {
  const { user } = useAuth();
  const shouldRun = config?.enabled !== false;
  const eventsQuery = useGetAllEvents(
    { status: 'ACTIVE' },
    {
      enabled:
        shouldRun && containsUnscopedResource(requirements, 'Experience'),
    },
  );

  const myOrgQuery = useGetAllOrganisations({
    enabled:
      shouldRun &&
      (containsUnscopedResource(requirements, 'Organisation') ||
        containsUnscopedResource(requirements, 'Practitioner')),
  });
  const { status: myOrgStatus, data: myOrgData } = myOrgQuery;

  const allGroupsQuery = useGetAllGroups({
    enabled:
      shouldRun && containsUnscopedResource(requirements, 'Organisation_Group'),
  });
  const { data: myGroupData } = allGroupsQuery;

  const enableGetPractitioners =
    shouldRun &&
    myOrgStatus === 'success' &&
    containsUnscopedResource(requirements, 'Practitioner');
  const practitionersQuery = useGetPractitioners(
    myOrgData ? myOrgData[0]?.id : '',
    {
      enabled: enableGetPractitioners,
    },
  );
  const learnersQuery = useGetAllLearnersAndInvitees({
    enabled: shouldRun && containsUnscopedResource(requirements, 'Learner'),
  });

  const status = queryStatusIgnoreIdle(
    allGroupsQuery,
    eventsQuery,
    practitionersQuery,
    myOrgQuery,
    learnersQuery,
  );

  if (config?.enabled === false) {
    return { status: 'idle' };
  }

  if (!user) {
    return {
      status: 'error',
    };
  }

  if (status === 'error') {
    return { user, status: 'error' };
  }
  if (
    status === 'pending' ||
    (practitionersQuery.status === 'pending' &&
      practitionersQuery.fetchStatus === 'idle' &&
      containsUnscopedResource(requirements, 'Practitioner'))
  ) {
    return {
      user,
      status: 'pending',
    };
  }

  const scopedRequirements = requirements.map((requirement) => {
    const resource = getResourceFromAction(requirement.action);
    if (!requirement.scope && !requirement.resourceId) {
      throw new Error(`Cannot determine the necessary scope of an unknown entity. Please provide a resourceId or scope for each requirement. Provided Requirement:
        Resource: ${resource}
        Action: ${requirement.action}
        Scope: ${requirement.scope}
        Resource ID: ${requirement.resourceId}`);
    }
    const result = cond([
      [Boolean(requirement.scope), () => ({ ...requirement })],
      [
        resource === 'Experience',
        () => ({
          ...requirement,
          scope: getRequiredExperienceScope(requirement, eventsQuery.data!),
        }),
      ],
      [
        resource === 'Practitioner',
        () => ({
          ...requirement,
          scope: getRequiredPractitionerScope(
            requirement,
            practitionersQuery.data!,
            user,
          ),
        }),
      ],
      [
        resource === 'Organisation',
        () => ({
          ...requirement,
          scope: getRequiredOrganisationScope(requirement, myOrgData!),
        }),
      ],
      [
        resource === 'Learner',
        () => ({
          ...requirement,
          scope: getRequiredLearnerScope(requirement, learnersQuery.data!),
        }),
      ],
      [
        resource === 'Unit',
        () => ({
          ...requirement,
          scope: getRequiredUnitScope(requirement),
        }),
      ],
      [
        resource === 'Organisation_Group',
        () => ({
          ...requirement,
          scope: getRequiredOrganisationGroupScope(requirement, myGroupData!),
        }),
      ],
    ]);
    return result ? result() : result;
  });

  return { user, scopedRequirements, status: 'success' };
};

export default useGetRequiredScopes;
