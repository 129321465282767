import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  SxProps,
  styled,
  Theme,
} from '@mui/material';
import { FormLabelV2, Text } from '@insights-ltd/design-library/components';
import {
  GroupText,
  OrganisationAutocompleteV2,
} from 'components/OrganisationPicker/OrganisationAutocomplete';
import { Group, GroupType, OrganisationResponse } from 'api';
import { useTranslation } from 'react-i18next';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';

const StyledText = styled(Text)({
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  fontSize: '1rem !important',
});

type OrgListRenderProps = {
  name: string;
  actionText: string;
  group?: Group;
  onRemove?: (id: string) => void;
  id: string;
};

export const OrgListRender = ({
  name,
  group,
  actionText,
  onRemove,
  id,
}: OrgListRenderProps) => {
  const { t } = useTranslation();
  const typeTranslations: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.organisations.type.customer'),
    PARTNER: t('ui.event-management.organisations.type.partner'),
  };
  return (
    <Grid
      container
      sx={(theme) => ({
        padding: '13px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
      })}
    >
      <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={5}>
        <StyledText color="textPrimary" variant="body-bold">
          {name}
        </StyledText>
      </Grid>

      <Grid item xs={5}>
        {group && (
          <StyledText color="textSecondary">
            <GroupText
              groupName={group.name}
              groupType={typeTranslations[group.type]}
            />
          </StyledText>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        {onRemove ? (
          <Button
            data-testid={`${id}-remove-button`}
            onClick={() => onRemove(id)}
            sx={{ minWidth: 0, padding: 0 }}
            color="error"
          >
            {actionText}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

type OrgsListSearchProps = {
  onSelect: (value: OrganisationResponse) => void;
  loading?: boolean;
  options: OrganisationResponse[];
  labelKey?: string;
  selectedOrgs: OrganisationResponse[];
  onRemoveOrg?: (id: string) => void;
  dataTestId?: string;
  groupHashMap?: GroupOrganisationHashMap;
  sx?: SxProps<Theme>;
};

export const OrgsListSearch = ({
  onSelect,
  loading = false,
  options,
  labelKey,
  selectedOrgs,
  onRemoveOrg,
  dataTestId,
  groupHashMap,
  sx,
}: OrgsListSearchProps) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const onSelectHandler = (value: OrganisationResponse) => {
    if (value?.id) {
      onSelect(value);
    } else setSelectedValue(null);
  };
  const { t } = useTranslation();

  return (
    <Box sx={sx}>
      <FormLabelV2 label="Organisations" optionalText="" />
      {selectedOrgs && selectedOrgs.length > 0 ? (
        <Paper
          elevation={0}
          sx={(theme) => ({
            '>:last-child': { borderBottom: 'none' },
            border: `1px solid ${theme.palette.grey[500]}`,
            borderBottomRightRadius: '0',
            borderBottomLeftRadius: '0',
          })}
        >
          {selectedOrgs.map(({ id, name }: OrganisationResponse) => (
            <OrgListRender
              key={id}
              name={name}
              id={id}
              actionText={t(
                'ui.event-management.organisations.edit.select-group.remove',
              )}
              onRemove={onRemoveOrg}
              group={groupHashMap?.[id]}
            />
          ))}
        </Paper>
      ) : null}
      <Paper
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[200],
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          marginTop: '-2px',
          border: `1px solid ${theme.palette.grey[500]}`,
          borderBottom: 'none',
        })}
      >
        <Box
          sx={{
            paddingTop: '12px',
            paddingBottom: '24px',
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <OrganisationAutocompleteV2
            dataTestId={dataTestId}
            options={options.filter(
              (option) =>
                !selectedOrgs.find(
                  (selectedOrg) => selectedOrg?.id === option?.id,
                ),
            )}
            onSelect={onSelectHandler}
            loading={loading}
            labelKey={labelKey}
            clearOnSelect
            selectedValue={selectedValue}
            organisationsInGroups={groupHashMap}
          />
        </Box>
      </Paper>
    </Box>
  );
};
