import { QueryStatus, useQueryClient } from '@tanstack/react-query';
import {
  Group,
  useAddOrganisationToGroup,
  useRemoveOrganisationFromGroup,
} from 'api';
import { queryStatus } from 'utils/queryStatus';
import { RequestError } from 'api/httpEntities';

const useTransferOrganisationToGroup = (
  initialGroup: Group | null,
  organisationId: string | undefined,
  onError: (err: RequestError) => void,
): {
  status: QueryStatus;
  mutateAsync: (
    newGroup: Group | null,
    onSuccess?: () => void,
  ) => Promise<void>;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: addToGroup, status: removeStatus } =
    useAddOrganisationToGroup(queryClient, onError);
  const { mutateAsync: removeFromGroup, status: addStatus } =
    useRemoveOrganisationFromGroup(queryClient);
  const status = queryStatus(
    removeStatus as QueryStatus,
    addStatus as QueryStatus,
  );

  const mutateAsync = async (newGroup: Group | null, onSuccess = () => {}) => {
    if (organisationId) {
      if (initialGroup && newGroup && newGroup.id === initialGroup.id) {
        onSuccess();
        return;
      }

      if (initialGroup) {
        await removeFromGroup(
          { organisationId, groupId: initialGroup.id },
          { onSuccess },
        );
        if (newGroup) {
          await addToGroup(
            { organisationId, groupId: newGroup.id },
            { onSuccess },
          );
        }
        return;
      }

      if (newGroup && !initialGroup) {
        await addToGroup(
          { organisationId, groupId: newGroup.id },
          { onSuccess },
        );
      }
    }
  };

  return { status, mutateAsync };
};

export default useTransferOrganisationToGroup;
