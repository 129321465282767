import React from 'react';
import { SvgComponentProps } from './types';

const SvgCalendar = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect width={40} height={40} fill="#FCE5F0" rx={4} />
    <path
      fill="#DE0072"
      d="M27.875 11.5h-2.292a.208.208 0 0 1-.208-.208V9.833a.833.833 0 1 0-1.667 0v3.959a.625.625 0 1 1-1.25 0v-1.875a.417.417 0 0 0-.416-.417h-5.209a.208.208 0 0 1-.208-.207v-1.46a.833.833 0 1 0-1.667 0v3.959a.625.625 0 1 1-1.25 0v-1.875a.417.417 0 0 0-.416-.417h-1.25a1.666 1.666 0 0 0-1.667 1.667v14.166A1.666 1.666 0 0 0 12.042 29h15.833a1.667 1.667 0 0 0 1.667-1.667V13.167a1.666 1.666 0 0 0-1.667-1.667Zm-.417 15.833h-15a.417.417 0 0 1-.416-.416v-10a.417.417 0 0 1 .416-.417h15a.417.417 0 0 1 .417.417v10a.417.417 0 0 1-.417.416Z"
    />
  </svg>
);

export default SvgCalendar;
