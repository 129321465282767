import { useMemo } from 'react';

import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { Group, GroupResponse } from 'api/httpEntities';

const useGetAssociatedGroup = (
  id: string,
  allGroups: GroupResponse[],
): {
  associatedGroup: Group;
} => {
  const hashMap = useMemo(
    () =>
      allGroups && allGroups.length > 0
        ? createGroupOrganisationHashMap(allGroups ?? [])
        : {},
    [allGroups],
  );

  const associatedGroup = hashMap[id];

  return { associatedGroup };
};

export default useGetAssociatedGroup;
