import { _delete, get, post, put } from '@insights-ltd/design-library/utils';
import { API_ROOT } from 'mocks/handlers/common';
import {
  EvaluatorLinkResponse,
  EventSummaryResponse,
  GroupResponse,
  OrganisationResponse,
  PractitionerData,
  PractitionerProductsResponse,
  RequestErrorBody,
  TransactionResponse,
  WalletResponse,
} from 'api/httpEntities';

export const practitioners = {
  me: get<PractitionerData | undefined>(`${API_ROOT}/practitioners/me`),
  get: (practitionerId: string) =>
    get<PractitionerData | undefined>(
      `${API_ROOT}/practitioners/${practitionerId}`,
    ),
  getByEmail: get<PractitionerData | undefined>(`${API_ROOT}/practitioners`),
  all: get<PractitionerData | undefined>(`${API_ROOT}/practitioners`),
  update: (practitionerId: string) =>
    put<undefined>(`${API_ROOT}/practitioners/${practitionerId}`),
  organisations: {
    all: (practitionerId: string) =>
      get<{ organisations: OrganisationResponse[] }>(
        `${API_ROOT}/practitioners/${practitionerId}/organisations`,
      ),
    update: (practitionerId: string) =>
      post<{ organisations: OrganisationResponse[] }>(
        `${API_ROOT}/practitioners/${practitionerId}/organisations`,
      ),
  },
  wallet: {
    get: (practitionerId: string) =>
      get<WalletResponse | undefined | RequestErrorBody>(
        `${API_ROOT}/practitioners/${practitionerId}/wallet`,
      ),
  },
  transactions: {
    all: (practitionerId: string) =>
      get<TransactionResponse[] | undefined>(
        `${API_ROOT}/practitioners/${practitionerId}/wallet/transactions`,
      ),
  },
  events: {
    all: (practitionerId: string) =>
      get<{ events: EventSummaryResponse[] }>(
        `${API_ROOT}/practitioners/${practitionerId}/events`,
      ),
  },
  access: {
    update: (practitionerId: string) =>
      put<undefined>(`${API_ROOT}/practitioners/${practitionerId}/access`),
  },
  invites: {
    delete: _delete<undefined>(`${API_ROOT}/practitioners/invites`),
    post: post<undefined>(`${API_ROOT}/practitioners/invites`),
  },
  reinvites: { create: post<undefined>(`${API_ROOT}/practitioners/reinvites`) },
  evaluatorLinks: {
    all: (practitionerId: string) =>
      get<EvaluatorLinkResponse>(
        `${API_ROOT}/practitioners/${practitionerId}/evaluator-links`,
      ),
  },
  products: {
    all: (practitionerId: string) =>
      get<PractitionerProductsResponse>(
        `${API_ROOT}/practitioners/${practitionerId}/products`,
      ),
  },
  groups: {
    all: (practitionerId: string) =>
      get<{ organisationGroups: GroupResponse[] }>(
        `${API_ROOT}/practitioners/${practitionerId}/organisation-groups`,
      ),
  },
};
