import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useQueryClient } from '@tanstack/react-query';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import SectionHeader from 'components/SectionHeader';
import {
  Group,
  GroupResponse,
  OrganisationResponse,
  useMoveLearnerOrganisation,
} from 'api';
import AccountOrganisationCard from 'components/OrganisationInfoCard/AccountOrganisationCard';
import { styled } from '@mui/material';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { useAuth } from 'contexts/AuthContext';
import Notification from 'components/Notification';
import LinkButton from 'components/LinkButton';
import ChangeOrgDialog from './ChangeOrgDialog';
import ConfirmOrgDialog from './ConfirmOrgDialog';

const StyledIcon = styled(OrganisationIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  fill: theme.palette.common.black,
  color: theme.palette.text.primary,
}));

const changeOrgPermittedRoles = [
  'SUPER_ADMIN',
  'ADVANCED_USER',
  'GROUP_MANAGER',
];

const OrganisationInfo = ({
  name,
  organisationId,
  dataRetentionPolicy,
  organisations,
  groups,
  organisationsInGroups,
  learnerId,
  parent,
}: {
  name: string;
  organisationId: string;
  dataRetentionPolicy?: { maximumInactivityPeriod: string };
  organisations: OrganisationResponse[];
  groups: GroupResponse[];
  learnerId: string;
  parent?: Group;
  organisationsInGroups?: GroupOrganisationHashMap;
}) => {
  const { t } = useTranslation();
  const [showChangeOrgDialog, setShowChangeOrgDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [newOrgId, setNewOrgId] = useState('');
  const [reason, setReason] = useState('');
  const { state } = useLocation();
  const locationState: any = state;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const isPermitted = user?.roles?.some((role) =>
    changeOrgPermittedRoles.includes(role),
  );

  const { mutate, isError, isPending, reset } = useMoveLearnerOrganisation(
    organisationId,
    queryClient,
  );

  const onChangeOrg = (orgId: string, reasonText: string) => {
    setNewOrgId(orgId);
    setReason(reasonText);
    setShowConfirmDialog(true);
  };

  const handleConfirmChangeOrg = () =>
    mutate(
      {
        learnerId,
        sourceOrganisationId: organisationId,
        targetOrganisationId: newOrgId,
        reason,
      },
      {
        onSuccess: () => {
          setTimeout(() => {
            setShowConfirmDialog(false);
            navigate(`/learners/${newOrgId}/${learnerId}`, {
              state: {
                showTransferSuccess: true,
              },
            });
          }, 650);
        },
      },
    );

  return (
    <>
      <SectionHeader
        icon={<StyledIcon />}
        title={t('ui.event-management.learner.organisation')}
      />
      {locationState?.showTransferSuccess ? (
        <Notification
          message={t('ui.event-management.learner.transferred.notification')}
        />
      ) : null}
      <Grid
        container
        direction="row"
        sx={() => ({
          marginBottom: '1.5rem',
        })}
      >
        <AccountOrganisationCard
          id={organisationId}
          name={name}
          dataRetentionPolicy={dataRetentionPolicy}
          type="Organisation"
          parent={parent}
        >
          {isPermitted &&
            groups.some((group) => group.organisations.length >= 2) && (
              <LinkButton
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '0.5rem 0',
                  lineHeight: '28px',
                }}
                onClick={() => setShowChangeOrgDialog(true)}
              >
                {t('ui.event-management.learners.organisation.change-org')}
              </LinkButton>
            )}
        </AccountOrganisationCard>
      </Grid>
      <ChangeOrgDialog
        open={showChangeOrgDialog}
        onClose={() => {
          setShowChangeOrgDialog(false);
        }}
        currentOrganisationCard={
          <AccountOrganisationCard
            sx={{ paddingTop: '4px' }}
            id={organisationId}
            name={name}
            type="Organisation"
            dataRetentionPolicy={dataRetentionPolicy}
            parent={parent}
            variant="small"
          />
        }
        organisations={organisations}
        organisationsInGroups={organisationsInGroups}
        organisationId={organisationId}
        groups={groups}
        handleOrganisationChange={onChangeOrg}
      />
      <ConfirmOrgDialog
        open={showConfirmDialog}
        isLoading={isPending}
        isError={isError}
        onClose={() => {
          reset();
          setShowConfirmDialog(false);
        }}
        onConfirm={handleConfirmChangeOrg}
      />
    </>
  );
};
export default OrganisationInfo;
