import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, P } from '@insights-ltd/design-library/components';
import { Box } from '@mui/material';
import Evaluator from 'components/Svgs/Evaluator';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const ConfirmDialog = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      content={
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: {
              textAlign: 'center',
            },
            svg: {
              marginBottom: theme.spacing(spacingSizeMap.S),
            },
          })}
        >
          <Evaluator />
          <P variant="body-bold" color="textPrimary">
            {t(
              'ui.event-management.learners.purchase-profiles.confirm-purchase.modal-sub-heading',
            )}
          </P>
          <P variant="body-bold" color="textSecondary">
            {t(
              'ui.event-management.learners.purchase-profiles.confirm-purchase.modal-content.email',
            )}{' '}
            {t(
              'ui.event-management.learners.purchase-profiles.confirm-purchase.modal-content.charge',
            )}
          </P>
        </Box>
      }
      secondaryButtonText={t(
        'ui.event-management.learners.purchase-profiles.confirm-purchase.close-button.label',
      )}
      title={t(
        'ui.event-management.learners.purchase-profiles.confirm-purchase.modal-heading',
      )}
    />
  );
};

export default ConfirmDialog;
