import { getDownloadFileBlobForTransactionHistory } from 'api/practitioners/practitioners';

const downloadTransactionHistory = async (externalId: string) => {
  const { blob, filename } =
    await getDownloadFileBlobForTransactionHistory(externalId);
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};

export default downloadTransactionHistory;
