import { useEffect } from 'react';

export const usePromptBeforeUnload = (prompt: boolean) => {
  useEffect(() => {
    const promptBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Chrome (and legacy IE) requires returnValue to be set.
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    };

    if (prompt) window.addEventListener('beforeunload', promptBeforeUnload);

    return () => {
      if (prompt) {
        window.removeEventListener('beforeunload', promptBeforeUnload);
      }
    };
  }, [prompt]);
};
