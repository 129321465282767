import React from 'react';
import { useTranslation } from 'react-i18next';
import { AllDialectOptions } from 'types/dialects';
import { ENABLED_EVALUATOR_DIALECTS } from 'variables';
import { InputSelectV2 } from '@insights-ltd/design-library/components';
import { MenuItem } from '@mui/material';

export type Props = {
  dialect: AllDialectOptions;
  setDialect: (selection: AllDialectOptions) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  options?: { textKey: string; value: string }[];
};

const SelectDialectControl = ({
  dialect,
  setDialect,
  disabled,
  fullWidth,
  options = ENABLED_EVALUATOR_DIALECTS,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (selection: string) => {
    setDialect(selection as AllDialectOptions);
  };

  return (
    <InputSelectV2
      labelText=""
      id="language-select"
      onChange={(event) => handleChange(event.target.value as string)}
      value={dialect}
      disabled={!!disabled}
      fullWidth={!!fullWidth}
    >
      {options.map(({ textKey, value }) => (
        <MenuItem data-testid="language-option" key={value} value={value}>
          {t(textKey)}
        </MenuItem>
      ))}
    </InputSelectV2>
  );
};

export default SelectDialectControl;
