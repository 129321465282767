import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Dialog } from '@insights-ltd/design-library/components';
import { InviteeRequest, RequestError, useAddInvitee } from 'api';
import { VALID_EMAIL_REGEX } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type AddLearnerFormProps = {
  eventId: string;
};
const AddLearnerForm = ({ eventId }: AddLearnerFormProps) => {
  const { t } = useTranslation();
  const [allowErrorDialog, setAllowErrorDialog] = useState(true);
  const { mutate, isPending, isError, error } = useAddInvitee();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset: resetForm,
  } = useForm<InviteeRequest>();

  const onSubmit = (formData: InviteeRequest) => {
    setAllowErrorDialog(true);
    mutate({ eventId, formData }, { onSuccess: () => resetForm() });
  };

  const openIdtlFailedError =
    isError &&
    error instanceof RequestError &&
    error.errorCodes.find((code) => code === 'NO_PROFILE_AVAILABLE');

  const emailValidationError =
    isError &&
    error instanceof RequestError &&
    error.errorCodes.find((code) => code === 'INVALID_EMAIL_ADDRESS');

  const duplicateEmailError =
    isError &&
    error instanceof RequestError &&
    error.errorCodes.find((code) => code === 'EMAIL_ADDRESS_ALREADY_TAKEN');

  const miscEmailError =
    isError &&
    !openIdtlFailedError &&
    !emailValidationError &&
    !duplicateEmailError;

  const fullNameLabel = t(
    'ui.event-management.events.add-learners.label.full-name',
  );
  const emailLabel = t(
    'ui.event-management.events.add-learners.label.email-address',
  );

  const getEmailHelperMessage = () => {
    if (miscEmailError) {
      return t('ui.event-management.events.invitee.error-adding-invitee');
    }
    if (duplicateEmailError) {
      return t(
        'ui.event-management.events.invitee.error-adding-duplicate-invitee',
      );
    }
    if (errors.email || emailValidationError) {
      return t('ui.event-management.events.create.error.value-email-required');
    }
    return undefined;
  };

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={5}>
            <TextField
              id="fullName"
              label={fullNameLabel}
              required
              variant="outlined"
              fullWidth
              type="text"
              {...register('fullName', { required: true })}
              inputProps={{
                'aria-label': fullNameLabel,
              }}
              error={Boolean(errors.fullName)}
              helperText={
                errors.fullName &&
                t('ui.event-management.events.create.error.name-required')
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              id="email"
              label={emailLabel}
              required
              variant="outlined"
              fullWidth
              type="email"
              {...register('email', {
                required: true,
                pattern: VALID_EMAIL_REGEX,
              })}
              inputProps={{
                'aria-label': emailLabel,
              }}
              error={Boolean(
                errors.email ||
                  emailValidationError ||
                  duplicateEmailError ||
                  miscEmailError,
              )}
              helperText={getEmailHelperMessage()}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isPending}
              sx={(theme) => ({ marginTop: theme.spacing(spacingSizeMap.XS) })}
              fullWidth
            >
              {t('ui.event-management.events.add-learners.button.add-learner')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={Boolean(openIdtlFailedError) && allowErrorDialog}
        title={t(
          'ui.event-management.events.add-learner.idtl-error-dialog.title',
        )}
        content={t(
          'ui.event-management.events.add-learner.idtl-error-dialog.content',
        )}
        secondaryButtonText={t(
          'ui.event-management.events.add-learner.idtl-error-dialog.okay',
        )}
        onClose={() => setAllowErrorDialog(false)}
      />
    </>
  );
};

export default AddLearnerForm;
