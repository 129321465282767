import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useClearHistoryState = (shouldClear: boolean): void => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    function clearHistoryState() {
      if (shouldClear) {
        navigate(pathname, {});
      }
    }
    window.addEventListener('beforeunload', clearHistoryState);

    return () => {
      window.removeEventListener('beforeunload', clearHistoryState);
    };
  }, [pathname, shouldClear, navigate]);
};

export default useClearHistoryState;
