import {
  CSV_ROW_LIMIT,
  VALID_EMAIL_REGEX,
  VALID_FULL_NAME_REGEX,
} from 'variables';
import { parse as csvParse } from 'papaparse';

export type BulkUploadInviteeData = {
  email: string;
  validEmail: boolean;
  fullName: string;
  validFullName: boolean;
  rowNumber: number;
};
export const processFile = (
  fileLoadedEvent: ProgressEvent<FileReader>,
): BulkUploadInviteeData[] => {
  const textFromFileLoaded = String(fileLoadedEvent?.target?.result);

  if (!textFromFileLoaded || textFromFileLoaded === '') {
    throw new Error('ui.event-management.events.upload-error.empty');
  }

  const results = csvParse<string[]>(textFromFileLoaded, {
    header: false,
    skipEmptyLines: true,
  });
  const rows = results.data;
  const invitees = rows
    .map((row, index): BulkUploadInviteeData => {
      const fullName = row[0]?.trim() ?? '';
      const email = row[1]?.trim() ?? '';
      const validFullName =
        Boolean(fullName?.match(VALID_FULL_NAME_REGEX)) || false;
      const validEmail = Boolean(email?.match(VALID_EMAIL_REGEX)) || false;

      return {
        email,
        validEmail,
        fullName,
        validFullName,
        rowNumber: index,
      };
    })
    .filter(
      (user, index, array): user is BulkUploadInviteeData =>
        (user.fullName !== '' || user.email !== '') &&
        array.findIndex((r) => r.email === user.email) === index, // only retain first occurrence of duplicate emails
    );

  if (
    invitees.length < 1 ||
    invitees.every((invitee) => !invitee.validEmail || !invitee.validFullName)
  ) {
    throw new Error('ui.event-management.events.upload-error.empty');
  }

  if (invitees.length > CSV_ROW_LIMIT) {
    throw new Error('ui.event-management.events.upload-error.too-many');
  }
  return invitees;
};
