import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgOrganisations = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect width={40} height={40} fill="#FDF1E7" rx={4} />
    <path
      fill="#D46914"
      d="M30 11.5a.624.624 0 0 0-.625-.625h-18.75a.624.624 0 1 0 0 1.25h.625a.417.417 0 0 1 .417.417v14.166a.417.417 0 0 0 .416.417H17.5a.417.417 0 0 0 .417-.417v-2.083a2.083 2.083 0 0 1 4.166 0v2.083a.417.417 0 0 0 .417.417h5.417a.417.417 0 0 0 .416-.417V12.542a.417.417 0 0 1 .417-.417h.625A.624.624 0 0 0 30 11.5Zm-12.292 9.375h-3.333a.624.624 0 1 1 0-1.25h3.333a.625.625 0 1 1 0 1.25Zm0-3.333h-3.333a.625.625 0 0 1 0-1.25h3.333a.625.625 0 1 1 0 1.25Zm0-3.334h-3.333a.625.625 0 1 1 0-1.25h3.333a.625.625 0 0 1 0 1.25Zm7.917 6.667h-3.333a.625.625 0 1 1 0-1.25h3.333a.624.624 0 1 1 0 1.25Zm0-3.333h-3.333a.625.625 0 0 1 0-1.25h3.333a.625.625 0 1 1 0 1.25Zm.625-3.959a.625.625 0 0 1-.625.625h-3.333a.625.625 0 1 1 0-1.25h3.333a.625.625 0 0 1 .625.625Z"
    />
  </svg>
);

export default SvgOrganisations;
