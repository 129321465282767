import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Group, OrganisationResponse } from 'api';
import OrganisationAutocomplete from 'components/OrganisationPicker/OrganisationAutocomplete';
import { H3 } from '@insights-ltd/design-library/components';
import React from 'react';
import OrganisationGroupLink from 'components/OrganisationGroupLink/OrganisationGroupLink';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled('div')({});
const StyledGroupLink = styled(OrganisationGroupLink)({});

type Props = {
  options: OrganisationResponse[];
  selectedId?: string;
  onSelect: (organisation: OrganisationResponse) => void;
  onChange?: () => void;
  isError?: boolean;
  errorText?: string;
  loading?: boolean;
  associatedGroup?: Group | undefined;
  disabled?: boolean;
  label?: string;
};

const SelectOrganisation = ({
  selectedId,
  associatedGroup,
  options,
  onSelect,
  onChange,
  loading = false,
  isError,
  errorText,
  disabled,
  label,
}: Props) => {
  const selectedOrg = options.find(({ id }) => id === selectedId);
  const { t } = useTranslation();

  return selectedId ? (
    <Card
      variant="outlined"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '480px',
        padding: `1rem 1.25rem`,
        borderColor: theme.palette.grey[500],
      })}
      data-testid="select-org-card"
    >
      <StyledDiv
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <H3>{selectedOrg?.name}</H3>
        {associatedGroup ? (
          <StyledGroupLink
            sx={{ marginTop: '5px !important' }}
            group={associatedGroup}
            i18nKey="ui.event-management.teams.organisation.group"
          />
        ) : null}
      </StyledDiv>

      <Button
        sx={{
          minWidth: '100px !important',
          height: '48px',
          paddingLeft: '0',
          paddingRight: '0',
          visibility: disabled ? 'hidden' : 'visible',
        }}
        variant="outlined"
        onClick={onChange}
      >
        {t('ui.event-management.organisations.edit.select-group.change')}
      </Button>
    </Card>
  ) : (
    <OrganisationAutocomplete
      options={options}
      onSelect={onSelect}
      selectedValue={selectedId}
      loading={loading}
      disableClearable
      error={isError}
      helperText={isError ? errorText : undefined}
      disabled={disabled}
      label={label}
    />
  );
};

export default SelectOrganisation;
