import { Dispatch, useState } from 'react';
import {
  LearnerAndInviteeResponse,
  TeamResponse,
  useSearchLearnersForOrgsTeam,
} from 'api';
import { MINIMUM_SEARCH_CHARACTER_LIMIT } from 'variables';
import { useDebounceValue } from './useDebouncedValue';

const useLearnerOrgSearch = (
  team?: TeamResponse,
): {
  data: {
    learners: {
      id: string;
      fullName: string;
      emailAddress: string;
    }[];
  };
  handleLearnerInputChange: (inputVal: string) => void;
  searchInputValue: string;
  clearSearch: () => void;
  setSearchOrganisation: Dispatch<any>;
  searchOrganisation: string;
  isLoadingLearners: boolean;
} => {
  const [searchOrganisation, setSearchOrganisation] = useState('');

  const [searchInputValue, setSearchInputValue] = useState('');

  const clearSearch = () => {
    setSearchInputValue('');
  };
  const searchTerm = useDebounceValue(searchInputValue);

  const teamOrgsIds =
    team?.organisationContext?.organisations?.map(({ id }) => ({ id })) || [];

  const {
    isLoading: isLoadingLearners,
    isError: isErrorSearchLearners,
    data: learnersData,
  } = useSearchLearnersForOrgsTeam(searchTerm, teamOrgsIds, {
    enabled:
      searchTerm.length >= MINIMUM_SEARCH_CHARACTER_LIMIT &&
      !!searchOrganisation,
  });

  const learners =
    learnersData?.learners?.map(
      ({ id, fullName, primaryEmail }: LearnerAndInviteeResponse) => ({
        id,
        fullName,
        emailAddress: primaryEmail,
      }),
    ) || [];

  const handleLearnerInputChange = (inputVal: string) => {
    setSearchInputValue(inputVal);
  };

  const searchResults = {
    isLoadingLearners,
    isErrorSearchLearners,
    filteredSearchLearners: learnersData,
  };

  return {
    ...searchResults,
    data: {
      learners,
    },
    handleLearnerInputChange,
    searchInputValue,
    clearSearch,
    setSearchOrganisation,
    searchOrganisation,
  };
};

export default useLearnerOrgSearch;
