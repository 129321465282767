import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgLiveHelp = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g clipPath="url(#live-help_svg__a)">
      <path
        fill="#6E750A"
        fillRule="evenodd"
        d="M.628.628A2.143 2.143 0 0 1 2.143 0h15.714A2.143 2.143 0 0 1 20 2.143v12.143a2.143 2.143 0 0 1-2.143 2.143H9.708l-4.261 3.414a.714.714 0 0 1-1.161-.557v-2.857H2.143A2.143 2.143 0 0 1 0 14.286V2.143C0 1.575.226 1.029.628.628ZM10 4.623c-.821 0-1.488.666-1.488 1.488a.893.893 0 0 1-1.785 0 3.273 3.273 0 1 1 4.166 3.15.893.893 0 1 1-1.785 0v-.77c0-.493.4-.893.892-.893a1.488 1.488 0 0 0 0-2.975Zm1.19 7.736a1.19 1.19 0 1 1-2.38 0 1.19 1.19 0 0 1 2.38 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="live-help_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLiveHelp;
