export const chapterDescriptionMap: any = {
  FOUNDATION:
    'ui.event-management.events.assign-chapters.chapter-description.foundation',
  MANAGEMENT:
    'ui.event-management.events.assign-chapters.chapter-description.management',
  EFFECTIVE_SELLING:
    'ui.event-management.events.assign-chapters.chapter-description.effective-selling',
  PERSONAL_ACHIEVEMENT:
    'ui.event-management.events.assign-chapters.chapter-description.personal-achievement',
  INTERVIEW:
    'ui.event-management.events.assign-chapters.chapter-description.interview',
};
