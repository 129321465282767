import { useAuth } from 'contexts/AuthContext';
import {
  EventSummaryResponse,
  GroupResponse,
  OrganisationResponse,
  OrganisationSupportedRole,
  TransactionResponse,
  WalletResponse,
  useGetAllEvents,
  useGetAllGroups,
  useGetAllSupportedRoles,
  useGetMyLatestTransactions,
  useGetPractitionerOrganisations,
  useGetPractitionerWallet,
} from 'api';
import useGetMyOrganisations from 'components/hooks/useGetMyOrganisations';
import { queryStatus } from 'utils/queryStatus';
import { sortCancelledExperiences } from 'utils/mappers/sorting';
import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { JobTitle } from 'types/types';

const useGetAccountData = (): {
  isPending: boolean;
  isError: boolean;
  activeEvents: EventSummaryResponse[] | undefined;
  cancelledEvents: EventSummaryResponse[];
  wallet: WalletResponse | undefined;
  organisation: OrganisationResponse | undefined;
  allMyOrganisations: OrganisationResponse[] | undefined;
  emailAddress: string;
  jobTitle: JobTitle;
  transactions: TransactionResponse[] | undefined;
  practitionerOrganisations: OrganisationResponse[] | undefined;
  groups: GroupResponse[] | undefined;
  organisationsInGroups: Record<string, GroupResponse>;
  supportedRoles: OrganisationSupportedRole[] | undefined;
} => {
  const { user } = useAuth();
  const canViewGroups = user?.permissions.Organisation_Group_ReadAll !== 'None';
  const { status: getOrganisationStatus, allMyOrganisations } =
    useGetMyOrganisations();

  const { status: getEventsStatus, data: activeEvents } = useGetAllEvents({
    status: 'ACTIVE',
  });

  const { data: cancelledEvents = [] } = useGetAllEvents({
    status: 'CANCELLED',
  });
  const { data: groups, status: groupStatus } = useGetAllGroups({
    enabled: canViewGroups,
  });

  const { status: getWalletStatus, data: wallet } = useGetPractitionerWallet(
    user!.id,
  );
  const { status: getTransactionsStatus, data: transactions } =
    useGetMyLatestTransactions(user!.id, 3);
  const { emailAddress, jobTitle } = user!;
  const { status: practitionerOrganisationsStatus, data: organisations } =
    useGetPractitionerOrganisations(user?.id, {
      enabled: !!user?.id,
    });

  const { data: supportedRoles, status: supportedRolesStatus } =
    useGetAllSupportedRoles();

  const organisationsInGroups = createGroupOrganisationHashMap(groups ?? []);

  const practitionerOrganisations = organisations?.filter(
    ({ id }) => !organisationsInGroups[id],
  );

  const status = queryStatus(
    getOrganisationStatus,
    getEventsStatus,
    getWalletStatus,
    getTransactionsStatus,
    practitionerOrganisationsStatus,
    supportedRolesStatus,
    canViewGroups ? groupStatus : 'success',
  );

  return {
    isPending: status === 'pending',
    isError: status === 'error',
    activeEvents,
    cancelledEvents: sortCancelledExperiences(cancelledEvents),
    wallet,
    organisation: allMyOrganisations?.[0],
    allMyOrganisations,
    emailAddress,
    jobTitle,
    transactions,
    practitionerOrganisations,
    groups,
    organisationsInGroups,
    supportedRoles,
  };
};
export default useGetAccountData;
