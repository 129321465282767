import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgEvaluatorLinks = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect width={40} height={40} fill="#E5F2F7" rx={4} />
    <path
      fill="#0E567B"
      d="M20.341 21.425a.833.833 0 0 0-.458 1.084.834.834 0 0 1-.175.908l-2.358 2.358a1.666 1.666 0 0 1-2.359 0L13.225 24a1.667 1.667 0 0 1 0-2.358l2.358-2.35a.833.833 0 0 1 .908-.175.833.833 0 1 0 .634-1.542 2.5 2.5 0 0 0-2.725.542l-2.359 2.358a3.334 3.334 0 0 0 0 4.709l1.775 1.775a3.333 3.333 0 0 0 4.709 0l2.358-2.359a2.5 2.5 0 0 0 .542-2.725.833.833 0 0 0-1.084-.45Z"
    />
    <path
      fill="#0E567B"
      d="M16.466 22.534a.834.834 0 0 0 1.175 0l6.525-6.484a.833.833 0 0 0-1.175-1.175l-6.525 6.483a.834.834 0 0 0 0 1.175Z"
    />
    <path
      fill="#0E567B"
      d="m27.958 12.809-1.775-1.767a3.333 3.333 0 0 0-4.708 0L19.117 13.4a2.5 2.5 0 0 0-.542 2.725.833.833 0 1 0 1.542-.633.833.833 0 0 1 .175-.908l2.358-2.359a1.667 1.667 0 0 1 2.358 0L26.775 14a1.666 1.666 0 0 1 0 2.358L24.417 18.7a.832.832 0 0 1-.909.184.833.833 0 0 0-.633 1.541 2.5 2.5 0 0 0 2.725-.541l2.358-2.359a3.332 3.332 0 0 0 0-4.716Z"
    />
  </svg>
);

export default SvgEvaluatorLinks;
