import { useState, useCallback } from 'react';
import { CoordPair } from 'pages/DownloadTeamWheel/TeamWheel72/teamWheelUtils';
import { ScoreType } from 'types/types';
import { useWheelContext } from './useWheelContext';

export const useDotOverrides = () => {
  const [dotPositionOverrides, setDotPositionOverrides] = useState<
    Record<string, CoordPair | undefined>
  >({});

  const getDotPositionOverride = useCallback(
    (dotKey: string) => dotPositionOverrides[dotKey],
    [dotPositionOverrides],
  );

  const resetDotPositionOverrides = useCallback(
    () => setDotPositionOverrides({}),
    [],
  );

  const setDotPositionOverride = useCallback(
    (dotKey: string, newOverride: CoordPair) =>
      setDotPositionOverrides((previousOverrides) => ({
        ...previousOverrides,
        [dotKey]: newOverride,
      })),
    [],
  );

  return {
    getDotPositionOverride,
    setDotPositionOverride,
    resetDotPositionOverrides,
  };
};

export const useDotSize = () => {
  const [{ dotSize, setDotSize }] = useWheelContext();
  return { dotSize, setDotSize };
};

export const useDotPositionOverrides = (wheelType: ScoreType) => {
  const [{ consciousOverrides, lessConsciousOverrides }] = useWheelContext();
  return wheelType === 'CONSCIOUS'
    ? consciousOverrides
    : lessConsciousOverrides;
};
