import { Colour } from '@insights-ltd/design-library/components';
import {
  ColourScore,
  ColourScoreWithPreference,
  Score,
  TeamColourCount,
} from 'api';
import { ScoreType } from 'types/types';

export type InitialledScore = Score & {
  initials: string;
  duplicateNumber: number;
  index: number;
};
export const getInitialsFromScores = (
  scores: (Score & { organisationId?: string; organisationName?: string })[],
): (InitialledScore & {
  organisationId: string;
  organisationName: string;
})[] => {
  const initialsStack: Record<string, number> = {};
  return scores
    .map((score) => {
      const name = score.fullName.trim().toUpperCase().split(/\s/);
      const initials =
        name.length > 1 ? name[0][0] + name[name.length - 1][0] : name[0][0];
      const frequency = (initialsStack[initials] || 0) + 1;
      initialsStack[initials] = frequency;
      return { ...score, initials, frequency };
    })
    .map(({ initials, frequency, ...score }, index) => {
      const duplicateNumber = initialsStack[initials] > 1 ? frequency : 0;
      return {
        ...score,
        initials,
        duplicateNumber,
        index,
        organisationId: score.organisationId ?? '',
        organisationName: score.organisationName ?? '',
      };
    });
};

export const getWheelPositionCountsFromScores = (
  scores: Score[],
): InitialledScore[] => {
  const wheelPositionStack: Record<number, number> = {};
  return scores
    .map((score) => {
      const frequency =
        (wheelPositionStack[score.wheelPositions.conscious] || 0) + 1;
      wheelPositionStack[score.wheelPositions.conscious] = frequency;
      return { ...score, frequency };
    })
    .map(({ frequency, ...score }, index) => {
      return {
        ...score,
        duplicateNumber: frequency,
        index,
        initials: `${frequency}`,
      };
    })
    .filter(
      (score) =>
        score.duplicateNumber ===
        wheelPositionStack[score.wheelPositions.conscious],
    );
};

const getConsciousScores = (scores: Score[]): ColourScoreWithPreference[] =>
  scores.map((score) => score.colourScores.conscious);

const getLessConsciousScores = (scores: Score[]): ColourScoreWithPreference[] =>
  scores.map((score) => score.colourScores.lessConscious);

const getPreferredPieChart = (
  scores: ColourScoreWithPreference[],
): ColourScore => {
  const emptyScore: ColourScore = {
    red: 0,
    yellow: 0,
    green: 0,
    blue: 0,
  };
  const raw = scores.reduce((prev, curr) => {
    return {
      red: curr.preferred?.includes('red') ? prev.red + 1 : prev.red,
      green: curr.preferred?.includes('green') ? prev.green + 1 : prev.green,
      blue: curr.preferred?.includes('blue') ? prev.blue + 1 : prev.blue,
      yellow: curr.preferred?.includes('yellow')
        ? prev.yellow + 1
        : prev.yellow,
    };
  }, emptyScore);
  const preferredScoreCount = scores.reduce(
    (prev, curr) => prev + curr.preferred.length,
    0,
  );
  return preferredScoreCount > 0
    ? {
        red: raw.red / preferredScoreCount,
        green: raw.green / preferredScoreCount,
        blue: raw.blue / preferredScoreCount,
        yellow: raw.yellow / preferredScoreCount,
      }
    : emptyScore;
};

const getDominantPieChart = (
  scores: ColourScoreWithPreference[],
): ColourScore => {
  const emptyScore: ColourScore = {
    red: 0,
    yellow: 0,
    green: 0,
    blue: 0,
  };
  const raw = scores.reduce((prev, curr) => {
    const currentScore = curr as ColourScore;
    const vals = [...Object.values(currentScore)].slice(0, -1);
    const maxScore = Math.max(...vals);
    const max = Object.entries(currentScore).reduce(
      (acc, [currentKey, currentVal]) =>
        currentVal === maxScore ? acc.concat(currentKey) : acc,
      [] as string[],
    );
    return {
      red: max.includes('red') ? prev.red + 1 : prev.red,
      green: max.includes('green') ? prev.green + 1 : prev.green,
      blue: max.includes('blue') ? prev.blue + 1 : prev.blue,
      yellow: max.includes('yellow') ? prev.yellow + 1 : prev.yellow,
    };
  }, emptyScore);
  return scores.length > 0
    ? {
        red: raw.red / scores.length,
        green: raw.green / scores.length,
        blue: raw.blue / scores.length,
        yellow: raw.yellow / scores.length,
      }
    : emptyScore;
};

export interface Percentages {
  conscious: Record<Colour, number>;
  lessConscious: Record<Colour, number>;
}
export interface BothPercentages {
  dominant: Percentages;
  preferred: Percentages;
}

export const getPercentagesFromScores = (scores: Score[]): BothPercentages => {
  const concious = getConsciousScores(scores);
  const lessConcious = getLessConsciousScores(scores);

  return {
    dominant: {
      conscious: getDominantPieChart(concious),
      lessConscious: getDominantPieChart(lessConcious),
    },
    preferred: {
      conscious: getPreferredPieChart(concious),
      lessConscious: getPreferredPieChart(lessConcious),
    },
  };
};

export const getPercentagesFromCounts = (
  counts: TeamColourCount[],
): BothPercentages => {
  const dominant = {
    conscious: { red: 0, green: 0, blue: 0, yellow: 0 },
    lessConscious: { red: 0, green: 0, blue: 0, yellow: 0 },
  };
  const preferred = {
    conscious: { red: 0, green: 0, blue: 0, yellow: 0 },
    lessConscious: { red: 0, green: 0, blue: 0, yellow: 0 },
  };
  const addScores = (total: ColourScore, score: ColourScore) => ({
    red: total.red + score.red,
    blue: total.blue + score.blue,
    green: total.green + score.green,
    yellow: total.yellow + score.yellow,
  });
  counts.forEach(({ count }) => {
    dominant.conscious = addScores(
      dominant.conscious,
      count.dominant.conscious,
    );
    dominant.lessConscious = addScores(
      dominant.lessConscious,
      count.dominant.lessConscious,
    );
    preferred.conscious = addScores(
      preferred.conscious,
      count.preferred.conscious,
    );
    preferred.lessConscious = addScores(
      preferred.lessConscious,
      count.preferred.lessConscious,
    );
  });

  const percentagesForColourScore = ({
    red,
    blue,
    green,
    yellow,
  }: ColourScore) => {
    const total = red + yellow + blue + green;
    return {
      red: red / total,
      blue: blue / total,
      green: green / total,
      yellow: yellow / total,
    };
  };
  return {
    dominant: {
      conscious: percentagesForColourScore(dominant.conscious),
      lessConscious: percentagesForColourScore(dominant.lessConscious),
    },
    preferred: {
      conscious: percentagesForColourScore(preferred.conscious),
      lessConscious: percentagesForColourScore(preferred.lessConscious),
    },
  };
};

export const dedupedInitialFromScore = (score: InitialledScore) =>
  `${score.initials}-${score.duplicateNumber}`;

export const createDuplicateScoreMap = (
  scores: InitialledScore[],
  scoreType: ScoreType,
): Record<string, number> => {
  const duplicateMap: Record<number, number> = {};
  const initialsMap: Record<string, number> = {};

  scores.forEach((score) => {
    const position =
      scoreType === 'CONSCIOUS'
        ? score.wheelPositions.conscious
        : score.wheelPositions.lessConscious;
    const dedupedInitial = dedupedInitialFromScore(score);

    if (duplicateMap[position] !== undefined) {
      const duplicateNumber = duplicateMap[position] + 1;
      duplicateMap[position] = duplicateNumber;
      initialsMap[dedupedInitial] = duplicateNumber;
    } else {
      duplicateMap[position] = 0;
      initialsMap[dedupedInitial] = 0;
    }
  });

  return initialsMap;
};

export function filterNonActiveScores(
  scores: (Score & { organisationId?: string })[],
  activeOrganisations?: Record<string, boolean>,
) {
  if (activeOrganisations && Object.keys(activeOrganisations).length > 1) {
    return scores.filter(
      ({ organisationId = '' }) => !!activeOrganisations[organisationId],
    );
  }

  return scores;
}

export const getPurchaseCount = (
  type: 'Experience' | 'Team',
  summaryScoreCount = 0,
  eventScoreCount = 0,
  purchasedTeamScoreCount = 0,
  isLearnerCountExceeded = false,
) => {
  if (type === 'Experience') {
    return eventScoreCount;
  }

  return isLearnerCountExceeded ? summaryScoreCount : purchasedTeamScoreCount;
};
