import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import React, { memo } from 'react';
import { styled, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

type Props = {
  count: number | string;
  sx?: SxProps<Theme>;
};

const CountBox = styled('span')(({ theme }) => ({
  padding: `5px ${theme.spacing(spacingSizeMap.XS)}`,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.pink.main,
  borderRadius: '4px',
  lineHeight: '2rem',
  display: 'inline-block',
}));

const SearchCount: React.FC<Props> = ({ count, sx }) => (
  <CountBox data-testid="count-box" sx={sx}>
    {count}
  </CountBox>
);

export default memo(SearchCount);
