import React from 'react';
import { Group, OrganisationResponse } from 'api';
import { styled, SxProps, Theme, useAutocomplete } from '@mui/material';
import {
  InputText,
  InputType,
  Span,
} from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import DisplayOrgAssign from './SelectGroupDisplayOrgAssigned';

const StyledDiv = styled('div')({});

const StyledInput = styled(InputText)(({ theme }) => ({
  '& > div': {
    backgroundColor: theme.palette.background.paper,
    height: '3rem',
    width: '100%',
  },
}));

const ErrorMessage = styled('p')(({ theme }) => ({
  ...theme.typography.caption,
  color: `${theme.palette.error.main}`,
  margin: '3px 14px 0 14px',
}));

interface TextFieldParamsProps extends AutocompleteRenderInputParams {
  InputLabelProps: Omit<
    ReturnType<ReturnType<typeof useAutocomplete>['getInputLabelProps']>,
    'children'
  >;
}

type GroupAutoCompleteProps = {
  onSelect: (value: Group | null) => void;
  options: Group[];
  loading?: boolean;
  helperText?: string | null;
  required?: boolean;
  error?: boolean;
  selectedValue?: string | null;
  fieldLabel?: string | React.ReactNode;
};
const GroupAutoComplete = ({
  options,
  selectedValue,
  onSelect,
  loading,
  helperText,
  required,
  error,
  fieldLabel,
}: GroupAutoCompleteProps) => {
  const { t } = useTranslation();
  const getSelectedItem = () =>
    options?.length && selectedValue
      ? (options.find(({ id }) => id === selectedValue) as Group)
      : null;

  return (
    <>
      <Autocomplete
        id="group-search"
        data-testid="group-autocomplete"
        options={options}
        fullWidth
        noOptionsText={t(
          'ui.event-management.organisations.add.to-group.no-options-text',
        )}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        onChange={(_, changeValue, reason) => {
          if (
            reason === ('selectOption' as AutocompleteChangeReason) &&
            changeValue !== null
          ) {
            onSelect(changeValue);
          }
        }}
        filterOptions={createFilterOptions({
          stringify: (option: Group) => `${option.name}`,
        })}
        getOptionLabel={(option) => option.name || ''}
        renderOption={(fullProps, val) => {
          const { color, ...rest } = fullProps;
          return (
            <li {...rest} key={val.id} data-testid={`group-option-${val.id}`}>
              <Span variant="body-bold" color="primary">
                {val.name}
              </Span>
            </li>
          );
        }}
        value={getSelectedItem()}
        loading={loading}
        renderInput={(params: TextFieldParamsProps) => {
          const { size, id, ...rest } = params;
          const { ref } = rest.InputProps;
          const { children, ...props } = rest.inputProps;
          return (
            <div ref={ref}>
              <StyledInput
                data-testid="group-autocomplete-text-field"
                id={id}
                {...props}
                label={fieldLabel}
                placeholder={t(
                  'ui.event-management.organisations.add.select-group-placeholder',
                )}
                required={!!required}
                error={error}
                type={'text' as InputType}
                sx={{ with: '100%' }}
              />
            </div>
          );
        }}
        sx={{ marginTop: '0.5rem', borderColor: 'black' }}
      />
      {helperText ? <ErrorMessage>{helperText}</ErrorMessage> : null}
    </>
  );
};

type Props = {
  options: Group[];
  selectedGroup?: (Group & { organisations?: OrganisationResponse[] }) | null;
  onSelect: (
    group: (Group & { organisations?: OrganisationResponse[] }) | null,
  ) => void;
  sx?: SxProps<Theme>;
  selectedLabel?: string | React.ReactNode;
  fieldLabel?: string | React.ReactNode;
  error?: string | null;
  actionType?: 'remove' | 'change';
  disabled?: boolean;
};
const SelectGroup = ({
  selectedGroup = null,
  options,
  onSelect,
  sx,
  selectedLabel,
  fieldLabel,
  error,
  actionType = 'remove',
  disabled,
}: Props) => {
  return (
    <StyledDiv sx={sx}>
      {!disabled && selectedGroup === null ? (
        <GroupAutoComplete
          fieldLabel={fieldLabel}
          selectedValue={selectedGroup}
          options={options}
          onSelect={onSelect}
          error={!!error}
          helperText={error}
        />
      ) : (
        <DisplayOrgAssign
          {...{
            selectedGroup,
            onSelect,
            selectedLabel,
            error,
            actionType,
            disabled,
          }}
        />
      )}
    </StyledDiv>
  );
};

export default SelectGroup;
