import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { P, Span } from '@insights-ltd/design-library/components';
import usePagination, { Paginator } from 'components/hooks/usePagination';
import { TransactionResponse } from 'api';
import { Link as RouterLink } from 'react-router-dom';
import UnescapedTrans from 'components/i18n/UnescapedTrans';
import { isoToLocaleString } from 'utils/dates';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const ITEMS_PER_PAGE = 12;

const unitOptions = {
  CREDIT: 'ui.event-management.transactions.content.units_credited',
  REMOVE: 'ui.event-management.transactions.content.units_removed',
  TRANSFER: 'ui.event-management.transactions.content.units_transferred',
  PURCHASE: 'ui.event-management.transactions.content.units-spent',
};

const TransactionDescription = ({
  transaction,
}: {
  transaction: TransactionResponse;
}) => {
  const description = transaction.comment && `"${transaction.comment}"`;

  switch (transaction.operation) {
    case 'TRANSFER': {
      return (
        <UnescapedTrans
          i18nKey="ui.event-management.transactions.content.units_transferred.description.bold"
          components={{ bold: <Span variant="body-bold" /> }}
          values={{
            senderFullName: transaction.sender.fullName,
            recipientFullName: transaction.recipient.fullName,
            description,
          }}
        />
      );
    }
    case 'PURCHASE': {
      return (
        <UnescapedTrans
          i18nKey="ui.event-management.transactions.content.units-spent.profiles-description.bold"
          components={{ bold: <Span variant="body-bold" /> }}
          values={{
            recipientFullName: transaction.recipient.fullName,
            description,
          }}
        />
      );
    }
    default: {
      return (
        <UnescapedTrans
          i18nKey="ui.event-management.transactions.content.description.bold"
          components={{ bold: <Span variant="body-bold" /> }}
          values={{
            fullName: transaction.createdBy.fullName,
            description,
          }}
        />
      );
    }
  }
};

const OrganisationButton = ({ id, name }: { id?: string; name?: string }) => (
  <Button
    color="primary"
    component={RouterLink}
    to={`/organisations/${id}`}
    sx={(theme) => ({
      justifyContent: 'left',
      paddingLeft: '0 !important',
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
    })}
  >
    {name}
  </Button>
);

const Cell = ({
  children,
  bold = false,
  secondary = false,
}: {
  children: React.ReactNode;
  bold?: boolean;
  secondary?: boolean;
}) => (
  <TableCell>
    <Span
      variant={bold ? 'body-bold' : 'body'}
      color={secondary ? 'textSecondary' : 'textPrimary'}
    >
      {children}
    </Span>
  </TableCell>
);

const PaginationControls = ({
  label,
  pagination,
}: {
  label: React.ReactNode;
  pagination: React.ReactNode;
}) => {
  return (
    <>
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
      <Grid
        container
        item
        alignItems="baseline"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid container item alignItems="center">
          <Box mr={3} flexWrap="nowrap">
            {label}
          </Box>
          {pagination}
        </Grid>
      </Grid>
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
    </>
  );
};

const ItemCountLabel = ({
  currentPage,
  totalItems,
}: {
  currentPage: number;
  totalItems: number;
}) => {
  const { t } = useTranslation();
  const start = ITEMS_PER_PAGE * currentPage - (ITEMS_PER_PAGE - 1);
  const end = ITEMS_PER_PAGE * currentPage;

  return (
    <P color="textSecondary">
      {t('ui.event-management.transactions.item_count_label', {
        start: start > totalItems ? totalItems : start,
        end: end > totalItems ? totalItems : end,
        max: totalItems,
      })}
    </P>
  );
};

const Controls = ({
  totalCount,
  pageNumber,
  pageCount,
  getPaginationAriaLabel,
  handlePageChange,
}: Paginator<TransactionResponse>) => (
  <PaginationControls
    label={<ItemCountLabel currentPage={pageNumber} totalItems={totalCount} />}
    pagination={
      <Pagination
        count={pageCount}
        getItemAriaLabel={getPaginationAriaLabel}
        hidePrevButton
        onChange={(_, page) => handlePageChange(page)}
        page={pageNumber}
      />
    }
  />
);

const TransactionsTable = ({
  transactions,
  isMultiOrgView = false,
}: {
  transactions: TransactionResponse[];
  isMultiOrgView?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;

  const paginator = usePagination<TransactionResponse>(
    transactions!,
    ITEMS_PER_PAGE,
  );

  return (
    <>
      <Controls {...paginator} />
      <TableContainer component={Paper} elevation={0} variant="outlined">
        <Table>
          <TableBody>
            <TableRow
              sx={(theme) => ({ backgroundColor: theme.palette.grey[200] })}
            >
              <Cell bold secondary>
                {t('ui.event-management.transactions.header.datetime')}
              </Cell>
              <Cell bold secondary>
                {t('ui.event-management.transactions.header.reference')}
              </Cell>
              <Cell bold secondary>
                {t('ui.event-management.transactions.header.units')}
              </Cell>
              <Cell bold secondary>
                {t('ui.event-management.transactions.header.description')}
              </Cell>
              <Cell bold secondary>
                {t('ui.event-management.transactions.header.unit_wallet')}
              </Cell>
              {isMultiOrgView ? (
                <Cell bold secondary>
                  {t('ui.event-management.transactions.header.organisation')}
                </Cell>
              ) : null}
            </TableRow>
            {paginator.pagedItems.map((transaction) => (
              <TableRow
                key={`transaction-${transaction.createdAt + Math.random()}`}
              >
                <Cell>{isoToLocaleString(transaction.createdAt, locale)}</Cell>
                <Cell>{transaction.reference}</Cell>
                <Cell bold>
                  {t(unitOptions[transaction.operation], {
                    units: transaction.units,
                  })}
                </Cell>
                <Cell>
                  <TransactionDescription transaction={transaction} />
                </Cell>
                <Cell>{transaction.walletBalance}</Cell>
                {isMultiOrgView ? (
                  <Cell>
                    <OrganisationButton {...transaction.organisation} />
                  </Cell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Controls {...paginator} />
    </>
  );
};

export default TransactionsTable;
