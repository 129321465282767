import {
  AllDialectOptions,
  DialectCodes,
  DialectDetails,
  Dialects,
  Inclusivity,
  Pronoun,
} from 'types/dialects';
import { LicensedProduct, ProfileType } from 'types/types';

export const DIALECT_CODES: DialectCodes = {
  AFRIKAANS: 'af-ZA',
  BAHASA: 'id-ID',
  BULGARIAN: 'bg-BG',
  CHINESE_SIMPLIFIED: 'zh-CN',
  CHINESE_TRADITIONAL_HK: 'zh-HK',
  CROATIAN: 'hr-HR',
  CZECH: 'cs-CZ',
  DANISH: 'da-DK',
  DUTCH: 'nl-NL',
  ENGLISH: 'en-GB',
  ENGLISH_SOUTH_AFRICA: 'en-ZA',
  ESTONIAN: 'et-EE',
  FINNISH: 'fi-FI',
  FRENCH: 'fr-FR',
  FRENCH_CANADA: 'fr-CA',
  GEORGIAN: 'ka-GE',
  GERMAN: 'de-DE',
  GREEK: 'el-GR',
  HUNGARIAN: 'hu-HU',
  ITALIAN: 'it-IT',
  JAPANESE: 'ja-JP',
  KOREAN: 'ko-KR',
  LITHUANIAN: 'lt-LT',
  NORWEGIAN_BOKMAL: 'nb-NO',
  POLISH: 'pl-PL',
  PORTUGUESE_BRAZIL: 'pt-BR',
  PORTUGUESE_PORTUGAL: 'pt-PT',
  ROMANIAN: 'ro-RO',
  RUSSIAN: 'ru-RU',
  SERBIAN_LATIN: 'sr-RS',
  SLOVAKIAN: 'sk-SK',
  SLOVENIAN: 'sl-SI',
  SPANISH_MEXICO: 'es-MX',
  SPANISH_SPAIN: 'es-ES',
  SWEDISH: 'sv-SE',
  TURKISH: 'tr-TR',
  UKRAINIAN: 'uk-UA',
  VIETNAMESE: 'vi-VN',
  EVALUATION_DIALECT: 'evaluation-dialect',
};

export const PRONOUN_HE: Pronoun = 'HE';
export const PRONOUN_SHE: Pronoun = 'SHE';
export const PRONOUN_THEY: Pronoun = 'THEY';

export const PRONOUNS_PLURAL_INCLUSIVE: Inclusivity[] = [
  { textKey: 'ui.event-management.pronouns.he/him/his', value: PRONOUN_HE },
  {
    textKey: 'ui.event-management.pronouns.she/her/hers',
    value: PRONOUN_SHE,
  },
  {
    textKey: 'ui.event-management.pronouns.they/them/theirs',
    value: PRONOUN_THEY,
  },
];

export const PRONOUNS_PLURAL_EXCLUSIVE: Inclusivity[] = [
  { textKey: 'ui.event-management.pronouns.he/him/his', value: PRONOUN_HE },
  {
    textKey: 'ui.event-management.pronouns.she/her/hers',
    value: PRONOUN_SHE,
  },
];

export const PRONOUNS_SINGULAR_INCLUSIVE: Inclusivity[] = [
  { textKey: 'ui.event-management.pronouns.he/him', value: PRONOUN_HE },
  { textKey: 'ui.event-management.pronouns.she/her', value: PRONOUN_SHE },
  {
    textKey: 'ui.event-management.pronouns.they/them',
    value: PRONOUN_THEY,
  },
];

export const PRONOUNS_SINGULAR_EXCLUSIVE: Inclusivity[] = [
  { textKey: 'ui.event-management.pronouns.he/him', value: PRONOUN_HE },
  {
    textKey: 'ui.event-management.pronouns.she/her',
    value: PRONOUN_THEY,
  },
];

export const DIALECTS: DialectDetails = {
  [DIALECT_CODES.EVALUATION_DIALECT]: {
    textKey: 'ui.event-management.events.download-profiles.evaluation-dialect',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_INCLUSIVE,
      plural: PRONOUNS_PLURAL_INCLUSIVE,
    },
  },
  [DIALECT_CODES.AFRIKAANS]: {
    textKey: 'ui.event-management.dialects.afrikaans',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.BAHASA]: {
    textKey: 'ui.event-management.dialects.bahasa',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.BULGARIAN]: {
    textKey: 'ui.event-management.dialects.bulgarian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.CHINESE_SIMPLIFIED]: {
    textKey: 'ui.event-management.dialects.chinese',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.CHINESE_TRADITIONAL_HK]: {
    textKey: 'ui.event-management.dialects.chinese',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.CROATIAN]: {
    textKey: 'ui.event-management.dialects.croatian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.CZECH]: {
    textKey: 'ui.event-management.dialects.czech',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.DANISH]: {
    textKey: 'ui.event-management.dialects.danish',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.DUTCH]: {
    textKey: 'ui.event-management.dialects.dutch',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.ENGLISH]: {
    textKey: 'ui.event-management.dialects.english',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
      'INSIGHTS_DISCOVERY_FULL_CIRCLE',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_INCLUSIVE,
      plural: PRONOUNS_PLURAL_INCLUSIVE,
    },
  },
  [DIALECT_CODES.ENGLISH_ZA]: {
    textKey: 'ui.event-management.dialects.english',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.ESTONIAN]: {
    textKey: 'ui.event-management.dialects.estonian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.FINNISH]: {
    textKey: 'ui.event-management.dialects.finnish',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.FRENCH]: {
    textKey: 'ui.event-management.dialects.french',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.FRENCH_CANADA]: {
    textKey: 'ui.event-management.dialects.french',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.GEORGIAN]: {
    textKey: 'ui.event-management.dialects.georgian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.GERMAN]: {
    textKey: 'ui.event-management.dialects.german',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.GREEK]: {
    textKey: 'ui.event-management.dialects.greek',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.HUNGARIAN]: {
    textKey: 'ui.event-management.dialects.hungarian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.ITALIAN]: {
    textKey: 'ui.event-management.dialects.italian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.JAPANESE]: {
    textKey: 'ui.event-management.dialects.japanese',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.KOREAN]: {
    textKey: 'ui.event-management.dialects.korean',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.LITHUANIAN]: {
    textKey: 'ui.event-management.dialects.lithuanian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.NORWEGIAN_BOKMAL]: {
    textKey: 'ui.event-management.dialects.norwegian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.POLISH]: {
    textKey: 'ui.event-management.dialects.polish',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.PORTUGUESE_BRAZIL]: {
    textKey: 'ui.event-management.dialects.portuguese',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.PORTUGUESE_PORTUGAL]: {
    textKey: 'ui.event-management.dialects.portuguese',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.ROMANIAN]: {
    textKey: 'ui.event-management.dialects.romanian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.RUSSIAN]: {
    textKey: 'ui.event-management.dialects.russian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.SERBIAN_LATIN]: {
    textKey: 'ui.event-management.dialects.serbian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.SLOVAKIAN]: {
    textKey: 'ui.event-management.dialects.slovak',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.SLOVENIAN]: {
    textKey: 'ui.event-management.dialects.slovenian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.SPANISH_MEXICO]: {
    textKey: 'ui.event-management.dialects.spanish',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.SPANISH_SPAIN]: {
    textKey: 'ui.event-management.dialects.spanish',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.SWEDISH]: {
    textKey: 'ui.event-management.dialects.swedish',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.TURKISH]: {
    textKey: 'ui.event-management.dialects.turkish',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.UKRAINIAN]: {
    textKey: 'ui.event-management.dialects.ukrainian',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
  [DIALECT_CODES.VIETNAMESE]: {
    textKey: 'ui.event-management.dialects.vietnamese',
    eventType: [
      'INSIGHTS_DISCOVERY_WORKSHOP',
      'INSIGHTS_SELF_AWARE_LEADER',
      'INSIGHTS_DISCOVERING_LEADERSHIP_EFFECTIVENESS',
    ],
    pronouns: {
      singular: PRONOUNS_SINGULAR_EXCLUSIVE,
      plural: PRONOUNS_PLURAL_EXCLUSIVE,
    },
  },
};

export type Model = 'EXPLORE' | 'DFC' | 'ALL_DIALECTS';

export type DialectKeyValue = Pick<DialectMap, 'textKey' | 'value'>;

export type DialectMap = {
  textKey: string;
  value: AllDialectOptions;
  evaluator: boolean;
  profile: boolean;
  teamWheel: boolean;
  wheelFontSize: number;
  tableFontSize: number;
  disabledForProducts?: LicensedProduct[];
  disabledForProfiles: ProfileType[];
};

export const DIALECT_MAP: DialectMap[] = [
  {
    textKey: 'ui.event-management.events.download-profiles.evaluation-dialect',
    value: DIALECT_CODES.EVALUATION_DIALECT,
    evaluator: true,
    profile: false,
    teamWheel: false,
    wheelFontSize: 15,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.afrikaans_south_africa',
    value: DIALECT_CODES.AFRIKAANS,
    evaluator: false,
    profile: false,
    teamWheel: false,
    wheelFontSize: 15,
    tableFontSize: 6,
    disabledForProfiles: [
      'IDTL_PROFILE',
      'DFC_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.bahasa_indonesia',
    value: DIALECT_CODES.BAHASA,
    evaluator: false,
    profile: false,
    teamWheel: false,
    wheelFontSize: 15,
    tableFontSize: 6,
    disabledForProfiles: [
      'IDTL_PROFILE',
      'DFC_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.bulgarian_bulgaria',
    value: DIALECT_CODES.BULGARIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 3,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.chinese_simplified',
    value: DIALECT_CODES.CHINESE_SIMPLIFIED,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 20,
    tableFontSize: 8,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.chinese_traditional',
    value: DIALECT_CODES.CHINESE_TRADITIONAL_HK,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 20,
    tableFontSize: 8,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.croatian_croatia',
    value: DIALECT_CODES.CROATIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.czech_czech_republic',
    value: DIALECT_CODES.CZECH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 16,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.danish_denmark',
    value: DIALECT_CODES.DANISH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 16,
    tableFontSize: 4,
    disabledForProfiles: ['IDTL_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.dutch_netherlands',
    value: DIALECT_CODES.DUTCH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 19,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.english_south_africa',
    value: DIALECT_CODES.ENGLISH_SOUTH_AFRICA,
    evaluator: false,
    profile: false,
    teamWheel: false,
    wheelFontSize: 20,
    tableFontSize: 6,
    disabledForProfiles: [
      'IDTL_PROFILE',
      'DFC_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
    disabledForProducts: <LicensedProduct[]>[
      'DISCOVERY_SELF_AWARE_PROFILE',
      'DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE',
      'DISCOVERY_FULL_CIRCLE_PROFILE',
    ],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.english_united_kingdom',
    value: DIALECT_CODES.ENGLISH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 20,
    tableFontSize: 6,
    disabledForProfiles: [],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.estonian_estonia',
    value: DIALECT_CODES.ESTONIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 19,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.finnish_finland',
    value: DIALECT_CODES.FINNISH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 20,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.french_france',
    value: DIALECT_CODES.FRENCH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 17,
    tableFontSize: 4,
    disabledForProfiles: ['IDTL_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.french_canada',
    value: DIALECT_CODES.FRENCH_CANADA,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 17,
    tableFontSize: 4,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.georgian_georgia',
    value: DIALECT_CODES.GEORGIAN,
    evaluator: false,
    profile: false,
    teamWheel: false,
    wheelFontSize: 17,
    tableFontSize: 6,
    disabledForProfiles: [
      'IDTL_PROFILE',
      'DFC_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.german_germany',
    value: DIALECT_CODES.GERMAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 16,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.greek_greece',
    value: DIALECT_CODES.GREEK,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 17,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.hungarian_hungary',
    value: DIALECT_CODES.HUNGARIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.italian_italy',
    value: DIALECT_CODES.ITALIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 17,
    tableFontSize: 4,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.japanese_japan',
    value: DIALECT_CODES.JAPANESE,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.korean_korea',
    value: DIALECT_CODES.KOREAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 20,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.lithuanian_lithuania',
    value: DIALECT_CODES.LITHUANIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.norwegian_norway',
    value: DIALECT_CODES.NORWEGIAN_BOKMAL,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 16,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.polish_poland',
    value: DIALECT_CODES.POLISH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.portuguese_brazil',
    value: DIALECT_CODES.PORTUGUESE_BRAZIL,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 3,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.portuguese_portugal',
    value: DIALECT_CODES.PORTUGUESE_PORTUGAL,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.romanian_romania',
    value: DIALECT_CODES.ROMANIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.russian_russian_federation',
    value: DIALECT_CODES.RUSSIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 15,
    tableFontSize: 4,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.serbian_bosnia_herzegovina_latin',
    value: DIALECT_CODES.SERBIAN_LATIN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.slovak_slovakia',
    value: DIALECT_CODES.SLOVAKIAN,
    evaluator: false,
    profile: false,
    teamWheel: false,
    wheelFontSize: 15,
    tableFontSize: 6,
    disabledForProfiles: [
      'IDTL_PROFILE',
      'DFC_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.slovenian_slovenia',
    value: DIALECT_CODES.SLOVENIAN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 19,
    tableFontSize: 5,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.spanish_mexico',
    value: DIALECT_CODES.SPANISH_MEXICO,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 4,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.spanish_spain',
    value: DIALECT_CODES.SPANISH_SPAIN,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 4,
    disabledForProfiles: ['IDTL_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.swedish_sweden',
    value: DIALECT_CODES.SWEDISH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 18,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey: 'ui.event-management.events.create.event-language.turkish_turkey',
    value: DIALECT_CODES.TURKISH,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 17,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.ukrainian_ukraine',
    value: DIALECT_CODES.UKRAINIAN,
    evaluator: false,
    profile: false,
    teamWheel: false,
    wheelFontSize: 15,
    tableFontSize: 6,
    disabledForProfiles: [
      'IDTL_PROFILE',
      'DFC_PROFILE',
      'DISCOVERY_PERSONAL_PROFILE',
    ],
  },
  {
    textKey:
      'ui.event-management.events.create.event-language.vietnamese_vietnam',
    value: DIALECT_CODES.VIETNAMESE,
    evaluator: true,
    profile: true,
    teamWheel: true,
    wheelFontSize: 10,
    tableFontSize: 6,
    disabledForProfiles: ['IDTL_PROFILE', 'DFC_PROFILE'],
  },
];

export const getDialect = (dialectCode: Dialects) =>
  DIALECT_MAP.find(({ value }) => value === dialectCode);

export const ENABLED_EVALUATOR_DIALECTS_NO_PREFERRED = DIALECT_MAP.filter(
  ({ evaluator, value }) =>
    evaluator && value !== DIALECT_CODES.EVALUATION_DIALECT,
);

export const ENABLED_EVALUATOR_DIALECTS = DIALECT_MAP.filter(
  ({ evaluator }) => evaluator,
);

export const ENABLED_WHEEL_DIALECTS = DIALECT_MAP.filter(
  ({ teamWheel }) => teamWheel,
);

export const ENABLED_PROFILE_DIALECTS = DIALECT_MAP.filter(
  ({ profile }) => profile,
);

export const DFC_CORE_LANGUAGES = DIALECT_MAP.filter(
  (dialect) =>
    !dialect.disabledForProfiles.includes('DFC_PROFILE') && dialect.profile,
);

export const isIDTLSupported = (dialect?: Dialects): boolean => {
  const detailedDialect = dialect ? getDialect(dialect) : undefined;

  return (
    !!dialect &&
    !!detailedDialect &&
    !detailedDialect.disabledForProfiles.includes('IDTL_PROFILE')
  );
};
