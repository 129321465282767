import { RequestError } from 'api';
import { useTranslation } from 'react-i18next';

export type GroupOrganisationError =
  | 'OTHER'
  | 'GROUPED_ORGANISATION_NAME_ALREADY_TAKEN'
  | 'UNGROUPED_ORGANISATION_NAME_ALREADY_TAKEN';

export const useGetGroupOrganisationErrorMessage = (
  error: GroupOrganisationError,
  organisationToAdd: string,
): string => {
  const { t } = useTranslation();

  if (error === 'GROUPED_ORGANISATION_NAME_ALREADY_TAKEN') {
    return t(
      'ui.event-management.group.add-remove-organisation.error.add.grouped-org-already-exists',
      { child: organisationToAdd },
    );
  }

  if (error === 'UNGROUPED_ORGANISATION_NAME_ALREADY_TAKEN') {
    return t(
      'ui.event-management.group.add-remove-organisation.error.remove.ungrouped-org-already-exists',
      { child: organisationToAdd },
    );
  }
  return t('ui.event-management.group.add-remove-organisation.error.add', {
    child: organisationToAdd,
  });
};

export const errorHandler =
  (
    errorSetter: (value: GroupOrganisationError) => void,
    showErrorDialog: (value: boolean) => void,
  ) =>
  (err: RequestError) => {
    if (err.errorCodes.includes('GROUPED_ORGANISATION_NAME_ALREADY_TAKEN')) {
      errorSetter('GROUPED_ORGANISATION_NAME_ALREADY_TAKEN');
      return;
    }
    if (err.errorCodes.includes('UNGROUPED_ORGANISATION_NAME_ALREADY_TAKEN')) {
      errorSetter('UNGROUPED_ORGANISATION_NAME_ALREADY_TAKEN');
      return;
    }
    showErrorDialog(true);
  };
