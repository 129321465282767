import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, styled } from '@mui/material';
import { ChapterCost, LearnerEvaluation } from 'api';
import { P, Span } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { sortChapters } from 'utils/mappers/sorting';
import { chapterNameMap } from 'components/PurchaseProfiles/ChapterCheckboxes/ChapterNameMap';
import { isoToLocaleString } from 'utils/dates';
import { LearnerErrorList } from './LearnerErrorList';
import { RemoveButton } from './RemoveButton';

const AssignedChapters = styled('div')(({ theme }) => ({
  position: 'static',
  top: theme.spacing(spacingSizeMap.XS),
  left: '0',
  width: '12px',
  height: '12px',
  marginRight: theme.spacing(spacingSizeMap.XS),
  borderRadius: '50%',
  border: `2px solid ${theme.palette.orange.main}`,
}));

const PurchasedChapters = styled('div')(({ theme }) => ({
  position: 'static',
  top: theme.spacing(spacingSizeMap.XS),
  left: '0',
  width: '12px',
  height: '12px',
  marginRight: theme.spacing(spacingSizeMap.XS),
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
}));

const ChaptersStatus = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& span': {
    lineHeight: '1.8rem',
  },
});

const ChaptersList = styled('div')({
  '& > p': {
    fontWeight: '600 !important',
  },
});

const LearnerListRow = ({
  latestEvaluation,
  fullName,
  primaryEmail,
  purchaseTotal = 0,
  removeCheckedItem,
  assignedChapters,
  purchasedChapters,
  cantPurchaseIDTL,
}: {
  latestEvaluation: LearnerEvaluation;
  fullName: string;
  primaryEmail: string;
  id: string;
  purchaseTotal?: number;
  removeCheckedItem?: (id: string) => void;
  assignedChapters: ChapterCost[];
  purchasedChapters: string[];
  cantPurchaseIDTL: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const assignedChaptersList = assignedChapters
    .sort(({ name: nameA }, { name: nameB }) => sortChapters(nameA, nameB))
    .map((chapter) => t(chapterNameMap[chapter.name]))
    .join(', ');

  const purchasedChaptersList = purchasedChapters
    .sort((nameA, nameB) => sortChapters(nameA, nameB))
    .map((chapter) => t(chapterNameMap[chapter]))
    .join(', ');

  if (cantPurchaseIDTL) {
    return (
      <LearnerErrorList
        removeCheckedItem={removeCheckedItem}
        latestProfiles={latestEvaluation.latestProfiles}
        fullName={fullName}
        primaryEmail={primaryEmail}
        latestEvaluationId={latestEvaluation.id}
      />
    );
  }
  return (
    <Grid
      container
      alignItems="baseline"
      spacing={1}
      sx={(theme) => ({
        width: '100%',
        marginTop: '0',
        marginBottom: theme.spacing(spacingSizeMap.XS),
        padding: `0.75rem 1rem 0.75rem`,
        wordBreak: 'break-word',
        border: `solid 1px ${theme.palette.grey[500]}`,
        borderRadius: '4px',
        ...(assignedChapters.length === 0 && {
          backgroundColor: theme.palette.grey[300],
          border: '0',
        }),
      })}
    >
      <Grid
        item
        xs={3}
        sx={() => ({
          '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          paddingTop: `0 !important`,
        })}
      >
        <P variant="body-bold">{fullName}</P>
        <P color="textSecondary">{primaryEmail}</P>
        <P color="textSecondary">
          {t(
            'ui.event-management.learners.purchase-profiles.your-basket.completed-date',
            {
              completedDate: isoToLocaleString(
                latestEvaluation.submittedOn,
                locale,
              ),
            },
          )}
        </P>
      </Grid>
      <Grid sx={{ paddingTop: `0 !important` }} item xs={1}>
        <RemoveButton
          removeCheckedItem={removeCheckedItem}
          latestEvaluationId={latestEvaluation.id}
          t={t}
        />
      </Grid>
      <Grid
        sx={(theme) => ({
          padding: `0 ${theme.spacing(spacingSizeMap.S)} !important`,
        })}
        item
        xs={4}
      >
        {assignedChapters.length > 0 && (
          <>
            <ChaptersStatus>
              <AssignedChapters />
              <span>
                &nbsp;
                {t('ui.event-management.events.assign-chapters.assigned')}
              </span>
            </ChaptersStatus>
            <ChaptersList>
              <P data-testid={`${latestEvaluation.id}-assign-chapters`}>
                {assignedChaptersList}
              </P>
            </ChaptersList>
          </>
        )}
      </Grid>
      <Grid
        sx={(theme) => ({
          padding: `0 ${theme.spacing(spacingSizeMap.S)} !important`,
        })}
        item
        xs={3}
      >
        {purchasedChapters.length > 0 && (
          <>
            <ChaptersStatus>
              <PurchasedChapters />
              <span>
                &nbsp;
                {t('ui.event-management.events.assign-chapters.purchased')}
              </span>
            </ChaptersStatus>
            <ChaptersList>
              <P data-testid={`${latestEvaluation.id}-purchase-chapters`}>
                {purchasedChaptersList}
              </P>
            </ChaptersList>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          paddingTop: `0 !important`,
        }}
      >
        <Span
          color="textSecondary"
          variant="body-bold"
          data-testid={`${latestEvaluation.id}-sub-total`}
        >
          {purchaseTotal}&nbsp;
          {t('ui.event-management.events.assign-chapters.summary-box.units')}
        </Span>
      </Grid>
    </Grid>
  );
};

export default LearnerListRow;
