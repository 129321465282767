import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgSingleNeutral = ({ title, ...props }: SvgComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    {title ? <title>{title}</title> : null}
    <g transform="scale(.66667)">
      <circle cx={12} cy={6.25} r={5.5} style={{ fill: '#b1105d' }} />
      <path
        d="M12 13.25a9.51 9.51 0 0 0-9.5 9.5.5.5 0 0 0 .5.5h18a.5.5 0 0 0 .5-.5 9.51 9.51 0 0 0-9.5-9.5Z"
        style={{ fill: '#b1105d' }}
      />
    </g>
  </svg>
);

export default SvgSingleNeutral;
