import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import { RequestError } from 'api';
import { styled, Alert } from '@mui/material';
import FormHeader from 'components/FormHeader';
import { getTheme, spacingSizeMap } from '@insights-ltd/design-library/themes';
import { InputText } from '@insights-ltd/design-library/components';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import PermissionGuard from 'components/PermissionGuard';
import { OrganisationData } from './types';

type Props = {
  organisation: OrganisationData;
  isGroupedOrg?: boolean;
  showWarning?: boolean;
  onSubmit: (
    formData: OrganisationData,
    onError: (error: RequestError) => void,
  ) => void;
  children: ReactNode;
};

const localTheme = getTheme();

const alertProps = {
  border: '0',
  '&.MuiAlert-filledInfo': {
    border: '0',
    backgroundColor: localTheme.brandColours.lightBlue,
    color: localTheme.grey[700],
  },
  '&.MuiAlert-filledError': {
    border: '0',
    backgroundColor: localTheme.validationColours.lightError,
    color: localTheme.validationColours.error,
  },
};

const StyledForm = styled('form')(({ theme }) => ({
  '& > *': {
    marginTop: theme.spacing(spacingSizeMap.M),
  },
}));

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  fill: theme.palette.pink.dark,
  height: '20px',
}));

interface StyledInputTextProps {
  disabled?: boolean;
}
const StyledInputText = styled(InputText)<StyledInputTextProps>(
  ({ theme, disabled }) => ({
    width: '100%',
    '& > div': {
      height: '3rem',
      backgroundColor: !disabled ? theme.palette.common.white : null,
    },
    '& > span': {
      marginTop: '0.25rem',
    },
  }),
);

const AddOrganisationForm = ({
  organisation,
  onSubmit,
  children,
  isGroupedOrg = false,
  showWarning = false,
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    setValue,
  } = useForm<OrganisationData>({
    defaultValues: organisation,
  });
  const { t } = useTranslation();
  const { openErrorModal } = useRequestErrorContext();

  const messages = {
    required: t('ui.event-management.organisations.add.error.name-required'),
    notUnique: t('ui.event-management.organisations.add.error.not-unique'),
    notUniqueGroupedOrg: t(
      'ui.event-management.organisations.add.error.not-unique-grouped-organisation',
    ),
    pattern: t(
      'ui.event-management.organisations.add.error.no-trailing-or-leading-spaces',
    ),
  };

  const submitHandler = handleSubmit((formData: OrganisationData) => {
    const processedData = {
      ...formData,
      name: formData.name.trim(),
    };

    setValue('name', processedData.name);

    if (processedData.name === '') {
      return setError('name', { type: 'required' });
    }

    return onSubmit(processedData, (error: RequestError) => {
      if (
        error.errorCodes?.includes('UNGROUPED_ORGANISATION_NAME_ALREADY_TAKEN')
      ) {
        setError('name', { type: 'notUnique' });
      }
      if (
        error.errorCodes?.includes('GROUPED_ORGANISATION_NAME_ALREADY_TAKEN')
      ) {
        setError('name', { type: 'notUniqueGroupedOrg' });
      }

      if (error.status === 500) {
        openErrorModal();
      }
    });
  });

  const errorText =
    errors.name && messages[errors.name.type as keyof typeof messages];

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={submitHandler}>
      <FormHeader
        icon={StyledOrganisationIcon}
        title={t('ui.event-management.organisations.add.organisation-details')}
        showMargin={false}
      />
      <StyledInputText
        label={t('ui.event-management.organisations.add.label.company-name')}
        id="organisation-name-field"
        placeholder={t(
          'ui.event-management.organisations.add.placeholder.company-name',
        )}
        fullWidth
        maxLength={100}
        {...register('name', {
          required: true,
        })}
        error={Boolean(errors.name)}
        helperText={errorText}
      />
      <PermissionGuard
        requiredPermissions={[
          { action: 'Organisation_Group_Update', scope: 'Global' },
        ]}
      >
        {!isGroupedOrg && showWarning ? (
          <Alert sx={alertProps} variant="filled" severity="info">
            {t('ui.event-management.organisations.add.to-group-message')}
          </Alert>
        ) : null}
      </PermissionGuard>

      {children}
    </StyledForm>
  );
};

export default AddOrganisationForm;
