import React from 'react';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import { Alert, Dialog, Span } from '@insights-ltd/design-library/components';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type Props = {
  open: boolean;
  isError: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const StyledAlert = styled(Alert)({
  display: 'flex !important',
  alignItems: 'flex-start !important',
  '&> div:first-of-type': {
    marginTop: '4px',
  },
});

const ConfirmOrgDialog = ({
  open,
  onClose,
  onConfirm,
  isError,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      title={t('ui.event-management.learner.confirm-org.heading')}
      onClose={onClose}
      onSubmit={onConfirm}
      content={
        <>
          <DialogContentText id="alert-dialog-description">
            {t(
              'ui.event-management.learners.organisation.change-org.confirm-description',
            )}
          </DialogContentText>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)} />
          {isError ? (
            <StyledAlert
              iconType="warning"
              kind="error"
              data-testid="error-message"
            >
              <Span variant="body-bold" color="error">
                {t('ui.event-management.learner.confirm-org.error')}
              </Span>
            </StyledAlert>
          ) : (
            <StyledAlert
              iconType="warning"
              kind="error"
              data-testid="warning-message"
            >
              <Span variant="body-bold" color="error">
                {t('ui.event-management.learner.confirm-org.body-bottom')}
              </Span>
            </StyledAlert>
          )}
        </>
      }
      isLoading={isLoading}
      primaryButtonText={t('ui.event-management.learner.confirm-org.confirm')}
      secondaryButtonText={t('ui.event-management.learner.confirm-org.cancel')}
    />
  );
};

export default ConfirmOrgDialog;
