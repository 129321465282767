import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Dialects } from 'types/dialects';
import {
  DotType,
  ScoresGroupingType,
  ScoreType,
  SortDirection,
  SortType,
} from 'types/types';
import { CoordPair } from 'pages/DownloadTeamWheel/TeamWheel72/teamWheelUtils';
import { OrganisationResponse } from 'api';

export type LabelType = 'NUMBER' | 'INITIALS' | 'ANONYMOUS' | 'OTHER';

type Overrides = {
  getDotPositionOverride: (dotKey: string) => CoordPair | undefined;
  setDotPositionOverride: (dotKey: string, newOverride: CoordPair) => void;
  resetDotPositionOverrides: () => void;
};

export type WheelState = {
  fontSize: number;
  tableFontSize: number;
  scoreType: ScoreType;
  dotType: DotType;
  languageType: Dialects;
  labelType: LabelType;
  scoresGroupingType: ScoresGroupingType;
  sortType: SortType;
  sortDirection: SortDirection;
  isLearnerCountExceeded: boolean;
  activeOrganisations?: Record<string, boolean>;
  teamOrganisations?: OrganisationResponse[];
  namesColumns: {
    showNumbers: boolean;
  };
  isMultiOrg: boolean;
};

export type WheelContextType = [
  WheelState & {
    changeLanguage: (newLanguageType: string) => void;
    i18n: any;
    consciousOverrides: Overrides;
    lessConsciousOverrides: Overrides;
    dotSize: number;
    setDotSize: (dotSize: number) => void;
  },
  Dispatch<SetStateAction<WheelState>>,
];

export const WheelContext = React.createContext<WheelContextType | undefined>(
  undefined,
);

export const useWheelContext = () => {
  const context = useContext(WheelContext);

  if (context === undefined) {
    throw new Error('useWheelContext must be used within a WheelProvider');
  }
  return context;
};
