import { useMemo } from 'react';
import { queryStatus } from 'utils/queryStatus';
import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { Group, OrganisationResponse, useGetPractitionerGroups } from 'api';
import { useAuth } from 'contexts/AuthContext';

type OrganisationGroupProps = {
  enabled: boolean;
  organisations?: OrganisationResponse[];
  practitionerId: string;
};
type GroupOrganisationHashMap = Record<string, Group>;

const useGetOrganisationGroups = ({
  enabled,
  organisations,
  practitionerId,
}: OrganisationGroupProps) => {
  const { user } = useAuth();
  const organisationIds = organisations?.map(({ id }) => id);
  const canSeeGroups = user?.permissions.Organisation_Group_ReadAll !== 'None';
  const isEnabled = enabled && !!organisationIds && canSeeGroups;
  const { data: groupsData, status: groupsStatus } = useGetPractitionerGroups(
    practitionerId,
    {
      enabled: isEnabled,
    },
  );

  const status = isEnabled ? queryStatus(groupsStatus) : 'success';
  const groups = useMemo(
    () => (enabled && groupsData ? groupsData : []),
    [enabled, groupsData],
  );

  const data: GroupOrganisationHashMap = useMemo(
    () => (groups?.length > 0 ? createGroupOrganisationHashMap(groups) : {}),
    [groups],
  );
  return { data, status, groups };
};

export default useGetOrganisationGroups;
