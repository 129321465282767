import React from 'react';
import { CoreDetailsForm } from 'components/EventForms';
import FormNavigation from './FormNavigation';
import { StepProps } from './types';

const CoreDetails = ({ onNext, event, updateEvent }: StepProps) => {
  const onSubmit = (formData: any) => {
    updateEvent(formData);
    onNext();
  };

  return (
    <CoreDetailsForm
      event={event}
      onSubmit={onSubmit}
      isCreating
      invitedCount={0}
    >
      <FormNavigation />
    </CoreDetailsForm>
  );
};

export default CoreDetails;
