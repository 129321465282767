import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { H1, P, Span } from '@insights-ltd/design-library/components';
import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import { useSignIn as useSignInQuery } from 'api/practitioners/practitionerHooks';
import { useQueryClient } from '@tanstack/react-query';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type SignInFormData = {
  emailAddress: string;
  password: string;
};

const isErrorStatus = (isError: boolean, error: unknown, statusCode: string) =>
  isError && error instanceof Error && error.message === statusCode;

const useSignIn = () => {
  const queryClient = useQueryClient();
  const { isError, error, ...rest } = useSignInQuery(queryClient);
  const isForbidden = isErrorStatus(isError, error, '403');
  const isUnauthorized = isErrorStatus(isError, error, '401');
  return {
    ...rest,
    isForbidden,
    isUnauthorized,
  };
};

const SignIn = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { mutate, isPending, isUnauthorized, isForbidden } = useSignIn();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>();

  const onSubmit = (credentials: SignInFormData) => mutate(credentials);

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.sign-in')}</title>
      </Helmet>
      <SelfServiceLayout>
        <Paper
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '31rem',
            marginBottom: theme.spacing(spacingSizeMap.L),
            padding: theme.spacing(spacingSizeMap.L),
            '& > * + *': {
              margin: `${theme.spacing(spacingSizeMap.M)} 0 0 0 !important`,
            },
          })}
        >
          <H1 variant="h3">{t('ui.event-management.sign-in.heading')}</H1>
          <P variant="body" color="textSecondary">
            <Trans
              i18nKey="ui.event-management.sign-in.sub-heading.bold"
              components={{ bold: <Span variant="body-bold" /> }}
            />
          </P>
          {isForbidden && (
            <Box my={1}>
              <Alert severity="error" variant="standard" icon={false}>
                <Span variant="body-bold" color="error">
                  <Trans
                    i18nKey="ui.event-management.sign-in.account-inactive.error"
                    values={{ emailAddress: 'customerservice@insights.com' }}
                    components={[
                      <Link
                        key="1"
                        href="mailto:customerservice@insights.com"
                      />,
                    ]}
                  />
                </Span>
              </Alert>
            </Box>
          )}
          <TextField
            id="emailAddress"
            label={t('ui.event-management.sign-in.email.input-label')}
            type="email"
            variant="outlined"
            required
            error={Boolean(errors.emailAddress)}
            helperText={
              errors.emailAddress &&
              t('ui.event-management.sign-in.email.error.required')
            }
            {...register('emailAddress', { required: true })}
          />
          <TextField
            id="password"
            autoComplete="off"
            label={t('ui.event-management.sign-in.password.input-label')}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            required
            error={Boolean(errors.password)}
            helperText={
              errors.password &&
              t('ui.event-management.sign-in.password.error.required')
            }
            {...register('password', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t(
                      'ui.event-management.authentication.password.visibility',
                    )}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <P variant="body-bold">
            <Link component={RouterLink} to="/forgotten-password">
              {t('ui.event-management.sign-in.forgotten-password.link')}
            </Link>
          </P>
          <Button
            variant="contained"
            color="primary"
            disabled={isPending}
            type="submit"
            size="large"
          >
            {t('ui.event-management.sign-in.cta')}
          </Button>
          <Snackbar open={isUnauthorized} autoHideDuration={6000}>
            <Alert elevation={6} variant="filled" severity="error">
              {t('ui.event-management.sign-in.credentials.error')}
            </Alert>
          </Snackbar>
        </Paper>
      </SelfServiceLayout>
    </>
  );
};

export default SignIn;
