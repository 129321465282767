import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/material';
import { H1 } from '@insights-ltd/design-library/components';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import BackButton from 'components/BackButton/BackButton';

export type Props = {
  titleKey: string;
  titleKeyOptions?: { [key: string]: string };
  activeTextKey: string;
  crumbs: { [key: string]: string };
  fullWidth?: boolean;
  headingKey: string;
  secondaryContent?: ReactElement;
  children: React.ReactElement | React.ReactElement[];
  parentLink?: string;
};

export const backButtonStyles: SxProps = {
  alignItems: 'center',
  display: 'flex',
  marginBottom: '0.5rem',
  '& > svg': {
    margin: '1px 5px 0 0',
    fontSize: '17px',
  },
};

const BreadcrumbLayout: React.FunctionComponent<Props> = ({
  titleKey,
  titleKeyOptions = {},
  activeTextKey,
  headingKey,
  crumbs,
  fullWidth,
  children,
  secondaryContent,
  parentLink,
}) => {
  const { t } = useTranslation();
  const gridWidth = fullWidth ? 12 : 8;

  return (
    <>
      <Helmet>
        <title>{t(titleKey, titleKeyOptions)}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs crumbs={crumbs} activeText={t(activeTextKey)} />
          </Box>
          <Grid container>
            {parentLink && (
              <BackButton
                parentLink={parentLink}
                i18nKey="ui.event-management.events.add-learners-and-contributors.learner.back-to-experience-overview"
                sx={backButtonStyles}
              />
            )}
            <Grid item xs={12} md={gridWidth}>
              <Grid container justifyContent="space-between">
                <Grid
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  container
                  item
                >
                  <Grid item>
                    <H1 variant="h2">{t(headingKey)}</H1>
                  </Grid>
                  {secondaryContent && <Grid item>{secondaryContent}</Grid>}
                </Grid>
              </Grid>
              <Box sx={{ marginTop: '1.75rem', marginBottom: '2rem' }}>
                <Divider />
              </Box>
              {children}
            </Grid>
          </Grid>
        </Container>
      </FullHeight>
    </>
  );
};

export default BreadcrumbLayout;
