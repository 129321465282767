import { P, CheckboxV2 } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  showNumbers: boolean;
  onToggle: (column: Record<string, boolean>) => void;
};

const NamesOptions = ({ showNumbers, onToggle }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <P id="direction-sort-label" variant="body-bold">
        {t('ui.event-management.events.team-table.controls.toggle-columns')}
      </P>
      <CheckboxV2
        label={t(
          'ui.event-management.events.team-table.controls.toggle-columns.options.number',
        )}
        checked={showNumbers}
        onClick={() => {
          onToggle({ showNumbers: !showNumbers });
        }}
        sx={(theme) => ({
          marginLeft: '-9px',
          marginTop: '1px',
          '& span:nth-of-type(2)': {
            paddingLeft: theme.spacing(spacingSizeMap.S),
          },
        })}
        disableRipple
        disableTouchRipple
      />
    </>
  );
};

export default NamesOptions;
