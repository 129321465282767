import { ContactFormRequest } from './httpEntities';
import { authenticatedFetch } from './fetch';

export * from './events/events';
export * from './evaluatorLinks/evaluatorLinks';
export * from './organisationGroups/organisationGroups';
export * from './organisations/organisations';
export * from './practitioners/practitioners';
export * from './teams/teams';
export * from './wallets/wallets';
export * from './teams/teamApiTypes';

export const getDownloadFileBlob = async (
  fileUrl: string,
  signal?: AbortSignal,
): Promise<Blob> => {
  const { blob } = await authenticatedFetch(fileUrl, { signal });

  return blob();
};

export const sendContactForm = async (
  formData: ContactFormRequest,
): Promise<void> => {
  const { json } = await authenticatedFetch(`/api/v1/contact`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  return json();
};
