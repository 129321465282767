import React, { useMemo, useState } from 'react';
import { SortDirection, SortType } from 'types/types';
import { useTranslation } from 'react-i18next';
import { useDotOverrides } from './hooks/useWheelOverrides';
import {
  WheelContext,
  WheelContextType,
  WheelState,
} from './hooks/useWheelContext';

export const defaultInitialState: WheelState = {
  fontSize: 20,
  tableFontSize: 6,
  scoreType: 'CONSCIOUS',
  dotType: '4-COLOUR',
  languageType: 'en-GB',
  labelType: 'INITIALS',
  scoresGroupingType: 'DISTINCT',
  sortType: SortType.NUMBER,
  sortDirection: SortDirection.ASCENDING,
  isLearnerCountExceeded: false,
  namesColumns: {
    showNumbers: true,
  },
  isMultiOrg: false,
};

const WheelProvider = ({
  children,
  initialState = defaultInitialState,
}: {
  children: React.ReactNode;
  initialState?: WheelState;
}) => {
  const { i18n } = useTranslation();
  const i18next = useMemo(() => i18n.cloneInstance(), [i18n]);
  const consciousOverrides = useDotOverrides();
  const lessConsciousOverrides = useDotOverrides();
  const [dotSize, setDotSize] = useState<number>(0.5);
  const [state, setState] = useState<WheelState>(initialState);

  const value = useMemo(() => {
    const changeLanguage = (newLanguageType: string) => {
      i18next.changeLanguage(newLanguageType);
    };

    return [
      {
        ...state,
        i18n: i18next,
        consciousOverrides,
        lessConsciousOverrides,
        dotSize,
        setDotSize,
        changeLanguage,
      },
      setState,
    ] as WheelContextType;
  }, [state, consciousOverrides, lessConsciousOverrides, dotSize, i18next]);

  return (
    <WheelContext.Provider value={value}>{children}</WheelContext.Provider>
  );
};

export default WheelProvider;
