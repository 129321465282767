import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import EventOverview from './EventOverview';
import EventSummary from './EventSummary';
import { useEventData } from './EventOverview/Event.helper';

type WrapperProps = {
  show: boolean;
  children: ReactElement;
};

const StyledPrimaryWrapper = styled(FullHeight)(({ theme }) => {
  return {
    paddingLeft: `calc((100vw - ${
      theme.breakpoints.values.lg + spacingSizeMap.L * 8
    }px) / 2)`,
  };
});

const StyledSecondaryWrapper = styled(FullHeight)(({ theme }) => {
  return {
    paddingRight: `calc((100vw - ${
      theme.breakpoints.values.lg + spacingSizeMap.L * 8
    }px) / 2)`,
  };
});

const Wrapper = ({ show, children }: WrapperProps) =>
  show ? (
    <StyledPrimaryWrapper backgroundColor="white">
      <StyledSecondaryWrapper>{children}</StyledSecondaryWrapper>
    </StyledPrimaryWrapper>
  ) : (
    children
  );

const StyledBreadcrumbWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.M)} 0`,
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
}));

const Event = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();
  const { data, status } = useEventData(eventId!);
  const aboveMobile = useIsAboveMobile();

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.events.overview.error-loading-event')}
      />
    );
  }

  const [event, invitees, readExpiredIsPermitted, contributorLearners = []] =
    data;

  const eventInviteesCount = invitees?.length || 0;
  const eventBalance = event?.wallet?.availableUnits || 0;
  const profileCost = event?.chapters[0]?.cost || 0;

  const evaluatorNotCompletedButInvited = invitees.filter(
    ({ profileStatus, status: inviteeStatus }) =>
      profileStatus !== 'PURCHASED' && inviteeStatus !== 'ADDED',
  ).length;

  const refundableUnits =
    eventBalance + evaluatorNotCompletedButInvited * profileCost;

  return (
    <>
      <Helmet>
        <title>
          {t('ui.event-management.title.event', { eventName: event!.name })}
        </title>
      </Helmet>
      <StyledBreadcrumbWrapper>
        <Container maxWidth="lg" sx={{ overflowWrap: 'anywhere' }}>
          <Breadcrumbs
            crumbs={{
              '/': t('ui.event-management.events.nav.home'),
              '/experiences': t('ui.event-management.events.title'),
            }}
            activeText={event!.name}
          />
        </Container>
      </StyledBreadcrumbWrapper>
      <Wrapper show={aboveMobile}>
        <Grid
          container
          sx={(theme) => ({
            '> :first-of-type': {
              backgroundColor: theme.palette.grey[100],
              borderRight: `1px solid ${theme.palette.grey[400]}`,
            },
            '> *': {
              padding: theme.spacing(spacingSizeMap.S),
            },
            [theme.breakpoints.up(theme.breakpoints.values.md)]: {
              height: '100%',
              '> *': {
                padding: `${theme.spacing(spacingSizeMap.L)} !important`,
              },
            },
          })}
        >
          <Grid item xs={12} md={5}>
            <EventSummary
              event={event!}
              eventInviteesCount={eventInviteesCount}
              eventRefundableUnits={refundableUnits}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <EventOverview
              invitees={invitees!}
              contributorLearners={contributorLearners}
              event={event!}
              readExpiredIsPermitted={readExpiredIsPermitted}
            />
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default Event;
