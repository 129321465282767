import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PAGE_TITLE = 'Practitioner Portal';

const isProduction = (): boolean =>
  import.meta.env.VITE_PRODUCTION_HOST
    ? window.location.host === import.meta.env.VITE_PRODUCTION_HOST
    : false;

const gaEnabled = (): boolean => Boolean(window.gtag);

if (isProduction() && gaEnabled()) {
  window.gtag('config', import.meta.env.VITE_GA_ID, {
    page_path: window.location.pathname + window.location.search,
    page_title: PAGE_TITLE,
  });
}

export const useGATrackPage = (): void => {
  const location = useLocation();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isProduction() && gaEnabled()) {
      return window.gtag('config', import.meta.env.VITE_GA_ID, {
        page_path: location.pathname + location.search,
        page_title: PAGE_TITLE,
      });
    }
  }, [location]);
};
