import { styled } from '@mui/material';
import {
  Alert,
  Dialog,
  P,
  Span,
} from '@insights-ltd/design-library/components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  organisationText: string;
  onClose: () => void;
  onSubmit: () => void;
};

const StyledAlert = styled(Alert)(() => ({
  marginTop: '1rem',
  marginBottom: '9px',
  display: 'flex',
  alignItems: 'flex-start !important',
  lineHeight: '28px',
  padding: '10px 15px !important',
  '& > div:first-of-type': {
    marginTop: '5px',
    marginLeft: '0',
    marginRight: '4px',
  },
  '& > div:nth-of-type(2)': {
    marginRight: '6px',
  },
}));

const RemoveOrganisationDialog = ({
  open,
  organisationText,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog
      dialogProps={{
        sx: {
          '& > div > div': {
            maxWidth: '360px !important',
          },
          '& > div > div > h2': {
            paddingTop: '0',
            paddingBottom: '0',
          },
        },
      }}
      variant="delete"
      open={open}
      title={t(
        'ui.event-management.practitioner-account.revoke-organisation.title',
      )}
      primaryButtonText={t(
        'ui.event-management.practitioner-account.revoke-organisation.confirm',
      )}
      secondaryButtonText={t(
        'ui.event-management.practitioner-account.revoke-organisation.deny',
      )}
      content={
        <>
          <P>
            <Trans
              i18nKey="ui.event-management.practitioner-account.revoke-organisation.description"
              components={{
                strong: <Span variant="body-bold" />,
              }}
              values={{
                organisationText,
              }}
            />
          </P>
          <StyledAlert iconType="warning" kind="error">
            {t(
              'ui.event-management.practitioner-account.revoke-organisation.alert',
            )}
          </StyledAlert>
        </>
      }
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default RemoveOrganisationDialog;
