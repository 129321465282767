import { OrganisationResponse } from 'api';

const getOrganisationHashMap = (orgs: OrganisationResponse[]) =>
  orgs.reduce(
    (acc, org) => {
      // eslint-disable-next-line no-param-reassign
      acc[org.id] = org;
      return acc;
    },
    {} as Record<string, OrganisationResponse>,
  );

const getOrganisationsDiff = (
  originalOrgs: OrganisationResponse[],
  newOrgs: OrganisationResponse[],
) => {
  const originalOrgsMap = getOrganisationHashMap(originalOrgs);
  const newOrgsMap = getOrganisationHashMap(newOrgs);

  const add = newOrgs.filter(({ id }) => !originalOrgsMap[id]);
  const remove = originalOrgs.filter(({ id }) => !newOrgsMap[id]);

  return { add, remove };
};

export default getOrganisationsDiff;
