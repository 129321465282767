import Paper from '@mui/material/Paper';
import { InviteeResponse, ProfileReuseStatus } from 'api';
import React from 'react';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import ActionRequiredListRow from './ActionRequiredListRow';

type ActionRequiredStatusListProps = {
  invitees: InviteeResponse[];
  updateInvitee: (
    profileReusePreference: ProfileReuseStatus,
    invitee: InviteeResponse,
  ) => Promise<void>;
  deleteInvitee: (selectedInviteeId: string) => Promise<void>;
  isPastExperience: boolean;
  eventTimezone: string;
  eventDialect: string;
};

const ActionRequiredStatusList = ({
  invitees,
  updateInvitee,
  deleteInvitee,
  isPastExperience,
  eventTimezone,
  eventDialect,
}: ActionRequiredStatusListProps) => {
  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
          spacingSizeMap.M,
        )}`,
      })}
    >
      {invitees.map((invitee, index) => (
        <ActionRequiredListRow
          updateInvitee={updateInvitee}
          deleteInvitee={deleteInvitee}
          invitee={invitee}
          key={invitee.id}
          isLastRow={index === invitees.length - 1}
          isPastExperience={isPastExperience}
          eventTimezone={eventTimezone}
          eventDialect={eventDialect}
        />
      ))}
    </Paper>
  );
};

export default ActionRequiredStatusList;
