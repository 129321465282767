import { createContext, useContext } from 'react';
import { PractitionerData } from 'api/httpEntities';
import { UserPermissions } from 'permissions';

export type User = PractitionerData & { permissions: UserPermissions };

type AuthContextType = {
  user?: User;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export default AuthContext;
