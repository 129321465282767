import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  onBack?: () => void;
  lastStep?: boolean;
  loading?: boolean;
};

const FormNavigation = ({
  onBack,
  lastStep = false,
  loading = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <Grid container justifyContent="space-between">
        {onBack && (
          <Button variant="outlined" color="primary" onClick={onBack}>
            {t('ui.event-management.events.create.back-button')}
          </Button>
        )}
        <div />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          endIcon={
            loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )
          }
        >
          {lastStep
            ? t('ui.event-management.events.create.create-event-button')
            : t('ui.event-management.events.create.next-button')}
        </Button>
      </Grid>
    </>
  );
};

export default FormNavigation;
