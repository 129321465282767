import React from 'react';
import { SvgComponentProps } from './types';

const SvgEvaluator = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={94}
    height={120}
    fill="none"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fill="#BA3135"
      d="M93.752 46.876a46.876 46.876 0 0 1-32.39 44.582L46.876 46.876h46.876Z"
    />
    <path
      fill="#FFC85F"
      d="M61.95 91.263a46.876 46.876 0 0 1-35.801-2.342l20.727-42.045L61.95 91.263Z"
    />
    <path
      fill="#8AA52D"
      d="M28.105 89.83a46.878 46.878 0 0 1-16.28-74.08l35.051 31.126-18.77 42.954Z"
    />
    <path
      fill="#0073B8"
      d="M11.822 15.755a46.877 46.877 0 0 1 81.923 31.931l-46.869-.81-35.054-31.121Z"
    />
    <circle cx={13.841} cy={105.75} r={3} fill="#0073B8" />
    <circle cx={13.834} cy={117} r={3} fill="#BA3135" />
    <circle cx={54.346} cy={105.75} r={3} fill="#8AA52D" />
    <circle cx={54.346} cy={117} r={3} fill="#FFC85F" />
    <rect
      width={24.001}
      height={4.5}
      x={18.328}
      y={103.5}
      fill="#E6E6E6"
      rx={2.25}
    />
    <rect
      width={24.001}
      height={4.5}
      x={18.328}
      y={114.752}
      fill="#E6E6E6"
      rx={2.25}
    />
    <rect
      width={24.001}
      height={4.5}
      x={58.84}
      y={103.5}
      fill="#E6E6E6"
      rx={2.25}
    />
    <rect
      width={24.001}
      height={4.5}
      x={58.84}
      y={114.752}
      fill="#E6E6E6"
      rx={2.25}
    />
  </svg>
);

export default SvgEvaluator;
