import React from 'react';
import { SvgComponentProps } from 'components/Svgs/types';

const SvgAnalyticsBars = ({ title, ...props }: SvgComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    {title ? <title>{title}</title> : null}
    <path d="M.833 11.333h2.334a.167.167 0 0 0 .166-.166V9.833a.667.667 0 0 0-.666-.666H1.333a.667.667 0 0 0-.666.666v1.334a.167.167 0 0 0 .166.166ZM5.333 5.833a.667.667 0 0 0-.666.667v4.667a.167.167 0 0 0 .166.166h2.334a.167.167 0 0 0 .166-.166V6.5a.667.667 0 0 0-.666-.667ZM9.333 7.167a.667.667 0 0 0-.666.666v3.334a.167.167 0 0 0 .166.166h2.334a.167.167 0 0 0 .166-.166V7.833a.667.667 0 0 0-.666-.666ZM12.833 11.333h2.334a.167.167 0 0 0 .166-.166v-8a.667.667 0 0 0-.666-.667h-1.334a.667.667 0 0 0-.666.667v8a.167.167 0 0 0 .166.166ZM.667 13.5h14.666a.667.667 0 0 0 .667-.667.667.667 0 0 0-.373-.6.7.7 0 0 0-.294-.066H.667a.7.7 0 0 0-.294.066.667.667 0 0 0-.373.6.667.667 0 0 0 .667.667Z" />
  </svg>
);

export default SvgAnalyticsBars;
