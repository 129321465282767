import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useTranslation } from 'react-i18next';
import { User } from 'types/types';
import { getTheme, spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import UserAutocomplete from './UserAutocomplete';
import UsersList from './UsersList';

const localTheme = getTheme();

const alertProps = {
  border: '0',
  '&.MuiAlert-filledInfo': {
    border: '0',
    backgroundColor: localTheme.brandColours.lightBlue,
    color: localTheme.grey[700],
  },
  '&.MuiAlert-filledError': {
    border: '0',
    backgroundColor: localTheme.validationColours.lightError,
    color: localTheme.validationColours.error,
  },
};

type Props = {
  selectedUsers: User[];
  users: User[];
  loading: boolean;
  isLoadingError?: boolean;
  onChange: (users: User[]) => void;
  error?: boolean;
  helperText?: string;
};

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.M),
}));

const UserPicker = ({
  selectedUsers,
  users,
  loading,
  isLoadingError,
  onChange,
  error,
  helperText,
}: Props) => {
  const [options, setOptions] = useState<Array<User>>([]);
  const [currentLearner, setCurrentLearner] = useState<User>({} as User);
  const { t } = useTranslation();

  const onSelect = (selectedUser: User) => {
    setCurrentLearner(selectedUser);
  };

  const addLearnerToList = () => {
    if (currentLearner?.id) {
      onChange([...selectedUsers, currentLearner]);
      setCurrentLearner({} as User);
    }
  };

  useEffect(() => {
    if (users) {
      const newOptions = users?.filter(
        (item) => !selectedUsers.some((user: User) => user.id === item.id),
      );
      setOptions(newOptions);
    }
  }, [selectedUsers, users]);

  const hasUsers = !!users?.length;
  const hasOptions = !!options?.length;

  if (isLoadingError) {
    return (
      <Alert sx={alertProps} variant="filled" severity="error">
        {t('ui.event-management.teams.edit.error-loading-learners')}
      </Alert>
    );
  }
  if (loading) {
    return (
      <Alert sx={alertProps} variant="filled" severity="info">
        {t('ui.event-management.loading')}
      </Alert>
    );
  }
  return (
    <>
      {selectedUsers.length > 0 && (
        <Paper elevation={0} variant="outlined">
          <Box sx={{ m: 0, p: 0 }}>
            <TableContainer>
              <Table aria-label="practitioners table">
                <TableBody>
                  <UsersList users={selectedUsers} setUsers={onChange} />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      )}
      <StyledDiv>
        {!hasOptions ? (
          <Alert sx={alertProps} variant="filled" severity="info">
            {t('ui.event-management.teams.edit.no-more-learners-to-choose')}
          </Alert>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!hasUsers ? (
              <Alert sx={alertProps} variant="filled" severity="info">
                {t('ui.event-management.teams.edit.no-learners-in-organistion')}
              </Alert>
            ) : (
              <>
                <UserAutocomplete
                  options={options}
                  onSelect={onSelect}
                  loading={loading}
                  selectedValue={currentLearner.id}
                />
                {error && (
                  <Alert sx={alertProps} severity="error">
                    {helperText}
                  </Alert>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  sx={(theme) => ({
                    display: 'block',
                    marginTop: theme.spacing(spacingSizeMap.M),
                    marginLeft: 'auto',
                  })}
                  onClick={addLearnerToList}
                >
                  {t(
                    'ui.event-management.events.edit.practitioners.add-person',
                  )}
                </Button>
              </>
            )}
          </>
        )}
      </StyledDiv>
    </>
  );
};

export default UserPicker;
