import { QueryStatus } from '@tanstack/react-query';
import {
  LearnerAndInviteeResponse,
  useGetFilteredLearners,
  useGetOrganisationLearners,
} from 'api';
import { useBetaEnabled } from 'features';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';
import { MINIMUM_SEARCH_CHARACTER_LIMIT } from 'variables/constants';

const useGetLearners = (
  searchTerm: string,
  organisationId?: string | null | undefined,
): {
  status: QueryStatus;
  isFetched: boolean;
  learners: LearnerAndInviteeResponse[];
  limitExceeded: boolean;
} => {
  const shouldFetchInitialList =
    !!organisationId && searchTerm?.length < MINIMUM_SEARCH_CHARACTER_LIMIT;

  const betaEnabled = useBetaEnabled('learner-search-performance');
  const {
    status: orgLearnerStatus,
    isFetched: isOrgLearnerFetch,
    data: { learners: orgLearners, limitExceeded: orgLearnersLimitExceeded } = {
      learners: [],
      limitExceeded: false,
    },
  } = useGetOrganisationLearners(organisationId ?? '', {
    enabled: shouldFetchInitialList,
  });

  const getFilteredLearnersQuery = useGetFilteredLearners(
    betaEnabled,
    searchTerm?.length >= MINIMUM_SEARCH_CHARACTER_LIMIT ? searchTerm : '',
    organisationId,
  );

  const {
    data: { learners: filterLearners, limitExceeded: filterLimitExceeded } = {
      learners: [],
      limitExceeded: false,
    },
    isFetched: isFilteredListFetched,
  } = getFilteredLearnersQuery;

  const getFilterStatus = queryStatusIgnoreIdle(getFilteredLearnersQuery);

  const response = shouldFetchInitialList
    ? {
        status: orgLearnerStatus,
        isFetched: isOrgLearnerFetch,
        learners: orgLearners,
        limitExceeded: orgLearnersLimitExceeded,
      }
    : {
        status: getFilterStatus,
        isFetched: isFilteredListFetched,
        learners: filterLearners,
        limitExceeded: filterLimitExceeded,
      };

  return response;
};

export default useGetLearners;
