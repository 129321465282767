import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, P, Span } from '@insights-ltd/design-library/components';
import Evaluator from 'components/Svgs/Evaluator';
import { Alert, Box, Grid, Link, List, ListItem, styled } from '@mui/material';
import { ChaptersStatus } from 'api';
import { Link as RouterLink } from 'react-router-dom';
import { getChapterKey } from 'utils/getChapterKey';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

export type PurchaseSummaryLine = {
  chapterName: string;
  unitCost: number;
  count: number;
};

export type PurchaseDialogProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmPurchase: () => void;
  chapterSummaries: PurchaseSummaryLine[];
  purchaseChapters: ChaptersStatus[];
  unitBalance: number;
  onSuccess: () => void;
};

const StyledDivRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '> :last-child': {
    marginLeft: 'auto',
  },
});

const StyledTransactionBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'invalidTransaction',
})<{ invalidTransaction: boolean }>(({ invalidTransaction, theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.XS),
  padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
    spacingSizeMap.S,
  )}`,
  background: theme.palette.primary.light,
  borderRadius: '4px',
  border: invalidTransaction
    ? `solid 1px ${theme.palette.error.main}`
    : 'undefined,',
}));

const StyledSummaryBox = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.S),
}));

const StyledSummaryRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '> :first-of-type': {
    width: '25px',
  },
  '> :last-child': {
    marginLeft: 'auto',
  },
});

const PurchaseSummary = ({
  lines,
  unitBalance,
  totalUnitCost,
  invalidTransaction,
}: {
  lines: PurchaseSummaryLine[];
  unitBalance: number;
  totalUnitCost: number;
  invalidTransaction: boolean;
}) => {
  const { t } = useTranslation();

  const postTransBalance = unitBalance - totalUnitCost;
  const summaryUnitsLabel = t(
    'ui.event-management.events.assign-chapters.summary-box.units',
  );
  return (
    <Grid container spacing={2} direction="column">
      {invalidTransaction && (
        <Grid item>
          <Alert
            severity="error"
            sx={(theme) => ({
              alignItems: 'center',
              padding: '0',
              svg: {
                fill: theme.palette.error.main,
              },
            })}
          >
            <Span color="error" variant="body-bold">
              {t('ui.event-management.events.purchase-profiles.error-balance')}{' '}
              <Link component={RouterLink} to="/account" color="textPrimary">
                <Span color="primary">
                  {t(
                    'ui.event-management.events.purchase-profiles.error-balance-action-link',
                  )}
                </Span>
              </Link>
            </Span>
          </Alert>
        </Grid>
      )}
      <Grid item>
        <StyledTransactionBox invalidTransaction={invalidTransaction}>
          <StyledDivRow>
            <Span color="textSecondary">
              {t('ui.event-management.events.purchase-profiles.unit-balance')}
            </Span>
            <Span variant="body-bold" color="textSecondary">
              {unitBalance}
            </Span>
          </StyledDivRow>
          <StyledDivRow>
            <Span color={invalidTransaction ? 'error' : 'textSecondary'}>
              {t(
                'ui.event-management.events.purchase-profiles.transaction-total',
              )}
            </Span>
            <Span
              variant="body-bold"
              color={invalidTransaction ? 'error' : 'textSecondary'}
            >
              {totalUnitCost}
            </Span>
          </StyledDivRow>
          {!invalidTransaction && (
            <StyledDivRow>
              <Span color="textSecondary">
                {t(
                  'ui.event-management.events.purchase-profiles.post-transaction-balance',
                )}
              </Span>
              <Span variant="body-bold" color="textSecondary">
                {postTransBalance}
              </Span>
            </StyledDivRow>
          )}
        </StyledTransactionBox>
      </Grid>
      <Grid item>
        <StyledSummaryBox>
          <P variant="body-bold" color="textPrimary">
            {t('ui.event-management.events.purchase-profiles.summary-title')}:
          </P>
          <List disablePadding>
            {lines.map(({ chapterName, count, unitCost }) => {
              const titleKey = getChapterKey(chapterName);
              const sumChapterUnits = count * unitCost;
              return (
                <ListItem dense disableGutters key={titleKey}>
                  <StyledSummaryRow>
                    <Span color="textSecondary">{count}x</Span>
                    <Span color="textSecondary">{t(titleKey)}</Span>
                    <Span color="textSecondary">
                      {`${sumChapterUnits} ${summaryUnitsLabel}`}
                    </Span>
                  </StyledSummaryRow>
                </ListItem>
              );
            })}
            <ListItem dense disableGutters key="total-line">
              <StyledDivRow>
                <P variant="h4" color="textPrimary">
                  {t(
                    'ui.event-management.events.purchase-profiles.basket-total-label',
                  )}
                  :
                </P>
                <P
                  data-testid="totalTransactionUnits"
                  variant="h4"
                  color="textPrimary"
                >
                  {`${totalUnitCost}  ${summaryUnitsLabel}`}
                </P>
              </StyledDivRow>
            </ListItem>
          </List>
        </StyledSummaryBox>
      </Grid>
    </Grid>
  );
};

const PurchaseDialog = ({
  open,
  onClose,
  handleConfirmPurchase,
  chapterSummaries,
  unitBalance,
  purchaseChapters,
  onSuccess,
}: PurchaseDialogProps) => {
  const { t } = useTranslation();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirm = () => {
    setIsConfirmed(true);
    handleConfirmPurchase();
  };

  const handleSuccessClose = () => {
    setIsConfirmed(false);
    onSuccess();
  };

  const summaryLines = chapterSummaries
    .map((summary) => {
      return {
        ...summary,
        count: purchaseChapters.filter(
          ({ status, name }) =>
            status === 'PENDING' && name === summary.chapterName,
        ).length,
      };
    })
    .filter((sum) => sum.count > 0);

  const totalUnitCost = summaryLines.reduce(
    (acc, val) => acc + val.count * val.unitCost,
    0,
  );
  const invalidTransaction: boolean = unitBalance < totalUnitCost;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isConfirmed ? (
        <Dialog
          onClose={handleSuccessClose}
          open={open}
          content={
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: {
                  textAlign: 'center',
                },
                svg: {
                  marginBottom: theme.spacing(spacingSizeMap.S),
                },
              })}
            >
              {' '}
              <Evaluator />{' '}
              <P variant="body-bold" color="textPrimary">
                {t(
                  'ui.event-management.events.purchase-profiles.confirmation-sub-title',
                )}
              </P>
              <P color="textPrimary">
                {t(
                  'ui.event-management.events.purchase-profiles.confirmation-content.no-email',
                )}
              </P>
            </Box>
          }
          secondaryButtonText={t(
            'ui.event-management.events.purchase-profiles.confirmation-close-button',
          )}
          title={t(
            'ui.event-management.events.purchase-profiles.confirmation-title',
          )}
        />
      ) : (
        <Dialog
          onSubmit={handleConfirm}
          onClose={onClose}
          open={open}
          content={
            <PurchaseSummary
              lines={summaryLines}
              unitBalance={unitBalance}
              totalUnitCost={totalUnitCost}
              invalidTransaction={invalidTransaction}
            />
          }
          primaryButtonText={t(
            'ui.event-management.events.purchase-profiles.purchase-title',
          )}
          secondaryButtonText={t('ui.event-management.cancel')}
          title={t(
            'ui.event-management.events.purchase-profiles.purchase-title',
          )}
          disablePrimaryButton={invalidTransaction}
        />
      )}
    </>
  );
};

export default PurchaseDialog;
