import { useGetPractitionerById } from 'api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { QUERY_STATUS } from 'variables';
import AddUser from 'pages/AddUser/AddUser';

const AddPractitionerToOrgs = () => {
  const { t } = useTranslation();
  const { practitionerId } = useParams();
  const { status: getPractitionerStatus, data: practitioner } =
    useGetPractitionerById(practitionerId);

  if (getPractitionerStatus === QUERY_STATUS.PENDING) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  if (getPractitionerStatus === QUERY_STATUS.ERROR) {
    return (
      <FullScreenError
        message={t('ui.event-management.organisations.error-loading')}
      />
    );
  }

  return <AddUser initialPractitioner={practitioner} />;
};

export default AddPractitionerToOrgs;
