import { Profile } from 'api';
import { LearnerForPurchase } from 'components/PurchaseProfiles/types';

export const getDiscoveryProfile = (latestProfiles: Profile[]) => {
  return latestProfiles.find(
    (profile: any) => profile.profileType === 'DISCOVERY_PROFILE',
  );
};
export const getIDTLProfile = (latestProfiles: Profile[]) => {
  return latestProfiles.find(
    (profile: any) => profile?.profileType === 'IDTL_PROFILE',
  );
};

export const isUnableToPurchaseIDTL = (latestEvaluation: any) => {
  return !!(
    !getDiscoveryProfile(latestEvaluation.latestProfiles) ||
    getIDTLProfile(latestEvaluation.latestProfiles)
  );
};

export const hasPurchaseIDTLError = (purchaseItems: LearnerForPurchase[]) => {
  return purchaseItems
    .map((item: LearnerForPurchase) =>
      isUnableToPurchaseIDTL(item.latestEvaluation),
    )
    .includes(true);
};

export const hasPurchaseDiscoveryError = (
  purchaseItems: LearnerForPurchase[],
) => {
  return purchaseItems.some(
    (item: LearnerForPurchase) => item.assignedChapters.length < 1,
  );
};
