import React from 'react';
import {
  CompletedEvaluation,
  EvaluationChapters,
  FlattenedCompletedEvaluation,
} from 'api/httpEntities';
import { Link, useParams } from 'react-router-dom';
import {
  ColourEnergyIndicator,
  DataTable,
} from '@insights-ltd/design-library/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isoToLocaleString } from 'utils/dates';
import LinkEmail from 'components/LinkEmail/LinkEmail';
import { LICENSED_PRODUCTS_ABREV } from 'variables/constants';

export const evaluationDataTransformer = (
  evaluations: CompletedEvaluation[],
  locale = 'en-GB',
): FlattenedCompletedEvaluation[] => {
  return evaluations.map((evaluation) => {
    const { learner, createdAt, profile, colourScores, ...data } = evaluation;
    const { id: learnerId, fullName, emailAddress } = learner;

    return {
      learnerId,
      fullName,
      emailAddress,
      dateCompleted: isoToLocaleString(createdAt, locale),
      latestPurchase: profile?.id || null,
      purchased: !!profile?.id,
      chapters: profile?.chapters.map(({ name }) => name) ?? [],
      colourScores:
        profile && colourScores?.conscious ? colourScores?.conscious : null,
      datePurchased: profile
        ? isoToLocaleString(profile.createdAt, locale)
        : '',
      ...data,
    };
  });
};

type Headers = keyof Omit<FlattenedCompletedEvaluation, 'id' | 'learnerId'>;

export type CompletedEvaluationDataStructure = Record<Headers, string>;

type Props = {
  items: FlattenedCompletedEvaluation[];
  dataStructure:
    | Omit<CompletedEvaluationDataStructure, 'latestPurchase' | 'colourScores'>
    | Omit<CompletedEvaluationDataStructure, 'purchased' | 'chapters'>;
  checkedItems: Record<string, boolean>;
  onCheckedItems: (checkedItemsMap: Record<string, boolean>) => void;
  organisationId: string;
  onPageChange: (page: { pageNumber: number; lastPage?: boolean }) => void;
};

const BoldSpan = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightBold,
  maxWidth: '200px',
  '&:hover': {
    color: `${theme.palette.blue.dark} !important`,
    textDecoration: 'underline',
  },
}));

const StyledDiv = styled('div')({});

const StyledTable = styled(DataTable)({});

const getProductTranslationFromChapter = (t: any, chapters: string[]) => {
  if (chapters.includes('DFC')) {
    return t(LICENSED_PRODUCTS_ABREV.DFC_PROFILE);
  }
  if (chapters.includes('EXPLORE')) {
    return t(LICENSED_PRODUCTS_ABREV.EXPLORE_PROFILE);
  }

  if (chapters.includes('IDTL')) {
    return t(LICENSED_PRODUCTS_ABREV.IDTL_PROFILE);
  }

  if (chapters.includes('FOUNDATION')) {
    return t(LICENSED_PRODUCTS_ABREV.DISCOVERY_PERSONAL_PROFILE);
  }

  return '';
};

const getProfileText = (t: any, chapters: string[], datePurchased: string) => {
  if (chapters.length > 0) {
    const chapterName = getProductTranslationFromChapter(t, chapters);
    return (
      <BoldSpan data-testid="latest-purchase">
        {t(
          'ui.event-management.evaluator-links.completed-evaluators.table.latest-purchase.details',
          {
            productName: chapterName,
            date: datePurchased,
          },
        )}
      </BoldSpan>
    );
  }

  return (
    <BoldSpan sx={(theme) => ({ color: theme.palette.text.secondary })}>
      {t(
        'ui.event-management.evaluator-links.completed-evaluators.table.latest-purchase.ready',
      )}
    </BoldSpan>
  );
};

const evaluationColumnPredicate = (
  t: any,
  columnName: keyof FlattenedCompletedEvaluation,
  columnValue: any,
  props: Record<any, any>,
) => {
  switch (columnName) {
    case 'fullName':
      return (
        <StyledDiv sx={{ maxWidth: '200px', wordBreak: 'break-word' }}>
          <StyledLink
            to={`/evaluator-links/${props.evaluatorLinkId}/${props.organisationId}/${props.learnerId}`}
          >
            {columnValue}
          </StyledLink>
        </StyledDiv>
      );
    case 'referralCode':
      return (
        <StyledDiv sx={{ maxWidth: '100px', wordBreak: 'break-word' }}>
          <BoldSpan>{columnValue}</BoldSpan>
        </StyledDiv>
      );
    case 'latestPurchase':
      return getProfileText(t, props.chapters ?? [], props.datePurchased);
    case 'colourScores':
      return (
        <StyledDiv data-testid="colour-scores" sx={{ maxWidth: '330px' }}>
          <ColourEnergyIndicator
            {...(columnValue?.preferred ? columnValue : {})}
          />
        </StyledDiv>
      );
    case 'purchased':
      return (
        <div>
          {columnValue ? (
            <CheckCircleIcon
              data-testid="purchased"
              sx={(theme) => ({
                color: theme.palette.success.main,
                marginLeft: theme.spacing(spacingSizeMap.M),
                marginRight: theme.spacing(spacingSizeMap.M),
              })}
            />
          ) : null}
        </div>
      );
    case 'chapters':
      return (
        <StyledDiv sx={{ maxWidth: '330px' }}>
          {(columnValue as EvaluationChapters[]).join(', ')}
        </StyledDiv>
      );
    case 'emailAddress':
      return (
        <StyledDiv sx={{ maxWidth: '200px', wordBreak: 'break-word' }}>
          <LinkEmail email={columnValue as string} />
        </StyledDiv>
      );
    case 'dateCompleted':
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{columnValue}</>;
  }
};

const EvaluationsTable = ({
  dataStructure,
  items,
  checkedItems,
  onCheckedItems,
  organisationId,
  onPageChange,
}: Props) => {
  const { evaluatorLinkId } = useParams<{ evaluatorLinkId: string }>();
  const { t } = useTranslation();
  return (
    <StyledTable
      sx={{
        '& thead > tr > th:first-of-type': {
          padding: '10px 0 10px 1rem',
          textAlign: 'right',
        },
        '& tbody > tr > th': {
          paddingLeft: '8px',
        },
        '& thead > tr > th': {
          paddingLeft: '8px',
        },
        '& thead > tr > th:nth-of-type(2)': {
          paddingLeft: '0',
          paddingRight: '1rem',
        },
        '& tbody > tr > td:first-of-type': {
          padding: '10px 0 10px 1rem',
          textAlign: 'right',
        },
        '& tbody > tr > td': {
          paddingLeft: '8px',
        },
        '& tbody > tr > td:nth-of-type(2)': {
          paddingLeft: '0',
        },
      }}
      dataStructure={dataStructure}
      items={items}
      checkedItems={checkedItems}
      onCheckedItems={onCheckedItems}
      columnPredicate={(columnName, columnValue, props) =>
        evaluationColumnPredicate(
          t,
          columnName as keyof FlattenedCompletedEvaluation,
          columnValue,
          {
            evaluatorLinkId,
            organisationId,
            ...props,
          },
        )
      }
      onPageChange={onPageChange}
      selectable
    />
  );
};

export default EvaluationsTable;
