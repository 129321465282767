import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChaptersStatus } from 'api';
import { ActionBar } from '@insights-ltd/design-library/components';
import PurchaseDialog, {
  PurchaseSummaryLine,
} from './PurchaseDialog/PurchaseDialog';
import DFCPurchaseDialog from './PurchaseDialog/DFCPurchaseDialog';

export type Props = {
  inviteeIds: string[];
  handleConfirmPurchase: () => void;
  handleSuccessClose: () => void;
  chapterSummaries: PurchaseSummaryLine[];
  purchaseChapters: ChaptersStatus[];
  contributorsCount?: number;
  unitBalance: number;
  event: string | undefined;
  deselectAllForPurchase: () => void;
  newContributors: number;
  showNewContributors: boolean;
};

const PurchaseSelectionBar = ({
  inviteeIds,
  handleConfirmPurchase,
  handleSuccessClose,
  chapterSummaries,
  purchaseChapters,
  contributorsCount = NaN,
  unitBalance,
  event,
  deselectAllForPurchase,
  newContributors,
  showNewContributors,
}: Props) => {
  const { t } = useTranslation();
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState<boolean>(false);

  const handlePurchaseClick = () => setOpenPurchaseDialog(true);

  const [firstModalVisible, setFirstModalVisible] = useState<boolean>(true);
  const [secondModalVisible, setSecondModalVisible] = useState<boolean>(true);

  const handleConfirmDFC = () => {
    setFirstModalVisible(false);
    setSecondModalVisible(true);
  };

  const handleDecline = () => {
    setFirstModalVisible(false);
    setSecondModalVisible(true);
    deselectAllForPurchase();
  };

  return (
    <>
      <ActionBar
        primaryButtonObj={{
          primaryButtonText: t(
            'ui.event-management.events.purchase-profiles.purchase-button',
          ),
          primaryButtonDisabled:
            event !== 'INSIGHTS_DISCOVERY_FULL_CIRCLE'
              ? inviteeIds.length === 0
              : !secondModalVisible,
          primaryButtonOnClick: handlePurchaseClick,
        }}
      />
      {event === 'INSIGHTS_DISCOVERY_FULL_CIRCLE' ? (
        <DFCPurchaseDialog
          unitBalance={unitBalance}
          open={openPurchaseDialog}
          onClose={() => setOpenPurchaseDialog(false)}
          chapterSummaries={chapterSummaries}
          purchaseChapters={purchaseChapters}
          contributorsCount={contributorsCount}
          handleConfirmPurchase={handleConfirmPurchase}
          firstModalOpen={firstModalVisible}
          OnConfirm={handleConfirmDFC}
          OnDecline={handleDecline}
          newContributors={newContributors}
          showNewContributors={showNewContributors}
        />
      ) : (
        <PurchaseDialog
          unitBalance={unitBalance}
          open={openPurchaseDialog}
          onClose={() => setOpenPurchaseDialog(false)}
          chapterSummaries={chapterSummaries}
          purchaseChapters={purchaseChapters}
          handleConfirmPurchase={handleConfirmPurchase}
          onSuccess={handleSuccessClose}
        />
      )}
    </>
  );
};

export default PurchaseSelectionBar;
