import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { P, RadioGroupControl } from '@insights-ltd/design-library/components';
import React from 'react';
import { SortDirection, SortType } from 'types/types';
import { useTranslation } from 'react-i18next';
import {
  useWheelContext,
  WheelState,
} from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { styled } from '@mui/material';
import ToggleFilter from 'components/ToggleFilter/ToggleFilter';
import NamesOptions from './NamesOptions';

const StyledRadioGroupControl = styled(RadioGroupControl)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

type Props = {
  type?: 'wheel' | 'names';
};

const TableControls = ({ type = 'wheel' }: Props) => {
  const { t } = useTranslation();
  const [
    {
      sortType,
      sortDirection,
      namesColumns,
      activeOrganisations,
      teamOrganisations,
      isLearnerCountExceeded,
      isMultiOrg,
    },
    setState,
  ] = useWheelContext();

  const handleSortType = (value: SortType) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      sortType: value,
    }));
  };
  const handleSortDirection = (value: SortDirection) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      sortDirection: value,
    }));
  };
  const handleCheckbox = (payload: Record<string, boolean>) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      namesColumns: {
        ...prevState.namesColumns,
        ...payload,
      },
    }));
  };

  const handleFilterChange = (payload: Record<string, boolean>) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      activeOrganisations: payload,
    }));
  };

  const directionOptions = [
    {
      label: t('ui.event-management.events.team-table.controls.ascending'),
      value: SortDirection.ASCENDING,
    },
    {
      label: t('ui.event-management.events.team-table.controls.descending'),
      value: SortDirection.DESCENDING,
    },
  ];
  let typeOptions = [
    {
      label: t('ui.event-management.events.team-table.controls.number'),
      value: SortType.NUMBER,
    },
    {
      label: t('ui.event-management.events.team-table.controls.name'),
      value: SortType.NAME,
    },
    {
      label: t('ui.event-management.events.team-table.controls.wheel-position'),
      value: SortType.WHEEL_POSITION,
    },
    {
      label: t(
        'ui.event-management.events.team-table.controls.colour-order-preference',
      ),
      value: SortType.COLOUR_PREF,
    },
  ];

  const showOrganisationToggleFilter =
    isMultiOrg && !isLearnerCountExceeded && teamOrganisations;

  if (isMultiOrg && typeOptions.length < 5) {
    typeOptions = [
      ...typeOptions,
      {
        label: t('ui.event-management.events.team-table.controls.organisation'),
        value: SortType.ORGANISATION,
      },
    ];
  }

  return (
    <Box pr={2}>
      <Grid container spacing={3}>
        {showOrganisationToggleFilter && (
          <Grid item xs={12}>
            <ToggleFilter
              title={t(
                'ui.event-management.events.team-table.controls.toggle-organisations',
              )}
              items={teamOrganisations}
              onChange={handleFilterChange}
              value={activeOrganisations ?? {}}
            />
          </Grid>
        )}
        {type === 'names' && (
          <Grid item xs={12}>
            <NamesOptions
              showNumbers={namesColumns.showNumbers}
              onToggle={(payload) => {
                handleCheckbox(payload);
              }}
            />
          </Grid>
        )}
        <Grid
          sx={(theme) => ({
            '& label > span:nth-of-type(2)': {
              fontWeight: `${theme.typography.fontWeightBold} !important`,
            },
          })}
          item
          xs={12}
        >
          <P id="direction-sort-label" variant="body-bold">
            {t('ui.event-management.events.team-table.controls.direction')}
          </P>
          <StyledRadioGroupControl
            name="direction-sort-selector"
            aria-labelledby="direction-sort-label"
            onChange={(value) => handleSortDirection(value as SortDirection)}
            options={directionOptions}
            value={sortDirection}
            title="direction-sort"
          />
        </Grid>
        <Grid
          sx={(theme) => ({
            '& label > span:nth-of-type(2)': {
              fontWeight: `${theme.typography.fontWeightBold} !important`,
            },
          })}
          item
          xs={12}
        >
          <P id="name-sort-label" variant="body-bold">
            {t('ui.event-management.events.team-table.controls.type')}
          </P>
          <StyledRadioGroupControl
            name="name-sort-selector"
            aria-labelledby="name-sort-label"
            onChange={(value) => handleSortType(value as SortType)}
            options={typeOptions}
            value={sortType}
            title="name-sort"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableControls;
