import { _delete, get, post, put } from '@insights-ltd/design-library/utils';
import {
  RequestErrorBody,
  TeamColourCountsByOrganisationResponse,
  TeamLearnerResponse,
  TeamResponse,
  WheelPositionSummaryByOrganisationResponse,
  WheelPositionSummaryResponse,
} from 'api';
import { API_ROOT, NoResponse } from 'mocks/handlers/common';
import { ScoresResponse } from 'api/events/eventApiTypes';

export const teams = {
  addAll: (teamId: string) => post(`${API_ROOT}/teams/${teamId}/learners`),
  removeAll: (teamId: string) =>
    _delete(`${API_ROOT}/teams/${teamId}/learners`),
  get: (teamId: string) =>
    get<TeamResponse | undefined>(`${API_ROOT}/teams/${teamId}`),
  all: get<{ teams: TeamResponse[] } | NoResponse>(`${API_ROOT}/teams`),
  updateSingleOrg: (teamId: string, organisationId: string) =>
    put<undefined | RequestErrorBody | TeamResponse>(
      `${API_ROOT}/organisations/${organisationId}/teams/${teamId}`,
    ),
  updateMultiOrg: (teamId: string) =>
    put<undefined | RequestErrorBody>(`${API_ROOT}/teams/${teamId}`),
  myTeams: get<{ teams: TeamResponse[] }>(`${API_ROOT}/practitioners/me/teams`),
  myOrgTeams: (organisationId: string) =>
    get<{ teams: TeamResponse[] }>(
      `${API_ROOT}/practitioners/me/organisations/${organisationId}/teams`,
    ),
  delete: (teamId: string) => _delete<undefined>(`${API_ROOT}/teams/${teamId}`),
  learners: {
    get: (teamId: string) =>
      get<{ learners: TeamLearnerResponse[]; limitExceeded: boolean }>(
        `${API_ROOT}/teams/${teamId}/learners`,
      ),
    addToTeam: (teamId: string, learnerId: string) =>
      post<undefined>(`${API_ROOT}/teams/${teamId}/learners/${learnerId}`),
    removeFromTeam: (teamId: string, learnerId: string) =>
      _delete<undefined>(`${API_ROOT}/teams/${teamId}/learners/${learnerId}`),
    getProfiles: (teamId: string) =>
      post(`${API_ROOT}/teams/${teamId}/profiles`),
    shareProfiles: (teamId: string) =>
      post(`${API_ROOT}/teams/${teamId}/profiles/emails`),
    downloadTeamProfiles: (teamId: string) =>
      post(`${API_ROOT}/teams/${teamId}/profiles/email/me`),
    scores: {
      all: (teamId: string) =>
        get<ScoresResponse>(`${API_ROOT}/teams/${teamId}/wheel`),
      getTeamScoreSummary: (teamId?: string) =>
        get<WheelPositionSummaryResponse>(
          `${API_ROOT}/teams/${teamId}/wheel/summary`,
        ),
      getTeamScoreSummaryByOrganisations: (teamId?: string) =>
        get<WheelPositionSummaryByOrganisationResponse>(
          `${API_ROOT}/teams/${teamId}/wheel/summary-by-organisation`,
        ),

      getTeamColourCountsByOrganisations: (teamId?: string) =>
        get<TeamColourCountsByOrganisationResponse>(
          `${API_ROOT}/teams/${teamId}/wheel/colour-counts-by-organisation`,
        ),
    },
  },
};
