import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import BarChartIcon from '@mui/icons-material/BarChart';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import SelectDialectControl from 'components/SelectDialectControl';
import { P } from '@insights-ltd/design-library/components';
import arePronounsSupportedByDialect from 'utils/arePronounsSupportedByDialect';
import { ProfileResponse } from 'api';
import {
  DIALECT_CODES,
  ENABLED_EVALUATOR_DIALECTS,
  getDialect,
} from 'variables';
import { AllDialectOptions, Dialects } from 'types/dialects';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import Notification from 'components/Notification';
import { styled } from '@mui/material';
import ProfileCard from './ProfileCard';
import PurchaseCard from './PurchaseCard';

type ProfilesProps = {
  learnerId: string;
  profiles: ProfileResponse[];
  onProfileDownloadError: () => void;
  evaluatorSubmittedOn?: string;
};

export const profileDownloadable = (
  profile: ProfileResponse,
  dialect: AllDialectOptions,
) => {
  if (
    dialect === DIALECT_CODES.EVALUATION_DIALECT &&
    !getDialect(profile.dialect)
  ) {
    return false;
  }
  if (
    dialect === DIALECT_CODES.EVALUATION_DIALECT &&
    getDialect(profile.dialect)?.disabledForProfiles.includes(profile.type)
  ) {
    return false;
  }

  if (
    dialect !== DIALECT_CODES.EVALUATION_DIALECT &&
    !profile.supportedDialects.includes(dialect as Dialects)
  ) {
    return false;
  }

  return arePronounsSupportedByDialect({
    pronoun: profile.pronoun,
    dialect,
  });
};

const StyledIcon = styled(BarChartIcon)(({ theme: th }) => ({
  '& > path': {
    width: '20px',
    height: '20px',
  },

  fill: th.palette.common.black,
  color: th.palette.text.primary,
}));

const Profiles = ({
  profiles,
  learnerId,
  onProfileDownloadError,
  evaluatorSubmittedOn,
}: ProfilesProps) => {
  const { t } = useTranslation();
  const [selectedDialect, setSelectedDialect] = useState<AllDialectOptions>(
    DIALECT_CODES.EVALUATION_DIALECT as Dialects,
  );

  const handleDialectSelection = (selection: AllDialectOptions) => {
    setSelectedDialect(selection);
  };

  const profileCount = profiles.length;
  const disabledProfiles = profiles.filter(
    (profile) => !profileDownloadable(profile, selectedDialect),
  );
  const dialectString =
    selectedDialect === DIALECT_CODES.EVALUATION_DIALECT
      ? undefined
      : ENABLED_EVALUATOR_DIALECTS.find((dl) => dl.value === selectedDialect)
          ?.textKey;

  return (
    <Grid container item spacing={2}>
      <Grid
        item
        xs={12}
        container
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
      >
        <StyledIcon />
        <Box mr={(theme) => theme.spacing(spacingSizeMap.S)} />
        <P variant="body-large">
          {t('ui.event-management.learner.profiles', { profileCount })}
        </P>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Divider sx={(theme) => ({ color: theme.palette.grey[500] })} />
        </Grid>
        {profileCount > 0 ? (
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <P variant="body-large">
                {t('ui.event-management.distribute-profiles.dialect-label')}
              </P>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectDialectControl
                options={ENABLED_EVALUATOR_DIALECTS}
                dialect={selectedDialect}
                setDialect={handleDialectSelection}
                fullWidth
              />
            </Grid>
          </Grid>
        ) : null}
        {disabledProfiles.length > 0 && (
          <Grid item xs={12}>
            <Notification
              message={t(
                'ui.event-management.learner.purchase.download-warning',
              )}
            />
          </Grid>
        )}
        <Grid
          container
          item
          spacing={2}
          sx={() => ({
            marginBottom: '2.5rem',
          })}
        >
          {evaluatorSubmittedOn ? (
            <Grid item xs={12} sm={4} key="purchase">
              <PurchaseCard
                profileExists={profileCount > 0}
                evaluatorCompletedAt={evaluatorSubmittedOn}
              />
            </Grid>
          ) : null}
          {profiles.map((profile) => (
            <Grid item xs={12} sm={4} key={profile.id}>
              <ProfileCard
                learnerId={learnerId}
                profile={profile}
                disabled={!profileDownloadable(profile, selectedDialect)}
                selectedDialect={selectedDialect}
                dialectString={dialectString}
                onProfileDownloadError={onProfileDownloadError}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Profiles;
