import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
  FormLabel,
  InputText,
  P,
} from '@insights-ltd/design-library/components';
import SearchIcon from 'components/Svgs/icons/Search';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';

type ListFilterProps = {
  label: string;
  fieldLabel: string;
  placeholder: string;
  searchTerm: string;
  handleTermChange: (s: string) => void;
};

const StyledForm = styled('form')(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  flexShrink: '0',
  marginLeft: theme.spacing(spacingSizeMap.S),
}));

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexShrink: '0',
  paddingTop: theme.spacing(spacingSizeMap.XS),
  paddingRight: theme.spacing(spacingSizeMap.S),
}));

const ListFilter = ({
  label,
  fieldLabel,
  placeholder,
  searchTerm,
  handleTermChange,
}: ListFilterProps) => {
  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    handleTermChange(e.currentTarget.value);
  };
  const handleSearchClear = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      handleTermChange('');
    }
  };
  return (
    <Grid container alignItems="baseline" justifyContent="space-between">
      <Paper
        sx={(theme) => ({
          flexGrow: `1`,
          margin: `${theme.spacing(spacingSizeMap.M)} 0`,
          padding: `${theme.spacing(spacingSizeMap.M)} ${theme.spacing(
            spacingSizeMap.S,
          )}`,
        })}
      >
        <Grid container alignItems="center">
          <Grid item xs={8} wrap="nowrap" container alignItems="center">
            <StyledForm autoComplete="off" onSubmit={(e) => e.preventDefault()}>
              <FormLabel
                label={
                  <StyledDiv>
                    <P>{label}</P>
                    <P variant="body-bold">
                      &nbsp;
                      {fieldLabel}
                    </P>
                  </StyledDiv>
                }
                htmlFor="search-filter-input"
              />
              <InputText
                icon={<SearchIcon />}
                id="search-filter-input"
                label=""
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleSearchClear}
              />
            </StyledForm>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default ListFilter;
