import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Link as RouterLink,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Helmet } from 'react-helmet';
import { Checkbox, H1, P } from '@insights-ltd/design-library/components';
import { passwordValidationRules } from '@insights-ltd/design-library/utils';
import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import ValidationTags from 'components/ValidationTags';
import Link from '@mui/material/Link';
import { PRIVACY_POLICY_URL } from 'variables';
import { useResetPassword } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type FormData = {
  password: string;
  acceptTerms: boolean;
  acceptAUP: boolean;
};
const AUPCheckboxLabel = (
  <Trans i18nKey="ui.event-management.sign-up.aup">
    <Link
      href="https://www.insights.com/insights-online-acceptable-use-policy/"
      target="_blank"
      rel="noopener"
    >
      ui.event-management.sign-up.aup
    </Link>
  </Trans>
);

const TermsCheckboxLabel = (
  <Trans i18nKey="ui.event-management.sign-up.terms">
    <Link href={PRIVACY_POLICY_URL} target="_blank" rel="noopener">
      ui.event-management.sign-up.terms
    </Link>
  </Trans>
);

const StyledHeader = styled(H1)({
  textAlign: 'center',
});

const Onboard = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { mutate, isPending, isError } = useResetPassword();
  const { t, i18n } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dialect = searchParams.get('dialect');

  useMemo(() => {
    if (dialect && i18n.language !== dialect) {
      i18n.changeLanguage(dialect);
    }
  }, [dialect, i18n]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = ({ password }: FormData) => {
    const token = hash.slice('#token='.length);
    mutate({ password, token }, { onSuccess: () => navigate('/signin') });
  };

  if (!isError) {
    return (
      <>
        <Helmet>
          <title>{t('ui.event-management.title.sign-up')}</title>
        </Helmet>
        <SelfServiceLayout>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card
              sx={(theme) => ({
                maxWidth: '31rem',
                padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
              })}
            >
              <CardContent>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                >
                  <Grid
                    sx={(theme) => ({
                      width: '100%',
                      padding: `${theme.spacing(spacingSizeMap.XS)} 0`,
                    })}
                    item
                    xs={12}
                  >
                    <H1 variant="h2">
                      {t('ui.event-management.sign-up.heading')}
                    </H1>
                  </Grid>
                  <Grid item xs={12}>
                    <P variant="body-bold" color="textSecondary">
                      {t(
                        'ui.event-management.authentication.password.instruction',
                      )}
                    </P>
                    <ValidationTags
                      value={watch('password', '')}
                      errors={errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      sx={(theme) => ({
                        width: '100%',
                        padding: `${theme.spacing(spacingSizeMap.XS)} 0`,
                      })}
                    >
                      <InputLabel
                        htmlFor="password"
                        error={Boolean(errors.password)}
                      >
                        {t(
                          'ui.event-management.reset-password.password.input-label',
                        )}
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        label={t(
                          'ui.event-management.reset-password.password.input-label',
                        )}
                        type={showPassword ? 'text' : 'password'}
                        {...register('password', passwordValidationRules)}
                        error={Boolean(errors.password)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={t(
                                'ui.event-management.authentication.password.visibility',
                              )}
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              size="large"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Checkbox
                      id="accept-terms"
                      name="acceptTerms"
                      label={TermsCheckboxLabel}
                      required
                    />
                  </Grid>
                  <Grid item>
                    <Checkbox
                      id="accept-aup"
                      name="acceptAUP"
                      label={AUPCheckboxLabel}
                      required
                    />
                  </Grid>
                  <Grid item container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isPending}
                      type="submit"
                      size="large"
                    >
                      {t('ui.event-management.sign-up.cta')}
                    </Button>
                  </Grid>
                </Grid>
                <Snackbar open={isError} autoHideDuration={6000}>
                  <Alert elevation={6} variant="filled" severity="error">
                    {t('ui.event-management.reset-password.token.error')}
                  </Alert>
                </Snackbar>
              </CardContent>
            </Card>
          </form>
        </SelfServiceLayout>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.sign-up')}</title>
      </Helmet>
      <SelfServiceLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            sx={(theme) => ({
              maxWidth: '31rem',
              padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
            })}
          >
            <CardContent>
              <Grid container direction="column" justifyContent="space-between">
                <Grid
                  sx={(theme) => ({
                    width: '100%',
                    padding: `${theme.spacing(spacingSizeMap.XS)} 0`,
                  })}
                  item
                  xs={12}
                >
                  <StyledHeader variant="h2">
                    {t('ui.event-management.sign-up.heading')}
                  </StyledHeader>
                </Grid>
                <Alert
                  elevation={1}
                  variant="standard"
                  severity="error"
                  sx={{ fontWeight: 'bold' }}
                >
                  {t(
                    'ui.event-management.reset-password.token.error.link.main.body',
                  )}{' '}
                  <Link component={RouterLink} to="/forgotten-password">
                    {t('ui.event-management.reset-password.token.error.link')}
                  </Link>
                </Alert>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </SelfServiceLayout>
    </>
  );
};

export default Onboard;
