import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, Span } from '@insights-ltd/design-library/components';
import { Box, styled } from '@mui/material';
import Alert from '@mui/material/Alert';

type DFCAgreePurchaseModalProps = {
  open: boolean;
  onConfirm: () => void;
  onDecline: () => void;
};

const DFCAgreePurchaseModal = ({
  open,
  onConfirm,
  onDecline,
}: DFCAgreePurchaseModalProps) => {
  const { t } = useTranslation();

  const StyledBox = styled(Box)(({ theme }) => ({
    color: theme.palette.error.dark,
    fontWeight: theme.typography.fontWeightBold,
  }));

  return (
    <Dialog
      onSubmit={onConfirm}
      onClose={onDecline}
      open={open}
      title={t(
        'ui.event-management.events.purchase-profiles.dfc.pre.purchase.contributor.title',
      )}
      content={
        <Box margin={1} textAlign="left">
          <Box marginBottom={2}>
            <Trans
              i18nKey="ui.event-management.events.purchase-profiles.dfc.pre.purchase.contributor.warning.bold"
              components={{ bold: <Span variant="body-bold" /> }}
            />
            <Box>
              <Alert severity="error" variant="standard">
                <Trans
                  i18nKey="ui.event-management.events.purchase-profiles.dfc.pre.purchase.contributor.remove.link.warning.bold"
                  components={{ bold: <StyledBox /> }}
                />
              </Alert>
            </Box>
          </Box>
        </Box>
      }
      primaryButtonText={t(
        'ui.event-management.events.purchase-profiles.dfc.pre.purchase.contributor.confirm',
      )}
      secondaryButtonText={t(
        'ui.event-management.events.purchase-profiles.dfc.pre.purchase.contributor.decline',
      )}
    />
  );
};

export default DFCAgreePurchaseModal;
