import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import {
  InviteeRequest,
  RequestError,
  useAddInvitee,
  useGetEvent,
  useGetInviteeContributors,
} from 'api';
import {
  Dialog,
  H2,
  P,
  Tag,
  TagColor,
} from '@insights-ltd/design-library/components';
import { dateInPast } from '@insights-ltd/design-library/utils';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import Notification from 'components/Notification';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import { VALID_EMAIL_REGEX } from 'variables';
import { styled } from '@mui/material';
import { combineQueryResults } from 'utils/combineQueryStatus';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { InviteeListOptions } from 'types/types';
import { LearnerContributors } from 'api/organisationGroups/organisationGroupsTypes';
import { readyToPurchase } from 'domain/invitee';
import { isDFCExperience } from 'domain/event';
import {
  awaitingResponseFilter,
  readyToPurchaseFilter,
  createdStatusFilter,
  profileCreatedFilter,
} from 'utils/eventFilters';
import LearnersAndContributorsSummary from './LearnersAndContributorsSummary';

type LearnersAndContributorsPillGroupType = {
  profileGenerated?: string;
  learnerContributors: LearnerContributors[];
  tagColour: TagColor;
  tagText: string;
};

const StyledH2 = styled(H2)(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.M),
  marginBottom: theme.spacing(spacingSizeMap.XS),
}));

const AddLearner = ({ eventId }: { eventId: string }) => {
  const { t } = useTranslation();
  const [
    discoveryProfileRequiredDialogOpen,
    setDiscoveryProfileRequiredDialogOpen,
  ] = useState(false);

  const { mutate, isPending } = useAddInvitee();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset: resetForm,
    setError,
  } = useForm<InviteeRequest>();

  const onSubmit = (formData: InviteeRequest) =>
    mutate(
      { eventId, formData },
      {
        onSuccess: () => resetForm(),
        onError: (e) => {
          if (e instanceof RequestError) {
            if (e.errorCodes.find((code) => code === 'NO_PROFILE_AVAILABLE')) {
              setDiscoveryProfileRequiredDialogOpen(true);
            }
            if (
              e.errorCodes.find(
                (code) => code === 'EMAIL_ADDRESS_ALREADY_TAKEN',
              )
            ) {
              setError('email', {
                message: t(
                  'ui.event-management.events.add-learners-and-contributors.add-learner.error.email-address-taken',
                ),
              });
            }
          }
        },
      },
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledH2 variant="h3">
          {t(
            'ui.event-management.events.add-learners-and-contributors.add-learner',
          )}
        </StyledH2>
      </Grid>
      <Grid item xs={12}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={5}>
              <TextField
                id="fullName"
                label={t(
                  'ui.event-management.events.add-learners.label.full-name',
                )}
                required
                variant="outlined"
                fullWidth
                type="text"
                inputProps={{
                  'aria-label': t(
                    'ui.event-management.events.add-learners.label.full-name',
                  ),
                }}
                error={Boolean(errors.fullName)}
                helperText={
                  errors.fullName &&
                  t('ui.event-management.events.create.error.name-required')
                }
                {...register('fullName', { required: true, min: 1 })}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="email"
                label={t(
                  'ui.event-management.events.add-learners.label.email-address',
                )}
                required
                variant="outlined"
                fullWidth
                type="email"
                inputProps={{
                  'aria-label': t(
                    'ui.event-management.events.add-learners.label.email-address',
                  ),
                }}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                {...register('email', {
                  required: t(
                    'ui.event-management.events.create.error.value-email-required',
                  ),
                  pattern: VALID_EMAIL_REGEX,
                })}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isPending}
                sx={(theme) => ({
                  marginTop: theme.spacing(spacingSizeMap.XS),
                })}
                fullWidth
              >
                {t(
                  'ui.event-management.events.add-learners.button.add-learner',
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={discoveryProfileRequiredDialogOpen}
          title={t(
            'ui.event-management.events.add-learners-and-contributors.add-learner.error-modal.title',
          )}
          content={t(
            'ui.event-management.events.add-learners-and-contributors.add-learner.error-modal.content',
          )}
          secondaryButtonText={t(
            'ui.event-management.events.add-learners-and-contributors.add-learner.error-modal.dismiss',
          )}
          onClose={() => setDiscoveryProfileRequiredDialogOpen(false)}
        />
      </Grid>
    </Grid>
  );
};

const useLearnersAndContributorsData = (eventId: string) =>
  combineQueryResults([
    useGetEvent(eventId),
    useGetInviteeContributors(eventId),
  ]);

function otherLearnerIds(
  learnerContributors: LearnerContributors[],
  learnerId: string,
) {
  return learnerContributors
    .filter(({ learner }) => learner.id !== learnerId)
    .filter(({ learner }) => learner.profileStatus !== 'PURCHASED')
    .map((learnerContributor) => learnerContributor.learner.id);
}

const LearnersAndContributors = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const { data, status } = useLearnersAndContributorsData(eventId!);
  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.events.overview.error-loading-event')}
      />
    );
  }
  const [event, learnerContributors] = data;
  const crudOperationsDisabled: boolean = !dateInPast(
    event.endsAt,
    event.timezone,
  );

  const filteredInvitees = (
    searchText === ''
      ? learnerContributors!
      : learnerContributors!.filter(
          (invitee) =>
            invitee.learner.email
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            invitee.learner.email
              .toLowerCase()
              .includes(searchText.toLowerCase()),
        )
  )
    .map((invitee) => {
      if (
        readyToPurchase(invitee.learner) &&
        (invitee.learner.profileStatus === 'REUSED' ||
          invitee.learner.profileStatus === 'PURCHASED')
      ) {
        return {
          ...invitee,
          profileStatus: 'PROFILE_GENERATED' as InviteeListOptions,
        };
      }
      return invitee;
    })
    .sort();

  const learnerContributorsFilteredData: LearnersAndContributorsPillGroupType[] =
    [
      {
        learnerContributors: learnerContributors.filter(awaitingResponseFilter),
        tagColour: 'orange',
        tagText:
          'ui.event-management.events.add-learners-and-contributors.contributors-status.awaiting-responses',
      },
      {
        learnerContributors: learnerContributors.filter(readyToPurchaseFilter),
        tagColour: 'pink',
        tagText: 'ui.event-management.events.invitee.status.ready-to-purchase',
      },
      {
        learnerContributors: learnerContributors.filter(createdStatusFilter),
        tagColour: 'darkGreen',
        tagText: 'ui.event-management.events.invitee.status.profile-created',
      },
      {
        learnerContributors: filteredInvitees.filter(profileCreatedFilter),
        tagColour: 'darkGreen',
        tagText: 'ui.event-management.events.invitee.status.profile-created',
      },
    ];

  if (!isDFCExperience(event.eventType)) {
    return <Navigate to={`/experiences/${eventId}`} />;
  }

  return (
    <BreadcrumbLayout
      fullWidth
      activeTextKey="ui.event-management.events.summary.manage-learners-and-contributors"
      crumbs={{
        '/': t('ui.event-management.events.nav.home'),
        '/experiences': t('ui.event-management.events.title'),
        [`/experiences/${eventId}`]: event.name,
      }}
      headingKey="ui.event-management.events.add-learners-and-contributors.title"
      titleKey="ui.event-management.title.add-learners-and-contributors"
      titleKeyOptions={{
        eventName: event.name,
      }}
      parentLink={`/experiences/${eventId}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <P color="textSecondary">
            {t(
              'ui.event-management.events.add-learners-and-contributors.sub-title',
            )}
          </P>
        </Grid>
        <Grid item xs={12}>
          <Notification
            message={t(
              'ui.event-management.events.learners.notification.get-dfc-discovery-purchase',
            )}
          />
        </Grid>
        {crudOperationsDisabled && (
          <Grid item xs={12}>
            {event.eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE' &&
            learnerContributors.length < 13 ? (
              <AddLearner eventId={eventId!} />
            ) : (
              t(
                'ui.event-management.events.learners.notification.get-dfc-max-limit-reached',
              )
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider
            sx={(theme) => ({
              marginBottom: theme.spacing(spacingSizeMap.S),
              marginTop: theme.spacing(spacingSizeMap.S),
            })}
          />
        </Grid>
        {learnerContributors.length > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <H2 variant="h3">
                  {t(
                    'ui.event-management.events.add-learners-and-contributors.summary.title',
                    { count: learnerContributors.length },
                  )}
                </H2>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={(theme) => ({
                    padding: `${theme.spacing(
                      spacingSizeMap.XS,
                    )} ${theme.spacing(spacingSizeMap.S)}`,
                    color: theme.palette.grey[100],
                    backgroundColor: theme.palette.background.default,
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.spacing(0.5),
                  })}
                >
                  <Grid item xs={4}>
                    <P variant="body-bold">
                      {t(
                        'ui.event-management.events.add-learners-and-contributors.learner-header.full-name-email-address',
                      )}
                    </P>
                  </Grid>
                  <Grid item xs={8}>
                    <P variant="body-bold">
                      {t(
                        'ui.event-management.events.add-learners-and-contributors.learner-header.contributors',
                      )}
                    </P>
                  </Grid>
                </Grid>
              </Grid>
              {learnerContributorsFilteredData.map((learnerContributorData) => {
                return (
                  learnerContributorData.learnerContributors.length > 0 && (
                    <React.Fragment key={learnerContributorData.tagText}>
                      <Grid item xs={12} key={learnerContributorData.tagColour}>
                        <Tag
                          label={t(learnerContributorData.tagText)}
                          color={learnerContributorData.tagColour}
                        />
                      </Grid>
                      {learnerContributorData.learnerContributors.map(
                        ({ learner, contributors }) => (
                          <Grid item xs={12} key={learner.id}>
                            <LearnersAndContributorsSummary
                              event={event}
                              learner={learner}
                              contributors={contributors}
                              learnerIds={otherLearnerIds(
                                learnerContributors,
                                learner.id,
                              )}
                              crudOperationsDisabled={crudOperationsDisabled}
                              allContributorsRemoved={
                                learner.status === 'READY_TO_PURCHASE' &&
                                contributors.length === 0
                              }
                            />
                          </Grid>
                        ),
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </BreadcrumbLayout>
  );
};

export default LearnersAndContributors;
