import React from 'react';
import Container from '@mui/material/Container';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { H1, P } from '@insights-ltd/design-library/components';
import { Box, Link } from '@mui/material';
import Grid from '@mui/material/Grid';
import Breadcrumbs from 'components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import FullHeight from 'components/FullHeight';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.contact.thanks-page-title')}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.dashboard.home.title'),
              }}
              activeText={t(
                'ui.event-management.contact.thanks-breadcrumb-title',
              )}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <H1 variant="h2">
                {t('ui.event-management.contact.thanks-page-header')}
              </H1>
            </Grid>
          </Grid>
          <Box my={(theme) => theme.spacing(spacingSizeMap.L)} />
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <P color="textSecondary">
              {t('ui.event-management.contact.thanks-page-description')}
            </P>
          </Box>
          <Link component={RouterLink} to="/dashboard">
            {t('ui.event-management.contact.thanks-return-link-text')}
          </Link>
        </Container>
      </FullHeight>
    </>
  );
};

export default Thanks;
