import React from 'react';
import Fab from '@mui/material/Fab';
import { styled, useTheme } from '@mui/material';

type ButtonIcon = {
  icon: React.ReactElement;
  textPresent: boolean;
};

type ButtonIconColors = {
  backgroundColor: string;
  color: string;
};

type FloatingButtonProps = {
  icon: React.ReactElement;
  text?: string;
  onClick: () => void;
};

const StyledIcon = styled('div')(
  ({ backgroundColor, color }: ButtonIconColors) => ({
    backgroundColor,
    color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.5rem',
    width: '2.5rem',
    paddingBottom: '0.25rem',
    marginRight: '0.25rem',
    borderRadius: '1.75rem',
  }),
);

const FloatingButtonIcon = ({ icon, textPresent }: ButtonIcon) => {
  const theme = useTheme();
  const backgroundColor = textPresent
    ? theme.palette.grey[100]
    : theme.palette.blue.main;
  const color = textPresent ? theme.palette.blue.main : theme.palette.grey[100];
  return (
    <StyledIcon
      backgroundColor={backgroundColor}
      color={color}
      data-testid="floating-button-icon"
    >
      {icon}
    </StyledIcon>
  );
};

const FloatingButton = ({ text, icon, onClick }: FloatingButtonProps) => {
  return (
    <div>
      {text ? (
        <Fab
          variant="extended"
          sx={(theme) => ({
            borderRadius: '5rem',
            justifyContent: 'start',
            paddingRight: '0.6rem',
            paddingLeft: icon ? '0.25rem' : '0.6rem',
            color: 'white',
            backgroundColor: theme.palette.blue.main,
            '&:hover': {
              backgroundColor: theme.palette.blue.main,
            },
            width: 'fit-content',
            height: '3rem',
          })}
          onClick={onClick}
        >
          <FloatingButtonIcon icon={icon} textPresent />
          {text}
        </Fab>
      ) : (
        <FloatingButtonIcon icon={icon} textPresent={false} />
      )}
    </div>
  );
};

export default FloatingButton;
