import { Box, Container } from '@mui/material';
import { LearnerPurchaseRequest } from 'api';
import FullHeight from 'components/FullHeight';
import PurchaseProfiles from 'components/PurchaseProfiles';
import { PurchaseType } from 'components/PurchaseProfiles/types';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';

export type PurchasePageProps = {
  title: string;
  indexRoute: React.ReactNode;
  additionalRoutes?: Record<string, React.ReactNode>;
  onPurchase: (
    requestData: LearnerPurchaseRequest,
    onSuccess: () => void,
    onFailure: () => void,
  ) => void;
  onReset: () => void;
  removeCheckedItems?: (items: string[]) => void;
  evaluationIds: string[];
  parentLink: string;
  breadcrumbs?: Record<string, string>;
  error?: unknown | null;
  isError?: boolean;
  organisationIds: string[];
  type?: PurchaseType;
  isPurchasing?: boolean;
  backgroundColor?: 'white' | 'grey';
};

const PurchasePage = ({
  title,
  indexRoute,
  evaluationIds,
  additionalRoutes = {},
  onPurchase,
  parentLink,
  organisationIds,
  onReset,
  type = 'Evaluator',
  isPurchasing = false,
  error = null,
  isError = false,
  breadcrumbs = {},
  backgroundColor = 'white',
  removeCheckedItems,
}: PurchasePageProps) => {
  const routes =
    Object.entries(additionalRoutes).map(([path, element]) => (
      <Route key={path} path={path} element={element} />
    )) ?? [];

  return (
    <FullHeight backgroundColor={backgroundColor}>
      <Routes>
        <Route index element={indexRoute} />
        <Route
          path="purchase-profiles"
          element={
            <Container
              maxWidth="xl"
              sx={{
                maxWidth: '100%',
                paddingBottom: '5rem',
              }}
            >
              <Container maxWidth="lg">
                <Box py="1.5rem">
                  <Breadcrumbs crumbs={breadcrumbs} activeText={title} />
                </Box>
              </Container>
              <PurchaseProfiles
                evaluationIds={evaluationIds}
                error={error}
                isError={isError}
                isPurchasing={isPurchasing}
                onPurchase={onPurchase}
                parentLink={parentLink}
                organisationIds={organisationIds}
                removeCheckedItems={removeCheckedItems}
                resetMutate={onReset}
                type={type}
              />
            </Container>
          }
        />
        {routes}
      </Routes>
    </FullHeight>
  );
};

export default PurchasePage;
