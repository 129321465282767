import React from 'react';
import Button from '@mui/material/Button';
import MaterialDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library/components';

export type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  body: string;
};

const ErrorDialog = ({ open, onClose, title, body }: Props) => {
  const { t } = useTranslation();

  return (
    <MaterialDialog
      fullWidth
      maxWidth="xs"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <P variant="h4">{title}</P>
          <IconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box height={180}>
          <DialogContentText
            id="dialog-description"
            color="textSecondary"
            variant="body1"
          >
            {body}
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="primary" variant="outlined">
          {t('ui.event-management.learners.teams.error-dialog.confirm')}
        </Button>
      </DialogActions>
    </MaterialDialog>
  );
};

export default ErrorDialog;
