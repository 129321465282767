import {
  GroupResponse,
  OrganisationResponse,
  useGetAllGroups,
  useGetAllOrganisations,
} from 'api';
import { useMemo } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { queryStatus } from 'utils/queryStatus';
import { sortOrgs } from 'utils/mappers/sorting';
import { INSIGHTS_ORGANISATION_ID } from 'variables/constants';
import {
  createGroupOrganisationHashMap,
  GroupOrganisationHashMap,
} from 'utils/createGroupOrganisationMap';
import { QueryStatus } from '@tanstack/react-query';

export type GetAllOrganisationsAndGroupResponse =
  | OrganisationResponse
  | GroupResponse;

export const useGetAllOrganisationsAndGroups = (): {
  data: GetAllOrganisationsAndGroupResponse[];
  status: QueryStatus;
  isPending: boolean;
  orgsCount: number;
  groupsCount: number;
  orgsInGroupsCount: number;
  organisations: OrganisationResponse[];
  groups: GroupResponse[];
  organisationsInGroups: GroupOrganisationHashMap;
  availableOrganisations: OrganisationResponse[];
  refetchData: () => void;
} => {
  const { user } = useAuth();
  const { data: orgData, status: orgsStatus } = useGetAllOrganisations();
  const hasReadOrgGroupAccess =
    user?.permissions.Organisation_Group_ReadAll &&
    user?.permissions.Organisation_Group_ReadAll !== 'None';
  const {
    data: groupsData,
    status: groupsStatus,
    refetch,
  } = useGetAllGroups({
    enabled: hasReadOrgGroupAccess,
  });

  const statuses = [orgsStatus];

  if (hasReadOrgGroupAccess) {
    statuses.push(groupsStatus);
  }

  const status = queryStatus(...statuses);
  const orgs = useMemo(() => orgData ?? [], [orgData]);
  const orgsCount = orgs.length;
  const groups = useMemo(() => groupsData || [], [groupsData]);
  const groupsCount = groups.length;

  const organisationsInGroups: GroupOrganisationHashMap = useMemo(
    () => (groups?.length > 0 ? createGroupOrganisationHashMap(groups) : {}),
    [groups],
  );

  const orgsInGroupsCount = Object.keys(organisationsInGroups).length;

  const data: GetAllOrganisationsAndGroupResponse[] = [
    ...orgs.filter((org) => !organisationsInGroups[org.id]),
    ...groups,
  ].sort(sortOrgs);

  const availableOrganisations: OrganisationResponse[] = useMemo(() => {
    if (Object.keys(organisationsInGroups).length === 0) {
      return orgs;
    }
    return orgs.filter(
      ({ id }) => !organisationsInGroups[id] && id !== INSIGHTS_ORGANISATION_ID,
    );
  }, [orgs, organisationsInGroups]);

  const refetchData = () => {
    refetch();
  };

  const isPending = status === 'pending';

  return {
    data,
    status,
    isPending,
    orgsCount,
    groupsCount,
    orgsInGroupsCount,
    organisations: orgs,
    groups,
    organisationsInGroups,
    availableOrganisations,
    refetchData,
  };
};
