import { useRef, useEffect } from 'react';

const useGetPrevious = <V>(value: V): V | undefined => {
  const ref = useRef<V>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default useGetPrevious;
