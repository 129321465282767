import React from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputText, InputType } from '@insights-ltd/design-library/components';
import {
  LearnerRequest,
  LearnerWithEvaluation,
  RequestError,
  UpdateLearnerResponse,
  useUpdateLearner,
} from 'api';
import FormHeader from 'components/FormHeader';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import { Alert, AlertTitle, Snackbar, styled } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { VALID_EMAIL_REGEX } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type Props = {
  learner: LearnerWithEvaluation;
  organisationId: string;
};

const EMAIL_ERROR = 'EMAIL_ERROR';

const emailAddressTaken = (error: unknown) =>
  error instanceof RequestError &&
  error.errorCodes?.includes('EMAIL_ADDRESS_ALREADY_TAKEN');

const StyledForm = styled('form')(({ theme }) => ({
  '& > * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const EditLearnerForm = ({ learner, organisationId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending,
    isError,
    error,
    reset: resetMutation,
  } = useUpdateLearner(learner.id, queryClient);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<UpdateLearnerResponse>({
    defaultValues: {
      ...learner,
      knownAs: learner.knownAs || '',
      emailAddress: learner.primaryEmail,
    },
  });

  const onSubmit = (formData: LearnerRequest) =>
    mutate(
      { learnerId: learner.id, formData },
      {
        onSuccess: () => navigate(`/learners/${organisationId}/${learner.id}`),
        onError: (err) => {
          if (emailAddressTaken(err)) {
            setError('emailAddress', { type: EMAIL_ERROR });
          }
        },
      },
    );

  const isGeneralError = isError && !emailAddressTaken(error);
  return (
    <>
      <FormHeader
        icon={PersonIcon}
        title={t('ui.event-management.learner.edit.learner-details')}
      />
      <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputText
          id="fullName"
          label={t('ui.event-management.learner.edit.full-name')}
          placeholder={t('ui.event-management.learner.edit.full-name')}
          helperText={
            errors.fullName &&
            t('ui.event-management.learner.edit.error.full-name-required')
          }
          error={Boolean(errors.fullName)}
          required
          fullWidth
          {...register('fullName', { required: true })}
        />
        <InputText
          id="knownAs"
          label={t('ui.event-management.learner.edit.known-as')}
          placeholder={t('ui.event-management.learner.edit.known-as')}
          helperText={
            errors.knownAs &&
            t('ui.event-management.learner.edit.error.known-as-required')
          }
          error={Boolean(errors.knownAs)}
          required
          fullWidth
          {...register('knownAs', { required: true })}
        />
        <InputText
          id="emailAddress"
          label={t('ui.event-management.learner.edit.email-address')}
          placeholder={t('ui.event-management.learner.edit.email-address')}
          type={InputType.EMAIL}
          helperText={
            (errors.emailAddress?.type === EMAIL_ERROR &&
              t(
                'ui.event-management.learner.edit.error.unique-email-address-required',
              )) ||
            (errors.emailAddress?.type === 'pattern' &&
              t(
                'ui.event-management.learner.edit.error.valid-email-address-required',
              )) ||
            (errors.emailAddress?.type === 'required' &&
              t(
                'ui.event-management.learner.edit.error.email-address-required',
              ))
          }
          error={Boolean(errors.emailAddress)}
          required
          fullWidth
          {...register('emailAddress', {
            required: true,
            pattern: VALID_EMAIL_REGEX,
          })}
        />
        <Grid container justifyContent="space-between">
          <div />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            disabled={isPending}
          >
            {t('ui.event-management.learner.edit.save-details')}
          </Button>
        </Grid>
      </StyledForm>
      <Snackbar
        open={isGeneralError}
        autoHideDuration={6000}
        onClose={resetMutation}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={resetMutation}
        >
          <AlertTitle>
            {t('ui.event-management.learner.edit.error.title')}
          </AlertTitle>
          {t('ui.event-management.learner.edit.error.details')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditLearnerForm;
