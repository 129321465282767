import React, { useMemo, useState } from 'react';
import { Dialog, P } from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import { useForm } from 'react-hook-form';
import { User } from 'types/types';
import { OrganisationResponse, useGetPractitionersSummary } from 'api';
import UserAutocomplete from 'components/UserPicker/UserAutocomplete';
import OrganisationAutocomplete from 'components/OrganisationPicker/OrganisationAutocomplete';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { openNewWindow } from './globalMethods';

type Props = {
  open: boolean;
  onClose: () => void;
  organisations: OrganisationResponse[];
  requestingUser: User;
};

type RequestUnitsData = {
  amount: number;
  purchaseOrInvoiceNumber: string;
  comments: string;
  practitioner?: User;
};

const DialogContent = styled('div')(({ theme }) => ({
  '& > div': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
  '& > div:nth-of-type(1)': {
    marginTop: theme.spacing(spacingSizeMap.XS),
  },
}));

const AlertWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(spacingSizeMap.S),
  background: theme.palette.blue.light,
  borderRadius: '4px',
}));

const RequestUnitsDialog = ({
  open,
  onClose,
  organisations,
  requestingUser,
}: Props) => {
  const { t } = useTranslation();

  const isMultiOrg = organisations.length > 1;

  const [selectedOrganisation, setSelectedOrganisation] = useState(
    organisations[0],
  );

  const { data: practitioners, status: getPractitionersStatus } =
    useGetPractitionersSummary(selectedOrganisation?.id, { enabled: open });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<RequestUnitsData>({
    defaultValues: {
      amount: 1,
      purchaseOrInvoiceNumber: '',
      comments: '',
    },
  });

  useMemo(() => {
    register('practitioner', { required: true });
  }, [register]);

  const [amount, purchaseOrInvoiceNumber, comments, practitioner] = watch([
    'amount',
    'purchaseOrInvoiceNumber',
    'comments',
    'practitioner',
  ]);

  const generateEmail = () => {
    openNewWindow(
      `mailto:?subject=${t(
        'ui.event-management.wallet.request-units-dialog.email.subject',
      )}&body=${t(
        'ui.event-management.wallet.request-units-dialog.email.body-no-newline',
        {
          organisation: selectedOrganisation.name,
          requestingName: requestingUser.fullName,
          requestingEmail: requestingUser.emailAddress,
          amount,
          creditToName: practitioner?.fullName,
          creditToEmail: practitioner?.emailAddress,
          purchaseOrInvoiceNumber: encodeURIComponent(purchaseOrInvoiceNumber),
          comments: encodeURIComponent(comments),
          'line-break': '%0D%0A',
        },
      )}`,
      '_blank',
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(generateEmail)}
      isLoading={getPractitionersStatus === 'pending'}
      title={t('ui.event-management.wallet.request-units-dialog.title')}
      content={
        <DialogContent>
          <P variant="body">
            {t('ui.event-management.wallet.request-units-dialog.body')}
          </P>
          <AlertWrapper>
            <P variant="body-bold">
              {t('ui.event-management.wallet.request-units-dialog.alert')}
            </P>
          </AlertWrapper>
          {isMultiOrg ? (
            <OrganisationAutocomplete
              loading={false}
              selectedValue={selectedOrganisation.id}
              onSelect={(organisation) => {
                setSelectedOrganisation(organisation);
                reset({ practitioner: undefined });
              }}
              options={organisations}
            />
          ) : null}

          <UserAutocomplete
            label={t(
              'ui.event-management.wallet.request-units-dialog.select-user.label',
            )}
            placeholder={t(
              'ui.event-management.wallet.request-units-dialog.select-user.placeholder',
            )}
            options={practitioners || []}
            selectedValue={getValues('practitioner')?.id}
            onSelect={(newPractitioner) => {
              setValue('practitioner', newPractitioner, {
                shouldValidate: true,
              });
            }}
            required
            loading={getPractitionersStatus === 'pending'}
            disableClearable
            error={Boolean(errors.practitioner)}
            helperText={
              errors.practitioner &&
              t(
                'ui.event-management.wallet.request-units-dialog.select-user.required',
              )
            }
            isNarrow
          />
          <TextField
            id="request-units-amount"
            required
            variant="outlined"
            type="number"
            fullWidth
            {...register('amount', {
              required: true,
              min: 1,
            })}
            label={t(
              'ui.event-management.wallet.request-units-dialog.units.label',
            )}
            error={Boolean(errors.amount)}
            helperText={
              errors.amount &&
              t(
                'ui.event-management.wallet.request-units-dialog.units.required',
              )
            }
          />
          <TextField
            id="request-units-purchase-or-invoice-number"
            type="text"
            variant="outlined"
            fullWidth
            label={t(
              'ui.event-management.wallet.request-units-dialog.purchase-or-invoice-number.label',
            )}
            placeholder={t(
              'ui.event-management.wallet.request-units-dialog.purchase-or-invoice-number.placeholder',
            )}
            {...register('purchaseOrInvoiceNumber')}
          />
          <TextField
            id="request-units-comments"
            type="text"
            multiline
            variant="outlined"
            fullWidth
            label={t(
              'ui.event-management.wallet.request-units-dialog.comments.label',
            )}
            placeholder={t(
              'ui.event-management.wallet.request-units-dialog.comments.placeholder',
            )}
            {...register('comments')}
          />
        </DialogContent>
      }
      primaryButtonText={t(
        'ui.event-management.wallet.request-units-dialog.generate-email',
      )}
    />
  );
};

export default RequestUnitsDialog;
