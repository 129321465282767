import React, { useCallback, useMemo, useState } from 'react';
import { ScoreType } from 'types/types';
import { useDotPositionOverrides } from 'pages/DownloadTeamWheel/hooks/useWheelOverrides';
import { CoordPair } from './teamWheelUtils';

export const toSvgCoordinates = (
  { x: clientX, y: clientY }: CoordPair,
  ctm: Pick<DOMMatrix, 'a' | 'd' | 'e' | 'f'>,
): CoordPair => {
  const x = (clientX - ctm.e) / ctm.a;
  const y = (clientY - ctm.f) / ctm.d;

  return { x, y };
};

export const getCoordsOnPointerMove = (
  oldCoords: CoordPair,
  newCoords: CoordPair,
  offsetCoords: CoordPair,
): CoordPair => {
  const x = oldCoords.x + newCoords.x - offsetCoords.x;
  const y = oldCoords.y + newCoords.y - offsetCoords.y;

  return { x, y };
};

const useDraggable = (override: CoordPair) => {
  const [active, setActive] = useState(false);
  const [mouseOffset, setMouseOffset] = useState<CoordPair>({ x: 0, y: 0 });

  const onPointerDown: React.PointerEventHandler<SVGGraphicsElement> = (e) => {
    const el: SVGGraphicsElement = e.currentTarget;
    el.setPointerCapture(e.pointerId);
    setActive(true);

    const newMouseOffset = toSvgCoordinates(
      { x: e.clientX, y: e.clientY },
      el.getScreenCTM()!,
    );

    setMouseOffset(newMouseOffset);
  };

  const onPointerMove = (e: React.PointerEvent<SVGGraphicsElement>) => {
    if (active) {
      const newMouse = toSvgCoordinates(
        { x: e.clientX, y: e.clientY },
        e.currentTarget.getScreenCTM()!,
      );
      return getCoordsOnPointerMove(override, newMouse, mouseOffset);
    }
    return undefined;
  };

  const onPointerUp: React.PointerEventHandler<SVGGraphicsElement> = (e) => {
    if (active) {
      e.currentTarget.releasePointerCapture(e.pointerId);
      setActive(false);
      setMouseOffset({ x: 0, y: 0 });
    }
  };

  return {
    onPointerDown,
    onPointerUp,
    onPointerMove,
  };
};

export const useDraggableTeamWheelDot = (
  initialCoords: CoordPair,
  transformCoords: (c: CoordPair) => CoordPair,
  scoreType: ScoreType,
  dotKey: string,
) => {
  const { setDotPositionOverride, getDotPositionOverride } =
    useDotPositionOverrides(scoreType);
  const override = useMemo(
    () => getDotPositionOverride(dotKey) || initialCoords,
    [dotKey, getDotPositionOverride, initialCoords],
  );
  const { onPointerDown, onPointerUp, onPointerMove } = useDraggable(override);
  const handlePointerMove = useCallback(
    (e: React.PointerEvent<SVGGraphicsElement>) => {
      const coords = onPointerMove(e);
      if (coords) {
        setDotPositionOverride(dotKey, transformCoords(coords));
      }
    },
    [dotKey, onPointerMove, setDotPositionOverride, transformCoords],
  );
  return {
    transform: override,
    onPointerMove: handlePointerMove,
    onPointerDown,
    onPointerUp,
  };
};
