import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import { useTranslation } from 'react-i18next';
import AuthenticationProvider from 'components/Authentication';
import AppRoutes from 'Routes';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { BetaWidget } from '@insights-ltd/design-library/beta';
import {
  BetaProvider,
  EnvironmentIdentifier,
} from '@insights-ltd/design-library/components';
import { browserLang } from '@insights-ltd/design-library/utils';
import { LocalizationProvider } from '@insights-ltd/design-library/providers';
import { getMuiTheme } from '@insights-ltd/design-library/themes';
import { DATADOG_RUM_CONFIGS } from 'variables';
import useGetEnvProps from 'components/hooks/useGetEnvProps';
import RequestErrorModalProvider from 'components/RequestErrorDialog/RequestErrorProvider';
import { SearchProvider } from 'components/SearchFilter/SearchProvider';
import { Settings } from 'luxon';
import { BETA_DEFAULT, getBetaAvailable } from './features';

Settings.defaultZone = 'UTC';

const theme = getMuiTheme();
const betaAvailable = getBetaAvailable();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 0 /* currently set to 0 until react-query mutations are implemented on the create event journey */,
      refetchOnWindowFocus: false,
    },
  },
});

const ErrorFallback = () => {
  const { t } = useTranslation();
  return <FullScreenError message={t('ui.event-management.generic.error')} />;
};

const MetaTagProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t('ui.event-management.app.meta.description')}
        />
      </Helmet>
      {children}
    </>
  );
};

type ErrorReporter = (error: Error, info: React.ErrorInfo) => void;
// eslint-disable-next-line no-console
let errorReporter: ErrorReporter = (error, info) => console.error(error, info);

if (import.meta.env.PROD) {
  const hostConfig = DATADOG_RUM_CONFIGS.find(
    (config) => config.hostname === window.location.host,
  );

  if (hostConfig) {
    errorReporter = (error, info) => datadogRum.addError(error, info);
  }
}

export const AppProviders = ({
  children,
  queryClient: qC,
}: {
  children: React.ReactNode;
  queryClient: QueryClient;
}) => {
  return (
    <QueryClientProvider client={qC}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider adapterLocale={browserLang()}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={errorReporter}
            >
              <RequestErrorModalProvider>
                <MetaTagProvider>
                  {import.meta.env.MODE !== 'test' && betaAvailable && (
                    <BetaWidget />
                  )}
                  {children}
                </MetaTagProvider>
              </RequestErrorModalProvider>
            </ErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

const App = () => {
  const envProps = useGetEnvProps();
  const { t } = useTranslation();
  return (
    <Suspense
      fallback={
        <FullScreenSpinner message={t('ui.event-management.loading')} />
      }
    >
      <BetaProvider betaDefault={BETA_DEFAULT} enabled={getBetaAvailable()}>
        <AppProviders queryClient={queryClient}>
          <AuthenticationProvider>
            <BrowserRouter basename={import.meta.env.BASE_URL}>
              <SearchProvider>
                <EnvironmentIdentifier
                  color={envProps?.color}
                  text={envProps.text}
                  hidden={!envProps.visible}
                />
                <AppRoutes />
              </SearchProvider>
            </BrowserRouter>
          </AuthenticationProvider>
        </AppProviders>
      </BetaProvider>
    </Suspense>
  );
};

export default App;
