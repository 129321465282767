import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Dialog,
  Span,
  CheckboxV2,
  P,
} from '@insights-ltd/design-library/components';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

export type Props = {
  open: boolean;
  onCancel: () => void;
  onHandleRemoveProfileContributors: () => void;
  isPending: boolean;
};

const StyledDiv = styled('div')({
  display: 'flex',
  gap: '1rem',
});

const EditContributorsDialogConfirm = ({
  open,
  onCancel,
  onHandleRemoveProfileContributors,
  isPending,
}: Props) => {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const resetStates = () => {
    setConfirmDelete(false);
  };

  useEffect(() => {
    if (open === false) {
      resetStates();
    }
  }, [open]);

  return (
    <Dialog
      onSubmit={onHandleRemoveProfileContributors}
      onClose={() => {
        onCancel();
        resetStates();
      }}
      open={open}
      content={
        <Box>
          <Trans
            i18nKey="ui.event-management.learners.profile.dfc.edite-contributors.modal-confirm.content-1"
            components={{ bold: <Span variant="body-bold" /> }}
          />
          <br /> <br />
          <div>
            {t(
              'ui.event-management.learners.profile.dfc.edite-contributors.modal-confirm.content-2',
            )}
          </div>
          <br />
          <StyledDiv>
            <CheckboxV2 checked={confirmDelete} onChange={setConfirmDelete} />
            <P variant="body-bold" color="error">
              {t(
                'ui.event-management.learners.profile.dfc.edite-contributors.modal-confirm.checkbox-content',
              )}
            </P>
          </StyledDiv>
        </Box>
      }
      variant="delete"
      primaryButtonText={t(
        'ui.event-management.learners.profile.dfc.edite-contributors.modal-confirm.button.confirm',
      )}
      disablePrimaryButton={!confirmDelete}
      secondaryButtonText={t(
        'ui.event-management.learners.profile.dfc.edite-contributors.modal-confirm.button.cancel',
      )}
      title={t(
        'ui.event-management.learners.profile.dfc.edite-contributors.modal-confirm.title',
      )}
      isLoading={isPending}
    />
  );
};

export default EditContributorsDialogConfirm;
